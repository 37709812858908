import React, { memo } from "react";
import {
  Image,
  ScrollView,
  Text,
  View,
} from "react-native";
import fields from "../../../Utility/Fields";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
// import * as WebBrowser from 'expo-web-browser';
import { walkThrough } from "../../../Zoho/Tasks";

const Note = ({
  task,
  propertyConditionNotes,
  renderBox,
}) => {

  return (
    <View>
      {task[fields.appStageName] === "Property Walk-Through" && (
        <View style={{ marginTop: 16 }}>
          {walkThrough({
            "Property Condition Notes": propertyConditionNotes,
          }).map((item, index) => {
            return renderBox(item, index);
          })}
        </View>
      )}
    </View>
  );
};

export default memo(Note);