import "chart.js/auto";
import moment from "moment";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import React, { memo, useRef, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

import { MAIN_COLORS } from "../../../../../Utility/Colors";
import {
  formatDollar,
  externalTooltipHandlerNew,
  formatDollarUnit,
} from "../../../../../Utility/common";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../../../Utility/Fonts/font";

ChartJS.register(ArcElement, Tooltip, Legend);

const Strategy = ({ result, idxSkipped, onSelect, purchaseSelected }) => {
  const chartRef = useRef(null);

  const { purchasedProperties, scenarioSelected } = useSelector(
    (state) => state.PORTFOLIO_ROADMAP
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 6,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    aspectRatio: 1,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: { label: (value) => formatDollar(value.raw.y) },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { top: 6, right: 10, bottom: 6, left: 10 },
            "46px"
          ),
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          stepSize: 1,
          displayFormats: { year: "yyyy" },
        },
        grid: { display: false },
        border: { color: "#34393D" },
        ticks: {
          autoSkip: false,
          stepSize: 1,
          maxRotation: 90,
          minRotation: 90,
          color: MAIN_COLORS.G600,
          callback: function (value, index) {
            const isJanuary = moment(value).format("MM") === "01";
            if (isJanuary || index === 0) return moment(value).format("YYYY");
          },
        },
      },
      y: {
        border: { dash: [3, 3] },
        grid: { drawTicks: false, color: "#34393D" },
        beginAtZero: true,
        ticks: {
          max: 10,
          color: MAIN_COLORS.G600,
          font: { family: MAIN_FONT.regular },
          padding: 12,
          major: { enable: true },
          maxTicksLimit: 7,
          stepSize: 0.25,
          callback: (value) => formatDollarUnit(value),
        },
      },
    },
  };

  const skipped = (ctx, value) =>
    ctx.p0DataIndex >= idxSkipped ? value : [6, 0];

  const handleClick = (event) => {
    const chart = chartRef.current;
    if (chart) {
      const points = chart.getElementsAtEventForMode(
        event.nativeEvent,
        "nearest",
        { intersect: true },
        true
      );
      if (!!points.length) {
        const firstPoint = points[0];
        const value =
          chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

        const dateValue = moment(value.date).format("MM/YYYY");

        const itemPurchase = purchasedProperties?.find(
          (item) =>
            moment(Number(item["Purchase date "])).format("MM/YYYY") ===
            dateValue
        );
        const itemPurchaseTarget = scenarioSelected?.purchaseTargets?.find(
          (item) => {
            const [month, day, year] = item.purchaseDate.split("/");

            return `${month}/${year}` === dateValue;
          }
        );

        if (itemPurchase) {
          const isHightLighted = itemPurchase.id === purchaseSelected?.id;
          setTimeout(() => {
            onSelect(
              isHightLighted
                ? null
                : { type: "purchase-details", data: itemPurchase }
            );
          }, 100);
        }

        if (itemPurchaseTarget) {
          const isHightLighted = itemPurchaseTarget.id === purchaseSelected?.id;
          onSelect(
            isHightLighted
              ? null
              : {
                  type: "purchase-target-details",
                  data: itemPurchaseTarget,
                }
          );
        }
      }
    }
  };

  const mappingData = result?.map((item, index) => {
    const { value, date, isBought } = item;

    return {
      x:
        isBought || index === result.length - 1
          ? moment(date).format("YYYY-MM")
          : undefined,
      y: value,
      date,
      isBought,
    };
  });

  const data = {
    datasets: [
      {
        data: mappingData,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.15)",
            "rgba(250, 191, 1, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            data,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderWidth: 2,
        pointBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderColor: MAIN_COLORS.BACKGROUND_WHITE,
        pointRadius: ({ index }) => {
          if (mappingData?.[index]?.isBought) return 6;
          return 0;
        },
        pointHoverRadius: 5,
        lineTension: 0.5,
        borderWidth: 2,
        fill: true,
        hoverBorderWidth: 2,
        segment: { borderDash: (ctx) => skipped(ctx, [6, 6]) },
        spanGaps: true,
      },
    ],
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (purchaseSelected !== null) {
      const { data, type } = purchaseSelected;
      if (type === "purchase-details") return;
      const [month, day, year] = data.purchaseDate.split("/");
      const purchaseDate = `${month}/${year}`;

      const pointIndex = mappingData.findIndex(
        (item) =>
          moment(Number(item.date)).format("MM/YYYY") === purchaseDate &&
          item.isBought
      );

      if (pointIndex !== -1) {
        const meta = chart.getDatasetMeta(0);
        const point = meta.data[pointIndex];
        if (point) {
          chart?.tooltip?.setActiveElements(
            [{ datasetIndex: 0, index: pointIndex }],
            { x: point.x, y: point.y }
          );
          chart?.update();
        }
      }
    } else {
      chart?.tooltip?.setActiveElements([]);
      chart?.update();
    }
  }, [purchaseSelected, result]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Value</Text>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 6 }}>
          <Text style={styles.subTitle}>Growth of purchased properties:</Text>
          <Text style={styles.subValue}>
            {formatDollar(Math.round(result?.[idxSkipped - 1]?.value))}
          </Text>
        </View>
      </View>
      <View style={styles.chart}>
        <Line
          options={options}
          data={data}
          ref={chartRef}
          onClick={handleClick}
          plugins={[
            {
              afterDraw: function (chart) {
                if (
                  chart?.tooltip?._active &&
                  chart?.tooltip?._active?.length
                ) {
                  const activePoint = chart.tooltip._active[0];
                  const ctx = chart.ctx;
                  const x = activePoint.element.x;
                  const topY = chart.scales.y.chart.tooltip.caretY + 10;
                  const bottomY = chart.scales.y.bottom;

                  ctx.save();
                  ctx.beginPath();
                  ctx.moveTo(x, topY);
                  ctx.lineTo(x, bottomY);
                  ctx.lineWidth = 2;
                  ctx.strokeStyle = MAIN_COLORS.BACKGROUND_WHITE;
                  ctx.setLineDash([3, 3]);
                  ctx.stroke();
                  ctx.restore();
                }
              },
            },
          ]}
        />
      </View>
      <View style={styles.footer}>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 12 }}>
          <View style={styles.footerDot} />
          <Text style={styles.footerTitle}>Typical purchase</Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 12 }}>
          <View
            style={[styles.footerDot, { borderColor: MAIN_COLORS.GREEN }]}
          />
          <Text style={styles.footerTitle}>Dual Occupancy</Text>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center", gap: 12 }}>
          <View
            style={[styles.footerDot, { borderColor: MAIN_COLORS.PURPLE }]}
          />
          <Text style={styles.footerTitle}>SMSF purchase</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "#ADB9C74D",
    minHeight: 385,
    minWidth: 812,
    padding: 16,
    flex: 385 / 301,
  },

  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 21,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  subTitle: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: FONT_WEIGHT.medium,
    fontSize: 12,
    lineHeight: 16,
    color: MAIN_COLORS.G700,
  },

  subValue: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 18,
    color: MAIN_COLORS.PRIMARY_COLOR,
  },

  chart: {
    flex: 1,
    marginVertical: 24,
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  footerDot: {
    width: 14,
    height: 14,
    borderWidth: 2,
    borderRadius: "50%",
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    borderColor: MAIN_COLORS.BACKGROUND_WHITE,
  },

  footerTitle: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.small,
    lineHeight: 16,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
});

export default memo(Strategy);
