import { StyleSheet } from "react-native";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    marginTop: 32,
    display: "flex",
    flexDirection: "row",
    gap: 20,
    zIndex: -1,
    position: "absolute",
    padding: 25,
    top: 80,
    bottom: 0,
    left: 0,
    right: 0,
    flex: 1,
  },
  title: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.large,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
  listPurchase: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: 21,
  },
  blurView: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
  },
});
