import { StyleSheet } from "react-native";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../../Utility/Colors";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderWidth: 1,
    borderColor: "#4B555D99",
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    width: "40%",
  },
  title: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLORS.TEXT_LIGHT,
    lineHeight: 21,
    marginBottom: 6,
  },
  currentValue: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.PRIMARY_COLOR,
    lineHeight: 21,
  },

  currentName: {
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.small,
    lineHeight: 18,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.G600,
  },
});
