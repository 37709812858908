import React from "react";
import { View } from "react-native";
import FinancePlanner from "./FinancePlanner";
import Profile from "./Profile";
import Target from "./Target";
import { useMediaQuery } from "react-responsive";
import { usePermission } from "../../../hooks/usePermission";

const Header = ({ listPurchases }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const { isView } = usePermission("financePlanner");
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        gap: isTabletOrMobile ? 16 : 19,
        alignItems: isTabletOrMobile ? "flex-start" : "center",
        position: "unset",
        zIndex:1
      }}
    >
      {isView && <FinancePlanner listPurchases={listPurchases} />}
      <Profile listPurchases={listPurchases} />
    </View>
  );
};

export default Header;
