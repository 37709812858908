import React, { useState } from "react";
import { PropagateLoader } from "react-spinners";
import { Controller, useForm } from "react-hook-form";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
} from "react-native";

//Functions
import { MAIN_COLORS } from "../../Utility/Colors";
import { searchProperty } from "../../Clickup/api";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Utility/Fonts/font";

//Components
import Button from "../../components/Button";
import DetailProperty from "./DetailProperty";
import TextInput from "../../components/TextInput";

function SearchProperty({ goBack, setIsLoading }) {
  const [loading, setLoading] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);
  const [activeProperty, setActiveProperty] = useState(null);
  const [valueSearchFinal, setValueSearchFinal] = useState("");
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalNoti, setOpenModalNoti] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues: { valueSearch: "" } });

  const handleClearResultSearch = () => {
    setValueSearchFinal("");
    setResultSearch([]);
    reset();
  };

  const handleSelectProperty = async (item) => {
    setActiveProperty(item.propertyId);
    setOpenModalDetail(true);
  };

  const handleHideModalDetail = () => {
    setOpenModalNoti(true);
  };

  const handleSearch = async ({ valueSearch }) => {
    try {
      setLoading(true);
      const results = await searchProperty(valueSearch);
      setResultSearch(results.data);
      setValueSearchFinal(valueSearch);
    } catch (error) {
      setResultSearch([]);
      setValueSearchFinal("");
      return error.response;
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <View style={styles.inner}>
        <Text
          style={{
            fontSize: 20,
            fontWeight: FONT_WEIGHT.bold,
            color: MAIN_COLORS.TEXT_LIGHT,
            fontFamily: MAIN_FONT.semi,
          }}>
          Add Property
        </Text>
        <View style={styles.boxSearch}>
          <Controller
            name="valueSearch"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                value={value}
                placeholder="Search Property"
                styleInput={styles.inputSearch}
                stylePrefix={styles.prefixIconSearch}
                suffixIcon={require("../../assets/home/arrows.svg")}
                prefixIcon={require("../../assets/portfolio-roadmap/search-normal.png")}
                onChangeText={onChange}
                stylePrefixIcon={{}}
              />
            )}
          />
          <TouchableOpacity
            disabled={!isDirty}
            onPress={handleSubmit(handleSearch)}
            style={styles.btnSearch}>
            <Text style={styles.textSearch}>Search</Text>
          </TouchableOpacity>
        </View>

        {valueSearchFinal && (
          <View style={styles.boxTextResult}>
            <Text style={styles.textValueResult}>
              Search results of "{valueSearchFinal}"
            </Text>
            <TouchableOpacity onPress={handleClearResultSearch}>
              <Text style={styles.btnClearResult}>Clear All</Text>
            </TouchableOpacity>
          </View>
        )}
        <View
          style={{
            borderColor: MAIN_COLORS.BORDER_COLOR,
            borderWidth: 1,
          }}
        />
        {!loading &&
          valueSearchFinal &&
          (!!resultSearch.length ? (
            <ScrollView showsVerticalScrollIndicator={false}>
              {resultSearch.map((item) => {
                return (
                  <Button
                    key={item.propertyId}
                    text={item.address.fullAddress}
                    textStyle={styles.textItemProperty}
                    iconStyle={{ width: 20, height: 20 }}
                    leftIcon={require("../../assets/location.png")}
                    style={[
                      styles.itemProperty,
                      activeProperty === item.propertyId &&
                        styles.itemPropertyActive,
                    ]}
                    onPress={() => handleSelectProperty(item)}
                  />
                );
              })}
            </ScrollView>
          ) : (
            <Text
              style={{
                textAlign: "center",
                fontSize: 20,
                color: MAIN_COLORS.TEXT_LIGHT,
              }}>
              No Results Found
            </Text>
          ))}

        {openModalDetail && (
          <DetailProperty
            setIsLoading={setIsLoading}
            setOpenModalNoti={setOpenModalNoti}
            setOpenModalDetail={setOpenModalDetail}
            setActiveProperty={setActiveProperty}
            goBack={goBack}
            open={openModalDetail}
            openNoti={openModalNoti}
            propertyId={activeProperty}
            onHide={handleHideModalDetail}
          />
        )}
      </View>
      {loading && (
        <View style={{ height: 300 }}>
          <PropagateLoader
            color={MAIN_COLORS.PRIMARY_COLOR}
            style={{ margin: "auto" }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  inner: {
    borderRadius: 12,
    padding: 18,
    width: "70%",
    shadowColor: "#00000014",
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 15,
    marginHorizontal: "auto",
    marginTop: 27,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    display: "flex",
    gap: 22,
    maxHeight: 740,
  },
  boxSearch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 41,
    gap: 16,
  },
  inputSearch: {
    borderRadius: 5,
    outline: "unset",
    marginLeft: 0,
    paddingRight: 36,
    paddingLeft: 48,
    height: "100%",
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: MAIN_COLORS.BORDER_COLOR,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  prefixIconSearch: {
    borderRightWidth: 0,
    borderRightColor: "unset",
    backgroundColor: "transparent",
    paddingLeft: 16,
    width: 20,
    height: 20,
  },
  boxTextResult: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textValueResult: {
    fontSize: FONT_SIZE.large,
    color: MAIN_COLORS.G700,
    fontWeight: FONT_WEIGHT.bold,
    fontFamily: MAIN_FONT.semi,
  },
  btnClearResult: {
    color: MAIN_COLORS.TEXT_RED,
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.large,
    fontFamily: MAIN_FONT.regular,
  },
  itemProperty: {
    display: "flex",
    flexDirection: "row",
    gap: 18,
    borderWidth: 0,
    paddingHorizontal: 18,
    paddingVertical: 14,
    borderRadius: 0,
    height: 52,
  },
  textItemProperty: {
    flex: 1,
    fontSize: FONT_SIZE.medium,
    color: MAIN_COLORS.G600,
    lineHeight: 21,
    fontWeight: FONT_WEIGHT.medium,
  },
  itemPropertyActive: {
    borderWidth: 1,
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
    borderRadius: 5,
  },
  btnSearch: {
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 6,
    borderWidth: 1.5,
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
  },
  textSearch: {
    color: MAIN_COLORS.PRIMARY_COLOR,
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    height: "100%",
    fontFamily: MAIN_FONT.semi,
  },
});

export default SearchProperty;
