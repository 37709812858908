export const COLORS = {
    MAIN_COLORS: "MAIN_COLORS"
}

export const USER = {
    SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
    SET_USER_TOKEN: "SET_USER_TOKEN",
    SET_USER_INFO: "SET_USER_INFO",
    SET_SPPLIER_LIST: "SET_SPPLIER_LIST",
    SET_ACTIVE_SUPPLIER: "SET_ACTIVE_SUPPLIER",
    SET_ACTIVE_SUPPLIER_INFO: "SET_ACTIVE_SUPPLIER_INFO",
    SET_USER_UPDATE_INFO: "SET_USER_UPDATE_INFO",
    SET_USER_UPDATE_ADDRESS_INFO: "SET_USER_UPDATE_ADDRESS_INFO",
    SET_USER_UPDATE_EMAIL: "SET_USER_UPDATE_EMAIL",
}

export const TEMPLATE = {
    SET_TEMPLATE: "SET_TEMPLATE",
}

export const CLIENT = {
    SET_CLIENT: "SET_CLIENT",
}

export const PROPERTIES = {
    SET_NOTIFICATIONS: "SET_PROPERTIES",
}

export const NOTIFICATIONS = {
    SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
    SET_NOTIFICATIONS_OBJECT: "SET_NOTIFICATIONS_OBJECT",
}

export const OPPORTUNITIES = {
    SET_OPPORTUNITIES: "SET_OPPORTUNITIES",
}

export const DETAILS = {
    SET_OPEN: "SET_OPEN",
}

export const SET_TEAM_TIA_DATA = "SET_TEAM_TIA_DATA";