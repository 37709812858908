import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import React, { memo, useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TouchableOpacity,
} from "react-native";

//Functions
import { MAIN_COLORS } from "../../../../Utility/Colors";
import { formatDollar } from "../../../../Utility/common";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../../Utility/Fonts/font";
import { setListPurchaseTarget } from "../../../../Reducer/portfolio-roadmap";
import { calcBuyingStructure } from "../../../../Utility/PortfolioRoadmapUtility/portfolio-roadmap";
import {
  setLoading,
  setScenarioSelected,
} from "../../../../Reducer/portfolio-roadmap";
import {
  retirementTable,
  calcGrossCFValue,
  calcPortfolioValue,
} from "../../../../Utility/PortfolioRoadmapUtility/retirement-overview";

//Components
import Slider from "../../../../components/Slider";

var retirementScenarioValue;

const PortfolioResults = ({ result, setLastRetirementOverviewDraff }) => {
  const firstYearPurchase = Number(moment(result?.[0]?.date).format("YYYY"));
  const years = Array.from({ length: 30 }, (_, i) => firstYearPurchase + i + 1);
  const flatListRef = useRef(null);

  const { scenarioSelected, newDataRedux, listPurchaseTarget, loading } =
    useSelector((state) => state.PORTFOLIO_ROADMAP);

  const dispatch = useDispatch();

  const [portfolioValue, setPortfolioValue] = useState(0);
  const [landmarkGrossCF, setLandmarkGrossCF] = useState(0);
  const [grossCashFlow, setGrossCashFlow] = useState(100000);
  const [maxGrossCashFlow, setMaxGrossCashFlow] = useState(0);
  const [landmarkPortfolio, setLandmarkPorfolio] = useState(0);
  const [maxPortfolioValue, setMaxPortfolioValue] = useState(0);
  const [adjustParameters, setAdjustParameters] = useState(null);

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => handleSelectYear(item)}
        style={styles.flatListItem}
      >
        <Text
          style={[
            scenarioSelected?.yearsOfResult === item
              ? styles.yearSelected
              : styles.yearItem,
          ]}
        >
          {item}
        </Text>
      </TouchableOpacity>
    );
  };

  const handleSelectYear = async (item) => {
    try {
      if (item < scenarioSelected?.yearsOfResult) {
        dispatch(
          setScenarioSelected({
            ...scenarioSelected,
            yearsOfResult: item,
            investingTimeLine: item - firstYearPurchase,
            type: "scenarios",
          })
        );
        scrollToIndex(years.indexOf(item));
      } else {
        dispatch(setLoading(true));
        scrollToIndex(years.indexOf(item));
        const newYearItem =
          scenarioSelected?.investingTimeLine -
          (scenarioSelected?.yearsOfResult - item);

        const newData = {
          indexChange: listPurchaseTarget.length - 1,
          years: newYearItem + 1,
          customIncomes: true,
        };
        const dataRedux = scenarioSelected;
        const listPurchase = await calcBuyingStructure(
          {
            cashSavings: scenarioSelected?.cashSavings,
            availableEquity: scenarioSelected?.availableEquity,
            years: scenarioSelected?.investingTimeLine,
            widgetProperties: scenarioSelected?.widgetProperties,
            customIncomes: scenarioSelected?.customIncomes,
          },
          window?._wiwo,
          newData,
          dataRedux,
          newDataRedux,
          listPurchaseTarget
        );
        setTimeout(() => {
          const payload = {
            ...scenarioSelected,
            yearsOfResult: item,
            investingTimeLine: item - firstYearPurchase,
            purchaseTargets: listPurchase,
          };
          dispatch(setScenarioSelected({ type: "scenarios", ...payload }));
        }, 800);
        dispatch(setListPurchaseTarget(listPurchase));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const scrollToIndex = (index) => {
    flatListRef.current.scrollToIndex({
      animated: true,
      index,
      viewPosition: 0.5,
    });
  };

  const handleChangePortfolioValue = (value) => {
    if (value === adjustParameters?.portfoliovalue) return;
    const data = calcPortfolioValue(retirementScenarioValue, value);
    setPortfolioValue(value);
    setGrossCashFlow(data.grossCashFlow);
    setAdjustParameters(data);
    setLastRetirementOverviewDraff({
      value,
      equityValue: data.equityValue,
      grossCFValue: data.grossCashFlow,
    });
  };

  const handleChangeGrossCF = (value) => {
    if (value === adjustParameters?.grossCashFlow) return;
    const data = calcGrossCFValue(retirementScenarioValue, value);
    setGrossCashFlow(value);
    setAdjustParameters(data);
    setPortfolioValue(data.portfoliovalue);
    setLastRetirementOverviewDraff({
      value: data.portfoliovalue,
      equityValue: data.equityValue,
      grossCFValue: data.grossCashFlow,
    });
  };

  useEffect(() => {
    const yearsOfResult = scenarioSelected?.yearsOfResult;
    if (yearsOfResult && years.length) {
      const idx = years.indexOf(yearsOfResult);
      if (idx === -1) scrollToIndex(0);
      else scrollToIndex(idx);
    }
  }, [result]);

  useEffect(() => {
    const retirementScenarioTable = retirementTable(result, scenarioSelected);
    retirementScenarioValue = retirementScenarioTable?.slice(-1)[0];
    if (retirementScenarioValue) {
      setMaxPortfolioValue(retirementScenarioValue.value);
      const maxGrossCashFlow = calcPortfolioValue(
        retirementScenarioValue,
        retirementScenarioValue.value
      );
      setMaxGrossCashFlow(maxGrossCashFlow.grossCashFlow);
      handleChangePortfolioValue(retirementScenarioValue.value);
    }
  }, [result]);

  useEffect(() => {
    if (retirementScenarioValue) {
      const { value, debtRemainingValue, grossCashFlowPM } =
        retirementScenarioValue;
      const valuePortfolio = value - debtRemainingValue;
      const valueGrossCF = grossCashFlowPM * (1 - debtRemainingValue / value);
      setLandmarkPorfolio(valuePortfolio);
      setLandmarkGrossCF(valueGrossCF);
    }
  }, [retirementScenarioValue]);

  return (
    <View style={styles.container}>
      <View style={styles.rowCenterBetween}>
        <Text style={styles.title}>Portfolio Results for</Text>
        <View style={{ flex: 1 }}>
          <FlatList
            ref={flatListRef}
            horizontal
            data={years}
            renderItem={renderItem}
            keyExtractor={(item) => item.toString()}
            showsHorizontalScrollIndicator={false}
            initialNumToRender={30}
            decelerationRate={0.9}
            style={styles.flatList}
            snapToInterval={100}
            getItemLayout={null}
            onScrollToIndexFailed={() => {}}
          />
        </View>
      </View>
      <View style={styles.sliders}>
        <View style={styles.slider}>
          <Slider
            styleTippy={{ backgroundColor: MAIN_COLORS.PRIMARY_COLOR }}
            styleContainer={{ flex: 1, alignItems: "flex-start" }}
            styleLabelText={{ color: MAIN_COLORS.TEXT_LIGHT }}
            styleSlider={{ marginTop: 10 }}
            styleLabel={{ height: 30 }}
            label="Portfolio Value"
            value={portfolioValue}
            minimumValue={0.001}
            maximumValue={maxPortfolioValue}
            landmark={landmarkPortfolio}
            onChange={handleChangePortfolioValue}
            handleToMark={() => {
              const value =
                retirementScenarioValue?.value -
                retirementScenarioValue?.debtRemainingValue;
              handleChangePortfolioValue(value);
            }}
          />
        </View>
        <View style={styles.slider}>
          <Slider
            styleContainer={{ flex: 1, alignItems: "flex-start" }}
            styleTippy={{ backgroundColor: MAIN_COLORS.GREEN }}
            styleLabelText={{ color: MAIN_COLORS.TEXT_LIGHT }}
            styleSlider={{ marginTop: 10 }}
            styleLabel={{ height: 30 }}
            styleLimit={{ flexDirection: "row-reverse" }}
            reverse={true}
            label="Gross Cash Flow"
            value={grossCashFlow}
            maximumValue={maxGrossCashFlow}
            minimumValue={0.001}
            onChange={handleChangeGrossCF}
            landmark={landmarkGrossCF}
            handleToMark={() => {
              const { grossCashFlowPM, value, debtRemainingValue } =
                retirementScenarioValue;
              const valueGrossCF =
                grossCashFlowPM * (1 - debtRemainingValue / value);
              handleChangeGrossCF(valueGrossCF);
            }}
          />
        </View>
      </View>
      <View style={styles.parameters}>
        <View style={styles.parameter}>
          <View style={styles.parameterItem}>
            <Text style={styles.parameterLabel}>Value reduce by:</Text>
            <Text style={styles.parameterValue}>
              {adjustParameters?.reduceBy?.toFixed(2)}%
            </Text>
          </View>
          <View style={styles.parameterItem}>
            <Text
              style={[
                styles.parameterLabel,
                {
                  height: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              Debt remaining:
            </Text>
            <Text style={styles.parameterValue}>
              {formatDollar(adjustParameters?.debtRemaining?.toFixed(0))}
            </Text>
          </View>
          <View style={styles.parameterItem}>
            <Text style={styles.parameterLabel}>Repayments p/m:</Text>
            <Text style={styles.parameterValue}>
              {formatDollar(adjustParameters?.loanPayment?.toFixed(0))}
            </Text>
          </View>
          <View style={styles.parameterItem}>
            <Text style={styles.parameterLabel}>LVR:</Text>
            <Text style={styles.parameterValue}>
              {adjustParameters?.lvr?.toFixed(2)}%
            </Text>
          </View>
          <View style={styles.parameterItem}>
            <Text style={styles.parameterLabel}>Liquidity:</Text>
            <Text style={styles.parameterValue}>
              {formatDollar(adjustParameters?.liquidity?.toFixed(0))}
            </Text>
          </View>
        </View>
        <View style={[styles.parameter, { gap: 16 }]}>
          <View style={styles.parameterRightItem}>
            <Text style={styles.parameterRightLabel}>Net Cashflow p/a</Text>
            <Text style={styles.parameterRightValue}>
              {formatDollar(adjustParameters?.netCashFlow.toFixed(0))}
            </Text>
          </View>
          <View style={styles.parameterRightItem}>
            <Text style={styles.parameterRightLabel}>Equity</Text>
            <Text style={styles.parameterRightValue}>
              {formatDollar(adjustParameters?.equityValue.toFixed(0))}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: "#4B555D99",
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    padding: 16,
    gap: 16,
    flex: 1,
    minWidth: 343,
    minHeight: 301,
  },

  rowCenterBetween: {
    gap: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  timeline: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  flatList: {
    width: 147,
    height: 30,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: "#4B555D99",
    marginLeft: "auto",
  },

  flatListItem: {
    marginVertical: "auto",
    width: 49,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  yearSelected: {
    fontWeight: 600,
    fontSize: FONT_SIZE.medium,
    lineHeight: 18,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  yearItem: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 18,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  sliders: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },

  slider: {
    flex: 1,
  },

  sliderHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 2,
  },

  sliderTitle: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 11,
    height: 30,
    color: MAIN_COLORS.TEXT_LIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: MAIN_FONT.regular,
  },

  sliderHeaderBox: {
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderRadius: 56,
    backgroundColor: "#FABF00",
  },

  sliderHeaderValue: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: 10,
    lineHeight: 12,
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontFamily: MAIN_FONT.regular,
  },

  parameters: {
    flex: 1,
    flexDirection: "row",
    gap: 16,
  },

  parameter: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
  },

  parameterItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 2,
  },

  parameterLabel: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 16,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  parameterValue: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 20,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  parameterRightItem: {
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    borderRadius: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 2,
    flex: 1,
  },

  parameterRightLabel: {
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 15,
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontFamily: MAIN_FONT.regular,
  },

  parameterRightValue: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 21,
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontFamily: MAIN_FONT.semi,
  },
});

export default memo(PortfolioResults);
