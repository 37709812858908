import { Dimensions, StyleSheet } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { MAIN_COLOR, MAIN_COLORS } from "../../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Utility/Fonts/font";

const { width } = Dimensions.get("window");

var styles;

export default styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: MAIN_COLORS.BACKGROUND_WHITE,
  },

  container: {
    flex: 1,
  },

  scrollViewContainer: {
    width,
  },

  scrollViewContentView: {
    flex: 1,
    backgroundColor: "rgb(243, 244, 249)",
  },

  icon: { height: 16, width: 16, marginRight: 10 },

  iconPlay: {
    height: 84,
    width: 84,
  },

  iconTitle: {
    fontSize: 14,
    color: MAIN_COLORS.G800,
    fontFamily: MAIN_FONT.semi,
    textAlign: "center",
    marginTop: 5,
  },

  price: {
    fontSize: 14,
    color: MAIN_COLORS.G800,
    fontFamily: MAIN_FONT.semi,
    textAlign: "right",
  },

  priceTitle: {
    fontSize: 13,
    color: MAIN_COLORS.G700,
    fontFamily: MAIN_FONT.regular,
    textAlign: "left",
  },

  topView: {
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_DARK,
    padding: wp("5%"),
  },

  centerView: {
    justifyContent: "flex-start",
    padding: wp("5%"),
    paddingBottom: wp("5%"),
    paddingTop: 0,
  },

  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },

  userImage: {
    borderRadius: 25,
    height: 50,
    width: 50,
    resizeMode: "cover",
    marginRight: 15,
  },

  shadow: {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 1,
    shadowColor: MAIN_COLORS.G700,
  },

  fileIcon: {
    height: 18,
    width: 18,
  },

  btnPlay: {
    position: "absolute",
    top: "40%",
    left: "50%",
    zIndex: 2,
    transform: [{ translateX: "-50%", translateY: "-40%" }],
  },
});

export const styles2 = StyleSheet.create({
  container: {
    display: "flex",
    gap: 22,
  },

  title: {
    fontSize: 20,
    lineHeight: 30,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  sectionRow: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },

  sectionContent: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    padding: 16,
  },

  sectionRadius: {
    borderRadius: 10,
  },

  sectionPadding: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },

  sectionTitle: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  sectionTitleSmall: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  sectionText: {
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },

  sectionValue: {
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    fontWeight: FONT_WEIGHT.medium,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  rowStartBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },

  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },

  rowCenterBetween: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 6,
  },

  oppunityImage: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    position: "relative",
  },

  propertyBox: {
    width: "max-content",
    flexDirection: "row",
    gap: 8,
    position: "absolute",
    bottom: 24,
    left: "50%",
    transform: [{ translateX: "-50%" }],
  },
  propertyHouse: {
    display: "flex",
    flexDirection: "row",
    gap: 6,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 5,
    backgroundColor: "#19232BD9",
  },

  propertyIcon: {
    width: 18,
    height: 18,
  },

  propertyText: {
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    fontSize: 10,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  btnViewProperty: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 6,
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 6,
    borderWidth: 1.5,
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
  },
  textBtbViewProperty: {
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: "#D3B100",
    fontFamily: MAIN_FONT.regular,
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#4B555D99",
  },

  footer: {
    position: "relative",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    height: "auto",
    paddingVertical: 26,
  },

  footerTitle: {
    fontSize: 34,
    fontWeight: FONT_WEIGHT.bold,
    fontFamily: MAIN_FONT.semi,
    lineHeight: 51,
    color: MAIN_COLORS.TEXT_LIGHT,
    textAlign: "center",
  },

  footerDescription: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.medium,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    textAlign: "center",
  },

  btnBooking: {
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 6,
    height: 38,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  textBtnBooking: {
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: "#19232B",
    fontFamily: MAIN_FONT.semi,
  },

  footerImage: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "32%",
    maxHeight: 196,
    aspectRatio: 2 / 1,
    resizeMode: "contain",
  },

  calendarImage: {
    position: "absolute",
    right: 22,
    bottom: 0,
    width: 316,
    height: 192,
  },

  modalTitle: {
    fontSize: 20,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
    textAlign: "center",
  },
  modalContent: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  modalBtnClose: {
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN
      ? "#E7ECF2"
      : MAIN_COLORS.PRIMARY_COLOR,
    borderRadius: 10,
    width: "25%",
    padding: 10,
    marginTop: 40,
  },
  modalTextClose: {
    color: MAIN_COLORS.PRIMARY_COLOR
      ? MAIN_COLORS.G800
      : MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
    fontSize: FONT_SIZE.medium,
    textAlign: "center",
    fontFamily: MAIN_FONT.regular,
  },
  btnPlay: {
    position: "absolute",
    top: "40%",
    left: "50%",
    zIndex: 2,
    transform: [{ translateX: "-50%", translateY: "-40%" }],
  },

  modalVideo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 10,
  },
});
