import { StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";
import { MAIN_COLOR, TAB_BAR_COLOR } from "../../../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    gap: 22,
  },
  partnerList: {
    gap: 20,
    maxHeight: 180,
  },
  item: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 5,
  },
  wrapContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
  },
  name: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: 400,
    fontSize: 14,
    color: "#FFFFFF",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    flex: 1,
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: "50%",
  },
  closeIc: {
    width: 20,
    height: 20,
  },
  footer: {
    flexDirection: "row",
    display: "flex",
    gap: 12,
  },
  btnText: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
    color: TAB_BAR_COLOR.INACTIVE_COLOR,
  },
  btnAdd: {
    flex: 1,
    maxWidth: 68,
    height: 40,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
    gap: 6,
  },
  disabledBtn: {
    backgroundColor: MAIN_COLOR.BORDER_COLOR,
  },
});
