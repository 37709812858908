import { StyleSheet } from "react-native";
import { MAIN_COLORS } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    minHeight: 44,
    width: 282,
    borderRadius: 6,
    borderWidth: 1,
    backgroundColor: "#000000",
    borderColor: MAIN_COLORS.BORDER_COLOR,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: 400,
    fontSize: 14,
    color: "#FFFFFF",
  },
  icon: {
    width: 24,
    height: 24,
  },
  rightIc: {
    width: 14,
    height: 14,
  },
  sectionExpand: {
    padding: 12,
    paddingTop: 10,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#ADB9C74D",
    marginBottom: 22,
  },
});
