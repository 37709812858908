import React, { memo } from "react";

// Component
import fields from "../../../Utility/Fields";
import CheckListComp from '../../../Utility/ComponentCheckList/indexv2';

const CheckList = ({ checklists, id, task, clickUpStatusList }) => {
  const filteredChecklist = checklists?.find((item) => item?.task_id === id);
  const resolvedItems = filteredChecklist?.items?.filter((check) => check?.resolved === true);

  const shouldRenderChecklist =
    resolvedItems?.length > 0 &&
    task[fields.appStageName] === "Unconditional" &&
    clickUpStatusList.some(
      (clickUp) =>
        ["settled", "market updates"].includes(String(clickUp?.status).toLowerCase()) &&
        clickUp?.taskId === id
    );

  // Render
  return shouldRenderChecklist ? (
    <CheckListComp
      checklists={checklists.filter((item) => item?.task_id === id)}
    />
  ) : null;
};

export default memo(CheckList);
