import { capitalize } from "lodash";
import moment from "moment";
import { showMessage } from "react-native-flash-message";
// https://jsapi.apiary.io/apis/clickup20/reference/0/tasks/get-tasks.html
//Accepted Values for operator: =, <, <=, >, >=, !=, IS NULL ,IS NOT NULL ,RANGE, ANY, ALL, NOT ANY, NOT ALL

const API_URL = "https://api.qa.diyba.pgtest.co"; // QA
// const API_URL = "https://api.diyba.com.au"; // Production

export const addPassword = async (client, email, password, props) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_89E86NB0DL3T35NK9XY0C0XKIBFCL4M9"
  );

  var body = {
    value: password,
  };

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: "follow",
  };

  return fetch(
    `https://api.clickup.com/api/v2/task/${client.id}/field/08208191-53bd-4985-97f1-2dd9b9327797`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (!result.err) {
        props.setClient({ ...client, email });
        props.navigation.navigate("MainScreen");
      } else {
        showMessage({
          message: result.err,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
        });
      }
    })
    .catch((error) =>
      showMessage({
        message: error.message,
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
      })
    );
};

export const addToken = async (client, token) => {
  let allTokens = client.custom_fields.filter(
    (item, index) => item.id === "736c1bd1-464e-4c68-b6d2-d74b9a25314e"
  );
  if (allTokens[0].value) {
    token = allTokens[0].value + "\n" + token;
  }

  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/addToken?id=${client.id}&token=${token}`
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const Register = async (email) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_89E86NB0DL3T35NK9XY0C0XKIBFCL4M9"
  );
  myHeaders.append("Cache-Control", "no-cache, no-store, must-revalidate");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `https://api.clickup.com/api/v2/list/168689315/task?archived=false&custom_fields=[{\"field_id\":\"8381e893-d3af-4c1d-9106-9ea923f9d1ad\",\"operator\":\"=\",\"value\":\"${email}\"}]`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const Login = async (email, password, responseToken) => {
  //replace with loginWeb before push
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/loginWeb?email=${email}&password=${password.toString()}&token=${responseToken}`
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const getPresentation = async (id) => {
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/getTask?id=${id}`
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getTemplate = async () => {
  const id = "162897534";
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/getList?id=${id}`
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getProperty = async (id) => {
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/getTask?id=${id}`
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const removeProperty = (propertyId, teamId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };

  return fetch(
    `https://api.clickup.com/api/v2/task/${propertyId}?custom_task_ids=${true}&team_id=${teamId}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const removeCustomFeildValue = (propertyId, fieldId, teamId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  const query = new URLSearchParams({
    custom_task_ids: true,
    team_id: teamId,
  }).toString();

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };

  return fetch(
    `https://api.clickup.com/api/v2/task/${propertyId}/field/${fieldId}?${query}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getDetailClient = (client_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `https://api.clickup.com/api/v2/task/${client_id}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getMarket = async (id) => {
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/getTask?id=${id}`
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const testApi = async (id) => {
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/getSum?a=1&b=2`
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getLeads = async (id) => {
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/getTask?id=${id}`
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const registerWeb = async (email) => {
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/register?email=${email}`
  )
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
};

export const addPasswordWeb = async (client, password, props) => {
  return fetch(
    `https://us-central1-stratprops-7d489.cloudfunctions.net/addPassword?id=${client.id}&password=${password}`
  )
    .then((response) => response.json())
    .then((result) => {
      if (!result.err) {
        showMessage({
          message: "Password updated successfully.",
          type: "success",
          color: "#FFF",
          // backgroundColor: MAIN_COLOR.PRIMARY_COLOR
        });
        props.navigation.navigate("LoginScreen");
      } else {
        showMessage({
          message: result.err,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
        });
      }
    })
    .catch((error) =>
      showMessage({
        message: error.message,
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
      })
    );
};

export const getContactByEmail = async (email) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `https://api.clickup.com/api/v2/list/210678355/task?archived=false&custom_fields=[{\"field_id\":\"237e4086-594d-403c-8b35-a13d4605e06e\",\"operator\":\"=\",\"value\":\"${email.toString()}\"}]`,
    requestOptions
  )
    .then((response) => {
      // console.log('response::\t', response);
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getTIATeamForApp = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `https://api.clickup.com/api/v2/list/901604938709/task`,
    requestOptions
  )
    .then((response) => {
      // console.log('response::\t', response);
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getBrandingByLeadSourceValue = async (value) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `https://api.clickup.com/api/v2/list/210678355/task?archived=false&custom_fields=[{\"field_id\":\"17f3da1d-a3c9-4ec7-aa2e-098b6897e5f8\",\"operator\":\"=\",\"value\":\"${value}\"}]`,
    requestOptions
  )
    .then((response) => {
      // console.log('response::\t', response);
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getTotalPotentialEquity = async (listProperties) => {
  const properties_id = listProperties.map((item) => `${item}`).join(",");
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${API_URL}/api/property/get-total-potential-equity?properties_id=${properties_id}`,
    requestOptions
  )
    .then((response) => {
      // console.log({ response });
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const fetchVideoLoom = async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  return fetch(
    `https://www.loom.com/api/campaigns/sessions/${id}/transcoded-url`,
    { method: "POST" }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const updatePresentationViewed = async (taskId, value) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({ value }),
  };

  return fetch(
    `https://api.clickup.com/api/v2/task/${taskId}/field/375f4e59-dcea-48cc-9fa0-ad8eed714415`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getPublicMarketHistory = (name) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ market_name: name }),
    headers: myHeaders,
  };
  return fetch(
    `${API_URL}/api/market/get-public-market-history/`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const searchProperty = (address) => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${API_URL}/api/property/search_property?query_str=${address}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getListScenarios = (userId, role) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(
    `${API_URL}/api/portfolio/list-scenario?user_id=${userId}&role=${role}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getDetailsByScenarioId = (scenarioId, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  return fetch(
    `${API_URL}/api/portfolio/detail-scenario/${scenarioId}?user_id=${userId}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.status === 401) {
        return { status: false };
      }
      const result = response.data;
      return {
        status: true,
        name: result.name,
        investingTimeLine: result.investing_timeline,
        totalGrossIncome: result.total_gross_income,
        createAt: result.created_at,
        id: result.id,
        yearsOfResult: result.year_of_result || moment().year(),
        cashSavings: result.cash_savings,
        availableEquity: result.available_equity,
        widgetProperties: JSON.parse(result.widget_properties),
        customIncomes: JSON.parse(result.custom_income),
        totalPrimaryIncome: result.total_primary_income,
        purchaseTargets: result.purchase_target_set.map((item) => ({
          valueAtTime: item.target_price,
          rentPW: item.rent_pw_time_line,
          incomeType: capitalize(item.income_type),
          expensesPM: item.living_expenses_pm,
          totalIncome: item.total_income,
          rateSelected: item.deposit_size,
          cashContribute: item.cash_contribution,
          loanAmount: item.loan_amount,
          interestRate: item.interest_rate,
          loanType: item.loan_type,
          id: item.id,
          noOfMonth: item.no_of_month,
          interestPerMonth: item.interest_per_month,
          recommendedBorrowing: item.recommended_borrowing,
          years: item.years,
          propertyPrice: item.property_price,
          equityFromPurchase: item.equity_from_purchase,
          targetRentPW: item.target_ren_pw,
          purchaseDate: item.purchase_date,
          dependant: item.dependant,
          income1: item.income_1,
          income2: item.income_2,
          deposit: item?.deposit,
          valuesDeposit: item?.values_deposit,
          totalSavingsAndEquity: item?.total_saving_and_equity,
          newWidgetProperty: item?.new_widget_property
            ? JSON.parse(item?.new_widget_property)
            : null,
          salaryAtTime: item?.salary_at_time ? item?.salary_at_time : null,
        })),
      };
    })
    .catch((error) => error);
};

export const addNewScenario = (data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(
    `${API_URL}/api/portfolio/create-portfolio-scenario`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getDetailProperty = (property_id) => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${API_URL}/api/property/detail_property?property_id=${property_id}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const deleteScenario = (scenarioId, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };
  return fetch(
    `${API_URL}/api/portfolio/detail-scenario/${scenarioId}?user_id=${userId}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const saveScenario = (body) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
  };

  return fetch(
    `https://hook.eu1.make.com/0auxouexqkx2276moca89iiq06rd3vch`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getListMarketHistory = (payload, flag) => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${API_URL}/api/market/list-filter-market-history/${
      payload.purchase_price
    }/${payload.id}?property_suburb=${payload.property_suburb}&no_bed_room=${
      payload.no_bed_room
    }&purchase_date=${payload.purchase_date}${
      payload.state ? `&state=${payload.state}` : ""
    }&${flag}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const addPartnerRoadmap = (payload) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(payload),
  };

  return fetch(`${API_URL}/api/portfolio/add-roadmap-partner`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const confirmPassword = (payload) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(payload),
  };

  return fetch(`${API_URL}/api/portfolio/confirm-password`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const signIn = (payload) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(payload),
  };

  return fetch(`${API_URL}/api/users/portal-login`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getListRoadmapPartner = (userId, role) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `${API_URL}/api/portfolio/list-roadmap-partner?user_id=${userId}&role=${role}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const deleteRoadmapPartner = (partnerId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };

  return fetch(
    `${API_URL}/api/portfolio/delete-roadmap-partner/${partnerId}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const marketFourBedrooms = ({ ssc_name, bedrooms }) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `${API_URL}/api/market/bedrooms-house-median/?ssc_name=${ssc_name}&bedrooms=${bedrooms}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const marketHistoricalData = ({ ssc_name, bedrooms }) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `${API_URL}/api/market/historical-data-view/?ssc_name=${ssc_name}&bedrooms=${bedrooms}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const marketSurbStatistics = ({ ssc_name }) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `${API_URL}/api/market/suburb-statistics-data-view/?ssc_name=${ssc_name}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const marketInvestment = ({ ssc_name }) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(
    `${API_URL}/api/market/market-investment-profile/?ssc_name=${ssc_name}`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};

export const getReportClause = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "pk_48620507_MGVFK026LJND2WKAPCZC8198J7R3GAY2"
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `https://api.clickup.com/api/v2/list/186841827/task?subtasks=true&status=bp finance clauses`,
    requestOptions
  )
    .then((response) => {
      // console.log('response::\t', response);
      return response.json();
    })
    .then((result) => result)
    .catch((error) => error);
};
