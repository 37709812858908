import React, { memo, useState, useEffect } from "react";
import {
  Image,
  Linking,
  Modal,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import Toast from "react-native-toast-message";
import { toastConfig } from "../../../configs/toastConfig";

//Functions
import { MAIN_COLOR, MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

const titleMapping = {
  "INVOICE URL": "Receipt.pdf",
  "AGREEMENT URL": "Agreement.pdf",
  "Client Profile Url": "Profile.pdf",
  "Rent Appraisal Link": "Rental Appraisal.pdf",
  "Signed Contract Url": "Purchase Contracts.pdf",
  "Building Report": "Building Report.pdf",
  "Pest Report": "Pest Report.pdf",
};

const DocumentProperty = ({ opportunity }) => {
  const documents = Object.keys(titleMapping)
    .filter((key) => opportunity[key])
    .map((key) => ({
      title: titleMapping[key],
      uri: opportunity[key],
    }));

  return (
    documents && documents.length > 0 ? (
      <View style={{ marginBottom: 100 }}>
        <Text
          style={{
            fontWeight: FONT_WEIGHT.bold,
            fontSize: FONT_SIZE.large,
            lineHeight: 27,
            color: MAIN_COLORS.TEXT_LIGHT,
            fontFamily: MAIN_FONT.semi,
            marginBottom: 8,
          }}
        >
          Documents
        </Text>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            marginHorizontal: -8,
          }}
        >
          {documents.map((item, index) => (
            <View
              key={index}
              style={{
                width: "33.33%",
                padding: 8,
              }}
            >
              <ThumbnailResult key={index} source={item} index={index} />
            </View>
          ))}
        </View>
      </View>
    ) : null
  );
};

const downloadFileWeb = async (fileName, path) => {
  fileName = fileName.replace(/ /g, "");
  console.log("fileName:: ", fileName);

  const fileIdMatch = path.match(/\/d\/([^\/]+)/);
  const fileId = fileIdMatch ? fileIdMatch[1] : null;

  if (!fileId) {
    console.error("Invalid Google Drive link. File ID not found.");
    return;
  }

  const downloadUrl = `https://drive.google.com/uc?id=${fileId}&export=download`;

  try {
    const supported = await Linking.canOpenURL(downloadUrl);
    if (supported) {
      await Linking.openURL(downloadUrl);
    }
  } catch (error) {
    console.error("Error opening URL:", error);
  }
};

const onCopyPath = (path, noti) => {
  navigator.clipboard.writeText(path);
  Toast.hide();
  setTimeout(() => {
    Toast.show({
      type: "info",
      text1: noti,
      position: "bottom",
      visibilityTime: 3000,
    });
  }, 300);
};

const ThumbnailResult = ({ source, index }) => {
  const { title, uri } = source;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const mappedTitle = titleMapping?.[title] || title;

  const handleOpenModal = (event) => {
    const { pageY, pageX } = event.nativeEvent;
    setModalPosition({ top: pageY + 20, left: pageX - 110 });
    setModalVisible(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setIsModalOpen(false);
  };

  const handleActionPress = (action) => {
    handleCloseModal();
    if (action === "View") {
      Linking.openURL(uri);
    } else if (action === "Download") {
      downloadFileWeb("Receipt", uri);
    } else if (action === "Share") {
      onCopyPath(uri, "Link drive copied!");
    }
  };

  return (
    <>
      {source ? (
        <View
          key={index}
          style={{
            padding: 12,
            backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
            borderRadius: 6,
            position: "relative",
          }}
        >
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexDirection: "row", marginRight: 12 }}>
              <Image
                source={require("../../../assets/pdf.svg")}
                resizeMode="contain"
                style={{
                  height: 46,
                  width: 46,
                  borderRadius: 6,
                  marginRight: 12,
                }}
              />
              <View>
                <Text
                  style={{
                    fontSize: 14,
                    color: "#FFFFFF",
                    fontFamily: MAIN_FONT.semi,
                    marginBottom: 4,
                  }}
                >
                  {mappedTitle}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    color: MAIN_COLOR.G600,
                    fontFamily: MAIN_FONT.regular,
                  }}
                >
                  23 MB
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 77,
                  padding: 4,
                  width: 32,
                  height: 32,
                  backgroundColor: isModalOpen
                    ? "#232D35"
                    : MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                }}
                onPress={handleOpenModal}
              >
                <Image
                  style={{
                    resizeMode: "contain",
                    width: 18,
                    height: 18,
                  }}
                  source={require("../../../assets/three-dots.svg")}
                />
              </TouchableOpacity>
            </View>
          </View>

          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={handleCloseModal}
          >
            <TouchableWithoutFeedback onPress={handleCloseModal}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    top: modalPosition.top,
                    left: modalPosition.left,
                    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                    borderRadius: 6,
                    padding: 16,
                    width: "auto",
                    height: "auto",
                    borderWidth: 1,
                    borderColor: "#34393D80",
                    shadowColor: "#000",
                    shadowOpacity: 0.1,
                    shadowOffset: {
                      width: 0,
                      height: 0,
                    },
                    shadowRadius: 12,
                  }}
                >
                  {["View", "Download", "Share"].map((action, index) => (
                    <TouchableWithoutFeedback
                      key={action}
                      onPress={() => handleActionPress(action)}
                      onPressIn={() => setHoveredIndex(index)}
                      onPressOut={() => setHoveredIndex(null)}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          paddingTop: action === "View" ? 0 : 12,
                          paddingBottom: action === "Share" ? 0 : 12,
                          borderBottomWidth: action === "Share" ? 0 : 1,
                          borderBottomColor: "#34393D",
                          borderBottomStyle: "solid",
                          cursor: "pointer",
                        }}
                      >
                        <Image
                          style={{
                            resizeMode: "contain",
                            width: 20,
                            height: 20,
                            marginRight: 8,
                            tintColor:
                              hoveredIndex === index
                                ? "#FFFFFF"
                                : MAIN_COLOR.G600,
                          }}
                          source={
                            action === "View"
                              ? require("../../../assets/eye.svg")
                              : action === "Download"
                              ? require("../../../assets/receive-square.svg")
                              : require("../../../assets/link-2.svg")
                          }
                        />
                        <Text
                          style={{
                            color:
                              hoveredIndex === index
                                ? "#FFFFFF"
                                : MAIN_COLOR.G600,
                          }}
                        >
                          {action}
                        </Text>
                      </View>
                    </TouchableWithoutFeedback>
                  ))}
                </View>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
        </View>
      ) : null}
      <View
        style={{ position: "fixed", right: "50%", bottom: 0, zIndex: 9999 }}
      >
        <Toast config={toastConfig} />
      </View>
    </>
  );
};

export default memo(DocumentProperty);
