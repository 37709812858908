import { StyleSheet } from "react-native";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  cardContainer: {
    position: "relative",
  },
  container: {
    height: 272,
    borderRadius: 10,
    width: "100%",
    overflow: "hidden",
  },
  center: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },
  imageOverview: {
    height: "100%",
    overflow: "hidden",
  },
  image: {
    height: "100%",
    transform: [{ scale: 2.5 }],
  },
  no_image: {
    height: "100%",
    transform: [{ scale: 1.2 }],
    backgroundColor: "#F5F6FA",
  },
  description: {
    position: "absolute",
    bottom: 0,
    zIndex: 1,
    backgroundColor: "#19232BD9",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingHorizontal: 14,
    paddingVertical: 10,
    gap: 8,
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  icon: {
    width: 20,
    height: 20,
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 21,
    color: "#FFFFFF",
    fontFamily: MAIN_FONT.semi,
    marginBottom: 8,
  },
  address: {
    fontSize: 12,
    fontWeight: 400,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },
  btnView: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: [{ translateY: "-50%" }, { translateX: "-50%" }],
    width: 26,
    height: 26,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: MAIN_COLORS.BACKGROUND_WHITE,
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    zIndex: 1,
  },
  params: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 23,
  },
  param: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
  },
  value: {
    fontSize: 12,
    fontWeight: 500,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },
  topLeftSection: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    position: "absolute",
    top: 10,
    left: 10,
    width: 36,
    height: 36,
    zIndex: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  topRightSection: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderRadius: 5,
    paddingHorizontal: 6,
    paddingVertical: 5,
    position: "absolute",
    top: 10,
    right: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  txtPurchaseBy: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 8,
    fontWeight: 400,
    color: MAIN_COLOR.G800,
    lineHeight: "120%",
  },
  txtAgency: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 10,
    fontWeight: 600,
    color: MAIN_COLOR.G800,
    lineHeight: "120%",
  },
  blur: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    zIndex: -1,
  },

  active: {
    shadowColor: "#FCCE15",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 30,
    elevation: 10,
    borderWidth: 1,
    borderColor: "#D3B10080",
  },
});
