import React, { memo } from "react";
import { Image, ScrollView, Text, View } from "react-native";
import fields from "../../../Utility/Fields";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
// import * as WebBrowser from 'expo-web-browser';
import ContactCard from "../../../Utility/ComponentCard/ContactCard/ContactCard2";
import MediaQuery from "react-responsive";
import CheckListComp from "../../../Utility/ComponentCheckList/indexv2";
import Date from "./Date";

const SideBar = ({
  time,
  files,
  pdfs,
  pngs,
  task,
  status,
  clickUpStatusList,
  id,
  checklists,
  walkThroughDate,
  preSettlementDate,
  settlementDate,
  buildingPestDate,
  financeDate,
  solicitorName,
  solicitorPhone,
  solicitorEmail,
  brokerName,
  brokerEmail,
  brokerPhone,
  adminStrationPhone,
  adminStrationAvatar,
  buyerAgentName,
  buyerAgentEmail,
  buyerAgentPhone,
  buyerAgentAvatar,
  headBuyerAgentName,
  headBuyerAgentEmail,
  headBuyerAgentPhone,
  headBuyerAgentAvatar,
  bpInspectorName,
  bpInspectorPhone,
  bpInspectorEmail,
  propertyManagerName,
  propertyManagerEmail,
  propertyManagerPhone,
  quantitySurveyorPhone,
  onCopyPhone,
  thumbnailResult,
  adminHeadName,
  adminHeadPhone,
  adminHeadEmail,
  adminHeadAvatar,
  adminSupportName,
  adminSupportPhone,
  adminSupportEmail,
  adminSupportAvatar,
  headStrategyName,
  headStrategyPhone,
  headStrategyEmail,
  headStrategyAvatar,
  directorOperationsName,
  directorOperationsPhone,
  directorOperationsEmail,
  directorOperationsAvatar,
  exchangeManagerName,
  exchangeManagerPhone,
  exchangeManagerEmail,
  exchangeManagerAvatar,
  propertyAnalystName,
  propertyAnalystPhone,
  propertyAnalystEmail,
  propertyAnalystAvatar,
}) => {
  const transformedFiles = Object.entries(files).map(([title, uri]) => ({
    title,
    uri,
  }));

  return (
    <View>
      <View
        style={{
          flexDirection: "column",
          gap: 16,
          ...(task[fields.appStageName] === "Property Walk-Through" ||
            task[fields.appStageName] ===
            "Contract, Building & Pest and Property Manager" ||
            task[fields.appStageName] === "Unconditional" ||
            task[fields.appStageName] === "Settlement"
            ? { marginBottom: 22 }
            : {}),
        }}
      >
        {task[fields.appStageName] === "Property Walk-Through" && (
          <Date
            title="Walk through Inspection Date"
            time={walkThroughDate}
            status={status}
          />
        )}
        {task[fields.appStageName] ===
          "Contract, Building & Pest and Property Manager" && (
            <>
              <Date
                title="Building & Pest Clause Date"
                time={buildingPestDate}
                status={status}
              />
              <Date
                title="Finance Clause Date"
                time={financeDate}
                status={status}
              />
              <Date
                title="Settlement Date"
                time={settlementDate}
                status={status}
              />
            </>
          )}
        {(task[fields.appStageName] === "Unconditional" ||
          task[fields.appStageName] === "Settlement") && (
            <Date
              title="Pre settlement inspection date"
              time={preSettlementDate}
              status={status}
            />
          )}
      </View>
      <>
        {!!(
          (task[fields.appStageName] == "Profile Review" &&
            (adminHeadName || adminSupportName || headStrategyName)) ||
          (task[fields.appStageName] == "Search Process" &&
            (adminHeadName ||
              adminSupportName ||
              headStrategyName)) ||
          (task[fields.appStageName] == "Property Walk-Through" &&
            (adminHeadName ||
              adminSupportName ||
              exchangeManagerName)) ||
          (task[fields.appStageName] ==
            "Contract, Building & Pest and Property Manager" &&
            (adminHeadName ||
              adminSupportName ||
              (propertyAnalystName && propertyAnalystName.length > 0) ||
              propertyManagerName ||
              solicitorName ||
              exchangeManagerName)) ||
          (task[fields.appStageName] == "Building & Pest Report Results" &&
            (adminHeadName ||
              adminSupportName ||
              (propertyAnalystName && propertyAnalystName.length > 0) ||
              bpInspectorName ||
              solicitorName ||
              exchangeManagerName)) ||
          (task[fields.appStageName] == "Unconditional" &&
            (adminHeadName ||
              adminSupportName ||
              (propertyAnalystName && propertyAnalystName.length > 0) ||
              brokerName ||
              solicitorName ||
              exchangeManagerName)) ||
          (task[fields.appStageName] == "Settlement" &&
            (adminHeadName ||
              headStrategyName ||
              (propertyAnalystName && propertyAnalystName.length > 0) ||
              directorOperationsName ||
              solicitorName ||
              exchangeManagerName))
        ) && (
            <Text
              style={{
                fontSize: 16,
                color: "#FFFFFF",
                fontFamily: MAIN_FONT.semi,
                marginBottom: 16,
              }}
            >
              Contacts
            </Text>
          )}
        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Profile Review" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 22,
                    },
                  })}
                >
                  {adminHeadName && adminHeadPhone && adminHeadEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Admin Head"}
                        name={adminHeadName}
                        phoneNumber={adminHeadPhone}
                        email={adminHeadEmail}
                        employeeAvatar={adminHeadAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {headStrategyName &&
                    headStrategyPhone &&
                    headStrategyEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Head of Strategy"}
                          name={headStrategyName}
                          phoneNumber={headStrategyPhone}
                          email={headStrategyEmail}
                          employeeAvatar={headStrategyAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Search Process" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 22,
                    },
                  })}
                >
                  {adminHeadName && adminHeadPhone && adminHeadEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Admin Head"}
                        name={adminHeadName}
                        phoneNumber={adminHeadPhone}
                        email={adminHeadEmail}
                        employeeAvatar={adminHeadAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {adminSupportName &&
                    adminSupportPhone &&
                    adminSupportEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Admin Support"}
                          name={adminSupportName}
                          phoneNumber={adminSupportPhone}
                          email={adminSupportEmail}
                          employeeAvatar={adminSupportAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {headStrategyName &&
                    headStrategyPhone &&
                    headStrategyEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Head of Strategy"}
                          name={headStrategyName}
                          phoneNumber={headStrategyPhone}
                          email={headStrategyEmail}
                          employeeAvatar={headStrategyAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyAnalystName &&
                    propertyAnalystPhone &&
                    propertyAnalystEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Analyst"}
                          name={propertyAnalystName}
                          phoneNumber={propertyAnalystPhone}
                          email={propertyAnalystEmail}
                          employeeAvatar={propertyAnalystAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {!propertyAnalystName &&
                    directorOperationsName &&
                    directorOperationsPhone &&
                    directorOperationsEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Director of Operations"}
                          name={directorOperationsName}
                          phoneNumber={directorOperationsPhone}
                          email={directorOperationsEmail}
                          employeeAvatar={directorOperationsAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Property Walk-Through" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 22,
                    },
                  })}
                >
                  {adminHeadName && adminHeadPhone && adminHeadEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Admin Head"}
                        name={adminHeadName}
                        phoneNumber={adminHeadPhone}
                        email={adminHeadEmail}
                        employeeAvatar={adminHeadAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {adminSupportName &&
                    adminSupportPhone &&
                    adminSupportEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Admin Support"}
                          name={adminSupportName}
                          phoneNumber={adminSupportPhone}
                          email={adminSupportEmail}
                          employeeAvatar={adminSupportAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {exchangeManagerName &&
                    exchangeManagerPhone &&
                    exchangeManagerEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Exchange Manager"}
                          name={exchangeManagerName}
                          phoneNumber={exchangeManagerPhone}
                          email={exchangeManagerEmail}
                          employeeAvatar={exchangeManagerAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyAnalystName &&
                    propertyAnalystPhone &&
                    propertyAnalystEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Analyst"}
                          name={propertyAnalystName}
                          phoneNumber={propertyAnalystPhone}
                          email={propertyAnalystEmail}
                          employeeAvatar={propertyAnalystAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {!propertyAnalystName &&
                    directorOperationsName &&
                    directorOperationsPhone &&
                    directorOperationsEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Director of Operations"}
                          name={directorOperationsName}
                          phoneNumber={directorOperationsPhone}
                          email={directorOperationsEmail}
                          employeeAvatar={directorOperationsAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] ==
                "Contract, Building & Pest and Property Manager" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 22,
                    },
                  })}
                >
                  {exchangeManagerName &&
                    exchangeManagerPhone &&
                    exchangeManagerEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Exchange Manager"}
                          name={exchangeManagerName}
                          phoneNumber={exchangeManagerPhone}
                          email={exchangeManagerEmail}
                          employeeAvatar={exchangeManagerAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {adminHeadName && adminHeadPhone && adminHeadEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Admin Head"}
                        name={adminHeadName}
                        phoneNumber={adminHeadPhone}
                        email={adminHeadEmail}
                        employeeAvatar={adminHeadAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {adminSupportName &&
                    adminSupportPhone &&
                    adminSupportEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Admin Support"}
                          name={adminSupportName}
                          phoneNumber={adminSupportPhone}
                          email={adminSupportEmail}
                          employeeAvatar={adminSupportAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyAnalystName &&
                    propertyAnalystPhone &&
                    propertyAnalystEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Analyst"}
                          name={propertyAnalystName}
                          phoneNumber={propertyAnalystPhone}
                          email={propertyAnalystEmail}
                          employeeAvatar={propertyAnalystAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyManagerName &&
                    propertyManagerEmail &&
                    propertyManagerPhone && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Manager"}
                          name={propertyManagerName}
                          phoneNumber={propertyManagerPhone}
                          email={propertyManagerEmail}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {solicitorName && solicitorEmail && solicitorPhone && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Solicitor"}
                        name={solicitorName}
                        phoneNumber={solicitorPhone}
                        email={solicitorEmail}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Building & Pest Report Results" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 22,
                    },
                  })}
                >
                  {exchangeManagerName &&
                    exchangeManagerPhone &&
                    exchangeManagerEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Exchange Manager"}
                          name={exchangeManagerName}
                          phoneNumber={exchangeManagerPhone}
                          email={exchangeManagerEmail}
                          employeeAvatar={exchangeManagerAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {adminHeadName && adminHeadPhone && adminHeadEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Admin Head"}
                        name={adminHeadName}
                        phoneNumber={adminHeadPhone}
                        email={adminHeadEmail}
                        employeeAvatar={adminHeadAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {adminSupportName &&
                    adminSupportPhone &&
                    adminSupportEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Admin Support"}
                          name={adminSupportName}
                          phoneNumber={adminSupportPhone}
                          email={adminSupportEmail}
                          employeeAvatar={adminSupportAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyAnalystName &&
                    propertyAnalystPhone &&
                    propertyAnalystEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Analyst"}
                          name={propertyAnalystName}
                          phoneNumber={propertyAnalystPhone}
                          email={propertyAnalystEmail}
                          employeeAvatar={propertyAnalystAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {bpInspectorName && bpInspectorPhone && bpInspectorEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Building and Pest Inspector"}
                        name={bpInspectorName}
                        phoneNumber={bpInspectorPhone}
                        email={bpInspectorEmail}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {solicitorName && solicitorEmail && solicitorPhone && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Solicitor"}
                        name={solicitorName}
                        phoneNumber={solicitorPhone}
                        email={solicitorEmail}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Unconditional" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 22,
                    },
                  })}
                >
                  {exchangeManagerName &&
                    exchangeManagerPhone &&
                    exchangeManagerEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Exchange Manager"}
                          name={exchangeManagerName}
                          phoneNumber={exchangeManagerPhone}
                          email={exchangeManagerEmail}
                          employeeAvatar={exchangeManagerAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {adminHeadName && adminHeadPhone && adminHeadEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Admin Head"}
                        name={adminHeadName}
                        phoneNumber={adminHeadPhone}
                        email={adminHeadEmail}
                        employeeAvatar={adminHeadAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {adminSupportName &&
                    adminSupportPhone &&
                    adminSupportEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Admin Support"}
                          name={adminSupportName}
                          phoneNumber={adminSupportPhone}
                          email={adminSupportEmail}
                          employeeAvatar={adminSupportAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyAnalystName &&
                    propertyAnalystPhone &&
                    propertyAnalystEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Analyst"}
                          name={propertyAnalystName}
                          phoneNumber={propertyAnalystPhone}
                          email={propertyAnalystEmail}
                          employeeAvatar={propertyAnalystAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {brokerName && brokerPhone && brokerEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Mortgage Broker"}
                        name={brokerName}
                        phoneNumber={brokerPhone}
                        email={brokerEmail}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {solicitorName && solicitorEmail && solicitorPhone && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Solicitor"}
                        name={solicitorName}
                        phoneNumber={solicitorPhone}
                        email={solicitorEmail}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>

        <MediaQuery minWidth={1281}>
          {(isBreakPoint) => (
            <>
              {task[fields.appStageName] == "Settlement" ? (
                <div
                  {...(isBreakPoint && {
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                      marginBottom: 22,
                    },
                  })}
                >
                  {exchangeManagerName &&
                    exchangeManagerPhone &&
                    exchangeManagerEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Exchange Manager"}
                          name={exchangeManagerName}
                          phoneNumber={exchangeManagerPhone}
                          email={exchangeManagerEmail}
                          employeeAvatar={exchangeManagerAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {adminHeadName && adminHeadPhone && adminHeadEmail && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Admin Head"}
                        name={adminHeadName}
                        phoneNumber={adminHeadPhone}
                        email={adminHeadEmail}
                        employeeAvatar={adminHeadAvatar}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {headStrategyName &&
                    headStrategyPhone &&
                    headStrategyEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Head of Strategy"}
                          name={headStrategyName}
                          phoneNumber={headStrategyPhone}
                          email={headStrategyEmail}
                          employeeAvatar={headStrategyAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {propertyAnalystName &&
                    propertyAnalystPhone &&
                    propertyAnalystEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Property Analyst"}
                          name={propertyAnalystName}
                          phoneNumber={propertyAnalystPhone}
                          email={propertyAnalystEmail}
                          employeeAvatar={propertyAnalystAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {directorOperationsName &&
                    directorOperationsPhone &&
                    directorOperationsEmail && (
                      <View
                        style={{
                          ...(isBreakPoint && {
                            width: "100%",
                          }),
                        }}
                      >
                        <ContactCard
                          type={"Director of Operations"}
                          name={directorOperationsName}
                          phoneNumber={directorOperationsPhone}
                          email={directorOperationsEmail}
                          employeeAvatar={directorOperationsAvatar}
                          onCopyPhone={onCopyPhone}
                        />
                      </View>
                    )}

                  {solicitorName && solicitorEmail && solicitorPhone && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Solicitor"}
                        name={solicitorName}
                        phoneNumber={solicitorPhone}
                        email={solicitorEmail}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )}

                  {/* {quantitySurveyorPhone && (
                    <View
                      style={{
                        ...(isBreakPoint && {
                          width: "100%",
                        }),
                      }}
                    >
                      <ContactCard
                        type={"Quantity Surveyor"}
                        name={"Ayden Hassan"}
                        phoneNumber={quantitySurveyorPhone}
                        email={"ayden.hassan@mcgqs.com.au"}
                        onCopyPhone={onCopyPhone}
                      />
                    </View>
                  )} */}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </MediaQuery>
      </>

      {pngs.length ? (
        <View style={{ marginBottom: 16 }}>
          <Text
            style={{
              fontSize: 16,
              color: "#FFFFFF",
              fontFamily: MAIN_FONT.semi,
              marginBottom: 16,
            }}
          >
            Market Data
          </Text>

          <ScrollView
            horizontal
            style={{
              paddingHorizontal: "0%",
              marginBottom: 20,
              paddingVertical: 10,
              minWidth: "100%",
              zIndex: 999,
            }}
          >
            {pngs.map((item, index) => {
              let ratio = 0.68;
              Image.getSize(item, (width, height) => {
                ratio = height / width;
              });

              return (
                <View
                  style={{
                    backgroundColor: "#FFFFFF",
                    padding: 10,
                    borderRadius: 20,
                    width: 320,
                    marginRight: 20,
                  }}
                >
                  <Image
                    source={{ uri: item }}
                    style={{
                      height: ratio * 300 - 20,
                      width: 300,
                      resizeMode: "contain",
                    }}
                  />
                </View>
              );
            })}
          </ScrollView>
        </View>
      ) : null}

      {transformedFiles.length ? (
        <View style={{ marginBottom: 16 }}>
          <Text
            style={{
              fontSize: 16,
              color: "#FFFFFF",
              fontFamily: MAIN_FONT.semi,
              marginBottom: 16,
            }}
          >
            {task[fields.appStageName] ===
              "Contract, Building & Pest and Property Manager"
              ? "Purchase Contracts"
              : task[fields.appStageName] === "Property Walk-Through"
                ? "Rent Appraisal"
                : "Documents"}
          </Text>

          <ScrollView
            horizontal
            contentContainerStyle={{ minWidth: "100%" }}
            showsHorizontalScrollIndicator={false}
            style={{
              flexDirection: "column",
              paddingHorizontal: 0,
            }}
          >
            <View style={{ flexWrap: "wrap", width: "100%" }}>
              {transformedFiles.map((item, index) =>
                thumbnailResult(item, index)
              )}
            </View>
          </ScrollView>
        </View>
      ) : null}
    </View>
  );
};

export default memo(SideBar);
