const masterTimelines1 = [];
const masterTimelines2 = [];

const genGrowthPercent = (month) => {
  switch (true) {
    case month <= 12:
      return 17;
    case month > 12 && month <= 36:
      return 12;
    case month > 36 && month <= 60:
      return 9;
    case month > 60 && month <= 96:
      return 7;
    case month > 96 && month <= 120:
      return 4;
    case month > 120 && month <= 240:
      return 7;
    default:
      return 10;
  }
};

// const genGrowthPercent1 = (month, propertyPrice) => {
//   const growthRates = [
//     { maxMonth: 12, rate: 17, divisor: 12 },
//     { maxMonth: 36, rate: 12, divisor: 24 },
//     { maxMonth: 60, rate: 9, divisor: 24 },
//     { maxMonth: 96, rate: 7, divisor: 24 },
//     { maxMonth: 121, rate: 4, divisor: 24 },
//     { maxMonth: 240, rate: 7, divisor: 24 },
//   ];
//   for (const { maxMonth, rate, divisor } of growthRates) {
//     if (month < maxMonth) {
//       totalGrowth +=
//         ((propertyPrice * rate) / divisor) * (month - previousMaxMonth);
//       return totalGrowth;
//     } else {
//       totalGrowth +=
//         ((propertyPrice * rate) / divisor) * (maxMonth - previousMaxMonth);
//       previousMaxMonth = maxMonth;
//     }
//   }
//   totalGrowth += ((equity * 10) / 24) * (month - previousMaxMonth);

//   return totalGrowth;
// };

export const calcNewEquity = (priceOfProperty, month) => {
  const result = [];
  const thresholds = [12, 36, 60, 96, 121, 240];
  let prevThreshold = 0;

  for (const threshold of thresholds) {
    if (month >= threshold) {
      result.push({
        min: prevThreshold,
        max: threshold,
        value: genGrowthPercent(threshold),
      });
      prevThreshold = threshold;
    } else {
      result.push({
        min: prevThreshold,
        max: month,
        value: genGrowthPercent(month),
      });
      break;
    }
  }

  if (month > thresholds[thresholds.length - 1]) {
    result.push({
      min: thresholds[thresholds.length - 1],
      max: month,
      value: genGrowthPercent(month),
    });
  }

  return result.reduce((total, item) => {
    return (total +=
      (priceOfProperty * (item.value / 100) * (item.max - item.min)) / 12);
  }, 0);
};
const genRentPerMonthPercent = (month) => {
  switch (true) {
    case month <= 24:
      return 10;
    case month > 24 && month <= 48:
      return 7.5;
    case month > 24 && month <= 48:
      return 7.5;
    default:
      return 5.2;
  }
};

export const firstMasterLineObject = (
  result = [],
  propertyPrice,
  rentalPerWeek,
  paymentPurchasePerMonth,
  debtOnProperty,
  availableEquity,
  newInterestPerMonth,
  months,
  growthPercent,
  rentPercent,
  deposit,
  interestRate,
  noOfMonth
) => {
  const month = 1;

  propertyPrice = propertyPrice ? Number(propertyPrice) : 0;
  rentalPerWeek = rentalPerWeek ? Number(rentalPerWeek) : 0;
  paymentPurchasePerMonth = paymentPurchasePerMonth
    ? Number(paymentPurchasePerMonth)
    : 0;
  debtOnProperty = debtOnProperty ? Number(debtOnProperty) : 0;
  availableEquity = availableEquity ? Number(availableEquity) : 0;
  newInterestPerMonth = newInterestPerMonth ? Number(newInterestPerMonth) : 0;

  const value =
    propertyPrice + (propertyPrice * (growthPercent ?? 17)) / 100 / 12;
  const rentPerMonth = (rentalPerWeek * 52) / 12;
  const grossYield = ((rentPerMonth * 12) / propertyPrice) * 100;
  const grossCF = rentPerMonth;
  const interestPerMonth = paymentPurchasePerMonth;
  const principalPerMonth = 0;
  const debtRemaining = debtOnProperty - principalPerMonth;
  const equity = deposit - propertyPrice * 0.1 + value - propertyPrice;
  const equityPullInterestPm =
    availableEquity && interestRate
      ? noOfMonth && noOfMonth === 1
        ? (availableEquity * interestRate) / 100 / 12
        : 0
      : 0;
  const netCF =
    grossCF - (grossCF * 25) / 100 - interestPerMonth - equityPullInterestPm;
  const netYield = (netCF / value) * 100;
  // const equity = value - propertyPrice + principalPerMonth;
  const equityPull = availableEquity
    ? noOfMonth && noOfMonth === 1
      ? -availableEquity
      : 0
    : 0;
  const newEquity = (equity + equityPull) * 0.8;

  result.push({
    month,
    value,
    rentPerMonth,
    grossYield,
    grossCF,
    interestPerMonth,
    netCF,
    netYield,
    principalPerMonth,
    debtRemaining,
    equity,
    newEquity,
    equityPull,
    equityPullInterestPm,
  });

  genMasterTimeline(
    result,
    month + 1,
    propertyPrice,
    value,
    rentPerMonth,
    paymentPurchasePerMonth,
    debtOnProperty,
    availableEquity,
    newInterestPerMonth,
    debtRemaining,
    months,
    growthPercent,
    rentPercent,
    equity,
    interestRate,
    noOfMonth
  );
};

const genMasterTimeline = (
  result,
  month,
  propertyPrice,
  odValue,
  oldRentalPerMonth,
  paymentPurchasePerMonth,
  debtOnProperty,
  availableEquity,
  newInterestPerMonth,
  oldDebtRemaining,
  months,
  newGrowthPercent,
  newRentPercent,
  previousEquity,
  interestRate,
  noOfMonth
) => {
  propertyPrice = propertyPrice ? Number(propertyPrice) : 0;
  odValue = odValue ? Number(odValue) : 0;
  oldRentalPerMonth = oldRentalPerMonth ? Number(oldRentalPerMonth) : 0;
  paymentPurchasePerMonth = paymentPurchasePerMonth
    ? Number(paymentPurchasePerMonth)
    : 0;
  debtOnProperty = debtOnProperty ? Number(debtOnProperty) : 0;
  availableEquity = availableEquity ? Number(availableEquity) : 0;
  newInterestPerMonth = newInterestPerMonth ? Number(newInterestPerMonth) : 0;
  oldDebtRemaining = oldDebtRemaining ? Number(oldDebtRemaining) : 0;

  const growthPercent = genGrowthPercent(month);

  const rentPerMonthPercent = genRentPerMonthPercent(month);

  const value =
    odValue + (odValue * (newGrowthPercent ?? growthPercent)) / 100 / 12;
  const rentPerMonth =
    oldRentalPerMonth +
    (oldRentalPerMonth * (newRentPercent ?? rentPerMonthPercent)) / 100 / 12;
  const grossYield = ((rentPerMonth * 12) / propertyPrice) * 100;
  const grossCF = rentPerMonth;
  const interestPerMonth = paymentPurchasePerMonth;
  const principalPerMonth = 0;
  const debtRemaining = oldDebtRemaining - principalPerMonth;
  const equityPull = availableEquity
    ? noOfMonth && noOfMonth <= result.length + 1
      ? -availableEquity
      : 0
    : 0;
  const equity = previousEquity + value - odValue;
  // const equity = value - propertyPrice + principalPerMonth;
  const newEquity = (equity + equityPull) * 0.8;
  const equityPullInterestPm =
    availableEquity && interestRate
      ? noOfMonth && noOfMonth <= result.length + 1
        ? (availableEquity * interestRate) / 100 / 12
        : 0
      : 0;
  const netCF =
    grossCF - (grossCF * 25) / 100 - interestPerMonth - equityPullInterestPm;
  const netYield = (netCF / value) * 100;
  result.push({
    month,
    value,
    rentPerMonth,
    grossYield,
    grossCF,
    interestPerMonth,
    netCF,
    netYield,
    principalPerMonth,
    debtRemaining,
    equity,
    newEquity,
    equityPull,
    equityPullInterestPm,
  });

  if (result.length < months) {
    genMasterTimeline(
      result,
      month + 1,
      propertyPrice,
      value,
      rentPerMonth,
      interestPerMonth,
      debtRemaining,
      availableEquity,
      newInterestPerMonth,
      debtRemaining,
      months,
      newGrowthPercent,
      newRentPercent,
      equity,
      interestRate,
      noOfMonth
    );
  } else {
    // console.log("🚀 ~ result:", result);
  }
};

// firstMasterLineObject(
//   masterTimelines1,
//   609232,
//   606,
//   1707,
//   520000,
//   121846,
//   2996
// );

// firstMasterLineObject(
//   masterTimelines2,
//   609232,
//   606,
//   3175,
//   520000,
//   121846,
//   3993
// );
