import React, { useState } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import OutsideClickHandler from "react-outside-click-handler";
import FormWidget from "./FormWidget/index.v2";
import styles from "./style.v2";

import { usePermission } from "../../../../hooks/usePermission";

const FinancePlanner = ({ listPurchases }) => {
  const [open, setOpen] = useState(false);

  const openShowDetails = () => setOpen(!open);

  const { isEdit } = usePermission("financePlanner");

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <View style={{ zIndex: 3 }}>
        <div id="finance_planner">
          <TouchableOpacity
            style={[styles.container, open && { borderColor: "#D3B10080" }]}
            onPress={openShowDetails}
            disabled={!isEdit}
          >
            <Image
              resizeMode="cover"
              style={styles.icon}
              source={require("../../../../assets/portfolio-roadmap/calculator.svg")}
            />
            <Text style={styles.text}>Finance Planner</Text>
            <Image
              resizeMode="cover"
              style={styles.arrowDown}
              source={require(`../../../../assets/portfolio-roadmap/arrow-${
                open ? "up" : "down"
              }.svg`)}
            />
          </TouchableOpacity>
        </div>

        <FormWidget
          open={open}
          toggleShowWidget={() => setOpen(false)}
          listPurchases={listPurchases}
        />
      </View>
    </OutsideClickHandler>
  );
};

export default FinancePlanner;
