import { formatDollar, formatUnit } from "../common";

export default {
  appStageName: "App Task & Details Name",
};

export const MARKET_INSIGHT_FIELDS = [
  "sales_median_12mths",
  "tom_12months_median",
  "sales_supply_1mth",
  "rent_supply_1mth",
  "rentalDays_1mth_median",
  "askingRent_median_12mths",
  "rentalYield_median",
  "vendor_discount_median_12mths",
];

export const MARKET_INSIGHT_LABELS = {
  sales_median_12mths: {
    title: "Median Sale Price",
    subTitle: "Median price",
    icon: require("../../assets/market/median-price.svg"),
    formatter: formatDollar,
    chartPdr: 74,
  },
  tom_12months_median: {
    title: "Median Sale Days on Market",
    subTitle: "Days on market",
    icon: require("../../assets/market/days-on-market.svg"),
    ySuffix: " Days",
    chartPdr: 64,
  },
  sales_supply_1mth: {
    title: "Sales Supply per Month",
    subTitle: "Sales supply",
    icon: require("../../assets/market/sales-supply.svg"),
    ySuffix: " Supply",
    unitCard: " Listings",
    chartPdr: 90,
  },
  rent_supply_1mth: {
    title: "Rent Supply per Month",
    subTitle: "Rental supply",
    icon: require("../../assets/market/rental-supply.svg"),
    ySuffix: " Supply",
    unitCard: " Listings",
    chartPdr: 84,
  },
  rentalDays_1mth_median: {
    title: "Median Rental Days on Market",
    subTitle: "Rental D.O.M",
    icon: require("../../assets/market/rental-dom.svg"),
    ySuffix: " Days",
    chartPdr: 70,
  },
  askingRent_median_12mths: {
    title: "Median Rent Asking Price",
    subTitle: "Rental median",
    icon: require("../../assets/market/rental-median.svg"),
    yPrefix: "$",
    ySuffix: " pw",
    chartPdr: 74,
  },
  rentalYield_median: {
    title: "Rental Yield per Month",
    subTitle: "Rental yield",
    icon: require("../../assets/market/rental-yield.svg"),
    ySuffix: "% Yield",
    chartPdr: 86,
  },
  vendor_discount_median_12mths: {
    title: "12 Month Median Vendor Discount",
    subTitle: "Vendor discount",
    icon: require("../../assets/market/vendor-discount.svg"),
    ySuffix: "%",
    chartPdr: 54,
  },
};

export const MARKET_FORMDATA_FIELDS = [
  "sales_median_12mths",
  "asking_rent_median_12mths",
  "rental_yield_12mths_median",
  "avg_hold_days_12mths",
];

export const MARKET_FORMDATA_LABELS = {
  sales_median_12mths: {
    title: "Historical Price growth",
    formatter: formatDollar,
    unit: 0,
  },
  asking_rent_median_12mths: {
    title: "Historical Rent growth",
    formatter: formatDollar,
    unit: 0,
  },
  rental_yield_12mths_median: {
    title: "Historical Yield growth",
    ySuffix: " %",
    formatter: (value) => value,
    unit: 2,
  },
  avg_hold_days_12mths: {
    title: "Historical Time Owned",
    ySuffix: " Years",
    valueUnit: "Days",
    formatter: formatUnit,
    unit: 2,
  },
};
