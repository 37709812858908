export const RENT_REACTIONS = {
  240000: [
    1500, 1513, 1525, 1538, 1551, 1564, 1577, 1590, 1603, 1616, 1630, 1643,
    1657, 1671, 1685, 1699, 1713, 1727, 1742, 1756, 1771, 1786, 1800, 1815,
    1827, 1838, 1850, 1861, 1873, 1885, 1896, 1908, 1920, 1932, 1944, 1956,
    1969, 1981, 1993, 2006, 2018, 2031, 2044, 2056, 2069, 2082, 2095, 2108,
    2117, 2127, 2136, 2145, 2154, 2164, 2173, 2182, 2192, 2201, 2211, 2221,
    2230, 2240, 2250, 2259, 2269, 2279, 2289, 2299, 2309, 2319, 2329, 2339,
    2349, 2359, 2369, 2380, 2390, 2400, 2411, 2421, 2432, 2442, 2453, 2463,
    2474, 2485, 2496, 2506, 2517, 2528, 2539, 2550, 2561, 2572, 2583, 2595,
    2606, 2617, 2628, 2640, 2651, 2663, 2674, 2686, 2698, 2709, 2721, 2733,
    2745, 2756, 2768, 2780, 2792, 2805, 2817, 2829, 2841, 2854, 2866, 2878,
    2891, 2903, 2916, 2929, 2941, 2954, 2967, 2980, 2993, 3005, 3019, 3032,
    3045, 3058, 3071, 3084, 3098, 3111, 3125, 3138, 3152, 3166, 3179, 3193,
    3207, 3221, 3235, 3249, 3263, 3277, 3291, 3305, 3320, 3334, 3349, 3363,
    3378, 3392, 3407, 3422, 3437, 3451, 3466, 3481, 3497, 3512, 3527, 3542,
    3558, 3573, 3588, 3604, 3620, 3635, 3651, 3667, 3683, 3699, 3715, 3731,
    3747, 3763, 3780, 3796, 3812, 3829, 3845, 3862, 3879, 3896, 3913, 3930,
    3947, 3964, 3981, 3998, 4015, 4033, 4050, 4068, 4085, 4103, 4121, 4139,
    4157, 4175, 4193, 4211, 4229, 4248, 4266, 4284, 4303, 4322, 4340, 4359,
    4378, 4397, 4416, 4435, 4455, 4474, 4493, 4513, 4532, 4552, 4572, 4591,
    4611, 4631, 4651, 4672, 4692, 4712, 4732, 4753, 4774, 4794, 4815, 4836,
    4857, 4878, 4899, 4920, 4942, 4963, 4985, 5006, 5028, 5050, 5072, 5093,
    5116, 5138, 5160, 5182, 5205, 5227, 5250, 5273, 5296, 5319, 5342, 5365,
    5388, 5411, 5435, 5458, 5482, 5506, 5530, 5554, 5578, 5602, 5626, 5650,
    5675, 5700, 5724, 5749, 5774, 5799, 5824, 5849, 5875, 5900, 5926, 5951,
    5977, 6003, 6029, 6055, 6081, 6108, 6134, 6161, 6188, 6214, 6241, 6268,
    6296, 6323, 6350, 6378, 6405, 6433, 6461, 6489, 6517, 6545, 6574, 6602,
    6631, 6660, 6688, 6717, 6746, 6776, 6805, 6835, 6864, 6894, 6924, 6954,
    6984, 7014, 7045, 7075, 7106, 7137, 7167, 7199, 7230, 7261, 7293, 7324,
    7356, 7388, 7420, 7452, 7484, 7517, 7549, 7582, 7615, 7648, 7681, 7714,
    7748, 7781, 7815, 7849, 7883, 7917, 7951, 7986, 8020, 8055, 8090, 8125,
  ],
  250000: [
    1563, 1576, 1589, 1602, 1615, 1629, 1642, 1656, 1670, 1684, 1698, 1712,
    1726, 1740, 1755, 1770, 1784, 1799, 1814, 1829, 1845, 1860, 1875, 1891,
    1903, 1915, 1927, 1939, 1951, 1963, 1975, 1988, 2000, 2013, 2025, 2038,
    2051, 2063, 2076, 2089, 2102, 2116, 2129, 2142, 2155, 2169, 2182, 2196,
    2206, 2215, 2225, 2234, 2244, 2254, 2264, 2273, 2283, 2293, 2303, 2313,
    2323, 2333, 2343, 2353, 2364, 2374, 2384, 2394, 2405, 2415, 2426, 2436,
    2447, 2457, 2468, 2479, 2490, 2500, 2511, 2522, 2533, 2544, 2555, 2566,
    2577, 2588, 2600, 2611, 2622, 2633, 2645, 2656, 2668, 2679, 2691, 2703,
    2714, 2726, 2738, 2750, 2762, 2774, 2786, 2798, 2810, 2822, 2834, 2847,
    2859, 2871, 2884, 2896, 2909, 2921, 2934, 2947, 2960, 2972, 2985, 2998,
    3011, 3024, 3037, 3051, 3064, 3077, 3090, 3104, 3117, 3131, 3144, 3158,
    3172, 3185, 3199, 3213, 3227, 3241, 3255, 3269, 3283, 3297, 3312, 3326,
    3340, 3355, 3370, 3384, 3399, 3413, 3428, 3443, 3458, 3473, 3488, 3503,
    3518, 3534, 3549, 3564, 3580, 3595, 3611, 3627, 3642, 3658, 3674, 3690,
    3706, 3722, 3738, 3754, 3770, 3787, 3803, 3820, 3836, 3853, 3870, 3886,
    3903, 3920, 3937, 3954, 3971, 3988, 4006, 4023, 4041, 4058, 4076, 4093,
    4111, 4129, 4147, 4165, 4183, 4201, 4219, 4237, 4256, 4274, 4293, 4311,
    4330, 4349, 4368, 4386, 4405, 4425, 4444, 4463, 4482, 4502, 4521, 4541,
    4561, 4580, 4600, 4620, 4640, 4660, 4680, 4701, 4721, 4742, 4762, 4783,
    4803, 4824, 4845, 4866, 4887, 4908, 4930, 4951, 4973, 4994, 5016, 5037,
    5059, 5081, 5103, 5125, 5148, 5170, 5192, 5215, 5237, 5260, 5283, 5306,
    5329, 5352, 5375, 5398, 5422, 5445, 5469, 5492, 5516, 5540, 5564, 5588,
    5612, 5637, 5661, 5686, 5710, 5735, 5760, 5785, 5810, 5835, 5860, 5886,
    5911, 5937, 5963, 5989, 6015, 6041, 6067, 6093, 6119, 6146, 6173, 6199,
    6226, 6253, 6280, 6308, 6335, 6362, 6390, 6418, 6445, 6473, 6501, 6530,
    6558, 6586, 6615, 6643, 6672, 6701, 6730, 6759, 6789, 6818, 6848, 6877,
    6907, 6937, 6967, 6997, 7028, 7058, 7089, 7119, 7150, 7181, 7212, 7244,
    7275, 7306, 7338, 7370, 7402, 7434, 7466, 7498, 7531, 7564, 7596, 7629,
    7662, 7696, 7729, 7762, 7796, 7830, 7864, 7898, 7932, 7966, 8001, 8036,
    8070, 8105, 8141, 8176, 8211, 8247, 8283, 8318, 8354, 8391, 8427, 8464,
  ],
  260000: [
    1625, 1639, 1652, 1666, 1680, 1694, 1708, 1722, 1737, 1751, 1766, 1780,
    1795, 1810, 1825, 1840, 1856, 1871, 1887, 1903, 1918, 1934, 1950, 1967,
    1979, 1991, 2004, 2016, 2029, 2042, 2054, 2067, 2080, 2093, 2106, 2119,
    2133, 2146, 2159, 2173, 2186, 2200, 2214, 2228, 2242, 2256, 2270, 2284,
    2294, 2304, 2314, 2324, 2334, 2344, 2354, 2364, 2375, 2385, 2395, 2406,
    2416, 2426, 2437, 2448, 2458, 2469, 2480, 2490, 2501, 2512, 2523, 2534,
    2545, 2556, 2567, 2578, 2589, 2600, 2612, 2623, 2634, 2646, 2657, 2669,
    2680, 2692, 2704, 2715, 2727, 2739, 2751, 2763, 2775, 2787, 2799, 2811,
    2823, 2835, 2847, 2860, 2872, 2885, 2897, 2910, 2922, 2935, 2948, 2960,
    2973, 2986, 2999, 3012, 3025, 3038, 3051, 3065, 3078, 3091, 3105, 3118,
    3132, 3145, 3159, 3173, 3186, 3200, 3214, 3228, 3242, 3256, 3270, 3284,
    3298, 3313, 3327, 3342, 3356, 3371, 3385, 3400, 3415, 3429, 3444, 3459,
    3474, 3489, 3504, 3519, 3535, 3550, 3565, 3581, 3596, 3612, 3628, 3643,
    3659, 3675, 3691, 3707, 3723, 3739, 3755, 3772, 3788, 3804, 3821, 3837,
    3854, 3871, 3887, 3904, 3921, 3938, 3955, 3972, 3990, 4007, 4024, 4042,
    4059, 4077, 4095, 4112, 4130, 4148, 4166, 4184, 4202, 4220, 4239, 4257,
    4275, 4294, 4313, 4331, 4350, 4369, 4388, 4407, 4426, 4445, 4464, 4484,
    4503, 4523, 4542, 4562, 4582, 4602, 4622, 4642, 4662, 4682, 4702, 4723,
    4743, 4764, 4784, 4805, 4826, 4847, 4868, 4889, 4910, 4931, 4953, 4974,
    4996, 5017, 5039, 5061, 5083, 5105, 5127, 5149, 5171, 5194, 5216, 5239,
    5262, 5284, 5307, 5330, 5353, 5377, 5400, 5423, 5447, 5470, 5494, 5518,
    5542, 5566, 5590, 5614, 5639, 5663, 5688, 5712, 5737, 5762, 5787, 5812,
    5837, 5862, 5888, 5913, 5939, 5965, 5990, 6016, 6042, 6069, 6095, 6121,
    6148, 6174, 6201, 6228, 6255, 6282, 6309, 6337, 6364, 6392, 6420, 6447,
    6475, 6503, 6532, 6560, 6588, 6617, 6645, 6674, 6703, 6732, 6761, 6791,
    6820, 6850, 6879, 6909, 6939, 6969, 6999, 7030, 7060, 7091, 7121, 7152,
    7183, 7214, 7246, 7277, 7309, 7340, 7372, 7404, 7436, 7468, 7501, 7533,
    7566, 7599, 7632, 7665, 7698, 7731, 7765, 7798, 7832, 7866, 7900, 7934,
    7969, 8003, 8038, 8073, 8108, 8143, 8178, 8214, 8249, 8285, 8321, 8357,
    8393, 8430, 8466, 8503, 8540, 8577, 8614, 8651, 8689, 8726, 8764, 8802,
  ],
  270000: [
    1688, 1702, 1716, 1730, 1744, 1759, 1774, 1788, 1803, 1818, 1834, 1849,
    1864, 1880, 1895, 1911, 1927, 1943, 1959, 1976, 1992, 2009, 2026, 2042,
    2055, 2068, 2081, 2094, 2107, 2120, 2133, 2147, 2160, 2174, 2187, 2201,
    2215, 2229, 2242, 2256, 2271, 2285, 2299, 2313, 2328, 2342, 2357, 2372,
    2382, 2392, 2403, 2413, 2424, 2434, 2445, 2455, 2466, 2477, 2487, 2498,
    2509, 2520, 2531, 2542, 2553, 2564, 2575, 2586, 2597, 2609, 2620, 2631,
    2643, 2654, 2666, 2677, 2689, 2700, 2712, 2724, 2736, 2747, 2759, 2771,
    2783, 2795, 2807, 2820, 2832, 2844, 2856, 2869, 2881, 2894, 2906, 2919,
    2932, 2944, 2957, 2970, 2983, 2996, 3009, 3022, 3035, 3048, 3061, 3074,
    3088, 3101, 3114, 3128, 3142, 3155, 3169, 3183, 3196, 3210, 3224, 3238,
    3252, 3266, 3280, 3295, 3309, 3323, 3338, 3352, 3367, 3381, 3396, 3411,
    3425, 3440, 3455, 3470, 3485, 3500, 3515, 3531, 3546, 3561, 3577, 3592,
    3608, 3623, 3639, 3655, 3671, 3687, 3703, 3719, 3735, 3751, 3767, 3783,
    3800, 3816, 3833, 3849, 3866, 3883, 3900, 3917, 3934, 3951, 3968, 3985,
    4002, 4020, 4037, 4054, 4072, 4090, 4107, 4125, 4143, 4161, 4179, 4197,
    4215, 4234, 4252, 4270, 4289, 4308, 4326, 4345, 4364, 4383, 4402, 4421,
    4440, 4459, 4478, 4498, 4517, 4537, 4557, 4576, 4596, 4616, 4636, 4656,
    4676, 4697, 4717, 4737, 4758, 4779, 4799, 4820, 4841, 4862, 4883, 4904,
    4925, 4947, 4968, 4990, 5011, 5033, 5055, 5077, 5099, 5121, 5143, 5165,
    5188, 5210, 5233, 5255, 5278, 5301, 5324, 5347, 5370, 5394, 5417, 5440,
    5464, 5488, 5511, 5535, 5559, 5583, 5608, 5632, 5656, 5681, 5705, 5730,
    5755, 5780, 5805, 5830, 5855, 5881, 5906, 5932, 5958, 5983, 6009, 6035,
    6061, 6088, 6114, 6141, 6167, 6194, 6221, 6248, 6275, 6302, 6329, 6357,
    6384, 6412, 6440, 6468, 6496, 6524, 6552, 6581, 6609, 6638, 6666, 6695,
    6724, 6753, 6783, 6812, 6842, 6871, 6901, 6931, 6961, 6991, 7021, 7052,
    7082, 7113, 7144, 7175, 7206, 7237, 7269, 7300, 7332, 7363, 7395, 7427,
    7460, 7492, 7524, 7557, 7590, 7623, 7656, 7689, 7722, 7756, 7789, 7823,
    7857, 7891, 7925, 7959, 7994, 8029, 8063, 8098, 8133, 8169, 8204, 8240,
    8275, 8311, 8347, 8383, 8420, 8456, 8493, 8530, 8567, 8604, 8641, 8678,
    8716, 8754, 8792, 8830, 8868, 8907, 8945, 8984, 9023, 9062, 9101, 9141,
  ],
  280000: [
    1750, 1765, 1779, 1794, 1809, 1824, 1839, 1855, 1870, 1886, 1901, 1917,
    1933, 1949, 1966, 1982, 1998, 2015, 2032, 2049, 2066, 2083, 2101, 2118,
    2131, 2145, 2158, 2171, 2185, 2199, 2212, 2226, 2240, 2254, 2268, 2282,
    2297, 2311, 2326, 2340, 2355, 2369, 2384, 2399, 2414, 2429, 2444, 2460,
    2470, 2481, 2492, 2503, 2513, 2524, 2535, 2546, 2557, 2568, 2579, 2591,
    2602, 2613, 2624, 2636, 2647, 2659, 2670, 2682, 2693, 2705, 2717, 2729,
    2740, 2752, 2764, 2776, 2788, 2800, 2812, 2825, 2837, 2849, 2862, 2874,
    2886, 2899, 2911, 2924, 2937, 2949, 2962, 2975, 2988, 3001, 3014, 3027,
    3040, 3053, 3067, 3080, 3093, 3107, 3120, 3134, 3147, 3161, 3174, 3188,
    3202, 3216, 3230, 3244, 3258, 3272, 3286, 3300, 3315, 3329, 3344, 3358,
    3373, 3387, 3402, 3417, 3431, 3446, 3461, 3476, 3491, 3506, 3522, 3537,
    3552, 3568, 3583, 3599, 3614, 3630, 3646, 3661, 3677, 3693, 3709, 3725,
    3741, 3758, 3774, 3790, 3807, 3823, 3840, 3856, 3873, 3890, 3907, 3924,
    3941, 3958, 3975, 3992, 4009, 4027, 4044, 4062, 4079, 4097, 4115, 4133,
    4150, 4168, 4187, 4205, 4223, 4241, 4260, 4278, 4297, 4315, 4334, 4353,
    4372, 4390, 4409, 4429, 4448, 4467, 4486, 4506, 4525, 4545, 4565, 4584,
    4604, 4624, 4644, 4664, 4685, 4705, 4725, 4746, 4766, 4787, 4808, 4829,
    4850, 4871, 4892, 4913, 4934, 4956, 4977, 4999, 5020, 5042, 5064, 5086,
    5108, 5130, 5152, 5175, 5197, 5219, 5242, 5265, 5288, 5311, 5334, 5357,
    5380, 5403, 5427, 5450, 5474, 5497, 5521, 5545, 5569, 5593, 5618, 5642,
    5666, 5691, 5716, 5740, 5765, 5790, 5815, 5840, 5866, 5891, 5917, 5942,
    5968, 5994, 6020, 6046, 6072, 6099, 6125, 6152, 6178, 6205, 6232, 6259,
    6286, 6313, 6341, 6368, 6396, 6423, 6451, 6479, 6507, 6535, 6564, 6592,
    6621, 6649, 6678, 6707, 6736, 6765, 6795, 6824, 6854, 6883, 6913, 6943,
    6973, 7004, 7034, 7064, 7095, 7126, 7157, 7188, 7219, 7250, 7282, 7313,
    7345, 7377, 7409, 7441, 7473, 7505, 7538, 7570, 7603, 7636, 7669, 7703,
    7736, 7769, 7803, 7837, 7871, 7905, 7939, 7974, 8008, 8043, 8078, 8113,
    8148, 8183, 8219, 8254, 8290, 8326, 8362, 8398, 8435, 8471, 8508, 8545,
    8582, 8619, 8656, 8694, 8732, 8769, 8807, 8846, 8884, 8922, 8961, 9000,
    9039, 9078, 9117, 9157, 9197, 9236, 9276, 9317, 9357, 9398, 9438, 9479,
  ],
  290000: [
    1813, 1828, 1843, 1858, 1874, 1889, 1905, 1921, 1937, 1953, 1969, 1986,
    2002, 2019, 2036, 2053, 2070, 2087, 2105, 2122, 2140, 2158, 2176, 2194,
    2207, 2221, 2235, 2249, 2263, 2277, 2291, 2306, 2320, 2335, 2349, 2364,
    2379, 2394, 2409, 2424, 2439, 2454, 2469, 2485, 2500, 2516, 2532, 2548,
    2559, 2570, 2581, 2592, 2603, 2614, 2626, 2637, 2649, 2660, 2672, 2683,
    2695, 2706, 2718, 2730, 2742, 2754, 2766, 2778, 2790, 2802, 2814, 2826,
    2838, 2851, 2863, 2875, 2888, 2900, 2913, 2926, 2938, 2951, 2964, 2977,
    2989, 3002, 3015, 3029, 3042, 3055, 3068, 3081, 3095, 3108, 3122, 3135,
    3149, 3162, 3176, 3190, 3204, 3218, 3231, 3245, 3260, 3274, 3288, 3302,
    3316, 3331, 3345, 3360, 3374, 3389, 3404, 3418, 3433, 3448, 3463, 3478,
    3493, 3508, 3523, 3539, 3554, 3569, 3585, 3600, 3616, 3632, 3647, 3663,
    3679, 3695, 3711, 3727, 3743, 3759, 3776, 3792, 3809, 3825, 3842, 3858,
    3875, 3892, 3909, 3926, 3943, 3960, 3977, 3994, 4011, 4029, 4046, 4064,
    4081, 4099, 4117, 4135, 4153, 4171, 4189, 4207, 4225, 4243, 4262, 4280,
    4299, 4317, 4336, 4355, 4374, 4393, 4412, 4431, 4450, 4469, 4489, 4508,
    4528, 4547, 4567, 4587, 4607, 4627, 4647, 4667, 4687, 4707, 4728, 4748,
    4769, 4789, 4810, 4831, 4852, 4873, 4894, 4915, 4937, 4958, 4979, 5001,
    5023, 5045, 5066, 5088, 5110, 5133, 5155, 5177, 5200, 5222, 5245, 5267,
    5290, 5313, 5336, 5359, 5383, 5406, 5429, 5453, 5476, 5500, 5524, 5548,
    5572, 5596, 5620, 5645, 5669, 5694, 5718, 5743, 5768, 5793, 5818, 5843,
    5869, 5894, 5920, 5945, 5971, 5997, 6023, 6049, 6075, 6102, 6128, 6155,
    6181, 6208, 6235, 6262, 6289, 6316, 6344, 6371, 6399, 6427, 6454, 6482,
    6510, 6539, 6567, 6595, 6624, 6653, 6682, 6711, 6740, 6769, 6798, 6828,
    6857, 6887, 6917, 6947, 6977, 7007, 7037, 7068, 7099, 7129, 7160, 7191,
    7222, 7254, 7285, 7317, 7348, 7380, 7412, 7444, 7477, 7509, 7542, 7574,
    7607, 7640, 7673, 7706, 7740, 7773, 7807, 7841, 7875, 7909, 7943, 7978,
    8012, 8047, 8082, 8117, 8152, 8187, 8223, 8258, 8294, 8330, 8366, 8402,
    8439, 8475, 8512, 8549, 8586, 8623, 8661, 8698, 8736, 8774, 8812, 8850,
    8888, 8927, 8966, 9004, 9043, 9083, 9122, 9161, 9201, 9241, 9281, 9321,
    9362, 9402, 9443, 9484, 9525, 9566, 9608, 9649, 9691, 9733, 9775, 9818,
  ],
  300000: [
    1875, 1891, 1906, 1922, 1938, 1954, 1971, 1987, 2004, 2020, 2037, 2054,
    2071, 2089, 2106, 2124, 2141, 2159, 2177, 2195, 2214, 2232, 2251, 2269,
    2284, 2298, 2312, 2327, 2341, 2356, 2370, 2385, 2400, 2415, 2430, 2445,
    2461, 2476, 2492, 2507, 2523, 2539, 2555, 2570, 2587, 2603, 2619, 2635,
    2647, 2658, 2670, 2681, 2693, 2705, 2716, 2728, 2740, 2752, 2764, 2776,
    2788, 2800, 2812, 2824, 2836, 2849, 2861, 2873, 2886, 2898, 2911, 2924,
    2936, 2949, 2962, 2975, 2987, 3000, 3013, 3026, 3040, 3053, 3066, 3079,
    3093, 3106, 3119, 3133, 3147, 3160, 3174, 3188, 3201, 3215, 3229, 3243,
    3257, 3271, 3286, 3300, 3314, 3328, 3343, 3357, 3372, 3387, 3401, 3416,
    3431, 3446, 3461, 3476, 3491, 3506, 3521, 3536, 3551, 3567, 3582, 3598,
    3613, 3629, 3645, 3661, 3676, 3692, 3708, 3724, 3741, 3757, 3773, 3789,
    3806, 3822, 3839, 3856, 3872, 3889, 3906, 3923, 3940, 3957, 3974, 3991,
    4009, 4026, 4043, 4061, 4079, 4096, 4114, 4132, 4150, 4168, 4186, 4204,
    4222, 4240, 4259, 4277, 4296, 4314, 4333, 4352, 4371, 4390, 4409, 4428,
    4447, 4466, 4486, 4505, 4525, 4544, 4564, 4584, 4603, 4623, 4643, 4664,
    4684, 4704, 4724, 4745, 4765, 4786, 4807, 4828, 4849, 4870, 4891, 4912,
    4933, 4955, 4976, 4998, 5019, 5041, 5063, 5085, 5107, 5129, 5151, 5174,
    5196, 5218, 5241, 5264, 5287, 5310, 5333, 5356, 5379, 5402, 5426, 5449,
    5473, 5496, 5520, 5544, 5568, 5592, 5617, 5641, 5665, 5690, 5714, 5739,
    5764, 5789, 5814, 5839, 5865, 5890, 5916, 5941, 5967, 5993, 6019, 6045,
    6071, 6097, 6124, 6150, 6177, 6204, 6231, 6258, 6285, 6312, 6339, 6367,
    6394, 6422, 6450, 6478, 6506, 6534, 6563, 6591, 6620, 6648, 6677, 6706,
    6735, 6764, 6793, 6823, 6852, 6882, 6912, 6942, 6972, 7002, 7033, 7063,
    7094, 7124, 7155, 7186, 7217, 7249, 7280, 7312, 7343, 7375, 7407, 7439,
    7471, 7504, 7536, 7569, 7602, 7635, 7668, 7701, 7734, 7768, 7802, 7835,
    7869, 7903, 7938, 7972, 8007, 8041, 8076, 8111, 8146, 8182, 8217, 8253,
    8288, 8324, 8360, 8397, 8433, 8470, 8506, 8543, 8580, 8617, 8655, 8692,
    8730, 8768, 8806, 8844, 8882, 8921, 8959, 8998, 9037, 9076, 9116, 9155,
    9195, 9235, 9275, 9315, 9355, 9396, 9437, 9477, 9518, 9560, 9601, 9643,
    9685, 9726, 9769, 9811, 9853, 9896, 9939, 9982, 10025, 10069, 10112, 10156,
  ],
  310000: [
    1912, 1928, 1944, 1960, 1976, 1993, 2009, 2026, 2043, 2060, 2077, 2094,
    2112, 2129, 2147, 2165, 2183, 2201, 2220, 2238, 2257, 2276, 2295, 2314,
    2328, 2343, 2357, 2372, 2387, 2402, 2417, 2432, 2447, 2462, 2478, 2493,
    2509, 2525, 2540, 2556, 2572, 2588, 2604, 2621, 2637, 2654, 2670, 2687,
    2699, 2710, 2722, 2734, 2746, 2758, 2769, 2781, 2794, 2806, 2818, 2830,
    2842, 2855, 2867, 2879, 2892, 2904, 2917, 2930, 2942, 2955, 2968, 2981,
    2994, 3007, 3020, 3033, 3046, 3059, 3072, 3086, 3099, 3112, 3126, 3139,
    3153, 3167, 3180, 3194, 3208, 3222, 3236, 3250, 3264, 3278, 3292, 3307,
    3321, 3335, 3350, 3364, 3379, 3394, 3408, 3423, 3438, 3453, 3468, 3483,
    3498, 3513, 3528, 3544, 3559, 3574, 3590, 3605, 3621, 3637, 3652, 3668,
    3684, 3700, 3716, 3732, 3748, 3765, 3781, 3797, 3814, 3830, 3847, 3864,
    3880, 3897, 3914, 3931, 3948, 3965, 3982, 4000, 4017, 4034, 4052, 4069,
    4087, 4105, 4122, 4140, 4158, 4176, 4194, 4213, 4231, 4249, 4268, 4286,
    4305, 4323, 4342, 4361, 4380, 4399, 4418, 4437, 4456, 4475, 4495, 4514,
    4534, 4554, 4573, 4593, 4613, 4633, 4653, 4673, 4693, 4714, 4734, 4755,
    4775, 4796, 4817, 4838, 4859, 4880, 4901, 4922, 4943, 4965, 4986, 5008,
    5030, 5051, 5073, 5095, 5117, 5140, 5162, 5184, 5207, 5229, 5252, 5275,
    5298, 5321, 5344, 5367, 5390, 5413, 5437, 5460, 5484, 5508, 5532, 5556,
    5580, 5604, 5628, 5653, 5677, 5702, 5726, 5751, 5776, 5801, 5826, 5851,
    5877, 5902, 5928, 5954, 5979, 6005, 6031, 6057, 6084, 6110, 6137, 6163,
    6190, 6217, 6244, 6271, 6298, 6325, 6353, 6380, 6408, 6435, 6463, 6491,
    6519, 6548, 6576, 6605, 6633, 6662, 6691, 6720, 6749, 6778, 6808, 6837,
    6867, 6896, 6926, 6956, 6986, 7017, 7047, 7078, 7108, 7139, 7170, 7201,
    7232, 7264, 7295, 7327, 7359, 7390, 7422, 7455, 7487, 7519, 7552, 7585,
    7618, 7651, 7684, 7717, 7750, 7784, 7818, 7852, 7886, 7920, 7954, 7989,
    8023, 8058, 8093, 8128, 8163, 8199, 8234, 8270, 8306, 8342, 8378, 8414,
    8451, 8487, 8524, 8561, 8598, 8635, 8673, 8710, 8748, 8786, 8824, 8862,
    8901, 8939, 8978, 9017, 9056, 9095, 9135, 9174, 9214, 9254, 9294, 9334,
    9375, 9415, 9456, 9497, 9538, 9580, 9621, 9663, 9705, 9747, 9789, 9831,
    9874, 9917, 9960, 10003, 10046, 10090, 10133, 10177, 10221, 10266, 10310,
    10355,
  ],
  320000: [
    1947, 1963, 1979, 1996, 2012, 2029, 2046, 2063, 2080, 2098, 2115, 2133,
    2151, 2168, 2186, 2205, 2223, 2242, 2260, 2279, 2298, 2317, 2337, 2356,
    2371, 2386, 2401, 2416, 2431, 2446, 2461, 2476, 2492, 2508, 2523, 2539,
    2555, 2571, 2587, 2603, 2619, 2636, 2652, 2669, 2685, 2702, 2719, 2736,
    2748, 2760, 2772, 2784, 2796, 2808, 2820, 2832, 2845, 2857, 2869, 2882,
    2894, 2907, 2919, 2932, 2945, 2958, 2970, 2983, 2996, 3009, 3022, 3035,
    3048, 3062, 3075, 3088, 3102, 3115, 3129, 3142, 3156, 3169, 3183, 3197,
    3211, 3225, 3239, 3253, 3267, 3281, 3295, 3309, 3324, 3338, 3353, 3367,
    3382, 3396, 3411, 3426, 3441, 3456, 3471, 3486, 3501, 3516, 3531, 3547,
    3562, 3577, 3593, 3608, 3624, 3640, 3655, 3671, 3687, 3703, 3719, 3735,
    3752, 3768, 3784, 3801, 3817, 3834, 3850, 3867, 3884, 3900, 3917, 3934,
    3951, 3968, 3986, 4003, 4020, 4038, 4055, 4073, 4090, 4108, 4126, 4144,
    4162, 4180, 4198, 4216, 4234, 4253, 4271, 4290, 4308, 4327, 4346, 4365,
    4383, 4402, 4422, 4441, 4460, 4479, 4499, 4518, 4538, 4557, 4577, 4597,
    4617, 4637, 4657, 4677, 4697, 4718, 4738, 4759, 4779, 4800, 4821, 4842,
    4863, 4884, 4905, 4926, 4948, 4969, 4991, 5012, 5034, 5056, 5078, 5100,
    5122, 5144, 5166, 5189, 5211, 5234, 5256, 5279, 5302, 5325, 5348, 5371,
    5395, 5418, 5441, 5465, 5489, 5512, 5536, 5560, 5584, 5609, 5633, 5657,
    5682, 5706, 5731, 5756, 5781, 5806, 5831, 5856, 5882, 5907, 5933, 5959,
    5984, 6010, 6036, 6063, 6089, 6115, 6142, 6168, 6195, 6222, 6249, 6276,
    6303, 6330, 6358, 6385, 6413, 6441, 6469, 6497, 6525, 6553, 6582, 6610,
    6639, 6668, 6697, 6726, 6755, 6784, 6813, 6843, 6873, 6902, 6932, 6962,
    6992, 7023, 7053, 7084, 7114, 7145, 7176, 7207, 7239, 7270, 7301, 7333,
    7365, 7397, 7429, 7461, 7493, 7526, 7558, 7591, 7624, 7657, 7690, 7724,
    7757, 7791, 7824, 7858, 7892, 7927, 7961, 7995, 8030, 8065, 8100, 8135,
    8170, 8206, 8241, 8277, 8313, 8349, 8385, 8421, 8458, 8494, 8531, 8568,
    8605, 8643, 8680, 8718, 8755, 8793, 8831, 8870, 8908, 8947, 8986, 9024,
    9064, 9103, 9142, 9182, 9222, 9262, 9302, 9342, 9383, 9423, 9464, 9505,
    9546, 9588, 9629, 9671, 9713, 9755, 9797, 9840, 9882, 9925, 9968, 10011,
    10055, 10098, 10142, 10186, 10230, 10274, 10319, 10364, 10409, 10454, 10499,
    10544,
  ],
  330000: [
    1980, 1997, 2013, 2030, 2047, 2064, 2081, 2098, 2116, 2134, 2151, 2169,
    2187, 2206, 2224, 2242, 2261, 2280, 2299, 2318, 2337, 2357, 2377, 2396,
    2411, 2426, 2442, 2457, 2472, 2488, 2503, 2519, 2535, 2550, 2566, 2582,
    2599, 2615, 2631, 2648, 2664, 2681, 2698, 2714, 2731, 2748, 2766, 2783,
    2795, 2807, 2819, 2831, 2844, 2856, 2868, 2881, 2893, 2906, 2918, 2931,
    2944, 2957, 2969, 2982, 2995, 3008, 3021, 3034, 3047, 3061, 3074, 3087,
    3101, 3114, 3128, 3141, 3155, 3168, 3182, 3196, 3210, 3224, 3238, 3252,
    3266, 3280, 3294, 3308, 3323, 3337, 3352, 3366, 3381, 3395, 3410, 3425,
    3440, 3455, 3470, 3485, 3500, 3515, 3530, 3545, 3561, 3576, 3592, 3607,
    3623, 3639, 3654, 3670, 3686, 3702, 3718, 3734, 3750, 3767, 3783, 3799,
    3816, 3832, 3849, 3866, 3882, 3899, 3916, 3933, 3950, 3967, 3984, 4002,
    4019, 4036, 4054, 4072, 4089, 4107, 4125, 4143, 4160, 4179, 4197, 4215,
    4233, 4251, 4270, 4288, 4307, 4326, 4344, 4363, 4382, 4401, 4420, 4439,
    4459, 4478, 4497, 4517, 4536, 4556, 4576, 4596, 4615, 4635, 4656, 4676,
    4696, 4716, 4737, 4757, 4778, 4799, 4819, 4840, 4861, 4882, 4903, 4925,
    4946, 4967, 4989, 5011, 5032, 5054, 5076, 5098, 5120, 5142, 5165, 5187,
    5209, 5232, 5255, 5277, 5300, 5323, 5346, 5370, 5393, 5416, 5440, 5463,
    5487, 5511, 5535, 5559, 5583, 5607, 5631, 5656, 5680, 5705, 5729, 5754,
    5779, 5804, 5829, 5855, 5880, 5905, 5931, 5957, 5983, 6008, 6035, 6061,
    6087, 6113, 6140, 6166, 6193, 6220, 6247, 6274, 6301, 6328, 6356, 6383,
    6411, 6439, 6467, 6495, 6523, 6551, 6580, 6608, 6637, 6665, 6694, 6723,
    6753, 6782, 6811, 6841, 6870, 6900, 6930, 6960, 6990, 7020, 7051, 7081,
    7112, 7143, 7174, 7205, 7236, 7268, 7299, 7331, 7362, 7394, 7426, 7459,
    7491, 7523, 7556, 7589, 7622, 7655, 7688, 7721, 7755, 7788, 7822, 7856,
    7890, 7924, 7958, 7993, 8028, 8062, 8097, 8132, 8168, 8203, 8239, 8274,
    8310, 8346, 8382, 8419, 8455, 8492, 8528, 8565, 8603, 8640, 8677, 8715,
    8753, 8791, 8829, 8867, 8905, 8944, 8983, 9022, 9061, 9100, 9139, 9179,
    9219, 9259, 9299, 9339, 9380, 9420, 9461, 9502, 9543, 9585, 9626, 9668,
    9710, 9752, 9794, 9837, 9879, 9922, 9965, 10008, 10052, 10095, 10139, 10183,
    10227, 10271, 10316, 10360, 10405, 10450, 10496, 10541, 10587, 10633, 10679,
    10725,
  ],
  340000: [
    2012, 2028, 2045, 2062, 2080, 2097, 2114, 2132, 2150, 2168, 2186, 2204,
    2222, 2241, 2260, 2278, 2297, 2316, 2336, 2355, 2375, 2395, 2415, 2435,
    2450, 2465, 2481, 2496, 2512, 2527, 2543, 2559, 2575, 2591, 2607, 2624,
    2640, 2657, 2673, 2690, 2707, 2724, 2741, 2758, 2775, 2792, 2810, 2827,
    2840, 2852, 2864, 2877, 2889, 2902, 2914, 2927, 2940, 2952, 2965, 2978,
    2991, 3004, 3017, 3030, 3043, 3056, 3070, 3083, 3096, 3110, 3123, 3137,
    3150, 3164, 3178, 3191, 3205, 3219, 3233, 3247, 3261, 3275, 3289, 3304,
    3318, 3332, 3347, 3361, 3376, 3390, 3405, 3420, 3435, 3450, 3465, 3480,
    3495, 3510, 3525, 3540, 3556, 3571, 3587, 3602, 3618, 3633, 3649, 3665,
    3681, 3697, 3713, 3729, 3745, 3761, 3778, 3794, 3810, 3827, 3843, 3860,
    3877, 3894, 3911, 3927, 3944, 3962, 3979, 3996, 4013, 4031, 4048, 4066,
    4083, 4101, 4119, 4137, 4155, 4173, 4191, 4209, 4227, 4245, 4264, 4282,
    4301, 4319, 4338, 4357, 4376, 4395, 4414, 4433, 4452, 4471, 4491, 4510,
    4530, 4549, 4569, 4589, 4609, 4629, 4649, 4669, 4689, 4710, 4730, 4750,
    4771, 4792, 4813, 4833, 4854, 4875, 4896, 4918, 4939, 4960, 4982, 5003,
    5025, 5047, 5069, 5091, 5113, 5135, 5157, 5180, 5202, 5225, 5247, 5270,
    5293, 5316, 5339, 5362, 5385, 5408, 5432, 5455, 5479, 5503, 5527, 5551,
    5575, 5599, 5623, 5647, 5672, 5697, 5721, 5746, 5771, 5796, 5821, 5846,
    5872, 5897, 5923, 5948, 5974, 6000, 6026, 6052, 6078, 6105, 6131, 6158,
    6184, 6211, 6238, 6265, 6292, 6319, 6347, 6374, 6402, 6430, 6458, 6486,
    6514, 6542, 6570, 6599, 6627, 6656, 6685, 6714, 6743, 6772, 6801, 6831,
    6861, 6890, 6920, 6950, 6980, 7010, 7041, 7071, 7102, 7133, 7164, 7195,
    7226, 7257, 7289, 7320, 7352, 7384, 7416, 7448, 7480, 7513, 7545, 7578,
    7611, 7644, 7677, 7710, 7744, 7777, 7811, 7845, 7879, 7913, 7947, 7981,
    8016, 8051, 8086, 8121, 8156, 8191, 8227, 8262, 8298, 8334, 8370, 8407,
    8443, 8480, 8516, 8553, 8590, 8627, 8665, 8702, 8740, 8778, 8816, 8854,
    8893, 8931, 8970, 9009, 9048, 9087, 9126, 9166, 9206, 9246, 9286, 9326,
    9366, 9407, 9448, 9489, 9530, 9571, 9612, 9654, 9696, 9738, 9780, 9822,
    9865, 9908, 9951, 9994, 10037, 10081, 10124, 10168, 10212, 10257, 10301,
    10346, 10390, 10435, 10481, 10526, 10572, 10618, 10664, 10710, 10756, 10803,
    10850, 10897,
  ],
  350000: [
    2042, 2059, 2076, 2093, 2111, 2128, 2146, 2164, 2182, 2200, 2218, 2237,
    2255, 2274, 2293, 2312, 2332, 2351, 2371, 2390, 2410, 2430, 2451, 2471,
    2486, 2502, 2518, 2533, 2549, 2565, 2581, 2597, 2614, 2630, 2646, 2663,
    2680, 2696, 2713, 2730, 2747, 2764, 2782, 2799, 2816, 2834, 2852, 2870,
    2882, 2895, 2907, 2920, 2932, 2945, 2958, 2971, 2983, 2996, 3009, 3022,
    3036, 3049, 3062, 3075, 3088, 3102, 3115, 3129, 3142, 3156, 3170, 3183,
    3197, 3211, 3225, 3239, 3253, 3267, 3281, 3295, 3310, 3324, 3338, 3353,
    3367, 3382, 3397, 3411, 3426, 3441, 3456, 3471, 3486, 3501, 3516, 3532,
    3547, 3562, 3578, 3593, 3609, 3624, 3640, 3656, 3672, 3688, 3704, 3720,
    3736, 3752, 3768, 3784, 3801, 3817, 3834, 3850, 3867, 3884, 3901, 3918,
    3935, 3952, 3969, 3986, 4003, 4021, 4038, 4056, 4073, 4091, 4109, 4126,
    4144, 4162, 4180, 4198, 4217, 4235, 4253, 4272, 4290, 4309, 4327, 4346,
    4365, 4384, 4403, 4422, 4441, 4460, 4480, 4499, 4519, 4538, 4558, 4578,
    4597, 4617, 4637, 4657, 4678, 4698, 4718, 4739, 4759, 4780, 4801, 4821,
    4842, 4863, 4884, 4905, 4927, 4948, 4969, 4991, 5013, 5034, 5056, 5078,
    5100, 5122, 5144, 5167, 5189, 5212, 5234, 5257, 5280, 5302, 5325, 5349,
    5372, 5395, 5418, 5442, 5465, 5489, 5513, 5537, 5561, 5585, 5609, 5633,
    5658, 5682, 5707, 5732, 5757, 5781, 5807, 5832, 5857, 5882, 5908, 5933,
    5959, 5985, 6011, 6037, 6063, 6089, 6116, 6142, 6169, 6196, 6222, 6249,
    6276, 6304, 6331, 6358, 6386, 6414, 6441, 6469, 6497, 6526, 6554, 6582,
    6611, 6639, 6668, 6697, 6726, 6755, 6785, 6814, 6843, 6873, 6903, 6933,
    6963, 6993, 7023, 7054, 7084, 7115, 7146, 7177, 7208, 7239, 7271, 7302,
    7334, 7365, 7397, 7429, 7462, 7494, 7526, 7559, 7592, 7625, 7658, 7691,
    7724, 7758, 7791, 7825, 7859, 7893, 7927, 7962, 7996, 8031, 8066, 8100,
    8136, 8171, 8206, 8242, 8278, 8313, 8349, 8386, 8422, 8458, 8495, 8532,
    8569, 8606, 8643, 8681, 8718, 8756, 8794, 8832, 8870, 8909, 8948, 8986,
    9025, 9064, 9104, 9143, 9183, 9222, 9262, 9303, 9343, 9383, 9424, 9465,
    9506, 9547, 9588, 9630, 9672, 9714, 9756, 9798, 9840, 9883, 9926, 9969,
    10012, 10056, 10099, 10143, 10187, 10231, 10275, 10320, 10365, 10409, 10455,
    10500, 10545, 10591, 10637, 10683, 10729, 10776, 10823, 10869, 10917, 10964,
    11011, 11059,
  ],
  360000: [
    2070, 2087, 2105, 2122, 2140, 2158, 2176, 2194, 2212, 2231, 2249, 2268,
    2287, 2306, 2325, 2344, 2364, 2384, 2404, 2424, 2444, 2464, 2485, 2505,
    2521, 2537, 2553, 2569, 2585, 2601, 2617, 2633, 2650, 2666, 2683, 2700,
    2717, 2734, 2751, 2768, 2785, 2803, 2820, 2838, 2856, 2873, 2891, 2909,
    2922, 2935, 2947, 2960, 2973, 2986, 2999, 3012, 3025, 3038, 3051, 3064,
    3078, 3091, 3104, 3118, 3131, 3145, 3159, 3172, 3186, 3200, 3214, 3228,
    3242, 3256, 3270, 3284, 3298, 3312, 3327, 3341, 3356, 3370, 3385, 3399,
    3414, 3429, 3444, 3459, 3474, 3489, 3504, 3519, 3534, 3550, 3565, 3581,
    3596, 3612, 3627, 3643, 3659, 3675, 3691, 3707, 3723, 3739, 3755, 3771,
    3788, 3804, 3820, 3837, 3854, 3870, 3887, 3904, 3921, 3938, 3955, 3972,
    3989, 4007, 4024, 4041, 4059, 4076, 4094, 4112, 4130, 4148, 4166, 4184,
    4202, 4220, 4238, 4257, 4275, 4294, 4312, 4331, 4350, 4368, 4387, 4406,
    4425, 4445, 4464, 4483, 4503, 4522, 4542, 4561, 4581, 4601, 4621, 4641,
    4661, 4681, 4702, 4722, 4742, 4763, 4784, 4804, 4825, 4846, 4867, 4888,
    4909, 4931, 4952, 4974, 4995, 5017, 5038, 5060, 5082, 5104, 5126, 5149,
    5171, 5193, 5216, 5238, 5261, 5284, 5307, 5330, 5353, 5376, 5399, 5423,
    5446, 5470, 5494, 5517, 5541, 5565, 5589, 5614, 5638, 5662, 5687, 5712,
    5736, 5761, 5786, 5811, 5836, 5862, 5887, 5913, 5938, 5964, 5990, 6016,
    6042, 6068, 6094, 6121, 6147, 6174, 6201, 6227, 6254, 6282, 6309, 6336,
    6364, 6391, 6419, 6447, 6475, 6503, 6531, 6559, 6588, 6616, 6645, 6674,
    6703, 6732, 6761, 6790, 6819, 6849, 6879, 6908, 6938, 6968, 6999, 7029,
    7059, 7090, 7121, 7152, 7183, 7214, 7245, 7276, 7308, 7340, 7371, 7403,
    7435, 7468, 7500, 7533, 7565, 7598, 7631, 7664, 7697, 7730, 7764, 7798,
    7831, 7865, 7899, 7934, 7968, 8003, 8037, 8072, 8107, 8142, 8177, 8213,
    8249, 8284, 8320, 8356, 8392, 8429, 8465, 8502, 8539, 8576, 8613, 8650,
    8688, 8725, 8763, 8801, 8839, 8878, 8916, 8955, 8994, 9033, 9072, 9111,
    9150, 9190, 9230, 9270, 9310, 9350, 9391, 9432, 9473, 9514, 9555, 9596,
    9638, 9680, 9722, 9764, 9806, 9848, 9891, 9934, 9977, 10020, 10064, 10107,
    10151, 10195, 10239, 10284, 10328, 10373, 10418, 10463, 10508, 10554, 10600,
    10646, 10692, 10738, 10785, 10831, 10878, 10925, 10973, 11020, 11068, 11116,
    11164, 11213,
  ],
  370000: [
    2097, 2114, 2132, 2150, 2167, 2185, 2204, 2222, 2241, 2259, 2278, 2297,
    2316, 2336, 2355, 2375, 2394, 2414, 2434, 2455, 2475, 2496, 2517, 2538,
    2553, 2569, 2585, 2602, 2618, 2634, 2651, 2667, 2684, 2701, 2718, 2735,
    2752, 2769, 2786, 2804, 2821, 2839, 2857, 2874, 2892, 2910, 2929, 2947,
    2960, 2972, 2985, 2998, 3011, 3024, 3037, 3051, 3064, 3077, 3090, 3104,
    3117, 3131, 3144, 3158, 3172, 3185, 3199, 3213, 3227, 3241, 3255, 3269,
    3283, 3298, 3312, 3326, 3341, 3355, 3370, 3384, 3399, 3414, 3428, 3443,
    3458, 3473, 3488, 3503, 3519, 3534, 3549, 3564, 3580, 3595, 3611, 3627,
    3642, 3658, 3674, 3690, 3706, 3722, 3738, 3754, 3771, 3787, 3803, 3820,
    3836, 3853, 3870, 3886, 3903, 3920, 3937, 3954, 3971, 3989, 4006, 4023,
    4041, 4058, 4076, 4093, 4111, 4129, 4147, 4165, 4183, 4201, 4219, 4237,
    4256, 4274, 4293, 4311, 4330, 4349, 4368, 4387, 4406, 4425, 4444, 4463,
    4482, 4502, 4521, 4541, 4561, 4580, 4600, 4620, 4640, 4660, 4681, 4701,
    4721, 4742, 4762, 4783, 4804, 4824, 4845, 4866, 4887, 4909, 4930, 4951,
    4973, 4994, 5016, 5038, 5059, 5081, 5103, 5125, 5148, 5170, 5192, 5215,
    5237, 5260, 5283, 5306, 5329, 5352, 5375, 5398, 5422, 5445, 5469, 5493,
    5516, 5540, 5564, 5588, 5613, 5637, 5661, 5686, 5711, 5735, 5760, 5785,
    5810, 5835, 5861, 5886, 5912, 5937, 5963, 5989, 6015, 6041, 6067, 6093,
    6120, 6146, 6173, 6200, 6226, 6253, 6280, 6308, 6335, 6362, 6390, 6418,
    6446, 6474, 6502, 6530, 6558, 6586, 6615, 6644, 6672, 6701, 6730, 6760,
    6789, 6818, 6848, 6877, 6907, 6937, 6967, 6997, 7028, 7058, 7089, 7120,
    7150, 7181, 7213, 7244, 7275, 7307, 7338, 7370, 7402, 7434, 7466, 7499,
    7531, 7564, 7597, 7630, 7663, 7696, 7729, 7763, 7796, 7830, 7864, 7898,
    7932, 7967, 8001, 8036, 8071, 8106, 8141, 8176, 8212, 8247, 8283, 8319,
    8355, 8391, 8427, 8464, 8501, 8537, 8574, 8612, 8649, 8686, 8724, 8762,
    8800, 8838, 8876, 8915, 8953, 8992, 9031, 9070, 9109, 9149, 9189, 9228,
    9268, 9309, 9349, 9389, 9430, 9471, 9512, 9553, 9595, 9636, 9678, 9720,
    9762, 9804, 9847, 9889, 9932, 9975, 10019, 10062, 10106, 10149, 10193,
    10238, 10282, 10326, 10371, 10416, 10461, 10507, 10552, 10598, 10644, 10690,
    10736, 10783, 10829, 10876, 10924, 10971, 11018, 11066, 11114, 11162, 11211,
    11259, 11308, 11357,
  ],
  380000: [
    2122, 2139, 2157, 2175, 2193, 2212, 2230, 2249, 2267, 2286, 2305, 2324,
    2344, 2363, 2383, 2403, 2423, 2443, 2463, 2484, 2505, 2526, 2547, 2568,
    2584, 2600, 2616, 2633, 2649, 2666, 2682, 2699, 2716, 2733, 2750, 2767,
    2785, 2802, 2819, 2837, 2855, 2873, 2891, 2909, 2927, 2945, 2964, 2982,
    2995, 3008, 3021, 3034, 3047, 3060, 3074, 3087, 3100, 3114, 3127, 3141,
    3154, 3168, 3182, 3196, 3209, 3223, 3237, 3251, 3265, 3280, 3294, 3308,
    3322, 3337, 3351, 3366, 3380, 3395, 3410, 3425, 3439, 3454, 3469, 3484,
    3499, 3515, 3530, 3545, 3560, 3576, 3591, 3607, 3623, 3638, 3654, 3670,
    3686, 3702, 3718, 3734, 3750, 3766, 3783, 3799, 3816, 3832, 3849, 3865,
    3882, 3899, 3916, 3933, 3950, 3967, 3984, 4001, 4019, 4036, 4054, 4071,
    4089, 4107, 4124, 4142, 4160, 4178, 4196, 4214, 4233, 4251, 4270, 4288,
    4307, 4325, 4344, 4363, 4382, 4401, 4420, 4439, 4458, 4477, 4497, 4516,
    4536, 4556, 4575, 4595, 4615, 4635, 4655, 4675, 4696, 4716, 4736, 4757,
    4778, 4798, 4819, 4840, 4861, 4882, 4903, 4924, 4946, 4967, 4989, 5010,
    5032, 5054, 5076, 5098, 5120, 5142, 5164, 5187, 5209, 5232, 5254, 5277,
    5300, 5323, 5346, 5369, 5392, 5416, 5439, 5463, 5486, 5510, 5534, 5558,
    5582, 5606, 5631, 5655, 5680, 5704, 5729, 5754, 5779, 5804, 5829, 5854,
    5879, 5905, 5931, 5956, 5982, 6008, 6034, 6060, 6086, 6113, 6139, 6166,
    6193, 6219, 6246, 6273, 6301, 6328, 6355, 6383, 6411, 6438, 6466, 6494,
    6522, 6551, 6579, 6608, 6636, 6665, 6694, 6723, 6752, 6781, 6811, 6840,
    6870, 6900, 6929, 6959, 6990, 7020, 7050, 7081, 7112, 7142, 7173, 7204,
    7236, 7267, 7299, 7330, 7362, 7394, 7426, 7458, 7490, 7523, 7555, 7588,
    7621, 7654, 7687, 7721, 7754, 7788, 7821, 7855, 7889, 7923, 7958, 7992,
    8027, 8062, 8097, 8132, 8167, 8202, 8238, 8274, 8309, 8345, 8382, 8418,
    8454, 8491, 8528, 8565, 8602, 8639, 8677, 8714, 8752, 8790, 8828, 8866,
    8905, 8943, 8982, 9021, 9060, 9099, 9139, 9178, 9218, 9258, 9298, 9338,
    9379, 9420, 9460, 9501, 9543, 9584, 9625, 9667, 9709, 9751, 9793, 9836,
    9878, 9921, 9964, 10007, 10051, 10094, 10138, 10182, 10226, 10270, 10315,
    10360, 10404, 10450, 10495, 10540, 10586, 10632, 10678, 10724, 10771, 10817,
    10864, 10911, 10959, 11006, 11054, 11102, 11150, 11198, 11247, 11295, 11344,
    11393, 11443, 11492,
  ],
  390000: [
    2145, 2163, 2181, 2199, 2217, 2236, 2255, 2273, 2292, 2311, 2331, 2350,
    2370, 2389, 2409, 2429, 2450, 2470, 2491, 2511, 2532, 2553, 2575, 2596,
    2612, 2629, 2645, 2662, 2678, 2695, 2712, 2729, 2746, 2763, 2780, 2798,
    2815, 2833, 2850, 2868, 2886, 2904, 2922, 2941, 2959, 2978, 2996, 3015,
    3028, 3041, 3054, 3067, 3081, 3094, 3107, 3121, 3134, 3148, 3162, 3175,
    3189, 3203, 3217, 3231, 3245, 3259, 3273, 3287, 3301, 3316, 3330, 3345,
    3359, 3374, 3388, 3403, 3418, 3432, 3447, 3462, 3477, 3492, 3507, 3523,
    3538, 3553, 3569, 3584, 3600, 3615, 3631, 3647, 3662, 3678, 3694, 3710,
    3726, 3742, 3759, 3775, 3791, 3808, 3824, 3841, 3857, 3874, 3891, 3908,
    3925, 3942, 3959, 3976, 3993, 4011, 4028, 4045, 4063, 4081, 4098, 4116,
    4134, 4152, 4170, 4188, 4206, 4224, 4242, 4261, 4279, 4298, 4316, 4335,
    4354, 4373, 4392, 4411, 4430, 4449, 4468, 4488, 4507, 4527, 4546, 4566,
    4586, 4606, 4626, 4646, 4666, 4686, 4706, 4727, 4747, 4768, 4788, 4809,
    4830, 4851, 4872, 4893, 4914, 4936, 4957, 4978, 5000, 5022, 5043, 5065,
    5087, 5109, 5131, 5154, 5176, 5198, 5221, 5244, 5266, 5289, 5312, 5335,
    5358, 5381, 5405, 5428, 5452, 5475, 5499, 5523, 5547, 5571, 5595, 5619,
    5644, 5668, 5693, 5717, 5742, 5767, 5792, 5817, 5842, 5868, 5893, 5919,
    5944, 5970, 5996, 6022, 6048, 6074, 6100, 6127, 6153, 6180, 6207, 6234,
    6261, 6288, 6315, 6342, 6370, 6398, 6425, 6453, 6481, 6509, 6537, 6566,
    6594, 6623, 6651, 6680, 6709, 6738, 6767, 6797, 6826, 6856, 6886, 6915,
    6945, 6975, 7006, 7036, 7067, 7097, 7128, 7159, 7190, 7221, 7252, 7284,
    7315, 7347, 7379, 7411, 7443, 7475, 7508, 7540, 7573, 7606, 7638, 7672,
    7705, 7738, 7772, 7805, 7839, 7873, 7907, 7942, 7976, 8011, 8045, 8080,
    8115, 8150, 8186, 8221, 8257, 8293, 8328, 8365, 8401, 8437, 8474, 8510,
    8547, 8584, 8622, 8659, 8696, 8734, 8772, 8810, 8848, 8887, 8925, 8964,
    9003, 9042, 9081, 9120, 9160, 9199, 9239, 9279, 9319, 9360, 9400, 9441,
    9482, 9523, 9564, 9606, 9647, 9689, 9731, 9773, 9816, 9858, 9901, 9944,
    9987, 10030, 10074, 10117, 10161, 10205, 10250, 10294, 10339, 10383, 10428,
    10474, 10519, 10564, 10610, 10656, 10702, 10749, 10795, 10842, 10889, 10936,
    10984, 11031, 11079, 11127, 11175, 11224, 11272, 11321, 11370, 11420, 11469,
    11519, 11569, 11619,
  ],
  400000: [
    2167, 2185, 2203, 2221, 2240, 2258, 2277, 2296, 2315, 2335, 2354, 2374,
    2394, 2413, 2434, 2454, 2474, 2495, 2516, 2537, 2558, 2579, 2601, 2622,
    2639, 2655, 2672, 2689, 2705, 2722, 2739, 2756, 2774, 2791, 2808, 2826,
    2844, 2861, 2879, 2897, 2915, 2934, 2952, 2970, 2989, 3008, 3026, 3045,
    3058, 3072, 3085, 3098, 3112, 3125, 3139, 3152, 3166, 3180, 3194, 3207,
    3221, 3235, 3249, 3263, 3278, 3292, 3306, 3320, 3335, 3349, 3364, 3378,
    3393, 3408, 3422, 3437, 3452, 3467, 3482, 3497, 3512, 3528, 3543, 3558,
    3574, 3589, 3605, 3620, 3636, 3652, 3668, 3683, 3699, 3715, 3732, 3748,
    3764, 3780, 3797, 3813, 3830, 3846, 3863, 3880, 3896, 3913, 3930, 3947,
    3964, 3982, 3999, 4016, 4034, 4051, 4069, 4086, 4104, 4122, 4140, 4158,
    4176, 4194, 4212, 4230, 4248, 4267, 4285, 4304, 4323, 4341, 4360, 4379,
    4398, 4417, 4436, 4455, 4475, 4494, 4514, 4533, 4553, 4572, 4592, 4612,
    4632, 4652, 4672, 4693, 4713, 4733, 4754, 4774, 4795, 4816, 4837, 4858,
    4879, 4900, 4921, 4943, 4964, 4985, 5007, 5029, 5051, 5072, 5094, 5117,
    5139, 5161, 5183, 5206, 5228, 5251, 5274, 5297, 5320, 5343, 5366, 5389,
    5412, 5436, 5459, 5483, 5507, 5531, 5555, 5579, 5603, 5627, 5652, 5676,
    5701, 5725, 5750, 5775, 5800, 5825, 5850, 5876, 5901, 5927, 5952, 5978,
    6004, 6030, 6056, 6083, 6109, 6135, 6162, 6189, 6216, 6242, 6270, 6297,
    6324, 6351, 6379, 6407, 6434, 6462, 6490, 6518, 6547, 6575, 6603, 6632,
    6661, 6690, 6719, 6748, 6777, 6806, 6836, 6865, 6895, 6925, 6955, 6985,
    7016, 7046, 7076, 7107, 7138, 7169, 7200, 7231, 7262, 7294, 7326, 7357,
    7389, 7421, 7453, 7486, 7518, 7551, 7583, 7616, 7649, 7682, 7716, 7749,
    7783, 7816, 7850, 7884, 7918, 7953, 7987, 8022, 8057, 8091, 8127, 8162,
    8197, 8233, 8268, 8304, 8340, 8376, 8413, 8449, 8486, 8522, 8559, 8596,
    8634, 8671, 8709, 8746, 8784, 8822, 8861, 8899, 8938, 8976, 9015, 9054,
    9094, 9133, 9172, 9212, 9252, 9292, 9333, 9373, 9414, 9454, 9495, 9536,
    9578, 9619, 9661, 9703, 9745, 9787, 9830, 9872, 9915, 9958, 10001, 10044,
    10088, 10132, 10176, 10220, 10264, 10308, 10353, 10398, 10443, 10488, 10534,
    10579, 10625, 10671, 10717, 10764, 10811, 10857, 10904, 10952, 10999, 11047,
    11095, 11143, 11191, 11240, 11288, 11337, 11386, 11436, 11485, 11535, 11585,
    11635, 11686, 11736,
  ],
  410000: [
    2187, 2205, 2223, 2242, 2260, 2279, 2298, 2317, 2337, 2356, 2376, 2396,
    2416, 2436, 2456, 2477, 2497, 2518, 2539, 2560, 2581, 2603, 2625, 2647,
    2663, 2680, 2696, 2713, 2730, 2747, 2765, 2782, 2799, 2817, 2834, 2852,
    2870, 2888, 2906, 2924, 2942, 2961, 2979, 2998, 3016, 3035, 3054, 3073,
    3087, 3100, 3114, 3127, 3141, 3154, 3168, 3182, 3195, 3209, 3223, 3237,
    3251, 3265, 3279, 3294, 3308, 3322, 3337, 3351, 3366, 3380, 3395, 3409,
    3424, 3439, 3454, 3469, 3484, 3499, 3514, 3529, 3545, 3560, 3576, 3591,
    3607, 3622, 3638, 3654, 3670, 3685, 3701, 3717, 3734, 3750, 3766, 3782,
    3799, 3815, 3832, 3848, 3865, 3882, 3899, 3915, 3932, 3949, 3967, 3984,
    4001, 4018, 4036, 4053, 4071, 4088, 4106, 4124, 4142, 4160, 4178, 4196,
    4214, 4232, 4251, 4269, 4288, 4306, 4325, 4344, 4362, 4381, 4400, 4419,
    4439, 4458, 4477, 4496, 4516, 4536, 4555, 4575, 4595, 4615, 4635, 4655,
    4675, 4695, 4716, 4736, 4756, 4777, 4798, 4819, 4839, 4860, 4881, 4903,
    4924, 4945, 4967, 4988, 5010, 5031, 5053, 5075, 5097, 5119, 5141, 5164,
    5186, 5209, 5231, 5254, 5277, 5299, 5322, 5345, 5369, 5392, 5415, 5439,
    5462, 5486, 5510, 5534, 5558, 5582, 5606, 5630, 5655, 5679, 5704, 5728,
    5753, 5778, 5803, 5828, 5854, 5879, 5904, 5930, 5956, 5982, 6007, 6033,
    6060, 6086, 6112, 6139, 6165, 6192, 6219, 6246, 6273, 6300, 6327, 6355,
    6382, 6410, 6438, 6466, 6494, 6522, 6550, 6578, 6607, 6636, 6664, 6693,
    6722, 6751, 6781, 6810, 6840, 6869, 6899, 6929, 6959, 6989, 7019, 7050,
    7080, 7111, 7142, 7173, 7204, 7235, 7266, 7298, 7329, 7361, 7393, 7425,
    7457, 7490, 7522, 7555, 7587, 7620, 7653, 7687, 7720, 7753, 7787, 7821,
    7854, 7889, 7923, 7957, 7992, 8026, 8061, 8096, 8131, 8166, 8202, 8237,
    8273, 8309, 8345, 8381, 8417, 8454, 8490, 8527, 8564, 8601, 8638, 8676,
    8713, 8751, 8789, 8827, 8865, 8904, 8942, 8981, 9020, 9059, 9098, 9138,
    9177, 9217, 9257, 9297, 9338, 9378, 9419, 9459, 9500, 9542, 9583, 9625,
    9666, 9708, 9750, 9792, 9835, 9877, 9920, 9963, 10006, 10050, 10093, 10137,
    10181, 10225, 10269, 10314, 10359, 10404, 10449, 10494, 10539, 10585, 10631,
    10677, 10723, 10770, 10816, 10863, 10910, 10958, 11005, 11053, 11101, 11149,
    11197, 11246, 11294, 11343, 11392, 11442, 11491, 11541, 11591, 11641, 11692,
    11743, 11793, 11844,
  ],
  420000: [
    2205, 2223, 2242, 2261, 2279, 2298, 2318, 2337, 2356, 2376, 2396, 2416,
    2436, 2456, 2477, 2497, 2518, 2539, 2560, 2582, 2603, 2625, 2647, 2669,
    2685, 2702, 2719, 2736, 2753, 2770, 2788, 2805, 2823, 2840, 2858, 2876,
    2894, 2912, 2930, 2948, 2967, 2985, 3004, 3023, 3042, 3061, 3080, 3099,
    3113, 3126, 3140, 3153, 3167, 3181, 3194, 3208, 3222, 3236, 3250, 3264,
    3278, 3293, 3307, 3321, 3336, 3350, 3365, 3379, 3394, 3408, 3423, 3438,
    3453, 3468, 3483, 3498, 3513, 3528, 3544, 3559, 3574, 3590, 3606, 3621,
    3637, 3653, 3668, 3684, 3700, 3716, 3732, 3749, 3765, 3781, 3798, 3814,
    3831, 3847, 3864, 3881, 3897, 3914, 3931, 3948, 3965, 3983, 4000, 4017,
    4035, 4052, 4070, 4087, 4105, 4123, 4141, 4159, 4177, 4195, 4213, 4231,
    4249, 4268, 4286, 4305, 4324, 4342, 4361, 4380, 4399, 4418, 4437, 4456,
    4476, 4495, 4515, 4534, 4554, 4574, 4593, 4613, 4633, 4653, 4674, 4694,
    4714, 4735, 4755, 4776, 4796, 4817, 4838, 4859, 4880, 4901, 4922, 4944,
    4965, 4987, 5008, 5030, 5052, 5074, 5096, 5118, 5140, 5162, 5185, 5207,
    5230, 5252, 5275, 5298, 5321, 5344, 5367, 5390, 5414, 5437, 5461, 5484,
    5508, 5532, 5556, 5580, 5604, 5628, 5653, 5677, 5702, 5727, 5752, 5776,
    5801, 5827, 5852, 5877, 5903, 5928, 5954, 5980, 6006, 6032, 6058, 6084,
    6110, 6137, 6163, 6190, 6217, 6244, 6271, 6298, 6325, 6353, 6380, 6408,
    6436, 6464, 6492, 6520, 6548, 6577, 6605, 6634, 6662, 6691, 6720, 6749,
    6779, 6808, 6837, 6867, 6897, 6927, 6957, 6987, 7017, 7048, 7078, 7109,
    7140, 7171, 7202, 7233, 7264, 7296, 7327, 7359, 7391, 7423, 7455, 7487,
    7520, 7552, 7585, 7618, 7651, 7684, 7718, 7751, 7785, 7818, 7852, 7886,
    7920, 7955, 7989, 8024, 8059, 8093, 8129, 8164, 8199, 8235, 8270, 8306,
    8342, 8378, 8415, 8451, 8488, 8524, 8561, 8599, 8636, 8673, 8711, 8749,
    8786, 8825, 8863, 8901, 8940, 8978, 9017, 9056, 9096, 9135, 9175, 9214,
    9254, 9294, 9335, 9375, 9416, 9457, 9498, 9539, 9580, 9622, 9663, 9705,
    9747, 9789, 9832, 9875, 9917, 9960, 10003, 10047, 10090, 10134, 10178,
    10222, 10266, 10311, 10356, 10400, 10445, 10491, 10536, 10582, 10628, 10674,
    10720, 10766, 10813, 10860, 10907, 10954, 11002, 11049, 11097, 11145, 11194,
    11242, 11291, 11340, 11389, 11438, 11488, 11538, 11588, 11638, 11688, 11739,
    11790, 11841, 11892, 11944,
  ],
  430000: [
    2222, 2240, 2259, 2278, 2297, 2316, 2335, 2355, 2374, 2394, 2414, 2434,
    2454, 2475, 2495, 2516, 2537, 2558, 2580, 2601, 2623, 2645, 2667, 2689,
    2706, 2723, 2740, 2757, 2774, 2791, 2809, 2826, 2844, 2862, 2880, 2898,
    2916, 2934, 2952, 2971, 2989, 3008, 3027, 3046, 3065, 3084, 3103, 3123,
    3136, 3150, 3163, 3177, 3191, 3205, 3219, 3232, 3247, 3261, 3275, 3289,
    3303, 3317, 3332, 3346, 3361, 3375, 3390, 3405, 3419, 3434, 3449, 3464,
    3479, 3494, 3509, 3524, 3540, 3555, 3571, 3586, 3602, 3617, 3633, 3649,
    3664, 3680, 3696, 3712, 3728, 3744, 3761, 3777, 3793, 3810, 3826, 3843,
    3860, 3876, 3893, 3910, 3927, 3944, 3961, 3978, 3995, 4013, 4030, 4048,
    4065, 4083, 4100, 4118, 4136, 4154, 4172, 4190, 4208, 4226, 4245, 4263,
    4282, 4300, 4319, 4337, 4356, 4375, 4394, 4413, 4432, 4451, 4471, 4490,
    4510, 4529, 4549, 4568, 4588, 4608, 4628, 4648, 4668, 4689, 4709, 4729,
    4750, 4770, 4791, 4812, 4833, 4854, 4875, 4896, 4917, 4938, 4960, 4981,
    5003, 5024, 5046, 5068, 5090, 5112, 5134, 5156, 5179, 5201, 5224, 5246,
    5269, 5292, 5315, 5338, 5361, 5384, 5408, 5431, 5455, 5478, 5502, 5526,
    5550, 5574, 5598, 5622, 5647, 5671, 5696, 5720, 5745, 5770, 5795, 5820,
    5845, 5871, 5896, 5922, 5947, 5973, 5999, 6025, 6051, 6077, 6104, 6130,
    6157, 6183, 6210, 6237, 6264, 6291, 6318, 6346, 6373, 6401, 6429, 6457,
    6484, 6513, 6541, 6569, 6598, 6626, 6655, 6684, 6713, 6742, 6771, 6800,
    6830, 6859, 6889, 6919, 6949, 6979, 7009, 7040, 7070, 7101, 7132, 7163,
    7194, 7225, 7256, 7288, 7319, 7351, 7383, 7415, 7447, 7479, 7511, 7544,
    7577, 7610, 7643, 7676, 7709, 7742, 7776, 7810, 7843, 7877, 7911, 7946,
    7980, 8015, 8050, 8084, 8119, 8155, 8190, 8225, 8261, 8297, 8333, 8369,
    8405, 8442, 8478, 8515, 8552, 8589, 8626, 8664, 8701, 8739, 8777, 8815,
    8853, 8891, 8930, 8968, 9007, 9046, 9086, 9125, 9164, 9204, 9244, 9284,
    9324, 9365, 9405, 9446, 9487, 9528, 9569, 9611, 9653, 9694, 9736, 9779,
    9821, 9863, 9906, 9949, 9992, 10036, 10079, 10123, 10167, 10211, 10255,
    10299, 10344, 10389, 10434, 10479, 10524, 10570, 10616, 10662, 10708, 10754,
    10801, 10848, 10895, 10942, 10989, 11037, 11085, 11133, 11181, 11230, 11278,
    11327, 11376, 11426, 11475, 11525, 11575, 11625, 11675, 11726, 11777, 11828,
    11879, 11930, 11982, 12034,
  ],
  440000: [
    2237, 2255, 2274, 2293, 2312, 2331, 2351, 2370, 2390, 2410, 2430, 2450,
    2471, 2491, 2512, 2533, 2554, 2576, 2597, 2619, 2640, 2662, 2685, 2707,
    2724, 2741, 2758, 2775, 2793, 2810, 2828, 2845, 2863, 2881, 2899, 2917,
    2935, 2954, 2972, 2991, 3010, 3028, 3047, 3066, 3085, 3105, 3124, 3144,
    3157, 3171, 3185, 3199, 3212, 3226, 3240, 3254, 3268, 3283, 3297, 3311,
    3325, 3340, 3354, 3369, 3383, 3398, 3413, 3428, 3442, 3457, 3472, 3487,
    3503, 3518, 3533, 3548, 3564, 3579, 3595, 3610, 3626, 3642, 3657, 3673,
    3689, 3705, 3721, 3737, 3753, 3770, 3786, 3802, 3819, 3835, 3852, 3869,
    3886, 3902, 3919, 3936, 3953, 3970, 3988, 4005, 4022, 4040, 4057, 4075,
    4092, 4110, 4128, 4146, 4164, 4182, 4200, 4218, 4237, 4255, 4273, 4292,
    4310, 4329, 4348, 4367, 4386, 4405, 4424, 4443, 4462, 4482, 4501, 4520,
    4540, 4560, 4579, 4599, 4619, 4639, 4659, 4680, 4700, 4720, 4741, 4761,
    4782, 4803, 4823, 4844, 4865, 4886, 4907, 4929, 4950, 4972, 4993, 5015,
    5036, 5058, 5080, 5102, 5124, 5147, 5169, 5191, 5214, 5236, 5259, 5282,
    5305, 5328, 5351, 5374, 5397, 5421, 5444, 5468, 5491, 5515, 5539, 5563,
    5587, 5611, 5636, 5660, 5685, 5709, 5734, 5759, 5784, 5809, 5834, 5859,
    5885, 5910, 5936, 5962, 5987, 6013, 6039, 6066, 6092, 6118, 6145, 6171,
    6198, 6225, 6252, 6279, 6306, 6334, 6361, 6389, 6416, 6444, 6472, 6500,
    6528, 6557, 6585, 6614, 6642, 6671, 6700, 6729, 6758, 6787, 6817, 6846,
    6876, 6906, 6936, 6966, 6996, 7026, 7057, 7087, 7118, 7149, 7180, 7211,
    7242, 7274, 7305, 7337, 7369, 7400, 7433, 7465, 7497, 7530, 7562, 7595,
    7628, 7661, 7694, 7727, 7761, 7795, 7828, 7862, 7896, 7931, 7965, 7999,
    8034, 8069, 8104, 8139, 8174, 8210, 8245, 8281, 8317, 8353, 8389, 8425,
    8462, 8499, 8535, 8572, 8610, 8647, 8684, 8722, 8760, 8798, 8836, 8874,
    8913, 8951, 8990, 9029, 9068, 9107, 9147, 9187, 9226, 9266, 9306, 9347,
    9387, 9428, 9469, 9510, 9551, 9592, 9634, 9676, 9718, 9760, 9802, 9845,
    9887, 9930, 9973, 10016, 10060, 10103, 10147, 10191, 10235, 10280, 10324,
    10369, 10414, 10459, 10504, 10550, 10595, 10641, 10688, 10734, 10780, 10827,
    10874, 10921, 10968, 11016, 11064, 11112, 11160, 11208, 11257, 11305, 11354,
    11404, 11453, 11503, 11553, 11603, 11653, 11703, 11754, 11805, 11856, 11908,
    11959, 12011, 12063, 12115,
  ],
  450000: [
    2250, 2269, 2288, 2307, 2326, 2345, 2365, 2385, 2404, 2424, 2445, 2465,
    2486, 2506, 2527, 2548, 2569, 2591, 2613, 2634, 2656, 2678, 2701, 2723,
    2740, 2757, 2775, 2792, 2809, 2827, 2845, 2862, 2880, 2898, 2916, 2935,
    2953, 2971, 2990, 3009, 3027, 3046, 3065, 3085, 3104, 3123, 3143, 3162,
    3176, 3190, 3204, 3218, 3232, 3246, 3260, 3274, 3288, 3302, 3316, 3331,
    3345, 3360, 3374, 3389, 3404, 3418, 3433, 3448, 3463, 3478, 3493, 3508,
    3523, 3539, 3554, 3569, 3585, 3600, 3616, 3632, 3647, 3663, 3679, 3695,
    3711, 3727, 3743, 3760, 3776, 3792, 3809, 3825, 3842, 3858, 3875, 3892,
    3909, 3926, 3943, 3960, 3977, 3994, 4011, 4029, 4046, 4064, 4081, 4099,
    4117, 4135, 4153, 4171, 4189, 4207, 4225, 4243, 4262, 4280, 4299, 4317,
    4336, 4355, 4374, 4393, 4412, 4431, 4450, 4469, 4489, 4508, 4528, 4547,
    4567, 4587, 4607, 4627, 4647, 4667, 4687, 4707, 4728, 4748, 4769, 4790,
    4810, 4831, 4852, 4873, 4894, 4915, 4937, 4958, 4980, 5001, 5023, 5045,
    5066, 5088, 5110, 5133, 5155, 5177, 5200, 5222, 5245, 5268, 5290, 5313,
    5336, 5359, 5383, 5406, 5429, 5453, 5477, 5500, 5524, 5548, 5572, 5596,
    5621, 5645, 5669, 5694, 5719, 5743, 5768, 5793, 5818, 5844, 5869, 5894,
    5920, 5946, 5971, 5997, 6023, 6049, 6075, 6102, 6128, 6155, 6181, 6208,
    6235, 6262, 6289, 6317, 6344, 6371, 6399, 6427, 6455, 6483, 6511, 6539,
    6567, 6596, 6624, 6653, 6682, 6711, 6740, 6769, 6798, 6828, 6857, 6887,
    6917, 6947, 6977, 7007, 7038, 7068, 7099, 7130, 7160, 7191, 7223, 7254,
    7285, 7317, 7349, 7380, 7412, 7445, 7477, 7509, 7542, 7574, 7607, 7640,
    7673, 7707, 7740, 7774, 7807, 7841, 7875, 7909, 7943, 7978, 8012, 8047,
    8082, 8117, 8152, 8188, 8223, 8259, 8294, 8330, 8366, 8403, 8439, 8476,
    8512, 8549, 8586, 8624, 8661, 8698, 8736, 8774, 8812, 8850, 8889, 8927,
    8966, 9005, 9044, 9083, 9122, 9162, 9201, 9241, 9281, 9322, 9362, 9403,
    9443, 9484, 9525, 9567, 9608, 9650, 9691, 9733, 9776, 9818, 9861, 9903,
    9946, 9989, 10033, 10076, 10120, 10164, 10208, 10252, 10296, 10341, 10386,
    10431, 10476, 10521, 10567, 10613, 10659, 10705, 10751, 10798, 10845, 10892,
    10939, 10986, 11034, 11082, 11130, 11178, 11226, 11275, 11324, 11373, 11422,
    11472, 11521, 11571, 11621, 11672, 11722, 11773, 11824, 11875, 11927, 11979,
    12030, 12083, 12135, 12188,
  ],
  460000: [
    2281, 2300, 2319, 2338, 2358, 2377, 2397, 2417, 2437, 2458, 2478, 2499,
    2520, 2541, 2562, 2583, 2605, 2626, 2648, 2670, 2693, 2715, 2738, 2761,
    2778, 2795, 2813, 2830, 2848, 2866, 2884, 2902, 2920, 2938, 2956, 2975,
    2993, 3012, 3031, 3050, 3069, 3088, 3107, 3127, 3146, 3166, 3186, 3206,
    3220, 3234, 3248, 3262, 3276, 3290, 3304, 3319, 3333, 3347, 3362, 3376,
    3391, 3406, 3421, 3435, 3450, 3465, 3480, 3495, 3510, 3526, 3541, 3556,
    3572, 3587, 3603, 3618, 3634, 3650, 3666, 3681, 3697, 3713, 3730, 3746,
    3762, 3778, 3795, 3811, 3828, 3844, 3861, 3878, 3894, 3911, 3928, 3945,
    3962, 3979, 3997, 4014, 4031, 4049, 4066, 4084, 4102, 4120, 4137, 4155,
    4173, 4191, 4210, 4228, 4246, 4265, 4283, 4302, 4320, 4339, 4358, 4377,
    4396, 4415, 4434, 4453, 4472, 4492, 4511, 4531, 4550, 4570, 4590, 4610,
    4630, 4650, 4670, 4690, 4710, 4731, 4751, 4772, 4793, 4813, 4834, 4855,
    4876, 4897, 4919, 4940, 4961, 4983, 5004, 5026, 5048, 5070, 5092, 5114,
    5136, 5158, 5181, 5203, 5226, 5248, 5271, 5294, 5317, 5340, 5363, 5386,
    5409, 5433, 5456, 5480, 5504, 5528, 5552, 5576, 5600, 5624, 5648, 5673,
    5698, 5722, 5747, 5772, 5797, 5822, 5847, 5873, 5898, 5924, 5949, 5975,
    6001, 6027, 6053, 6079, 6106, 6132, 6159, 6185, 6212, 6239, 6266, 6293,
    6321, 6348, 6375, 6403, 6431, 6459, 6487, 6515, 6543, 6571, 6600, 6628,
    6657, 6686, 6715, 6744, 6773, 6803, 6832, 6862, 6892, 6921, 6951, 6981,
    7012, 7042, 7073, 7103, 7134, 7165, 7196, 7227, 7259, 7290, 7322, 7353,
    7385, 7417, 7449, 7482, 7514, 7547, 7579, 7612, 7645, 7678, 7712, 7745,
    7778, 7812, 7846, 7880, 7914, 7948, 7983, 8018, 8052, 8087, 8122, 8157,
    8193, 8228, 8264, 8300, 8336, 8372, 8408, 8445, 8481, 8518, 8555, 8592,
    8629, 8666, 8704, 8742, 8780, 8818, 8856, 8894, 8933, 8971, 9010, 9049,
    9089, 9128, 9168, 9207, 9247, 9287, 9328, 9368, 9409, 9449, 9490, 9531,
    9573, 9614, 9656, 9698, 9740, 9782, 9824, 9867, 9910, 9953, 9996, 10039,
    10082, 10126, 10170, 10214, 10258, 10303, 10347, 10392, 10437, 10483, 10528,
    10574, 10619, 10665, 10712, 10758, 10805, 10852, 10899, 10946, 10993, 11041,
    11089, 11137, 11185, 11233, 11282, 11331, 11380, 11429, 11479, 11529, 11579,
    11629, 11679, 11730, 11781, 11832, 11883, 11935, 11986, 12038, 12090, 12143,
    12195, 12248, 12301, 12355,
  ],
  470000: [
    2311, 2330, 2350, 2369, 2389, 2409, 2429, 2449, 2469, 2490, 2511, 2532,
    2553, 2574, 2596, 2617, 2639, 2661, 2683, 2705, 2728, 2751, 2774, 2797,
    2814, 2832, 2850, 2867, 2885, 2903, 2921, 2940, 2958, 2977, 2995, 3014,
    3033, 3052, 3071, 3090, 3109, 3129, 3148, 3168, 3188, 3208, 3228, 3248,
    3262, 3276, 3290, 3305, 3319, 3333, 3348, 3362, 3377, 3391, 3406, 3421,
    3436, 3451, 3466, 3481, 3496, 3511, 3526, 3541, 3557, 3572, 3588, 3603,
    3619, 3634, 3650, 3666, 3682, 3698, 3714, 3730, 3746, 3762, 3779, 3795,
    3811, 3828, 3845, 3861, 3878, 3895, 3912, 3929, 3946, 3963, 3980, 3997,
    4014, 4032, 4049, 4067, 4084, 4102, 4120, 4138, 4156, 4174, 4192, 4210,
    4228, 4247, 4265, 4283, 4302, 4321, 4339, 4358, 4377, 4396, 4415, 4434,
    4453, 4473, 4492, 4512, 4531, 4551, 4570, 4590, 4610, 4630, 4650, 4670,
    4691, 4711, 4731, 4752, 4772, 4793, 4814, 4835, 4856, 4877, 4898, 4919,
    4940, 4962, 4983, 5005, 5027, 5048, 5070, 5092, 5114, 5136, 5159, 5181,
    5203, 5226, 5249, 5271, 5294, 5317, 5340, 5363, 5387, 5410, 5433, 5457,
    5481, 5504, 5528, 5552, 5576, 5600, 5625, 5649, 5673, 5698, 5723, 5748,
    5772, 5797, 5823, 5848, 5873, 5899, 5924, 5950, 5976, 6002, 6028, 6054,
    6080, 6106, 6133, 6159, 6186, 6213, 6240, 6267, 6294, 6321, 6349, 6376,
    6404, 6431, 6459, 6487, 6515, 6544, 6572, 6600, 6629, 6658, 6687, 6716,
    6745, 6774, 6803, 6833, 6862, 6892, 6922, 6952, 6982, 7012, 7043, 7073,
    7104, 7135, 7166, 7197, 7228, 7259, 7291, 7322, 7354, 7386, 7418, 7450,
    7482, 7515, 7547, 7580, 7613, 7646, 7679, 7712, 7746, 7779, 7813, 7847,
    7881, 7915, 7949, 7984, 8018, 8053, 8088, 8123, 8158, 8194, 8229, 8265,
    8300, 8336, 8373, 8409, 8445, 8482, 8519, 8556, 8593, 8630, 8667, 8705,
    8743, 8780, 8818, 8857, 8895, 8934, 8972, 9011, 9050, 9089, 9129, 9168,
    9208, 9248, 9288, 9328, 9369, 9409, 9450, 9491, 9532, 9574, 9615, 9657,
    9699, 9741, 9783, 9825, 9868, 9911, 9953, 9997, 10040, 10083, 10127, 10171,
    10215, 10259, 10304, 10348, 10393, 10438, 10484, 10529, 10575, 10620, 10666,
    10713, 10759, 10806, 10853, 10900, 10947, 10994, 11042, 11090, 11138, 11186,
    11235, 11283, 11332, 11381, 11431, 11480, 11530, 11580, 11630, 11680, 11731,
    11782, 11833, 11884, 11936, 11987, 12039, 12091, 12144, 12197, 12249, 12302,
    12356, 12409, 12463, 12517,
  ],
  480000: [
    2340, 2360, 2379, 2399, 2419, 2439, 2459, 2480, 2501, 2521, 2542, 2564,
    2585, 2607, 2628, 2650, 2672, 2695, 2717, 2740, 2762, 2785, 2809, 2832,
    2850, 2868, 2886, 2904, 2922, 2940, 2958, 2977, 2995, 3014, 3033, 3052,
    3071, 3090, 3110, 3129, 3149, 3168, 3188, 3208, 3228, 3248, 3268, 3289,
    3303, 3317, 3332, 3346, 3361, 3375, 3390, 3405, 3419, 3434, 3449, 3464,
    3479, 3494, 3509, 3525, 3540, 3555, 3571, 3586, 3602, 3617, 3633, 3649,
    3664, 3680, 3696, 3712, 3728, 3744, 3761, 3777, 3793, 3810, 3826, 3843,
    3860, 3876, 3893, 3910, 3927, 3944, 3961, 3978, 3995, 4013, 4030, 4048,
    4065, 4083, 4100, 4118, 4136, 4154, 4172, 4190, 4208, 4226, 4245, 4263,
    4282, 4300, 4319, 4337, 4356, 4375, 4394, 4413, 4432, 4451, 4471, 4490,
    4510, 4529, 4549, 4568, 4588, 4608, 4628, 4648, 4668, 4689, 4709, 4729,
    4750, 4770, 4791, 4812, 4833, 4854, 4875, 4896, 4917, 4938, 4960, 4981,
    5003, 5024, 5046, 5068, 5090, 5112, 5134, 5156, 5179, 5201, 5224, 5246,
    5269, 5292, 5315, 5338, 5361, 5384, 5408, 5431, 5455, 5478, 5502, 5526,
    5550, 5574, 5598, 5622, 5647, 5671, 5696, 5720, 5745, 5770, 5795, 5820,
    5845, 5871, 5896, 5922, 5947, 5973, 5999, 6025, 6051, 6077, 6104, 6130,
    6157, 6183, 6210, 6237, 6264, 6291, 6318, 6346, 6373, 6401, 6429, 6457,
    6485, 6513, 6541, 6569, 6598, 6626, 6655, 6684, 6713, 6742, 6771, 6800,
    6830, 6859, 6889, 6919, 6949, 6979, 7009, 7040, 7070, 7101, 7132, 7163,
    7194, 7225, 7256, 7288, 7319, 7351, 7383, 7415, 7447, 7479, 7511, 7544,
    7577, 7610, 7643, 7676, 7709, 7742, 7776, 7810, 7843, 7877, 7912, 7946,
    7980, 8015, 8050, 8084, 8119, 8155, 8190, 8225, 8261, 8297, 8333, 8369,
    8405, 8442, 8478, 8515, 8552, 8589, 8626, 8664, 8701, 8739, 8777, 8815,
    8853, 8891, 8930, 8968, 9007, 9046, 9086, 9125, 9165, 9204, 9244, 9284,
    9324, 9365, 9405, 9446, 9487, 9528, 9569, 9611, 9653, 9694, 9736, 9779,
    9821, 9864, 9906, 9949, 9992, 10036, 10079, 10123, 10167, 10211, 10255,
    10299, 10344, 10389, 10434, 10479, 10524, 10570, 10616, 10662, 10708, 10755,
    10801, 10848, 10895, 10942, 10990, 11037, 11085, 11133, 11181, 11230, 11278,
    11327, 11376, 11426, 11475, 11525, 11575, 11625, 11675, 11726, 11777, 11828,
    11879, 11931, 11982, 12034, 12086, 12139, 12191, 12244, 12297, 12350, 12404,
    12458, 12512, 12566, 12620, 12675,
  ],
  490000: [
    2368, 2388, 2408, 2428, 2448, 2469, 2489, 2510, 2531, 2552, 2573, 2595,
    2616, 2638, 2660, 2682, 2705, 2727, 2750, 2773, 2796, 2819, 2843, 2866,
    2884, 2902, 2920, 2939, 2957, 2976, 2994, 3013, 3032, 3051, 3070, 3089,
    3108, 3128, 3147, 3167, 3187, 3207, 3227, 3247, 3267, 3288, 3308, 3329,
    3343, 3358, 3372, 3387, 3401, 3416, 3431, 3446, 3461, 3476, 3491, 3506,
    3521, 3536, 3552, 3567, 3583, 3598, 3614, 3629, 3645, 3661, 3677, 3693,
    3709, 3725, 3741, 3757, 3773, 3790, 3806, 3823, 3839, 3856, 3873, 3889,
    3906, 3923, 3940, 3957, 3974, 3992, 4009, 4026, 4044, 4061, 4079, 4097,
    4114, 4132, 4150, 4168, 4186, 4204, 4222, 4241, 4259, 4278, 4296, 4315,
    4333, 4352, 4371, 4390, 4409, 4428, 4447, 4467, 4486, 4505, 4525, 4545,
    4564, 4584, 4604, 4624, 4644, 4664, 4684, 4704, 4725, 4745, 4766, 4787,
    4807, 4828, 4849, 4870, 4891, 4912, 4934, 4955, 4976, 4998, 5020, 5041,
    5063, 5085, 5107, 5129, 5152, 5174, 5196, 5219, 5242, 5264, 5287, 5310,
    5333, 5356, 5379, 5403, 5426, 5450, 5473, 5497, 5521, 5545, 5569, 5593,
    5617, 5641, 5666, 5690, 5715, 5740, 5765, 5790, 5815, 5840, 5865, 5891,
    5916, 5942, 5968, 5993, 6019, 6045, 6072, 6098, 6124, 6151, 6178, 6204,
    6231, 6258, 6285, 6313, 6340, 6367, 6395, 6423, 6451, 6478, 6507, 6535,
    6563, 6591, 6620, 6649, 6678, 6706, 6736, 6765, 6794, 6823, 6853, 6883,
    6913, 6943, 6973, 7003, 7033, 7064, 7094, 7125, 7156, 7187, 7218, 7249,
    7281, 7312, 7344, 7376, 7408, 7440, 7472, 7504, 7537, 7570, 7602, 7635,
    7668, 7702, 7735, 7769, 7802, 7836, 7870, 7904, 7938, 7973, 8007, 8042,
    8077, 8112, 8147, 8182, 8218, 8253, 8289, 8325, 8361, 8397, 8434, 8470,
    8507, 8544, 8581, 8618, 8655, 8693, 8731, 8768, 8806, 8845, 8883, 8921,
    8960, 8999, 9038, 9077, 9116, 9156, 9196, 9235, 9275, 9316, 9356, 9397,
    9437, 9478, 9519, 9561, 9602, 9644, 9685, 9727, 9769, 9812, 9854, 9897,
    9940, 9983, 10026, 10070, 10113, 10157, 10201, 10245, 10290, 10334, 10379,
    10424, 10469, 10515, 10560, 10606, 10652, 10698, 10744, 10791, 10838, 10885,
    10932, 10979, 11027, 11075, 11123, 11171, 11219, 11268, 11317, 11366, 11415,
    11464, 11514, 11564, 11614, 11664, 11715, 11766, 11817, 11868, 11919, 11971,
    12023, 12075, 12127, 12180, 12233, 12286, 12339, 12392, 12446, 12500, 12554,
    12609, 12663, 12718, 12773, 12829,
  ],
  500000: [
    2396, 2416, 2436, 2456, 2477, 2497, 2518, 2539, 2560, 2582, 2603, 2625,
    2647, 2669, 2691, 2713, 2736, 2759, 2782, 2805, 2828, 2852, 2876, 2900,
    2918, 2936, 2954, 2973, 2991, 3010, 3029, 3048, 3067, 3086, 3105, 3125,
    3144, 3164, 3184, 3204, 3224, 3244, 3264, 3285, 3305, 3326, 3346, 3367,
    3382, 3397, 3411, 3426, 3441, 3456, 3471, 3486, 3501, 3516, 3531, 3547,
    3562, 3578, 3593, 3609, 3624, 3640, 3656, 3672, 3687, 3703, 3719, 3736,
    3752, 3768, 3784, 3801, 3817, 3834, 3850, 3867, 3884, 3901, 3918, 3935,
    3952, 3969, 3986, 4003, 4021, 4038, 4055, 4073, 4091, 4108, 4126, 4144,
    4162, 4180, 4198, 4216, 4235, 4253, 4271, 4290, 4309, 4327, 4346, 4365,
    4384, 4403, 4422, 4441, 4460, 4480, 4499, 4518, 4538, 4558, 4577, 4597,
    4617, 4637, 4657, 4677, 4698, 4718, 4739, 4759, 4780, 4800, 4821, 4842,
    4863, 4884, 4905, 4927, 4948, 4969, 4991, 5013, 5034, 5056, 5078, 5100,
    5122, 5144, 5167, 5189, 5211, 5234, 5257, 5279, 5302, 5325, 5348, 5372,
    5395, 5418, 5442, 5465, 5489, 5513, 5537, 5561, 5585, 5609, 5633, 5658,
    5682, 5707, 5732, 5756, 5781, 5806, 5832, 5857, 5882, 5908, 5933, 5959,
    5985, 6011, 6037, 6063, 6089, 6116, 6142, 6169, 6195, 6222, 6249, 6276,
    6304, 6331, 6358, 6386, 6414, 6441, 6469, 6497, 6525, 6554, 6582, 6611,
    6639, 6668, 6697, 6726, 6755, 6784, 6814, 6843, 6873, 6903, 6933, 6963,
    6993, 7023, 7054, 7084, 7115, 7146, 7177, 7208, 7239, 7270, 7302, 7333,
    7365, 7397, 7429, 7461, 7494, 7526, 7559, 7592, 7625, 7658, 7691, 7724,
    7758, 7791, 7825, 7859, 7893, 7927, 7961, 7996, 8031, 8065, 8100, 8135,
    8171, 8206, 8242, 8277, 8313, 8349, 8385, 8422, 8458, 8495, 8532, 8569,
    8606, 8643, 8681, 8718, 8756, 8794, 8832, 8870, 8909, 8947, 8986, 9025,
    9064, 9103, 9143, 9182, 9222, 9262, 9302, 9343, 9383, 9424, 9465, 9506,
    9547, 9588, 9630, 9672, 9713, 9756, 9798, 9840, 9883, 9926, 9969, 10012,
    10055, 10099, 10143, 10187, 10231, 10275, 10320, 10364, 10409, 10454, 10500,
    10545, 10591, 10637, 10683, 10729, 10776, 10822, 10869, 10916, 10964, 11011,
    11059, 11107, 11155, 11203, 11252, 11301, 11349, 11399, 11448, 11498, 11547,
    11598, 11648, 11698, 11749, 11800, 11851, 11902, 11954, 12006, 12058, 12110,
    12162, 12215, 12268, 12321, 12375, 12428, 12482, 12536, 12591, 12645, 12700,
    12755, 12810, 12866, 12921, 12977,
  ],
  510000: [
    2423, 2443, 2463, 2484, 2504, 2525, 2546, 2567, 2589, 2610, 2632, 2654,
    2676, 2698, 2721, 2744, 2766, 2790, 2813, 2836, 2860, 2884, 2908, 2932,
    2950, 2969, 2987, 3006, 3025, 3044, 3063, 3082, 3101, 3120, 3140, 3160,
    3179, 3199, 3219, 3239, 3260, 3280, 3300, 3321, 3342, 3363, 3384, 3405,
    3420, 3434, 3449, 3464, 3479, 3494, 3509, 3525, 3540, 3555, 3571, 3586,
    3602, 3617, 3633, 3649, 3665, 3680, 3696, 3712, 3729, 3745, 3761, 3777,
    3794, 3810, 3827, 3843, 3860, 3876, 3893, 3910, 3927, 3944, 3961, 3978,
    3996, 4013, 4030, 4048, 4065, 4083, 4101, 4118, 4136, 4154, 4172, 4190,
    4208, 4227, 4245, 4263, 4282, 4300, 4319, 4338, 4357, 4375, 4394, 4413,
    4433, 4452, 4471, 4490, 4510, 4529, 4549, 4569, 4589, 4608, 4628, 4648,
    4669, 4689, 4709, 4730, 4750, 4771, 4791, 4812, 4833, 4854, 4875, 4896,
    4917, 4939, 4960, 4981, 5003, 5025, 5046, 5068, 5090, 5112, 5135, 5157,
    5179, 5202, 5224, 5247, 5269, 5292, 5315, 5338, 5361, 5385, 5408, 5431,
    5455, 5479, 5502, 5526, 5550, 5574, 5598, 5623, 5647, 5671, 5696, 5721,
    5745, 5770, 5795, 5820, 5846, 5871, 5896, 5922, 5948, 5973, 5999, 6025,
    6051, 6078, 6104, 6130, 6157, 6184, 6210, 6237, 6264, 6292, 6319, 6346,
    6374, 6401, 6429, 6457, 6485, 6513, 6541, 6570, 6598, 6627, 6655, 6684,
    6713, 6742, 6771, 6801, 6830, 6860, 6890, 6919, 6949, 6980, 7010, 7040,
    7071, 7101, 7132, 7163, 7194, 7225, 7257, 7288, 7320, 7351, 7383, 7415,
    7447, 7480, 7512, 7544, 7577, 7610, 7643, 7676, 7709, 7743, 7776, 7810,
    7844, 7878, 7912, 7946, 7981, 8015, 8050, 8085, 8120, 8155, 8190, 8226,
    8262, 8297, 8333, 8369, 8406, 8442, 8479, 8515, 8552, 8589, 8627, 8664,
    8702, 8739, 8777, 8815, 8853, 8892, 8930, 8969, 9008, 9047, 9086, 9125,
    9165, 9205, 9245, 9285, 9325, 9365, 9406, 9447, 9488, 9529, 9570, 9611,
    9653, 9695, 9737, 9779, 9822, 9864, 9907, 9950, 9993, 10036, 10080, 10123,
    10167, 10211, 10256, 10300, 10345, 10389, 10434, 10480, 10525, 10571, 10617,
    10663, 10709, 10755, 10802, 10849, 10896, 10943, 10990, 11038, 11086, 11134,
    11182, 11230, 11279, 11328, 11377, 11426, 11476, 11526, 11575, 11626, 11676,
    11727, 11777, 11828, 11880, 11931, 11983, 12035, 12087, 12139, 12192, 12245,
    12298, 12351, 12405, 12458, 12512, 12567, 12621, 12676, 12731, 12786, 12841,
    12897, 12953, 13009, 13065, 13122,
  ],
  520000: [
    2448, 2469, 2489, 2510, 2531, 2552, 2573, 2595, 2616, 2638, 2660, 2682,
    2705, 2727, 2750, 2773, 2796, 2819, 2843, 2866, 2890, 2914, 2939, 2963,
    2982, 3000, 3019, 3038, 3057, 3076, 3095, 3115, 3134, 3154, 3173, 3193,
    3213, 3233, 3254, 3274, 3294, 3315, 3336, 3356, 3377, 3399, 3420, 3441,
    3456, 3471, 3486, 3501, 3516, 3532, 3547, 3562, 3578, 3593, 3609, 3624,
    3640, 3656, 3672, 3688, 3704, 3720, 3736, 3752, 3768, 3785, 3801, 3817,
    3834, 3851, 3867, 3884, 3901, 3918, 3935, 3952, 3969, 3986, 4003, 4021,
    4038, 4056, 4073, 4091, 4109, 4126, 4144, 4162, 4180, 4198, 4217, 4235,
    4253, 4272, 4290, 4309, 4327, 4346, 4365, 4384, 4403, 4422, 4441, 4460,
    4480, 4499, 4519, 4538, 4558, 4578, 4598, 4617, 4637, 4658, 4678, 4698,
    4718, 4739, 4759, 4780, 4801, 4821, 4842, 4863, 4884, 4906, 4927, 4948,
    4970, 4991, 5013, 5035, 5056, 5078, 5100, 5122, 5145, 5167, 5189, 5212,
    5234, 5257, 5280, 5303, 5326, 5349, 5372, 5395, 5419, 5442, 5466, 5489,
    5513, 5537, 5561, 5585, 5609, 5634, 5658, 5683, 5707, 5732, 5757, 5782,
    5807, 5832, 5857, 5883, 5908, 5934, 5959, 5985, 6011, 6037, 6063, 6090,
    6116, 6142, 6169, 6196, 6223, 6250, 6277, 6304, 6331, 6359, 6386, 6414,
    6442, 6470, 6498, 6526, 6554, 6582, 6611, 6640, 6668, 6697, 6726, 6755,
    6785, 6814, 6844, 6873, 6903, 6933, 6963, 6993, 7024, 7054, 7085, 7115,
    7146, 7177, 7208, 7239, 7271, 7302, 7334, 7366, 7398, 7430, 7462, 7494,
    7527, 7559, 7592, 7625, 7658, 7691, 7724, 7758, 7792, 7825, 7859, 7893,
    7928, 7962, 7996, 8031, 8066, 8101, 8136, 8171, 8207, 8242, 8278, 8314,
    8350, 8386, 8422, 8459, 8495, 8532, 8569, 8606, 8644, 8681, 8719, 8756,
    8794, 8833, 8871, 8909, 8948, 8987, 9026, 9065, 9104, 9143, 9183, 9223,
    9263, 9303, 9343, 9384, 9424, 9465, 9506, 9547, 9589, 9630, 9672, 9714,
    9756, 9798, 9841, 9883, 9926, 9969, 10012, 10056, 10099, 10143, 10187,
    10231, 10276, 10320, 10365, 10410, 10455, 10500, 10546, 10591, 10637, 10683,
    10730, 10776, 10823, 10870, 10917, 10964, 11012, 11059, 11107, 11156, 11204,
    11252, 11301, 11350, 11399, 11449, 11498, 11548, 11598, 11648, 11699, 11750,
    11801, 11852, 11903, 11955, 12006, 12058, 12111, 12163, 12216, 12269, 12322,
    12375, 12429, 12483, 12537, 12591, 12646, 12701, 12756, 12811, 12866, 12922,
    12978, 13034, 13091, 13148, 13205, 13262,
  ],
  530000: [
    2473, 2494, 2515, 2536, 2557, 2578, 2600, 2621, 2643, 2665, 2687, 2710,
    2732, 2755, 2778, 2801, 2825, 2848, 2872, 2896, 2920, 2944, 2969, 2993,
    3012, 3031, 3050, 3069, 3088, 3108, 3127, 3146, 3166, 3186, 3206, 3226,
    3246, 3266, 3287, 3307, 3328, 3349, 3370, 3391, 3412, 3433, 3455, 3476,
    3491, 3507, 3522, 3537, 3552, 3568, 3583, 3599, 3614, 3630, 3646, 3661,
    3677, 3693, 3709, 3725, 3741, 3758, 3774, 3790, 3807, 3823, 3840, 3856,
    3873, 3890, 3907, 3924, 3941, 3958, 3975, 3992, 4009, 4027, 4044, 4062,
    4079, 4097, 4115, 4133, 4151, 4169, 4187, 4205, 4223, 4241, 4260, 4278,
    4297, 4315, 4334, 4353, 4372, 4391, 4410, 4429, 4448, 4467, 4487, 4506,
    4526, 4545, 4565, 4585, 4604, 4624, 4644, 4665, 4685, 4705, 4726, 4746,
    4767, 4787, 4808, 4829, 4850, 4871, 4892, 4913, 4934, 4956, 4977, 4999,
    5020, 5042, 5064, 5086, 5108, 5130, 5152, 5175, 5197, 5220, 5242, 5265,
    5288, 5311, 5334, 5357, 5380, 5403, 5427, 5450, 5474, 5498, 5521, 5545,
    5569, 5594, 5618, 5642, 5667, 5691, 5716, 5741, 5765, 5790, 5815, 5841,
    5866, 5891, 5917, 5943, 5968, 5994, 6020, 6046, 6072, 6099, 6125, 6152,
    6178, 6205, 6232, 6259, 6286, 6313, 6341, 6368, 6396, 6424, 6451, 6479,
    6507, 6536, 6564, 6592, 6621, 6650, 6678, 6707, 6736, 6766, 6795, 6824,
    6854, 6884, 6914, 6944, 6974, 7004, 7034, 7065, 7095, 7126, 7157, 7188,
    7219, 7250, 7282, 7313, 7345, 7377, 7409, 7441, 7473, 7506, 7538, 7571,
    7604, 7636, 7670, 7703, 7736, 7770, 7803, 7837, 7871, 7905, 7940, 7974,
    8008, 8043, 8078, 8113, 8148, 8183, 8219, 8255, 8290, 8326, 8362, 8399,
    8435, 8472, 8508, 8545, 8582, 8619, 8657, 8694, 8732, 8770, 8808, 8846,
    8884, 8923, 8961, 9000, 9039, 9078, 9118, 9157, 9197, 9237, 9277, 9317,
    9357, 9398, 9439, 9480, 9521, 9562, 9603, 9645, 9687, 9729, 9771, 9813,
    9856, 9898, 9941, 9984, 10028, 10071, 10115, 10159, 10203, 10247, 10291,
    10336, 10381, 10426, 10471, 10516, 10562, 10607, 10653, 10700, 10746, 10793,
    10839, 10886, 10933, 10981, 11028, 11076, 11124, 11172, 11221, 11269, 11318,
    11367, 11417, 11466, 11516, 11566, 11616, 11666, 11717, 11767, 11818, 11870,
    11921, 11973, 12025, 12077, 12129, 12182, 12234, 12287, 12341, 12394, 12448,
    12502, 12556, 12610, 12665, 12720, 12775, 12830, 12886, 12942, 12998, 13054,
    13111, 13168, 13225, 13282, 13339, 13397,
  ],
  540000: [
    2498, 2518, 2539, 2560, 2582, 2603, 2625, 2647, 2669, 2691, 2714, 2736,
    2759, 2782, 2805, 2829, 2852, 2876, 2900, 2924, 2948, 2973, 2998, 3023,
    3042, 3061, 3080, 3099, 3118, 3138, 3157, 3177, 3197, 3217, 3237, 3257,
    3278, 3298, 3319, 3340, 3360, 3381, 3403, 3424, 3445, 3467, 3488, 3510,
    3525, 3541, 3556, 3572, 3587, 3603, 3618, 3634, 3650, 3665, 3681, 3697,
    3713, 3729, 3746, 3762, 3778, 3794, 3811, 3827, 3844, 3861, 3877, 3894,
    3911, 3928, 3945, 3962, 3979, 3996, 4014, 4031, 4049, 4066, 4084, 4102,
    4119, 4137, 4155, 4173, 4191, 4209, 4228, 4246, 4264, 4283, 4301, 4320,
    4339, 4357, 4376, 4395, 4414, 4434, 4453, 4472, 4491, 4511, 4530, 4550,
    4570, 4590, 4609, 4629, 4649, 4670, 4690, 4710, 4731, 4751, 4772, 4792,
    4813, 4834, 4855, 4876, 4897, 4918, 4940, 4961, 4983, 5004, 5026, 5048,
    5069, 5091, 5113, 5136, 5158, 5180, 5203, 5225, 5248, 5271, 5293, 5316,
    5339, 5363, 5386, 5409, 5433, 5456, 5480, 5504, 5527, 5551, 5575, 5600,
    5624, 5648, 5673, 5697, 5722, 5747, 5772, 5797, 5822, 5847, 5872, 5898,
    5923, 5949, 5975, 6001, 6027, 6053, 6079, 6105, 6132, 6158, 6185, 6212,
    6239, 6266, 6293, 6320, 6348, 6375, 6403, 6430, 6458, 6486, 6514, 6543,
    6571, 6600, 6628, 6657, 6686, 6715, 6744, 6773, 6802, 6832, 6861, 6891,
    6921, 6951, 6981, 7011, 7042, 7072, 7103, 7134, 7165, 7196, 7227, 7258,
    7290, 7321, 7353, 7385, 7417, 7449, 7481, 7514, 7546, 7579, 7612, 7645,
    7678, 7711, 7744, 7778, 7812, 7846, 7880, 7914, 7948, 7982, 8017, 8052,
    8087, 8122, 8157, 8192, 8228, 8263, 8299, 8335, 8371, 8408, 8444, 8481,
    8517, 8554, 8591, 8629, 8666, 8704, 8741, 8779, 8817, 8855, 8894, 8932,
    8971, 9010, 9049, 9088, 9128, 9167, 9207, 9247, 9287, 9327, 9367, 9408,
    9449, 9490, 9531, 9572, 9614, 9655, 9697, 9739, 9781, 9824, 9866, 9909,
    9952, 9995, 10038, 10082, 10126, 10169, 10214, 10258, 10302, 10347, 10392,
    10437, 10482, 10527, 10573, 10619, 10665, 10711, 10758, 10804, 10851, 10898,
    10945, 10993, 11040, 11088, 11136, 11184, 11233, 11282, 11330, 11380, 11429,
    11478, 11528, 11578, 11628, 11679, 11729, 11780, 11831, 11882, 11934, 11986,
    12038, 12090, 12142, 12195, 12248, 12301, 12354, 12407, 12461, 12515, 12569,
    12624, 12679, 12734, 12789, 12844, 12900, 12956, 13012, 13068, 13125, 13182,
    13239, 13296, 13354, 13412, 13470, 13528,
  ],
  550000: [
    2521, 2542, 2563, 2584, 2606, 2628, 2650, 2672, 2694, 2716, 2739, 2762,
    2785, 2808, 2831, 2855, 2879, 2903, 2927, 2951, 2976, 3001, 3026, 3051,
    3070, 3089, 3109, 3128, 3148, 3167, 3187, 3207, 3227, 3247, 3267, 3288,
    3308, 3329, 3350, 3371, 3392, 3413, 3434, 3456, 3477, 3499, 3521, 3543,
    3558, 3574, 3589, 3605, 3621, 3636, 3652, 3668, 3684, 3700, 3716, 3732,
    3748, 3764, 3780, 3797, 3813, 3830, 3846, 3863, 3880, 3897, 3914, 3931,
    3948, 3965, 3982, 3999, 4016, 4034, 4051, 4069, 4086, 4104, 4122, 4140,
    4158, 4176, 4194, 4212, 4230, 4249, 4267, 4286, 4304, 4323, 4342, 4360,
    4379, 4398, 4417, 4436, 4456, 4475, 4494, 4514, 4533, 4553, 4573, 4593,
    4612, 4632, 4653, 4673, 4693, 4713, 4734, 4754, 4775, 4795, 4816, 4837,
    4858, 4879, 4900, 4922, 4943, 4964, 4986, 5007, 5029, 5051, 5073, 5095,
    5117, 5139, 5161, 5184, 5206, 5229, 5251, 5274, 5297, 5320, 5343, 5366,
    5389, 5413, 5436, 5460, 5483, 5507, 5531, 5555, 5579, 5603, 5627, 5652,
    5676, 5701, 5726, 5750, 5775, 5800, 5826, 5851, 5876, 5902, 5927, 5953,
    5979, 6005, 6031, 6057, 6083, 6109, 6136, 6162, 6189, 6216, 6243, 6270,
    6297, 6324, 6352, 6379, 6407, 6435, 6463, 6491, 6519, 6547, 6575, 6604,
    6632, 6661, 6690, 6719, 6748, 6777, 6807, 6836, 6866, 6896, 6926, 6956,
    6986, 7016, 7046, 7077, 7108, 7138, 7169, 7200, 7232, 7263, 7294, 7326,
    7358, 7390, 7422, 7454, 7486, 7518, 7551, 7584, 7617, 7650, 7683, 7716,
    7750, 7783, 7817, 7851, 7885, 7919, 7953, 7988, 8022, 8057, 8092, 8127,
    8162, 8198, 8233, 8269, 8305, 8341, 8377, 8413, 8450, 8486, 8523, 8560,
    8597, 8634, 8672, 8709, 8747, 8785, 8823, 8861, 8900, 8938, 8977, 9016,
    9055, 9094, 9133, 9173, 9213, 9253, 9293, 9333, 9374, 9414, 9455, 9496,
    9537, 9578, 9620, 9662, 9703, 9745, 9788, 9830, 9873, 9916, 9958, 10002,
    10045, 10089, 10132, 10176, 10220, 10265, 10309, 10354, 10399, 10444, 10489,
    10534, 10580, 10626, 10672, 10718, 10765, 10811, 10858, 10905, 10952, 11000,
    11047, 11095, 11143, 11192, 11240, 11289, 11338, 11387, 11436, 11486, 11536,
    11586, 11636, 11686, 11737, 11788, 11839, 11890, 11942, 11993, 12045, 12098,
    12150, 12203, 12256, 12309, 12362, 12416, 12469, 12523, 12578, 12632, 12687,
    12742, 12797, 12853, 12908, 12964, 13020, 13077, 13133, 13190, 13247, 13305,
    13363, 13420, 13479, 13537, 13596, 13655,
  ],
  560000: [
    2543, 2565, 2586, 2607, 2629, 2651, 2673, 2695, 2718, 2741, 2763, 2786,
    2810, 2833, 2857, 2880, 2904, 2929, 2953, 2978, 3003, 3028, 3053, 3078,
    3097, 3117, 3136, 3156, 3176, 3195, 3215, 3236, 3256, 3276, 3297, 3317,
    3338, 3359, 3380, 3401, 3422, 3444, 3465, 3487, 3509, 3530, 3552, 3575,
    3590, 3606, 3621, 3637, 3653, 3669, 3685, 3701, 3717, 3733, 3749, 3765,
    3781, 3798, 3814, 3831, 3847, 3864, 3881, 3898, 3914, 3931, 3948, 3966,
    3983, 4000, 4017, 4035, 4052, 4070, 4087, 4105, 4123, 4141, 4159, 4177,
    4195, 4213, 4231, 4250, 4268, 4287, 4305, 4324, 4343, 4361, 4380, 4399,
    4418, 4437, 4457, 4476, 4495, 4515, 4534, 4554, 4574, 4594, 4614, 4634,
    4654, 4674, 4694, 4714, 4735, 4755, 4776, 4797, 4817, 4838, 4859, 4880,
    4901, 4923, 4944, 4965, 4987, 5009, 5030, 5052, 5074, 5096, 5118, 5140,
    5162, 5185, 5207, 5230, 5253, 5275, 5298, 5321, 5344, 5367, 5391, 5414,
    5437, 5461, 5485, 5508, 5532, 5556, 5580, 5605, 5629, 5653, 5678, 5702,
    5727, 5752, 5777, 5802, 5827, 5852, 5878, 5903, 5929, 5954, 5980, 6006,
    6032, 6058, 6084, 6111, 6137, 6164, 6191, 6217, 6244, 6271, 6299, 6326,
    6353, 6381, 6408, 6436, 6464, 6492, 6520, 6549, 6577, 6605, 6634, 6663,
    6692, 6721, 6750, 6779, 6808, 6838, 6868, 6897, 6927, 6957, 6987, 7018,
    7048, 7079, 7109, 7140, 7171, 7202, 7233, 7265, 7296, 7328, 7359, 7391,
    7423, 7456, 7488, 7520, 7553, 7586, 7618, 7651, 7685, 7718, 7751, 7785,
    7819, 7853, 7887, 7921, 7955, 7990, 8024, 8059, 8094, 8129, 8164, 8200,
    8235, 8271, 8307, 8343, 8379, 8415, 8452, 8488, 8525, 8562, 8599, 8636,
    8674, 8711, 8749, 8787, 8825, 8863, 8902, 8940, 8979, 9018, 9057, 9096,
    9136, 9175, 9215, 9255, 9295, 9335, 9376, 9416, 9457, 9498, 9539, 9581,
    9622, 9664, 9706, 9748, 9790, 9832, 9875, 9918, 9961, 10004, 10047, 10091,
    10135, 10179, 10223, 10267, 10311, 10356, 10401, 10446, 10491, 10537, 10582,
    10628, 10674, 10721, 10767, 10814, 10861, 10908, 10955, 11002, 11050, 11098,
    11146, 11194, 11243, 11292, 11341, 11390, 11439, 11489, 11538, 11588, 11639,
    11689, 11740, 11791, 11842, 11893, 11944, 11996, 12048, 12100, 12153, 12206,
    12258, 12312, 12365, 12418, 12472, 12526, 12581, 12635, 12690, 12745, 12800,
    12856, 12911, 12967, 13023, 13080, 13137, 13193, 13251, 13308, 13366, 13424,
    13482, 13540, 13599, 13658, 13717, 13776,
  ],
  570000: [
    2565, 2586, 2608, 2630, 2652, 2674, 2696, 2718, 2741, 2764, 2787, 2810,
    2834, 2857, 2881, 2905, 2929, 2954, 2978, 3003, 3028, 3053, 3079, 3104,
    3124, 3143, 3163, 3183, 3203, 3223, 3243, 3263, 3283, 3304, 3325, 3345,
    3366, 3387, 3409, 3430, 3451, 3473, 3495, 3516, 3538, 3561, 3583, 3605,
    3621, 3636, 3652, 3668, 3684, 3700, 3716, 3732, 3748, 3764, 3781, 3797,
    3814, 3830, 3847, 3863, 3880, 3897, 3914, 3931, 3948, 3965, 3982, 3999,
    4017, 4034, 4052, 4069, 4087, 4104, 4122, 4140, 4158, 4176, 4194, 4212,
    4231, 4249, 4267, 4286, 4304, 4323, 4342, 4361, 4380, 4399, 4418, 4437,
    4456, 4475, 4495, 4514, 4534, 4553, 4573, 4593, 4613, 4633, 4653, 4673,
    4693, 4714, 4734, 4755, 4775, 4796, 4817, 4837, 4858, 4879, 4901, 4922,
    4943, 4965, 4986, 5008, 5029, 5051, 5073, 5095, 5117, 5139, 5162, 5184,
    5206, 5229, 5252, 5274, 5297, 5320, 5343, 5366, 5390, 5413, 5437, 5460,
    5484, 5508, 5531, 5555, 5579, 5604, 5628, 5652, 5677, 5701, 5726, 5751,
    5776, 5801, 5826, 5851, 5877, 5902, 5928, 5953, 5979, 6005, 6031, 6057,
    6083, 6110, 6136, 6163, 6190, 6216, 6243, 6270, 6298, 6325, 6352, 6380,
    6407, 6435, 6463, 6491, 6519, 6547, 6576, 6604, 6633, 6662, 6691, 6720,
    6749, 6778, 6807, 6837, 6866, 6896, 6926, 6956, 6986, 7016, 7047, 7077,
    7108, 7139, 7170, 7201, 7232, 7263, 7295, 7326, 7358, 7390, 7422, 7454,
    7487, 7519, 7552, 7584, 7617, 7650, 7683, 7717, 7750, 7784, 7817, 7851,
    7885, 7919, 7954, 7988, 8023, 8058, 8093, 8128, 8163, 8198, 8234, 8269,
    8305, 8341, 8377, 8414, 8450, 8487, 8524, 8560, 8598, 8635, 8672, 8710,
    8748, 8785, 8824, 8862, 8900, 8939, 8978, 9016, 9055, 9095, 9134, 9174,
    9213, 9253, 9293, 9334, 9374, 9415, 9456, 9497, 9538, 9579, 9621, 9662,
    9704, 9746, 9788, 9831, 9873, 9916, 9959, 10002, 10046, 10089, 10133, 10177,
    10221, 10265, 10310, 10354, 10399, 10444, 10490, 10535, 10581, 10627, 10673,
    10719, 10765, 10812, 10859, 10906, 10953, 11001, 11048, 11096, 11144, 11193,
    11241, 11290, 11339, 11388, 11437, 11487, 11536, 11586, 11637, 11687, 11738,
    11789, 11840, 11891, 11943, 11994, 12046, 12098, 12151, 12204, 12256, 12310,
    12363, 12416, 12470, 12524, 12579, 12633, 12688, 12743, 12798, 12853, 12909,
    12965, 13021, 13078, 13134, 13191, 13248, 13306, 13364, 13421, 13480, 13538,
    13597, 13656, 13715, 13774, 13834, 13894,
  ],
  580000: [
    2586, 2607, 2629, 2651, 2673, 2695, 2718, 2740, 2763, 2786, 2810, 2833,
    2857, 2880, 2904, 2929, 2953, 2978, 3002, 3027, 3053, 3078, 3104, 3130,
    3149, 3169, 3189, 3209, 3229, 3249, 3269, 3290, 3310, 3331, 3352, 3373,
    3394, 3415, 3436, 3458, 3479, 3501, 3523, 3545, 3567, 3589, 3612, 3634,
    3650, 3666, 3682, 3698, 3714, 3730, 3746, 3762, 3779, 3795, 3811, 3828,
    3845, 3861, 3878, 3895, 3912, 3929, 3946, 3963, 3980, 3997, 4014, 4032,
    4049, 4067, 4085, 4102, 4120, 4138, 4156, 4174, 4192, 4210, 4228, 4247,
    4265, 4283, 4302, 4321, 4339, 4358, 4377, 4396, 4415, 4434, 4453, 4473,
    4492, 4512, 4531, 4551, 4571, 4590, 4610, 4630, 4650, 4670, 4691, 4711,
    4731, 4752, 4772, 4793, 4814, 4835, 4856, 4877, 4898, 4919, 4940, 4962,
    4983, 5005, 5027, 5048, 5070, 5092, 5114, 5136, 5159, 5181, 5204, 5226,
    5249, 5272, 5294, 5317, 5340, 5363, 5387, 5410, 5433, 5457, 5481, 5504,
    5528, 5552, 5576, 5600, 5625, 5649, 5674, 5698, 5723, 5748, 5773, 5798,
    5823, 5848, 5873, 5899, 5924, 5950, 5976, 6002, 6028, 6054, 6080, 6106,
    6133, 6159, 6186, 6213, 6240, 6267, 6294, 6321, 6349, 6376, 6404, 6432,
    6459, 6487, 6516, 6544, 6572, 6601, 6629, 6658, 6687, 6716, 6745, 6774,
    6803, 6833, 6863, 6892, 6922, 6952, 6982, 7013, 7043, 7073, 7104, 7135,
    7166, 7197, 7228, 7259, 7291, 7322, 7354, 7386, 7418, 7450, 7482, 7515,
    7547, 7580, 7613, 7646, 7679, 7712, 7746, 7779, 7813, 7847, 7881, 7915,
    7949, 7984, 8018, 8053, 8088, 8123, 8158, 8194, 8229, 8265, 8301, 8337,
    8373, 8409, 8445, 8482, 8519, 8556, 8593, 8630, 8667, 8705, 8743, 8781,
    8819, 8857, 8895, 8934, 8972, 9011, 9050, 9090, 9129, 9169, 9208, 9248,
    9288, 9329, 9369, 9410, 9450, 9491, 9532, 9574, 9615, 9657, 9699, 9741,
    9783, 9825, 9868, 9911, 9954, 9997, 10040, 10084, 10127, 10171, 10215,
    10260, 10304, 10349, 10393, 10439, 10484, 10529, 10575, 10621, 10667, 10713,
    10759, 10806, 10853, 10900, 10947, 10994, 11042, 11090, 11138, 11186, 11235,
    11283, 11332, 11381, 11431, 11480, 11530, 11580, 11630, 11681, 11731, 11782,
    11833, 11884, 11936, 11988, 12040, 12092, 12144, 12197, 12250, 12303, 12356,
    12409, 12463, 12517, 12572, 12626, 12681, 12736, 12791, 12846, 12902, 12958,
    13014, 13070, 13127, 13184, 13241, 13298, 13356, 13414, 13472, 13530, 13589,
    13648, 13707, 13766, 13826, 13886, 13946, 14007,
  ],
  590000: [
    2606, 2628, 2649, 2672, 2694, 2716, 2739, 2762, 2785, 2808, 2831, 2855,
    2879, 2903, 2927, 2951, 2976, 3001, 3026, 3051, 3076, 3102, 3128, 3154,
    3174, 3193, 3213, 3233, 3254, 3274, 3294, 3315, 3336, 3357, 3378, 3399,
    3420, 3441, 3463, 3484, 3506, 3528, 3550, 3572, 3595, 3617, 3640, 3663,
    3678, 3694, 3710, 3726, 3743, 3759, 3775, 3791, 3808, 3824, 3841, 3858,
    3874, 3891, 3908, 3925, 3942, 3959, 3976, 3993, 4011, 4028, 4046, 4063,
    4081, 4098, 4116, 4134, 4152, 4170, 4188, 4206, 4224, 4243, 4261, 4279,
    4298, 4317, 4335, 4354, 4373, 4392, 4411, 4430, 4449, 4469, 4488, 4507,
    4527, 4546, 4566, 4586, 4606, 4626, 4646, 4666, 4686, 4707, 4727, 4747,
    4768, 4789, 4809, 4830, 4851, 4872, 4893, 4914, 4936, 4957, 4979, 5000,
    5022, 5044, 5066, 5087, 5110, 5132, 5154, 5176, 5199, 5221, 5244, 5267,
    5289, 5312, 5335, 5358, 5382, 5405, 5428, 5452, 5476, 5499, 5523, 5547,
    5571, 5595, 5619, 5644, 5668, 5693, 5717, 5742, 5767, 5792, 5817, 5842,
    5868, 5893, 5919, 5944, 5970, 5996, 6022, 6048, 6074, 6101, 6127, 6154,
    6180, 6207, 6234, 6261, 6288, 6315, 6343, 6370, 6398, 6426, 6453, 6481,
    6509, 6538, 6566, 6594, 6623, 6652, 6680, 6709, 6739, 6768, 6797, 6826,
    6856, 6886, 6916, 6946, 6976, 7006, 7036, 7067, 7097, 7128, 7159, 7190,
    7221, 7252, 7284, 7315, 7347, 7379, 7411, 7443, 7475, 7508, 7540, 7573,
    7606, 7639, 7672, 7705, 7738, 7772, 7806, 7840, 7873, 7908, 7942, 7976,
    8011, 8046, 8080, 8115, 8151, 8186, 8221, 8257, 8293, 8329, 8365, 8401,
    8437, 8474, 8511, 8548, 8585, 8622, 8659, 8697, 8734, 8772, 8810, 8849,
    8887, 8925, 8964, 9003, 9042, 9081, 9120, 9160, 9200, 9240, 9280, 9320,
    9360, 9401, 9441, 9482, 9523, 9565, 9606, 9648, 9690, 9732, 9774, 9816,
    9859, 9901, 9944, 9987, 10031, 10074, 10118, 10162, 10206, 10250, 10294,
    10339, 10384, 10429, 10474, 10519, 10565, 10611, 10657, 10703, 10749, 10796,
    10843, 10889, 10937, 10984, 11032, 11079, 11127, 11176, 11224, 11273, 11322,
    11371, 11420, 11469, 11519, 11569, 11619, 11670, 11720, 11771, 11822, 11873,
    11925, 11976, 12028, 12080, 12133, 12185, 12238, 12291, 12344, 12398, 12452,
    12505, 12560, 12614, 12669, 12724, 12779, 12834, 12890, 12946, 13002, 13058,
    13115, 13171, 13229, 13286, 13343, 13401, 13459, 13518, 13576, 13635, 13694,
    13754, 13813, 13873, 13933, 13993, 14054, 14115,
  ],
  600000: [
    2625, 2647, 2669, 2691, 2714, 2736, 2759, 2782, 2805, 2829, 2852, 2876,
    2900, 2924, 2948, 2973, 2998, 3023, 3048, 3073, 3099, 3125, 3151, 3177,
    3197, 3217, 3237, 3257, 3278, 3298, 3319, 3339, 3360, 3381, 3402, 3424,
    3445, 3467, 3488, 3510, 3532, 3554, 3576, 3599, 3621, 3644, 3667, 3689,
    3705, 3722, 3738, 3754, 3770, 3786, 3803, 3819, 3836, 3853, 3869, 3886,
    3903, 3920, 3937, 3954, 3971, 3988, 4005, 4023, 4040, 4058, 4075, 4093,
    4111, 4128, 4146, 4164, 4182, 4201, 4219, 4237, 4255, 4274, 4292, 4311,
    4330, 4348, 4367, 4386, 4405, 4424, 4443, 4463, 4482, 4501, 4521, 4541,
    4560, 4580, 4600, 4620, 4640, 4660, 4680, 4700, 4721, 4741, 4762, 4782,
    4803, 4824, 4845, 4866, 4887, 4908, 4929, 4951, 4972, 4994, 5015, 5037,
    5059, 5081, 5103, 5125, 5147, 5169, 5192, 5214, 5237, 5260, 5282, 5305,
    5328, 5351, 5375, 5398, 5421, 5445, 5468, 5492, 5516, 5540, 5564, 5588,
    5612, 5636, 5661, 5685, 5710, 5735, 5760, 5784, 5810, 5835, 5860, 5885,
    5911, 5937, 5962, 5988, 6014, 6040, 6066, 6093, 6119, 6145, 6172, 6199,
    6226, 6253, 6280, 6307, 6334, 6362, 6389, 6417, 6445, 6473, 6501, 6529,
    6557, 6586, 6614, 6643, 6672, 6701, 6730, 6759, 6788, 6817, 6847, 6877,
    6906, 6936, 6966, 6997, 7027, 7057, 7088, 7119, 7150, 7181, 7212, 7243,
    7274, 7306, 7337, 7369, 7401, 7433, 7466, 7498, 7530, 7563, 7596, 7629,
    7662, 7695, 7728, 7762, 7795, 7829, 7863, 7897, 7931, 7966, 8000, 8035,
    8070, 8105, 8140, 8175, 8211, 8246, 8282, 8318, 8354, 8390, 8426, 8463,
    8500, 8536, 8573, 8611, 8648, 8685, 8723, 8761, 8799, 8837, 8875, 8914,
    8952, 8991, 9030, 9069, 9108, 9148, 9188, 9227, 9267, 9307, 9348, 9388,
    9429, 9470, 9511, 9552, 9593, 9635, 9677, 9719, 9761, 9803, 9846, 9888,
    9931, 9974, 10017, 10061, 10104, 10148, 10192, 10236, 10281, 10325, 10370,
    10415, 10460, 10505, 10551, 10597, 10643, 10689, 10735, 10781, 10828, 10875,
    10922, 10970, 11017, 11065, 11113, 11161, 11209, 11258, 11307, 11356, 11405,
    11454, 11504, 11554, 11604, 11654, 11705, 11755, 11806, 11857, 11909, 11960,
    12012, 12064, 12117, 12169, 12222, 12275, 12328, 12381, 12435, 12489, 12543,
    12597, 12652, 12707, 12762, 12817, 12873, 12929, 12985, 13041, 13097, 13154,
    13211, 13268, 13326, 13384, 13442, 13500, 13558, 13617, 13676, 13735, 13795,
    13855, 13915, 13975, 14036, 14096, 14157, 14219,
  ],
  610000: [
    2664, 2686, 2708, 2731, 2754, 2777, 2800, 2823, 2847, 2870, 2894, 2918,
    2943, 2967, 2992, 3017, 3042, 3067, 3093, 3119, 3145, 3171, 3197, 3224,
    3244, 3264, 3285, 3305, 3326, 3347, 3368, 3389, 3410, 3431, 3453, 3474,
    3496, 3518, 3540, 3562, 3584, 3606, 3629, 3652, 3675, 3697, 3721, 3744,
    3760, 3776, 3793, 3809, 3826, 3842, 3859, 3876, 3892, 3909, 3926, 3943,
    3960, 3977, 3995, 4012, 4029, 4047, 4064, 4082, 4100, 4117, 4135, 4153,
    4171, 4189, 4207, 4226, 4244, 4262, 4281, 4299, 4318, 4337, 4356, 4374,
    4393, 4412, 4432, 4451, 4470, 4489, 4509, 4528, 4548, 4568, 4588, 4607,
    4627, 4647, 4668, 4688, 4708, 4728, 4749, 4770, 4790, 4811, 4832, 4853,
    4874, 4895, 4916, 4937, 4959, 4980, 5002, 5024, 5045, 5067, 5089, 5111,
    5133, 5156, 5178, 5200, 5223, 5246, 5268, 5291, 5314, 5337, 5360, 5383,
    5407, 5430, 5454, 5477, 5501, 5525, 5549, 5573, 5597, 5621, 5646, 5670,
    5695, 5719, 5744, 5769, 5794, 5819, 5844, 5870, 5895, 5921, 5946, 5972,
    5998, 6024, 6050, 6076, 6103, 6129, 6156, 6182, 6209, 6236, 6263, 6290,
    6317, 6345, 6372, 6400, 6428, 6455, 6483, 6512, 6540, 6568, 6597, 6625,
    6654, 6683, 6712, 6741, 6770, 6799, 6829, 6858, 6888, 6918, 6948, 6978,
    7008, 7039, 7069, 7100, 7130, 7161, 7192, 7224, 7255, 7286, 7318, 7350,
    7381, 7413, 7446, 7478, 7510, 7543, 7575, 7608, 7641, 7674, 7708, 7741,
    7775, 7808, 7842, 7876, 7910, 7945, 7979, 8014, 8048, 8083, 8118, 8153,
    8189, 8224, 8260, 8296, 8332, 8368, 8404, 8440, 8477, 8514, 8550, 8588,
    8625, 8662, 8700, 8737, 8775, 8813, 8851, 8890, 8928, 8967, 9006, 9045,
    9084, 9123, 9163, 9203, 9243, 9283, 9323, 9363, 9404, 9445, 9485, 9527,
    9568, 9609, 9651, 9693, 9735, 9777, 9819, 9862, 9905, 9948, 9991, 10034,
    10077, 10121, 10165, 10209, 10253, 10298, 10342, 10387, 10432, 10477, 10523,
    10568, 10614, 10660, 10706, 10753, 10799, 10846, 10893, 10940, 10988, 11035,
    11083, 11131, 11179, 11228, 11277, 11325, 11374, 11424, 11473, 11523, 11573,
    11623, 11673, 11724, 11775, 11826, 11877, 11929, 11980, 12032, 12084, 12137,
    12189, 12242, 12295, 12348, 12402, 12456, 12510, 12564, 12618, 12673, 12728,
    12783, 12838, 12894, 12950, 13006, 13062, 13119, 13176, 13233, 13290, 13348,
    13406, 13464, 13522, 13581, 13640, 13699, 13758, 13818, 13878, 13938, 13998,
    14059, 14120, 14181, 14242, 14304, 14366, 14428,
  ],
  620000: [
    2702, 2725, 2747, 2770, 2793, 2817, 2840, 2864, 2888, 2912, 2936, 2960,
    2985, 3010, 3035, 3060, 3086, 3112, 3138, 3164, 3190, 3217, 3243, 3270,
    3291, 3311, 3332, 3353, 3374, 3395, 3416, 3438, 3459, 3481, 3502, 3524,
    3546, 3569, 3591, 3613, 3636, 3659, 3681, 3704, 3728, 3751, 3774, 3798,
    3814, 3831, 3848, 3864, 3881, 3898, 3915, 3932, 3949, 3966, 3983, 4000,
    4018, 4035, 4052, 4070, 4088, 4105, 4123, 4141, 4159, 4177, 4195, 4213,
    4232, 4250, 4268, 4287, 4305, 4324, 4343, 4362, 4380, 4399, 4418, 4438,
    4457, 4476, 4496, 4515, 4535, 4554, 4574, 4594, 4614, 4634, 4654, 4674,
    4694, 4715, 4735, 4756, 4776, 4797, 4818, 4838, 4859, 4881, 4902, 4923,
    4944, 4966, 4987, 5009, 5030, 5052, 5074, 5096, 5118, 5140, 5163, 5185,
    5208, 5230, 5253, 5276, 5298, 5321, 5344, 5368, 5391, 5414, 5438, 5461,
    5485, 5509, 5533, 5557, 5581, 5605, 5629, 5653, 5678, 5703, 5727, 5752,
    5777, 5802, 5827, 5852, 5878, 5903, 5929, 5955, 5980, 6006, 6032, 6058,
    6085, 6111, 6138, 6164, 6191, 6218, 6245, 6272, 6299, 6326, 6354, 6381,
    6409, 6436, 6464, 6492, 6521, 6549, 6577, 6606, 6634, 6663, 6692, 6721,
    6750, 6779, 6809, 6838, 6868, 6898, 6927, 6957, 6988, 7018, 7048, 7079,
    7110, 7140, 7171, 7202, 7234, 7265, 7296, 7328, 7360, 7392, 7424, 7456,
    7488, 7521, 7553, 7586, 7619, 7652, 7685, 7718, 7752, 7785, 7819, 7853,
    7887, 7921, 7955, 7990, 8025, 8059, 8094, 8129, 8165, 8200, 8235, 8271,
    8307, 8343, 8379, 8415, 8452, 8489, 8525, 8562, 8599, 8637, 8674, 8712,
    8749, 8787, 8825, 8864, 8902, 8941, 8979, 9018, 9057, 9097, 9136, 9176,
    9215, 9255, 9295, 9336, 9376, 9417, 9458, 9499, 9540, 9581, 9623, 9664,
    9706, 9748, 9790, 9833, 9876, 9918, 9961, 10004, 10048, 10091, 10135, 10179,
    10223, 10267, 10312, 10357, 10401, 10447, 10492, 10537, 10583, 10629, 10675,
    10721, 10768, 10814, 10861, 10908, 10955, 11003, 11051, 11098, 11147, 11195,
    11243, 11292, 11341, 11390, 11440, 11489, 11539, 11589, 11639, 11690, 11740,
    11791, 11842, 11893, 11945, 11997, 12049, 12101, 12153, 12206, 12259, 12312,
    12365, 12419, 12473, 12527, 12581, 12636, 12690, 12745, 12801, 12856, 12912,
    12968, 13024, 13080, 13137, 13194, 13251, 13309, 13366, 13424, 13482, 13541,
    13599, 13658, 13718, 13777, 13837, 13897, 13957, 14017, 14078, 14139, 14200,
    14262, 14324, 14386, 14448, 14511, 14574, 14637,
  ],
  630000: [
    2741, 2763, 2786, 2810, 2833, 2857, 2880, 2904, 2929, 2953, 2978, 3002,
    3027, 3053, 3078, 3104, 3130, 3156, 3182, 3209, 3235, 3262, 3289, 3317,
    3338, 3358, 3379, 3401, 3422, 3443, 3465, 3486, 3508, 3530, 3552, 3574,
    3597, 3619, 3642, 3665, 3687, 3710, 3734, 3757, 3780, 3804, 3828, 3852,
    3869, 3885, 3902, 3919, 3936, 3953, 3970, 3987, 4005, 4022, 4039, 4057,
    4075, 4092, 4110, 4128, 4146, 4164, 4182, 4200, 4218, 4236, 4255, 4273,
    4292, 4310, 4329, 4348, 4366, 4385, 4404, 4423, 4443, 4462, 4481, 4501,
    4520, 4540, 4559, 4579, 4599, 4619, 4639, 4659, 4679, 4699, 4720, 4740,
    4761, 4781, 4802, 4823, 4844, 4865, 4886, 4907, 4928, 4950, 4971, 4993,
    5014, 5036, 5058, 5080, 5102, 5124, 5146, 5168, 5191, 5213, 5236, 5259,
    5281, 5304, 5327, 5350, 5374, 5397, 5420, 5444, 5467, 5491, 5515, 5539,
    5563, 5587, 5611, 5635, 5660, 5684, 5709, 5734, 5758, 5783, 5809, 5834,
    5859, 5884, 5910, 5935, 5961, 5987, 6013, 6039, 6065, 6091, 6118, 6144,
    6171, 6198, 6225, 6252, 6279, 6306, 6333, 6361, 6388, 6416, 6444, 6472,
    6500, 6528, 6556, 6585, 6613, 6642, 6670, 6699, 6728, 6758, 6787, 6816,
    6846, 6875, 6905, 6935, 6965, 6995, 7026, 7056, 7087, 7117, 7148, 7179,
    7210, 7242, 7273, 7305, 7336, 7368, 7400, 7432, 7464, 7497, 7529, 7562,
    7594, 7627, 7660, 7694, 7727, 7760, 7794, 7828, 7862, 7896, 7930, 7964,
    7999, 8034, 8068, 8103, 8138, 8174, 8209, 8245, 8280, 8316, 8352, 8388,
    8425, 8461, 8498, 8535, 8572, 8609, 8646, 8684, 8721, 8759, 8797, 8835,
    8874, 8912, 8951, 8989, 9028, 9067, 9107, 9146, 9186, 9226, 9266, 9306,
    9346, 9387, 9427, 9468, 9509, 9550, 9592, 9633, 9675, 9717, 9759, 9801,
    9844, 9887, 9929, 9972, 10016, 10059, 10103, 10146, 10190, 10234, 10279,
    10323, 10368, 10413, 10458, 10503, 10549, 10595, 10641, 10687, 10733, 10780,
    10826, 10873, 10920, 10968, 11015, 11063, 11111, 11159, 11207, 11256, 11305,
    11354, 11403, 11452, 11502, 11552, 11602, 11652, 11703, 11753, 11804, 11855,
    11907, 11958, 12010, 12062, 12114, 12167, 12220, 12273, 12326, 12379, 12433,
    12487, 12541, 12595, 12650, 12705, 12760, 12815, 12870, 12926, 12982, 13038,
    13095, 13152, 13209, 13266, 13323, 13381, 13439, 13497, 13556, 13615, 13674,
    13733, 13792, 13852, 13912, 13972, 14033, 14094, 14155, 14216, 14278, 14340,
    14402, 14464, 14527, 14590, 14653, 14717, 14780, 14844,
  ],
  640000: [
    2779, 2802, 2825, 2849, 2872, 2896, 2921, 2945, 2969, 2994, 3019, 3044,
    3070, 3095, 3121, 3147, 3173, 3200, 3226, 3253, 3280, 3308, 3335, 3363,
    3384, 3405, 3426, 3448, 3469, 3491, 3513, 3535, 3557, 3579, 3602, 3624,
    3647, 3670, 3692, 3716, 3739, 3762, 3786, 3809, 3833, 3857, 3881, 3905,
    3922, 3939, 3956, 3974, 3991, 4008, 4025, 4043, 4060, 4078, 4096, 4113,
    4131, 4149, 4167, 4185, 4203, 4222, 4240, 4258, 4277, 4295, 4314, 4333,
    4351, 4370, 4389, 4408, 4427, 4446, 4466, 4485, 4504, 4524, 4544, 4563,
    4583, 4603, 4623, 4643, 4663, 4683, 4704, 4724, 4744, 4765, 4786, 4806,
    4827, 4848, 4869, 4890, 4911, 4933, 4954, 4975, 4997, 5019, 5040, 5062,
    5084, 5106, 5128, 5151, 5173, 5195, 5218, 5240, 5263, 5286, 5309, 5332,
    5355, 5378, 5401, 5425, 5448, 5472, 5496, 5520, 5543, 5567, 5592, 5616,
    5640, 5665, 5689, 5714, 5739, 5763, 5788, 5813, 5839, 5864, 5889, 5915,
    5941, 5966, 5992, 6018, 6044, 6070, 6097, 6123, 6150, 6176, 6203, 6230,
    6257, 6284, 6311, 6339, 6366, 6394, 6421, 6449, 6477, 6505, 6533, 6562,
    6590, 6619, 6647, 6676, 6705, 6734, 6763, 6793, 6822, 6852, 6881, 6911,
    6941, 6971, 7001, 7032, 7062, 7093, 7124, 7154, 7185, 7217, 7248, 7279,
    7311, 7342, 7374, 7406, 7438, 7471, 7503, 7535, 7568, 7601, 7634, 7667,
    7700, 7734, 7767, 7801, 7834, 7868, 7903, 7937, 7971, 8006, 8040, 8075,
    8110, 8145, 8181, 8216, 8252, 8287, 8323, 8359, 8396, 8432, 8469, 8505,
    8542, 8579, 8616, 8654, 8691, 8729, 8767, 8805, 8843, 8881, 8920, 8958,
    8997, 9036, 9075, 9115, 9154, 9194, 9234, 9274, 9314, 9354, 9395, 9435,
    9476, 9517, 9559, 9600, 9642, 9683, 9725, 9767, 9810, 9852, 9895, 9938,
    9981, 10024, 10068, 10111, 10155, 10199, 10243, 10288, 10332, 10377, 10422,
    10467, 10513, 10558, 10604, 10650, 10696, 10742, 10789, 10836, 10883, 10930,
    10977, 11025, 11072, 11120, 11169, 11217, 11266, 11314, 11363, 11413, 11462,
    11512, 11562, 11612, 11662, 11713, 11763, 11814, 11866, 11917, 11969, 12020,
    12073, 12125, 12177, 12230, 12283, 12336, 12390, 12444, 12497, 12552, 12606,
    12661, 12715, 12771, 12826, 12882, 12937, 12993, 13050, 13106, 13163, 13220,
    13277, 13335, 13393, 13451, 13509, 13568, 13626, 13685, 13745, 13804, 13864,
    13924, 13984, 14045, 14106, 14167, 14228, 14290, 14352, 14414, 14477, 14539,
    14602, 14666, 14729, 14793, 14857, 14922, 14986, 15051,
  ],
  650000: [
    2817, 2840, 2864, 2888, 2912, 2936, 2960, 2985, 3010, 3035, 3060, 3086,
    3112, 3138, 3164, 3190, 3217, 3243, 3270, 3298, 3325, 3353, 3381, 3409,
    3430, 3452, 3473, 3495, 3517, 3539, 3561, 3583, 3606, 3628, 3651, 3674,
    3697, 3720, 3743, 3766, 3790, 3814, 3837, 3861, 3886, 3910, 3934, 3959,
    3976, 3993, 4011, 4028, 4045, 4063, 4081, 4098, 4116, 4134, 4152, 4170,
    4188, 4206, 4224, 4242, 4261, 4279, 4298, 4316, 4335, 4354, 4373, 4392,
    4411, 4430, 4449, 4468, 4488, 4507, 4527, 4546, 4566, 4586, 4606, 4626,
    4646, 4666, 4686, 4706, 4727, 4747, 4768, 4788, 4809, 4830, 4851, 4872,
    4893, 4914, 4936, 4957, 4979, 5000, 5022, 5044, 5065, 5087, 5109, 5132,
    5154, 5176, 5198, 5221, 5244, 5266, 5289, 5312, 5335, 5358, 5381, 5405,
    5428, 5452, 5475, 5499, 5523, 5547, 5571, 5595, 5619, 5644, 5668, 5693,
    5717, 5742, 5767, 5792, 5817, 5842, 5868, 5893, 5919, 5944, 5970, 5996,
    6022, 6048, 6074, 6100, 6127, 6153, 6180, 6207, 6234, 6261, 6288, 6315,
    6342, 6370, 6398, 6425, 6453, 6481, 6509, 6537, 6566, 6594, 6623, 6651,
    6680, 6709, 6738, 6768, 6797, 6826, 6856, 6886, 6915, 6945, 6975, 7006,
    7036, 7067, 7097, 7128, 7159, 7190, 7221, 7252, 7284, 7315, 7347, 7379,
    7411, 7443, 7475, 7508, 7540, 7573, 7606, 7639, 7672, 7705, 7738, 7772,
    7805, 7839, 7873, 7907, 7942, 7976, 8011, 8045, 8080, 8115, 8150, 8186,
    8221, 8257, 8293, 8328, 8365, 8401, 8437, 8474, 8511, 8547, 8584, 8622,
    8659, 8697, 8734, 8772, 8810, 8848, 8887, 8925, 8964, 9003, 9042, 9081,
    9120, 9160, 9199, 9239, 9279, 9319, 9360, 9400, 9441, 9482, 9523, 9564,
    9606, 9647, 9689, 9731, 9773, 9816, 9858, 9901, 9944, 9987, 10030, 10074,
    10117, 10161, 10205, 10250, 10294, 10339, 10383, 10428, 10474, 10519, 10565,
    10610, 10656, 10702, 10749, 10795, 10842, 10889, 10936, 10984, 11031, 11079,
    11127, 11175, 11224, 11272, 11321, 11370, 11420, 11469, 11519, 11569, 11619,
    11669, 11720, 11771, 11822, 11873, 11924, 11976, 12028, 12080, 12132, 12185,
    12238, 12291, 12344, 12397, 12451, 12505, 12559, 12614, 12668, 12723, 12778,
    12834, 12889, 12945, 13001, 13058, 13114, 13171, 13228, 13285, 13343, 13401,
    13459, 13517, 13576, 13635, 13694, 13753, 13813, 13873, 13933, 13993, 14054,
    14115, 14176, 14237, 14299, 14361, 14423, 14486, 14548, 14611, 14675, 14738,
    14802, 14866, 14931, 14995, 15060, 15126, 15191, 15257,
  ],
  660000: [
    2838, 2862, 2885, 2910, 2934, 2958, 2983, 3008, 3033, 3058, 3084, 3109,
    3135, 3161, 3188, 3214, 3241, 3268, 3295, 3323, 3350, 3378, 3406, 3435,
    3456, 3478, 3500, 3522, 3544, 3566, 3588, 3610, 3633, 3656, 3679, 3702,
    3725, 3748, 3771, 3795, 3819, 3842, 3867, 3891, 3915, 3939, 3964, 3989,
    4006, 4024, 4041, 4058, 4076, 4094, 4111, 4129, 4147, 4165, 4183, 4201,
    4220, 4238, 4256, 4275, 4293, 4312, 4330, 4349, 4368, 4387, 4406, 4425,
    4444, 4463, 4483, 4502, 4522, 4541, 4561, 4581, 4601, 4621, 4641, 4661,
    4681, 4701, 4722, 4742, 4763, 4783, 4804, 4825, 4846, 4867, 4888, 4909,
    4930, 4952, 4973, 4995, 5016, 5038, 5060, 5082, 5104, 5126, 5148, 5170,
    5193, 5215, 5238, 5261, 5283, 5306, 5329, 5352, 5376, 5399, 5422, 5446,
    5469, 5493, 5517, 5541, 5565, 5589, 5613, 5637, 5662, 5686, 5711, 5736,
    5761, 5786, 5811, 5836, 5861, 5887, 5912, 5938, 5963, 5989, 6015, 6041,
    6067, 6094, 6120, 6147, 6173, 6200, 6227, 6254, 6281, 6308, 6336, 6363,
    6391, 6418, 6446, 6474, 6502, 6530, 6559, 6587, 6615, 6644, 6673, 6702,
    6731, 6760, 6789, 6819, 6848, 6878, 6908, 6938, 6968, 6998, 7028, 7059,
    7089, 7120, 7151, 7182, 7213, 7244, 7276, 7307, 7339, 7371, 7403, 7435,
    7467, 7499, 7532, 7564, 7597, 7630, 7663, 7696, 7730, 7763, 7797, 7831,
    7865, 7899, 7933, 7967, 8002, 8036, 8071, 8106, 8141, 8177, 8212, 8248,
    8283, 8319, 8355, 8392, 8428, 8464, 8501, 8538, 8575, 8612, 8649, 8687,
    8725, 8762, 8800, 8838, 8877, 8915, 8954, 8993, 9032, 9071, 9110, 9150,
    9189, 9229, 9269, 9309, 9350, 9390, 9431, 9472, 9513, 9554, 9595, 9637,
    9679, 9721, 9763, 9805, 9847, 9890, 9933, 9976, 10019, 10063, 10106, 10150,
    10194, 10238, 10283, 10327, 10372, 10417, 10462, 10507, 10553, 10599, 10645,
    10691, 10737, 10784, 10830, 10877, 10924, 10972, 11019, 11067, 11115, 11163,
    11211, 11260, 11309, 11358, 11407, 11456, 11506, 11556, 11606, 11656, 11707,
    11758, 11809, 11860, 11911, 11963, 12015, 12067, 12119, 12171, 12224, 12277,
    12330, 12384, 12437, 12491, 12545, 12600, 12654, 12709, 12764, 12820, 12875,
    12931, 12987, 13043, 13100, 13157, 13214, 13271, 13328, 13386, 13444, 13502,
    13561, 13620, 13679, 13738, 13797, 13857, 13917, 13978, 14038, 14099, 14160,
    14221, 14283, 14345, 14407, 14470, 14532, 14595, 14659, 14722, 14786, 14850,
    14914, 14979, 15044, 15109, 15174, 15240, 15306, 15373,
  ],
  670000: [
    2859, 2882, 2907, 2931, 2955, 2980, 3005, 3030, 3055, 3080, 3106, 3132,
    3158, 3184, 3211, 3238, 3265, 3292, 3319, 3347, 3375, 3403, 3431, 3460,
    3481, 3503, 3525, 3547, 3569, 3592, 3614, 3637, 3659, 3682, 3705, 3728,
    3752, 3775, 3799, 3823, 3846, 3870, 3895, 3919, 3944, 3968, 3993, 4018,
    4035, 4053, 4070, 4088, 4106, 4124, 4141, 4159, 4177, 4195, 4214, 4232,
    4250, 4269, 4287, 4306, 4324, 4343, 4362, 4381, 4400, 4419, 4438, 4457,
    4477, 4496, 4515, 4535, 4555, 4574, 4594, 4614, 4634, 4654, 4674, 4695,
    4715, 4735, 4756, 4777, 4797, 4818, 4839, 4860, 4881, 4902, 4923, 4945,
    4966, 4988, 5009, 5031, 5053, 5075, 5097, 5119, 5141, 5163, 5186, 5208,
    5231, 5253, 5276, 5299, 5322, 5345, 5368, 5391, 5415, 5438, 5462, 5485,
    5509, 5533, 5557, 5581, 5605, 5630, 5654, 5678, 5703, 5728, 5753, 5778,
    5803, 5828, 5853, 5878, 5904, 5929, 5955, 5981, 6007, 6033, 6059, 6085,
    6112, 6138, 6165, 6191, 6218, 6245, 6272, 6299, 6327, 6354, 6382, 6409,
    6437, 6465, 6493, 6521, 6549, 6578, 6606, 6635, 6664, 6693, 6722, 6751,
    6780, 6809, 6839, 6868, 6898, 6928, 6958, 6988, 7019, 7049, 7079, 7110,
    7141, 7172, 7203, 7234, 7266, 7297, 7329, 7360, 7392, 7424, 7457, 7489,
    7521, 7554, 7587, 7619, 7653, 7686, 7719, 7752, 7786, 7820, 7854, 7888,
    7922, 7956, 7991, 8025, 8060, 8095, 8130, 8165, 8201, 8236, 8272, 8308,
    8344, 8380, 8416, 8453, 8489, 8526, 8563, 8600, 8637, 8675, 8712, 8750,
    8788, 8826, 8864, 8903, 8941, 8980, 9019, 9058, 9097, 9137, 9176, 9216,
    9256, 9296, 9337, 9377, 9418, 9458, 9499, 9541, 9582, 9623, 9665, 9707,
    9749, 9791, 9834, 9876, 9919, 9962, 10005, 10049, 10092, 10136, 10180,
    10224, 10268, 10313, 10357, 10402, 10447, 10493, 10538, 10584, 10630, 10676,
    10722, 10769, 10815, 10862, 10909, 10956, 11004, 11052, 11099, 11148, 11196,
    11244, 11293, 11342, 11391, 11441, 11490, 11540, 11590, 11640, 11691, 11741,
    11792, 11843, 11895, 11946, 11998, 12050, 12102, 12154, 12207, 12260, 12313,
    12367, 12420, 12474, 12528, 12582, 12637, 12692, 12747, 12802, 12857, 12913,
    12969, 13025, 13082, 13138, 13195, 13252, 13310, 13367, 13425, 13484, 13542,
    13601, 13660, 13719, 13778, 13838, 13898, 13958, 14019, 14079, 14140, 14202,
    14263, 14325, 14387, 14449, 14512, 14575, 14638, 14702, 14765, 14829, 14893,
    14958, 15023, 15088, 15153, 15219, 15285, 15351, 15418, 15485,
  ],
  680000: [
    2879, 2903, 2927, 2951, 2976, 3001, 3026, 3051, 3076, 3102, 3128, 3154,
    3180, 3207, 3233, 3260, 3287, 3315, 3342, 3370, 3398, 3427, 3455, 3484,
    3506, 3528, 3550, 3572, 3594, 3617, 3639, 3662, 3685, 3708, 3731, 3755,
    3778, 3802, 3825, 3849, 3873, 3898, 3922, 3946, 3971, 3996, 4021, 4046,
    4064, 4081, 4099, 4117, 4134, 4152, 4170, 4188, 4207, 4225, 4243, 4261,
    4280, 4299, 4317, 4336, 4355, 4374, 4392, 4411, 4431, 4450, 4469, 4488,
    4508, 4527, 4547, 4567, 4587, 4606, 4626, 4646, 4667, 4687, 4707, 4727,
    4748, 4769, 4789, 4810, 4831, 4852, 4873, 4894, 4915, 4936, 4958, 4979,
    5001, 5023, 5044, 5066, 5088, 5110, 5132, 5155, 5177, 5199, 5222, 5244,
    5267, 5290, 5313, 5336, 5359, 5382, 5406, 5429, 5453, 5476, 5500, 5524,
    5548, 5572, 5596, 5620, 5644, 5669, 5694, 5718, 5743, 5768, 5793, 5818,
    5843, 5868, 5894, 5919, 5945, 5971, 5997, 6023, 6049, 6075, 6101, 6128,
    6154, 6181, 6208, 6235, 6262, 6289, 6316, 6343, 6371, 6399, 6426, 6454,
    6482, 6510, 6538, 6567, 6595, 6624, 6652, 6681, 6710, 6739, 6769, 6798,
    6827, 6857, 6887, 6916, 6946, 6977, 7007, 7037, 7068, 7098, 7129, 7160,
    7191, 7222, 7253, 7285, 7316, 7348, 7380, 7412, 7444, 7476, 7509, 7541,
    7574, 7607, 7640, 7673, 7706, 7739, 7773, 7807, 7840, 7874, 7909, 7943,
    7977, 8012, 8047, 8081, 8116, 8152, 8187, 8222, 8258, 8294, 8330, 8366,
    8402, 8439, 8475, 8512, 8549, 8586, 8623, 8660, 8698, 8736, 8773, 8811,
    8850, 8888, 8926, 8965, 9004, 9043, 9082, 9122, 9161, 9201, 9241, 9281,
    9321, 9361, 9402, 9443, 9484, 9525, 9566, 9607, 9649, 9691, 9733, 9775,
    9817, 9860, 9903, 9945, 9989, 10032, 10075, 10119, 10163, 10207, 10251,
    10296, 10340, 10385, 10430, 10475, 10521, 10566, 10612, 10658, 10704, 10750,
    10797, 10844, 10891, 10938, 10985, 11033, 11081, 11129, 11177, 11226, 11274,
    11323, 11372, 11421, 11471, 11521, 11570, 11621, 11671, 11722, 11772, 11823,
    11875, 11926, 11978, 12030, 12082, 12134, 12187, 12240, 12293, 12346, 12399,
    12453, 12507, 12561, 12616, 12670, 12725, 12780, 12836, 12891, 12947, 13003,
    13060, 13116, 13173, 13230, 13288, 13345, 13403, 13461, 13519, 13578, 13637,
    13696, 13755, 13815, 13875, 13935, 13995, 14056, 14117, 14178, 14239, 14301,
    14363, 14425, 14488, 14551, 14614, 14677, 14741, 14804, 14869, 14933, 14998,
    15063, 15128, 15194, 15259, 15325, 15392, 15459, 15526, 15593,
  ],
  690000: [
    2898, 2922, 2947, 2971, 2996, 3021, 3046, 3071, 3097, 3123, 3149, 3175,
    3201, 3228, 3255, 3282, 3310, 3337, 3365, 3393, 3421, 3450, 3478, 3507,
    3529, 3551, 3574, 3596, 3618, 3641, 3664, 3687, 3710, 3733, 3756, 3780,
    3803, 3827, 3851, 3875, 3899, 3924, 3948, 3973, 3998, 4023, 4048, 4073,
    4091, 4109, 4126, 4144, 4162, 4180, 4198, 4217, 4235, 4253, 4272, 4290,
    4309, 4327, 4346, 4365, 4384, 4403, 4422, 4441, 4460, 4480, 4499, 4519,
    4538, 4558, 4578, 4597, 4617, 4637, 4657, 4678, 4698, 4718, 4739, 4759,
    4780, 4801, 4821, 4842, 4863, 4884, 4906, 4927, 4948, 4970, 4991, 5013,
    5034, 5056, 5078, 5100, 5122, 5144, 5167, 5189, 5212, 5234, 5257, 5280,
    5303, 5326, 5349, 5372, 5395, 5418, 5442, 5466, 5489, 5513, 5537, 5561,
    5585, 5609, 5633, 5658, 5682, 5707, 5732, 5757, 5782, 5807, 5832, 5857,
    5882, 5908, 5933, 5959, 5985, 6011, 6037, 6063, 6089, 6116, 6142, 6169,
    6196, 6223, 6249, 6277, 6304, 6331, 6359, 6386, 6414, 6442, 6469, 6497,
    6526, 6554, 6582, 6611, 6639, 6668, 6697, 6726, 6755, 6785, 6814, 6844,
    6873, 6903, 6933, 6963, 6993, 7023, 7054, 7084, 7115, 7146, 7177, 7208,
    7239, 7271, 7302, 7334, 7366, 7397, 7429, 7462, 7494, 7527, 7559, 7592,
    7625, 7658, 7691, 7724, 7758, 7791, 7825, 7859, 7893, 7927, 7962, 7996,
    8031, 8066, 8101, 8136, 8171, 8206, 8242, 8278, 8314, 8350, 8386, 8422,
    8459, 8495, 8532, 8569, 8606, 8643, 8681, 8718, 8756, 8794, 8832, 8871,
    8909, 8948, 8986, 9025, 9064, 9104, 9143, 9183, 9223, 9263, 9303, 9343,
    9384, 9424, 9465, 9506, 9547, 9589, 9630, 9672, 9714, 9756, 9798, 9841,
    9883, 9926, 9969, 10012, 10056, 10099, 10143, 10187, 10231, 10275, 10320,
    10365, 10410, 10455, 10500, 10546, 10591, 10637, 10683, 10729, 10776, 10823,
    10870, 10917, 10964, 11011, 11059, 11107, 11155, 11204, 11252, 11301, 11350,
    11399, 11448, 11498, 11548, 11598, 11648, 11699, 11749, 11800, 11851, 11903,
    11954, 12006, 12058, 12110, 12163, 12216, 12269, 12322, 12375, 12429, 12483,
    12537, 12591, 12646, 12700, 12755, 12811, 12866, 12922, 12978, 13034, 13091,
    13147, 13204, 13262, 13319, 13377, 13435, 13493, 13551, 13610, 13669, 13728,
    13788, 13848, 13908, 13968, 14028, 14089, 14150, 14212, 14273, 14335, 14397,
    14459, 14522, 14585, 14648, 14712, 14775, 14840, 14904, 14968, 15033, 15098,
    15164, 15230, 15296, 15362, 15428, 15495, 15562, 15630, 15698,
  ],
  700000: [
    2917, 2941, 2965, 2990, 3015, 3040, 3066, 3091, 3117, 3143, 3169, 3195,
    3222, 3249, 3276, 3303, 3331, 3359, 3387, 3415, 3443, 3472, 3501, 3530,
    3552, 3574, 3597, 3619, 3642, 3665, 3687, 3710, 3734, 3757, 3780, 3804,
    3828, 3852, 3876, 3900, 3924, 3949, 3974, 3999, 4024, 4049, 4074, 4099,
    4117, 4135, 4153, 4171, 4189, 4207, 4225, 4244, 4262, 4281, 4299, 4318,
    4336, 4355, 4374, 4393, 4412, 4431, 4450, 4470, 4489, 4509, 4528, 4548,
    4567, 4587, 4607, 4627, 4647, 4667, 4687, 4708, 4728, 4749, 4769, 4790,
    4811, 4832, 4852, 4873, 4895, 4916, 4937, 4958, 4980, 5002, 5023, 5045,
    5067, 5089, 5111, 5133, 5155, 5178, 5200, 5223, 5245, 5268, 5291, 5314,
    5337, 5360, 5383, 5406, 5430, 5453, 5477, 5501, 5525, 5548, 5573, 5597,
    5621, 5645, 5670, 5694, 5719, 5744, 5769, 5794, 5819, 5844, 5869, 5895,
    5920, 5946, 5972, 5998, 6024, 6050, 6076, 6102, 6129, 6155, 6182, 6209,
    6236, 6263, 6290, 6317, 6344, 6372, 6399, 6427, 6455, 6483, 6511, 6539,
    6568, 6596, 6625, 6653, 6682, 6711, 6740, 6770, 6799, 6828, 6858, 6888,
    6917, 6947, 6978, 7008, 7038, 7069, 7099, 7130, 7161, 7192, 7223, 7254,
    7286, 7317, 7349, 7381, 7413, 7445, 7477, 7510, 7542, 7575, 7608, 7641,
    7674, 7707, 7741, 7774, 7808, 7842, 7876, 7910, 7944, 7978, 8013, 8048,
    8083, 8118, 8153, 8188, 8224, 8259, 8295, 8331, 8367, 8403, 8440, 8476,
    8513, 8550, 8587, 8624, 8662, 8699, 8737, 8775, 8813, 8851, 8889, 8928,
    8966, 9005, 9044, 9083, 9123, 9162, 9202, 9242, 9282, 9322, 9363, 9403,
    9444, 9485, 9526, 9567, 9609, 9650, 9692, 9734, 9776, 9819, 9861, 9904,
    9947, 9990, 10033, 10077, 10120, 10164, 10208, 10253, 10297, 10342, 10386,
    10431, 10477, 10522, 10568, 10613, 10659, 10706, 10752, 10799, 10845, 10892,
    10940, 10987, 11035, 11082, 11130, 11179, 11227, 11276, 11325, 11374, 11423,
    11472, 11522, 11572, 11622, 11673, 11723, 11774, 11825, 11876, 11928, 11979,
    12031, 12083, 12136, 12188, 12241, 12294, 12348, 12401, 12455, 12509, 12563,
    12617, 12672, 12727, 12782, 12838, 12893, 12949, 13005, 13062, 13118, 13175,
    13232, 13289, 13347, 13405, 13463, 13521, 13580, 13639, 13698, 13757, 13817,
    13877, 13937, 13997, 14058, 14119, 14180, 14241, 14303, 14365, 14427, 14490,
    14553, 14616, 14679, 14743, 14807, 14871, 14935, 15000, 15065, 15130, 15196,
    15262, 15328, 15394, 15461, 15528, 15595, 15663, 15731, 15799,
  ],
  710000: [
    2929, 2953, 2978, 3003, 3028, 3053, 3078, 3104, 3130, 3156, 3182, 3209,
    3235, 3262, 3290, 3317, 3345, 3373, 3401, 3429, 3458, 3486, 3515, 3545,
    3567, 3589, 3612, 3634, 3657, 3680, 3703, 3726, 3749, 3773, 3796, 3820,
    3844, 3868, 3892, 3916, 3941, 3965, 3990, 4015, 4040, 4065, 4091, 4116,
    4134, 4152, 4170, 4188, 4206, 4225, 4243, 4261, 4280, 4298, 4317, 4336,
    4354, 4373, 4392, 4411, 4430, 4450, 4469, 4488, 4508, 4527, 4547, 4567,
    4586, 4606, 4626, 4646, 4666, 4687, 4707, 4727, 4748, 4768, 4789, 4810,
    4831, 4852, 4873, 4894, 4915, 4936, 4958, 4979, 5001, 5022, 5044, 5066,
    5088, 5110, 5132, 5154, 5177, 5199, 5222, 5244, 5267, 5290, 5313, 5336,
    5359, 5382, 5405, 5429, 5452, 5476, 5500, 5523, 5547, 5571, 5596, 5620,
    5644, 5669, 5693, 5718, 5743, 5768, 5793, 5818, 5843, 5868, 5894, 5919,
    5945, 5971, 5996, 6022, 6049, 6075, 6101, 6127, 6154, 6181, 6208, 6234,
    6261, 6289, 6316, 6343, 6371, 6398, 6426, 6454, 6482, 6510, 6538, 6566,
    6595, 6623, 6652, 6681, 6710, 6739, 6768, 6798, 6827, 6857, 6886, 6916,
    6946, 6976, 7006, 7037, 7067, 7098, 7129, 7160, 7191, 7222, 7253, 7284,
    7316, 7348, 7380, 7412, 7444, 7476, 7508, 7541, 7574, 7606, 7639, 7672,
    7706, 7739, 7773, 7806, 7840, 7874, 7908, 7942, 7977, 8011, 8046, 8081,
    8116, 8151, 8187, 8222, 8258, 8293, 8329, 8365, 8402, 8438, 8475, 8511,
    8548, 8585, 8623, 8660, 8697, 8735, 8773, 8811, 8849, 8888, 8926, 8965,
    9004, 9043, 9082, 9121, 9161, 9200, 9240, 9280, 9320, 9361, 9401, 9442,
    9483, 9524, 9565, 9607, 9649, 9690, 9732, 9774, 9817, 9859, 9902, 9945,
    9988, 10031, 10075, 10119, 10162, 10206, 10251, 10295, 10340, 10384, 10429,
    10475, 10520, 10566, 10611, 10657, 10704, 10750, 10797, 10843, 10890, 10938,
    10985, 11033, 11080, 11128, 11177, 11225, 11274, 11322, 11372, 11421, 11470,
    11520, 11570, 11620, 11670, 11721, 11772, 11823, 11874, 11925, 11977, 12029,
    12081, 12134, 12186, 12239, 12292, 12345, 12399, 12452, 12506, 12561, 12615,
    12670, 12725, 12780, 12835, 12891, 12947, 13003, 13059, 13116, 13172, 13230,
    13287, 13344, 13402, 13460, 13519, 13577, 13636, 13695, 13755, 13814, 13874,
    13934, 13995, 14055, 14116, 14177, 14239, 14300, 14362, 14425, 14487, 14550,
    14613, 14676, 14740, 14804, 14868, 14932, 14997, 15062, 15127, 15193, 15259,
    15325, 15391, 15458, 15525, 15592, 15660, 15728, 15796, 15864,
  ],
  720000: [
    2940, 2965, 2989, 3014, 3039, 3065, 3090, 3116, 3142, 3168, 3194, 3221,
    3248, 3275, 3302, 3330, 3357, 3385, 3414, 3442, 3471, 3500, 3529, 3558,
    3581, 3603, 3625, 3648, 3671, 3694, 3717, 3740, 3764, 3787, 3811, 3835,
    3859, 3883, 3907, 3931, 3956, 3981, 4005, 4031, 4056, 4081, 4107, 4132,
    4150, 4168, 4186, 4204, 4223, 4241, 4259, 4278, 4296, 4315, 4334, 4352,
    4371, 4390, 4409, 4428, 4447, 4467, 4486, 4505, 4525, 4545, 4564, 4584,
    4604, 4624, 4644, 4664, 4684, 4705, 4725, 4745, 4766, 4787, 4807, 4828,
    4849, 4870, 4891, 4912, 4934, 4955, 4977, 4998, 5020, 5042, 5063, 5085,
    5107, 5130, 5152, 5174, 5197, 5219, 5242, 5264, 5287, 5310, 5333, 5356,
    5379, 5403, 5426, 5450, 5473, 5497, 5521, 5545, 5569, 5593, 5617, 5641,
    5666, 5690, 5715, 5740, 5765, 5790, 5815, 5840, 5865, 5891, 5916, 5942,
    5968, 5994, 6019, 6046, 6072, 6098, 6124, 6151, 6178, 6204, 6231, 6258,
    6285, 6313, 6340, 6368, 6395, 6423, 6451, 6479, 6507, 6535, 6563, 6592,
    6620, 6649, 6678, 6707, 6736, 6765, 6794, 6824, 6853, 6883, 6913, 6943,
    6973, 7003, 7033, 7064, 7094, 7125, 7156, 7187, 7218, 7249, 7281, 7312,
    7344, 7376, 7408, 7440, 7472, 7505, 7537, 7570, 7603, 7636, 7669, 7702,
    7735, 7769, 7802, 7836, 7870, 7904, 7939, 7973, 8008, 8042, 8077, 8112,
    8147, 8183, 8218, 8254, 8289, 8325, 8361, 8398, 8434, 8471, 8507, 8544,
    8581, 8618, 8656, 8693, 8731, 8769, 8807, 8845, 8883, 8922, 8960, 8999,
    9038, 9077, 9117, 9156, 9196, 9236, 9276, 9316, 9356, 9397, 9438, 9478,
    9519, 9561, 9602, 9644, 9686, 9728, 9770, 9812, 9855, 9897, 9940, 9983,
    10026, 10070, 10114, 10157, 10201, 10246, 10290, 10335, 10379, 10424, 10470,
    10515, 10560, 10606, 10652, 10698, 10745, 10791, 10838, 10885, 10932, 10980,
    11027, 11075, 11123, 11171, 11219, 11268, 11317, 11366, 11415, 11465, 11514,
    11564, 11614, 11665, 11715, 11766, 11817, 11868, 11920, 11971, 12023, 12075,
    12128, 12180, 12233, 12286, 12339, 12393, 12446, 12500, 12554, 12609, 12664,
    12718, 12773, 12829, 12884, 12940, 12996, 13053, 13109, 13166, 13223, 13280,
    13338, 13396, 13454, 13512, 13571, 13629, 13688, 13748, 13807, 13867, 13927,
    13988, 14048, 14109, 14170, 14232, 14293, 14355, 14418, 14480, 14543, 14606,
    14669, 14733, 14796, 14861, 14925, 14990, 15055, 15120, 15185, 15251, 15317,
    15384, 15450, 15517, 15584, 15652, 15720, 15788, 15856, 15925,
  ],
  730000: [
    2950, 2975, 3000, 3025, 3050, 3075, 3101, 3127, 3153, 3179, 3206, 3232,
    3259, 3287, 3314, 3342, 3369, 3397, 3426, 3454, 3483, 3512, 3541, 3571,
    3593, 3616, 3638, 3661, 3684, 3707, 3730, 3753, 3777, 3800, 3824, 3848,
    3872, 3896, 3921, 3945, 3970, 3995, 4020, 4045, 4070, 4096, 4121, 4147,
    4165, 4183, 4201, 4219, 4237, 4256, 4274, 4293, 4311, 4330, 4349, 4368,
    4387, 4406, 4425, 4444, 4463, 4483, 4502, 4521, 4541, 4561, 4580, 4600,
    4620, 4640, 4660, 4681, 4701, 4721, 4742, 4762, 4783, 4804, 4824, 4845,
    4866, 4887, 4909, 4930, 4951, 4973, 4994, 5016, 5038, 5059, 5081, 5103,
    5125, 5148, 5170, 5192, 5215, 5238, 5260, 5283, 5306, 5329, 5352, 5375,
    5398, 5422, 5445, 5469, 5493, 5516, 5540, 5564, 5588, 5613, 5637, 5661,
    5686, 5711, 5735, 5760, 5785, 5810, 5835, 5861, 5886, 5912, 5937, 5963,
    5989, 6015, 6041, 6067, 6093, 6120, 6146, 6173, 6200, 6226, 6253, 6281,
    6308, 6335, 6363, 6390, 6418, 6446, 6474, 6502, 6530, 6558, 6586, 6615,
    6644, 6672, 6701, 6730, 6760, 6789, 6818, 6848, 6878, 6907, 6937, 6967,
    6997, 7028, 7058, 7089, 7120, 7150, 7181, 7213, 7244, 7275, 7307, 7338,
    7370, 7402, 7434, 7466, 7499, 7531, 7564, 7597, 7630, 7663, 7696, 7729,
    7763, 7796, 7830, 7864, 7898, 7932, 7967, 8001, 8036, 8071, 8106, 8141,
    8176, 8212, 8247, 8283, 8319, 8355, 8391, 8427, 8464, 8501, 8537, 8574,
    8612, 8649, 8686, 8724, 8762, 8800, 8838, 8876, 8915, 8953, 8992, 9031,
    9070, 9109, 9149, 9189, 9228, 9268, 9309, 9349, 9389, 9430, 9471, 9512,
    9553, 9595, 9636, 9678, 9720, 9762, 9804, 9847, 9889, 9932, 9975, 10019,
    10062, 10106, 10149, 10193, 10238, 10282, 10326, 10371, 10416, 10461, 10507,
    10552, 10598, 10644, 10690, 10736, 10783, 10829, 10876, 10924, 10971, 11018,
    11066, 11114, 11162, 11211, 11259, 11308, 11357, 11406, 11456, 11505, 11555,
    11605, 11656, 11706, 11757, 11808, 11859, 11910, 11962, 12014, 12066, 12118,
    12171, 12223, 12276, 12329, 12383, 12437, 12490, 12545, 12599, 12654, 12708,
    12763, 12819, 12874, 12930, 12986, 13042, 13099, 13156, 13213, 13270, 13327,
    13385, 13443, 13501, 13560, 13619, 13678, 13737, 13797, 13856, 13916, 13977,
    14037, 14098, 14159, 14220, 14282, 14344, 14406, 14469, 14531, 14594, 14657,
    14721, 14785, 14849, 14913, 14978, 15043, 15108, 15173, 15239, 15305, 15372,
    15438, 15505, 15572, 15640, 15707, 15776, 15844, 15913, 15981,
  ],
  740000: [
    2960, 2985, 3010, 3035, 3060, 3085, 3111, 3137, 3163, 3190, 3216, 3243,
    3270, 3297, 3325, 3352, 3380, 3408, 3437, 3466, 3494, 3524, 3553, 3583,
    3605, 3627, 3650, 3673, 3696, 3719, 3742, 3766, 3789, 3813, 3837, 3861,
    3885, 3909, 3933, 3958, 3983, 4008, 4033, 4058, 4083, 4109, 4134, 4160,
    4178, 4196, 4215, 4233, 4251, 4270, 4288, 4307, 4325, 4344, 4363, 4382,
    4401, 4420, 4439, 4458, 4478, 4497, 4517, 4536, 4556, 4576, 4595, 4615,
    4635, 4655, 4676, 4696, 4716, 4737, 4757, 4778, 4798, 4819, 4840, 4861,
    4882, 4903, 4925, 4946, 4967, 4989, 5010, 5032, 5054, 5076, 5098, 5120,
    5142, 5164, 5187, 5209, 5232, 5255, 5277, 5300, 5323, 5346, 5369, 5393,
    5416, 5439, 5463, 5487, 5510, 5534, 5558, 5582, 5607, 5631, 5655, 5680,
    5704, 5729, 5754, 5779, 5804, 5829, 5854, 5880, 5905, 5931, 5957, 5982,
    6008, 6034, 6060, 6087, 6113, 6140, 6166, 6193, 6220, 6247, 6274, 6301,
    6328, 6356, 6383, 6411, 6439, 6467, 6495, 6523, 6551, 6579, 6608, 6636,
    6665, 6694, 6723, 6752, 6782, 6811, 6840, 6870, 6900, 6930, 6960, 6990,
    7020, 7051, 7081, 7112, 7143, 7174, 7205, 7236, 7267, 7299, 7330, 7362,
    7394, 7426, 7458, 7491, 7523, 7556, 7588, 7621, 7654, 7688, 7721, 7754,
    7788, 7822, 7856, 7890, 7924, 7958, 7993, 8027, 8062, 8097, 8132, 8167,
    8203, 8238, 8274, 8310, 8346, 8382, 8418, 8455, 8491, 8528, 8565, 8602,
    8640, 8677, 8715, 8752, 8790, 8828, 8867, 8905, 8944, 8982, 9021, 9060,
    9100, 9139, 9179, 9218, 9258, 9299, 9339, 9379, 9420, 9461, 9502, 9543,
    9584, 9626, 9667, 9709, 9751, 9794, 9836, 9879, 9922, 9965, 10008, 10051,
    10095, 10138, 10182, 10226, 10271, 10315, 10360, 10405, 10450, 10495, 10541,
    10586, 10632, 10678, 10725, 10771, 10818, 10865, 10912, 10959, 11007, 11054,
    11102, 11150, 11199, 11247, 11296, 11345, 11394, 11443, 11493, 11543, 11593,
    11643, 11693, 11744, 11795, 11846, 11897, 11949, 12001, 12053, 12105, 12157,
    12210, 12263, 12316, 12370, 12423, 12477, 12531, 12585, 12640, 12695, 12750,
    12805, 12860, 12916, 12972, 13028, 13085, 13141, 13198, 13256, 13313, 13371,
    13429, 13487, 13545, 13604, 13663, 13722, 13782, 13841, 13901, 13962, 14022,
    14083, 14144, 14205, 14267, 14329, 14391, 14453, 14516, 14578, 14642, 14705,
    14769, 14833, 14897, 14962, 15026, 15092, 15157, 15223, 15289, 15355, 15421,
    15488, 15555, 15623, 15690, 15758, 15827, 15895, 15964, 16033,
  ],
  750000: [
    2969, 2993, 3018, 3044, 3069, 3095, 3120, 3146, 3173, 3199, 3226, 3253,
    3280, 3307, 3335, 3362, 3390, 3419, 3447, 3476, 3505, 3534, 3563, 3593,
    3616, 3638, 3661, 3684, 3707, 3730, 3753, 3777, 3800, 3824, 3848, 3872,
    3896, 3921, 3945, 3970, 3995, 4020, 4045, 4070, 4095, 4121, 4147, 4173,
    4191, 4209, 4227, 4245, 4264, 4282, 4301, 4319, 4338, 4357, 4376, 4395,
    4414, 4433, 4452, 4472, 4491, 4510, 4530, 4550, 4569, 4589, 4609, 4629,
    4649, 4669, 4689, 4710, 4730, 4751, 4771, 4792, 4813, 4833, 4854, 4875,
    4897, 4918, 4939, 4960, 4982, 5004, 5025, 5047, 5069, 5091, 5113, 5135,
    5157, 5180, 5202, 5225, 5247, 5270, 5293, 5316, 5339, 5362, 5385, 5409,
    5432, 5456, 5479, 5503, 5527, 5551, 5575, 5599, 5623, 5648, 5672, 5697,
    5721, 5746, 5771, 5796, 5821, 5846, 5872, 5897, 5923, 5948, 5974, 6000,
    6026, 6052, 6078, 6105, 6131, 6158, 6184, 6211, 6238, 6265, 6292, 6320,
    6347, 6374, 6402, 6430, 6458, 6486, 6514, 6542, 6570, 6599, 6627, 6656,
    6685, 6714, 6743, 6772, 6802, 6831, 6861, 6890, 6920, 6950, 6980, 7011,
    7041, 7071, 7102, 7133, 7164, 7195, 7226, 7257, 7289, 7320, 7352, 7384,
    7416, 7448, 7480, 7513, 7545, 7578, 7611, 7644, 7677, 7710, 7744, 7777,
    7811, 7845, 7879, 7913, 7947, 7982, 8016, 8051, 8086, 8121, 8156, 8191,
    8227, 8263, 8298, 8334, 8370, 8407, 8443, 8480, 8516, 8553, 8590, 8628,
    8665, 8703, 8740, 8778, 8816, 8854, 8893, 8931, 8970, 9009, 9048, 9087,
    9127, 9166, 9206, 9246, 9286, 9326, 9366, 9407, 9448, 9489, 9530, 9571,
    9613, 9654, 9696, 9738, 9780, 9823, 9865, 9908, 9951, 9994, 10037, 10081,
    10125, 10168, 10212, 10257, 10301, 10346, 10391, 10436, 10481, 10526, 10572,
    10618, 10664, 10710, 10756, 10803, 10850, 10897, 10944, 10991, 11039, 11087,
    11135, 11183, 11232, 11280, 11329, 11378, 11428, 11477, 11527, 11577, 11627,
    11677, 11728, 11779, 11830, 11881, 11933, 11984, 12036, 12088, 12141, 12193,
    12246, 12299, 12353, 12406, 12460, 12514, 12568, 12623, 12677, 12732, 12787,
    12843, 12898, 12954, 13010, 13067, 13123, 13180, 13237, 13295, 13352, 13410,
    13468, 13527, 13585, 13644, 13703, 13763, 13822, 13882, 13942, 14003, 14063,
    14124, 14186, 14247, 14309, 14371, 14433, 14496, 14558, 14622, 14685, 14749,
    14812, 14877, 14941, 15006, 15071, 15136, 15202, 15268, 15334, 15400, 15467,
    15534, 15601, 15669, 15737, 15805, 15874, 15942, 16011, 16081,
  ],
  760000: [
    2977, 3001, 3026, 3052, 3077, 3103, 3129, 3155, 3181, 3208, 3234, 3261,
    3288, 3316, 3343, 3371, 3399, 3428, 3456, 3485, 3514, 3543, 3573, 3603,
    3625, 3648, 3671, 3694, 3717, 3740, 3763, 3787, 3810, 3834, 3858, 3882,
    3907, 3931, 3956, 3980, 4005, 4030, 4055, 4081, 4106, 4132, 4158, 4184,
    4202, 4220, 4238, 4257, 4275, 4294, 4312, 4331, 4350, 4369, 4388, 4407,
    4426, 4445, 4464, 4483, 4503, 4522, 4542, 4562, 4581, 4601, 4621, 4641,
    4661, 4682, 4702, 4722, 4743, 4763, 4784, 4805, 4825, 4846, 4867, 4888,
    4910, 4931, 4952, 4974, 4995, 5017, 5039, 5060, 5082, 5104, 5127, 5149,
    5171, 5194, 5216, 5239, 5261, 5284, 5307, 5330, 5353, 5376, 5400, 5423,
    5446, 5470, 5494, 5518, 5542, 5566, 5590, 5614, 5638, 5663, 5687, 5712,
    5737, 5761, 5786, 5811, 5837, 5862, 5887, 5913, 5938, 5964, 5990, 6016,
    6042, 6068, 6095, 6121, 6147, 6174, 6201, 6228, 6255, 6282, 6309, 6336,
    6364, 6391, 6419, 6447, 6475, 6503, 6531, 6559, 6588, 6616, 6645, 6674,
    6703, 6732, 6761, 6790, 6820, 6849, 6879, 6909, 6939, 6969, 6999, 7029,
    7060, 7090, 7121, 7152, 7183, 7214, 7245, 7277, 7308, 7340, 7372, 7404,
    7436, 7468, 7500, 7533, 7565, 7598, 7631, 7664, 7697, 7731, 7764, 7798,
    7832, 7866, 7900, 7934, 7968, 8003, 8038, 8072, 8107, 8143, 8178, 8213,
    8249, 8285, 8320, 8357, 8393, 8429, 8466, 8502, 8539, 8576, 8613, 8651,
    8688, 8726, 8764, 8802, 8840, 8878, 8917, 8955, 8994, 9033, 9072, 9111,
    9151, 9191, 9230, 9270, 9311, 9351, 9391, 9432, 9473, 9514, 9555, 9597,
    9638, 9680, 9722, 9764, 9806, 9849, 9892, 9934, 9977, 10021, 10064, 10108,
    10152, 10196, 10240, 10284, 10329, 10373, 10418, 10463, 10509, 10554, 10600,
    10646, 10692, 10739, 10785, 10832, 10879, 10926, 10973, 11021, 11069, 11116,
    11165, 11213, 11262, 11310, 11359, 11409, 11458, 11508, 11558, 11608, 11658,
    11708, 11759, 11810, 11861, 11913, 11964, 12016, 12068, 12121, 12173, 12226,
    12279, 12332, 12386, 12439, 12493, 12547, 12602, 12656, 12711, 12766, 12821,
    12877, 12933, 12989, 13045, 13102, 13158, 13215, 13273, 13330, 13388, 13446,
    13504, 13563, 13622, 13681, 13740, 13799, 13859, 13919, 13980, 14040, 14101,
    14162, 14223, 14285, 14347, 14409, 14472, 14534, 14597, 14661, 14724, 14788,
    14852, 14916, 14981, 15046, 15111, 15177, 15242, 15308, 15375, 15441, 15508,
    15575, 15643, 15711, 15779, 15847, 15916, 15985, 16054, 16124,
  ],
  770000: [
    2984, 3009, 3034, 3059, 3084, 3110, 3136, 3162, 3189, 3215, 3242, 3269,
    3296, 3324, 3351, 3379, 3407, 3436, 3464, 3493, 3522, 3552, 3581, 3611,
    3634, 3657, 3679, 3702, 3726, 3749, 3772, 3796, 3820, 3843, 3867, 3892,
    3916, 3940, 3965, 3990, 4015, 4040, 4065, 4090, 4116, 4142, 4168, 4194,
    4212, 4230, 4248, 4267, 4285, 4304, 4323, 4341, 4360, 4379, 4398, 4417,
    4436, 4455, 4475, 4494, 4514, 4533, 4553, 4573, 4592, 4612, 4632, 4652,
    4672, 4693, 4713, 4733, 4754, 4775, 4795, 4816, 4837, 4858, 4879, 4900,
    4921, 4943, 4964, 4986, 5007, 5029, 5051, 5073, 5095, 5117, 5139, 5161,
    5183, 5206, 5228, 5251, 5274, 5297, 5320, 5343, 5366, 5389, 5412, 5436,
    5459, 5483, 5507, 5531, 5555, 5579, 5603, 5627, 5652, 5676, 5701, 5725,
    5750, 5775, 5800, 5825, 5851, 5876, 5901, 5927, 5953, 5978, 6004, 6030,
    6056, 6083, 6109, 6136, 6162, 6189, 6216, 6243, 6270, 6297, 6324, 6351,
    6379, 6407, 6434, 6462, 6490, 6518, 6547, 6575, 6604, 6632, 6661, 6690,
    6719, 6748, 6777, 6806, 6836, 6866, 6895, 6925, 6955, 6985, 7016, 7046,
    7077, 7107, 7138, 7169, 7200, 7231, 7263, 7294, 7326, 7357, 7389, 7421,
    7453, 7486, 7518, 7551, 7583, 7616, 7649, 7682, 7716, 7749, 7783, 7817,
    7850, 7884, 7919, 7953, 7987, 8022, 8057, 8092, 8127, 8162, 8197, 8233,
    8268, 8304, 8340, 8376, 8413, 8449, 8486, 8523, 8560, 8597, 8634, 8671,
    8709, 8747, 8784, 8823, 8861, 8899, 8938, 8976, 9015, 9054, 9094, 9133,
    9173, 9212, 9252, 9292, 9333, 9373, 9414, 9455, 9495, 9537, 9578, 9619,
    9661, 9703, 9745, 9787, 9830, 9872, 9915, 9958, 10001, 10045, 10088, 10132,
    10176, 10220, 10264, 10309, 10353, 10398, 10443, 10488, 10534, 10579, 10625,
    10671, 10718, 10764, 10811, 10858, 10905, 10952, 10999, 11047, 11095, 11143,
    11191, 11240, 11288, 11337, 11386, 11436, 11485, 11535, 11585, 11635, 11686,
    11736, 11787, 11838, 11890, 11941, 11993, 12045, 12097, 12149, 12202, 12255,
    12308, 12361, 12415, 12469, 12523, 12577, 12632, 12686, 12741, 12796, 12852,
    12908, 12964, 13020, 13076, 13133, 13190, 13247, 13304, 13362, 13420, 13478,
    13536, 13595, 13654, 13713, 13773, 13832, 13892, 13952, 14013, 14074, 14135,
    14196, 14257, 14319, 14381, 14443, 14506, 14569, 14632, 14695, 14759, 14823,
    14887, 14952, 15017, 15082, 15147, 15213, 15279, 15345, 15411, 15478, 15545,
    15613, 15680, 15748, 15816, 15885, 15954, 16023, 16092, 16162,
  ],
  780000: [
    2990, 3015, 3040, 3065, 3091, 3117, 3143, 3169, 3195, 3222, 3249, 3276,
    3303, 3331, 3358, 3386, 3415, 3443, 3472, 3501, 3530, 3559, 3589, 3619,
    3641, 3664, 3687, 3710, 3733, 3757, 3780, 3804, 3828, 3851, 3876, 3900,
    3924, 3949, 3973, 3998, 4023, 4048, 4074, 4099, 4125, 4150, 4176, 4202,
    4221, 4239, 4257, 4276, 4294, 4313, 4332, 4350, 4369, 4388, 4407, 4426,
    4445, 4465, 4484, 4504, 4523, 4543, 4562, 4582, 4602, 4622, 4642, 4662,
    4682, 4703, 4723, 4743, 4764, 4785, 4805, 4826, 4847, 4868, 4889, 4910,
    4932, 4953, 4974, 4996, 5018, 5039, 5061, 5083, 5105, 5127, 5150, 5172,
    5194, 5217, 5239, 5262, 5285, 5308, 5331, 5354, 5377, 5400, 5424, 5447,
    5471, 5495, 5518, 5542, 5566, 5590, 5615, 5639, 5663, 5688, 5713, 5737,
    5762, 5787, 5812, 5837, 5863, 5888, 5914, 5939, 5965, 5991, 6017, 6043,
    6069, 6095, 6122, 6148, 6175, 6202, 6229, 6256, 6283, 6310, 6337, 6365,
    6392, 6420, 6448, 6476, 6504, 6532, 6560, 6589, 6617, 6646, 6675, 6704,
    6733, 6762, 6791, 6821, 6850, 6880, 6910, 6940, 6970, 7000, 7030, 7061,
    7091, 7122, 7153, 7184, 7215, 7246, 7278, 7309, 7341, 7373, 7405, 7437,
    7469, 7501, 7534, 7567, 7599, 7632, 7665, 7699, 7732, 7765, 7799, 7833,
    7867, 7901, 7935, 7970, 8004, 8039, 8074, 8109, 8144, 8179, 8214, 8250,
    8286, 8322, 8358, 8394, 8430, 8467, 8504, 8540, 8577, 8615, 8652, 8689,
    8727, 8765, 8803, 8841, 8879, 8918, 8956, 8995, 9034, 9073, 9113, 9152,
    9192, 9232, 9272, 9312, 9352, 9393, 9433, 9474, 9515, 9557, 9598, 9640,
    9681, 9723, 9765, 9808, 9850, 9893, 9936, 9979, 10022, 10066, 10109, 10153,
    10197, 10241, 10286, 10330, 10375, 10420, 10465, 10510, 10556, 10602, 10648,
    10694, 10740, 10787, 10833, 10880, 10927, 10975, 11022, 11070, 11118, 11166,
    11215, 11263, 11312, 11361, 11410, 11460, 11509, 11559, 11609, 11660, 11710,
    11761, 11812, 11863, 11915, 11966, 12018, 12070, 12122, 12175, 12228, 12281,
    12334, 12387, 12441, 12495, 12549, 12603, 12658, 12713, 12768, 12823, 12879,
    12935, 12991, 13047, 13104, 13160, 13217, 13275, 13332, 13390, 13448, 13506,
    13565, 13624, 13683, 13742, 13801, 13861, 13921, 13982, 14042, 14103, 14164,
    14226, 14287, 14349, 14411, 14474, 14536, 14599, 14663, 14726, 14790, 14854,
    14919, 14983, 15048, 15113, 15179, 15245, 15311, 15377, 15444, 15511, 15578,
    15645, 15713, 15781, 15850, 15918, 15987, 16056, 16126, 16196,
  ],
  790000: [
    2995, 3020, 3046, 3071, 3097, 3122, 3148, 3175, 3201, 3228, 3255, 3282,
    3309, 3337, 3364, 3392, 3421, 3449, 3478, 3507, 3536, 3566, 3595, 3625,
    3648, 3671, 3694, 3717, 3740, 3763, 3787, 3811, 3834, 3858, 3883, 3907,
    3931, 3956, 3981, 4005, 4030, 4056, 4081, 4106, 4132, 4158, 4184, 4210,
    4228, 4247, 4265, 4284, 4302, 4321, 4339, 4358, 4377, 4396, 4415, 4434,
    4454, 4473, 4492, 4512, 4531, 4551, 4571, 4590, 4610, 4630, 4650, 4670,
    4691, 4711, 4731, 4752, 4773, 4793, 4814, 4835, 4856, 4877, 4898, 4919,
    4941, 4962, 4983, 5005, 5027, 5049, 5070, 5092, 5114, 5137, 5159, 5181,
    5204, 5226, 5249, 5272, 5294, 5317, 5340, 5364, 5387, 5410, 5434, 5457,
    5481, 5505, 5528, 5552, 5576, 5601, 5625, 5649, 5674, 5698, 5723, 5748,
    5773, 5798, 5823, 5848, 5873, 5899, 5924, 5950, 5976, 6002, 6028, 6054,
    6080, 6106, 6133, 6160, 6186, 6213, 6240, 6267, 6294, 6321, 6349, 6376,
    6404, 6432, 6460, 6488, 6516, 6544, 6572, 6601, 6629, 6658, 6687, 6716,
    6745, 6774, 6804, 6833, 6863, 6892, 6922, 6952, 6982, 7013, 7043, 7074,
    7104, 7135, 7166, 7197, 7228, 7259, 7291, 7323, 7354, 7386, 7418, 7450,
    7483, 7515, 7548, 7580, 7613, 7646, 7679, 7713, 7746, 7780, 7813, 7847,
    7881, 7915, 7950, 7984, 8019, 8053, 8088, 8123, 8158, 8194, 8229, 8265,
    8301, 8337, 8373, 8409, 8446, 8482, 8519, 8556, 8593, 8630, 8668, 8705,
    8743, 8781, 8819, 8857, 8895, 8934, 8973, 9012, 9051, 9090, 9129, 9169,
    9209, 9248, 9288, 9329, 9369, 9410, 9451, 9491, 9533, 9574, 9615, 9657,
    9699, 9741, 9783, 9826, 9868, 9911, 9954, 9997, 10040, 10084, 10128, 10171,
    10215, 10260, 10304, 10349, 10394, 10439, 10484, 10529, 10575, 10621, 10667,
    10713, 10760, 10806, 10853, 10900, 10947, 10995, 11042, 11090, 11138, 11186,
    11235, 11284, 11333, 11382, 11431, 11481, 11530, 11580, 11630, 11681, 11731,
    11782, 11833, 11885, 11936, 11988, 12040, 12092, 12144, 12197, 12250, 12303,
    12356, 12410, 12464, 12518, 12572, 12626, 12681, 12736, 12791, 12847, 12902,
    12958, 13014, 13071, 13127, 13184, 13241, 13299, 13356, 13414, 13472, 13531,
    13589, 13648, 13707, 13767, 13826, 13886, 13947, 14007, 14068, 14129, 14190,
    14251, 14313, 14375, 14437, 14500, 14563, 14626, 14689, 14753, 14817, 14881,
    14946, 15010, 15075, 15141, 15206, 15272, 15338, 15405, 15472, 15539, 15606,
    15674, 15741, 15810, 15878, 15947, 16016, 16086, 16155, 16225,
  ],
  800000: [
    3000, 3025, 3050, 3076, 3101, 3127, 3153, 3179, 3206, 3233, 3260, 3287,
    3314, 3342, 3370, 3398, 3426, 3455, 3483, 3512, 3542, 3571, 3601, 3631,
    3654, 3676, 3699, 3723, 3746, 3769, 3793, 3816, 3840, 3864, 3888, 3913,
    3937, 3962, 3987, 4012, 4037, 4062, 4087, 4113, 4138, 4164, 4190, 4217,
    4235, 4253, 4272, 4290, 4309, 4327, 4346, 4365, 4384, 4403, 4422, 4441,
    4460, 4480, 4499, 4519, 4538, 4558, 4578, 4597, 4617, 4637, 4657, 4678,
    4698, 4718, 4739, 4759, 4780, 4801, 4821, 4842, 4863, 4884, 4905, 4927,
    4948, 4970, 4991, 5013, 5034, 5056, 5078, 5100, 5122, 5144, 5167, 5189,
    5212, 5234, 5257, 5280, 5303, 5326, 5349, 5372, 5395, 5418, 5442, 5466,
    5489, 5513, 5537, 5561, 5585, 5609, 5633, 5658, 5682, 5707, 5732, 5757,
    5782, 5807, 5832, 5857, 5882, 5908, 5933, 5959, 5985, 6011, 6037, 6063,
    6089, 6116, 6142, 6169, 6196, 6223, 6249, 6277, 6304, 6331, 6359, 6386,
    6414, 6442, 6469, 6497, 6526, 6554, 6582, 6611, 6639, 6668, 6697, 6726,
    6755, 6785, 6814, 6844, 6873, 6903, 6933, 6963, 6993, 7023, 7054, 7084,
    7115, 7146, 7177, 7208, 7239, 7271, 7302, 7334, 7366, 7397, 7429, 7462,
    7494, 7526, 7559, 7592, 7625, 7658, 7691, 7724, 7758, 7791, 7825, 7859,
    7893, 7927, 7962, 7996, 8031, 8066, 8101, 8136, 8171, 8206, 8242, 8278,
    8314, 8350, 8386, 8422, 8459, 8495, 8532, 8569, 8606, 8643, 8681, 8718,
    8756, 8794, 8832, 8871, 8909, 8948, 8986, 9025, 9064, 9104, 9143, 9183,
    9223, 9263, 9303, 9343, 9383, 9424, 9465, 9506, 9547, 9589, 9630, 9672,
    9714, 9756, 9798, 9841, 9883, 9926, 9969, 10012, 10056, 10099, 10143, 10187,
    10231, 10275, 10320, 10365, 10410, 10455, 10500, 10546, 10591, 10637, 10683,
    10729, 10776, 10823, 10870, 10917, 10964, 11011, 11059, 11107, 11155, 11204,
    11252, 11301, 11350, 11399, 11448, 11498, 11548, 11598, 11648, 11699, 11749,
    11800, 11851, 11903, 11954, 12006, 12058, 12110, 12163, 12216, 12269, 12322,
    12375, 12429, 12483, 12537, 12591, 12646, 12700, 12755, 12811, 12866, 12922,
    12978, 13034, 13091, 13147, 13204, 13262, 13319, 13377, 13435, 13493, 13551,
    13610, 13669, 13728, 13788, 13848, 13908, 13968, 14028, 14089, 14150, 14212,
    14273, 14335, 14397, 14459, 14522, 14585, 14648, 14712, 14775, 14840, 14904,
    14968, 15033, 15098, 15164, 15230, 15296, 15362, 15428, 15495, 15562, 15630,
    15698, 15766, 15834, 15903, 15971, 16041, 16110, 16180, 16250,
  ],
  810000: [
    3004, 3029, 3054, 3079, 3105, 3131, 3157, 3183, 3210, 3237, 3264, 3291,
    3318, 3346, 3374, 3402, 3430, 3459, 3488, 3517, 3546, 3576, 3605, 3635,
    3658, 3681, 3704, 3727, 3750, 3774, 3798, 3821, 3845, 3869, 3893, 3918,
    3942, 3967, 3992, 4017, 4042, 4067, 4092, 4118, 4144, 4170, 4196, 4222,
    4240, 4258, 4277, 4295, 4314, 4333, 4352, 4370, 4389, 4408, 4427, 4447,
    4466, 4485, 4505, 4524, 4544, 4564, 4583, 4603, 4623, 4643, 4663, 4683,
    4704, 4724, 4745, 4765, 4786, 4807, 4827, 4848, 4869, 4890, 4912, 4933,
    4954, 4976, 4997, 5019, 5041, 5063, 5085, 5107, 5129, 5151, 5173, 5196,
    5218, 5241, 5263, 5286, 5309, 5332, 5355, 5379, 5402, 5425, 5449, 5472,
    5496, 5520, 5544, 5568, 5592, 5616, 5641, 5665, 5689, 5714, 5739, 5764,
    5789, 5814, 5839, 5864, 5890, 5915, 5941, 5967, 5993, 6018, 6045, 6071,
    6097, 6123, 6150, 6177, 6203, 6230, 6257, 6284, 6312, 6339, 6366, 6394,
    6422, 6450, 6478, 6506, 6534, 6562, 6591, 6619, 6648, 6677, 6706, 6735,
    6764, 6793, 6823, 6852, 6882, 6912, 6942, 6972, 7002, 7032, 7063, 7093,
    7124, 7155, 7186, 7217, 7248, 7280, 7311, 7343, 7375, 7407, 7439, 7471,
    7503, 7536, 7569, 7601, 7634, 7667, 7701, 7734, 7767, 7801, 7835, 7869,
    7903, 7937, 7972, 8006, 8041, 8076, 8111, 8146, 8181, 8217, 8252, 8288,
    8324, 8360, 8396, 8433, 8469, 8506, 8543, 8580, 8617, 8654, 8692, 8729,
    8767, 8805, 8843, 8882, 8920, 8959, 8998, 9037, 9076, 9115, 9155, 9194,
    9234, 9274, 9314, 9355, 9395, 9436, 9477, 9518, 9559, 9601, 9642, 9684,
    9726, 9768, 9810, 9853, 9896, 9938, 9982, 10025, 10068, 10112, 10156, 10200,
    10244, 10288, 10333, 10378, 10423, 10468, 10513, 10559, 10604, 10650, 10697,
    10743, 10789, 10836, 10883, 10930, 10978, 11025, 11073, 11121, 11169, 11218,
    11266, 11315, 11364, 11413, 11463, 11512, 11562, 11612, 11663, 11713, 11764,
    11815, 11866, 11918, 11969, 12021, 12073, 12126, 12178, 12231, 12284, 12337,
    12391, 12444, 12498, 12552, 12607, 12661, 12716, 12771, 12827, 12882, 12938,
    12994, 13050, 13107, 13164, 13221, 13278, 13336, 13393, 13452, 13510, 13568,
    13627, 13686, 13745, 13805, 13865, 13925, 13985, 14046, 14107, 14168, 14229,
    14291, 14353, 14415, 14478, 14540, 14603, 14667, 14730, 14794, 14858, 14922,
    14987, 15052, 15117, 15183, 15249, 15315, 15381, 15448, 15515, 15582, 15649,
    15717, 15785, 15854, 15922, 15991, 16061, 16130, 16200, 16270,
  ],
  820000: [
    3007, 3032, 3057, 3082, 3108, 3134, 3160, 3187, 3213, 3240, 3267, 3294,
    3322, 3349, 3377, 3405, 3434, 3462, 3491, 3520, 3550, 3579, 3609, 3639,
    3662, 3685, 3708, 3731, 3754, 3778, 3801, 3825, 3849, 3873, 3897, 3921,
    3946, 3971, 3995, 4020, 4046, 4071, 4096, 4122, 4148, 4174, 4200, 4226,
    4244, 4263, 4281, 4300, 4318, 4337, 4356, 4375, 4394, 4413, 4432, 4451,
    4470, 4490, 4509, 4529, 4548, 4568, 4588, 4608, 4628, 4648, 4668, 4688,
    4708, 4729, 4749, 4770, 4790, 4811, 4832, 4853, 4874, 4895, 4916, 4938,
    4959, 4981, 5002, 5024, 5046, 5067, 5089, 5111, 5134, 5156, 5178, 5201,
    5223, 5246, 5269, 5291, 5314, 5337, 5360, 5384, 5407, 5430, 5454, 5478,
    5501, 5525, 5549, 5573, 5597, 5622, 5646, 5670, 5695, 5720, 5744, 5769,
    5794, 5819, 5845, 5870, 5895, 5921, 5947, 5972, 5998, 6024, 6050, 6077,
    6103, 6129, 6156, 6183, 6209, 6236, 6263, 6291, 6318, 6345, 6373, 6400,
    6428, 6456, 6484, 6512, 6540, 6568, 6597, 6626, 6654, 6683, 6712, 6741,
    6770, 6800, 6829, 6859, 6888, 6918, 6948, 6978, 7009, 7039, 7070, 7100,
    7131, 7162, 7193, 7224, 7255, 7287, 7318, 7350, 7382, 7414, 7446, 7478,
    7511, 7543, 7576, 7609, 7642, 7675, 7708, 7741, 7775, 7809, 7843, 7877,
    7911, 7945, 7979, 8014, 8049, 8084, 8119, 8154, 8189, 8225, 8260, 8296,
    8332, 8368, 8404, 8441, 8477, 8514, 8551, 8588, 8625, 8663, 8700, 8738,
    8776, 8814, 8852, 8890, 8929, 8968, 9006, 9045, 9085, 9124, 9164, 9203,
    9243, 9283, 9323, 9364, 9404, 9445, 9486, 9527, 9568, 9610, 9652, 9693,
    9735, 9778, 9820, 9862, 9905, 9948, 9991, 10035, 10078, 10122, 10166, 10210,
    10254, 10298, 10343, 10388, 10433, 10478, 10523, 10569, 10615, 10661, 10707,
    10753, 10800, 10847, 10894, 10941, 10988, 11036, 11084, 11132, 11180, 11229,
    11277, 11326, 11375, 11424, 11474, 11524, 11574, 11624, 11674, 11725, 11775,
    11827, 11878, 11929, 11981, 12033, 12085, 12137, 12190, 12243, 12296, 12349,
    12403, 12456, 12510, 12565, 12619, 12674, 12729, 12784, 12839, 12895, 12951,
    13007, 13063, 13120, 13177, 13234, 13291, 13349, 13406, 13465, 13523, 13582,
    13640, 13699, 13759, 13818, 13878, 13938, 13999, 14060, 14120, 14182, 14243,
    14305, 14367, 14429, 14492, 14554, 14617, 14681, 14744, 14808, 14872, 14937,
    15002, 15067, 15132, 15198, 15263, 15330, 15396, 15463, 15530, 15597, 15665,
    15732, 15801, 15869, 15938, 16007, 16076, 16146, 16216, 16286,
  ],
  830000: [
    3009, 3034, 3059, 3085, 3110, 3136, 3162, 3189, 3215, 3242, 3269, 3296,
    3324, 3352, 3379, 3408, 3436, 3465, 3493, 3523, 3552, 3582, 3611, 3642,
    3664, 3687, 3710, 3733, 3757, 3780, 3804, 3828, 3852, 3876, 3900, 3924,
    3949, 3973, 3998, 4023, 4048, 4074, 4099, 4125, 4151, 4176, 4203, 4229,
    4247, 4266, 4284, 4303, 4321, 4340, 4359, 4378, 4397, 4416, 4435, 4454,
    4473, 4493, 4512, 4532, 4551, 4571, 4591, 4611, 4631, 4651, 4671, 4691,
    4712, 4732, 4753, 4773, 4794, 4815, 4835, 4856, 4877, 4899, 4920, 4941,
    4963, 4984, 5006, 5027, 5049, 5071, 5093, 5115, 5137, 5159, 5182, 5204,
    5227, 5249, 5272, 5295, 5318, 5341, 5364, 5387, 5411, 5434, 5458, 5481,
    5505, 5529, 5553, 5577, 5601, 5626, 5650, 5674, 5699, 5724, 5748, 5773,
    5798, 5824, 5849, 5874, 5900, 5925, 5951, 5977, 6002, 6028, 6055, 6081,
    6107, 6134, 6160, 6187, 6214, 6241, 6268, 6295, 6322, 6350, 6377, 6405,
    6432, 6460, 6488, 6516, 6545, 6573, 6602, 6630, 6659, 6688, 6717, 6746,
    6775, 6804, 6834, 6863, 6893, 6923, 6953, 6983, 7013, 7044, 7074, 7105,
    7136, 7167, 7198, 7229, 7260, 7292, 7323, 7355, 7387, 7419, 7451, 7483,
    7516, 7548, 7581, 7614, 7647, 7680, 7713, 7747, 7780, 7814, 7848, 7882,
    7916, 7950, 7985, 8020, 8054, 8089, 8124, 8159, 8195, 8230, 8266, 8302,
    8338, 8374, 8410, 8447, 8483, 8520, 8557, 8594, 8631, 8669, 8706, 8744,
    8782, 8820, 8858, 8896, 8935, 8974, 9013, 9052, 9091, 9130, 9170, 9210,
    9249, 9290, 9330, 9370, 9411, 9452, 9493, 9534, 9575, 9617, 9658, 9700,
    9742, 9784, 9827, 9869, 9912, 9955, 9998, 10041, 10085, 10129, 10173, 10217,
    10261, 10305, 10350, 10395, 10440, 10485, 10531, 10576, 10622, 10668, 10714,
    10761, 10807, 10854, 10901, 10949, 10996, 11044, 11091, 11140, 11188, 11236,
    11285, 11334, 11383, 11432, 11482, 11532, 11582, 11632, 11682, 11733, 11784,
    11835, 11886, 11937, 11989, 12041, 12093, 12146, 12198, 12251, 12304, 12358,
    12411, 12465, 12519, 12573, 12628, 12682, 12737, 12793, 12848, 12904, 12960,
    13016, 13072, 13129, 13186, 13243, 13300, 13358, 13416, 13474, 13532, 13591,
    13650, 13709, 13768, 13828, 13888, 13948, 14009, 14069, 14130, 14191, 14253,
    14315, 14377, 14439, 14502, 14564, 14628, 14691, 14755, 14819, 14883, 14947,
    15012, 15077, 15142, 15208, 15274, 15340, 15407, 15473, 15540, 15608, 15675,
    15743, 15812, 15880, 15949, 16018, 16087, 16157, 16227, 16297,
  ],
  840000: [
    3010, 3035, 3060, 3086, 3112, 3138, 3164, 3190, 3217, 3243, 3270, 3298,
    3325, 3353, 3381, 3409, 3437, 3466, 3495, 3524, 3553, 3583, 3613, 3643,
    3666, 3689, 3712, 3735, 3758, 3782, 3805, 3829, 3853, 3877, 3901, 3926,
    3950, 3975, 4000, 4025, 4050, 4075, 4101, 4126, 4152, 4178, 4204, 4231,
    4249, 4267, 4286, 4304, 4323, 4342, 4361, 4380, 4398, 4418, 4437, 4456,
    4475, 4495, 4514, 4534, 4553, 4573, 4593, 4613, 4633, 4653, 4673, 4693,
    4714, 4734, 4755, 4775, 4796, 4817, 4837, 4858, 4879, 4901, 4922, 4943,
    4965, 4986, 5008, 5029, 5051, 5073, 5095, 5117, 5139, 5162, 5184, 5206,
    5229, 5252, 5274, 5297, 5320, 5343, 5366, 5390, 5413, 5437, 5460, 5484,
    5507, 5531, 5555, 5579, 5604, 5628, 5652, 5677, 5701, 5726, 5751, 5776,
    5801, 5826, 5851, 5877, 5902, 5928, 5953, 5979, 6005, 6031, 6057, 6083,
    6110, 6136, 6163, 6190, 6216, 6243, 6270, 6297, 6325, 6352, 6380, 6407,
    6435, 6463, 6491, 6519, 6547, 6576, 6604, 6633, 6662, 6690, 6719, 6749,
    6778, 6807, 6837, 6866, 6896, 6926, 6956, 6986, 7016, 7047, 7077, 7108,
    7139, 7170, 7201, 7232, 7263, 7295, 7326, 7358, 7390, 7422, 7454, 7487,
    7519, 7552, 7584, 7617, 7650, 7683, 7717, 7750, 7784, 7817, 7851, 7885,
    7919, 7954, 7988, 8023, 8058, 8093, 8128, 8163, 8198, 8234, 8269, 8305,
    8341, 8377, 8414, 8450, 8487, 8524, 8560, 8598, 8635, 8672, 8710, 8748,
    8785, 8824, 8862, 8900, 8939, 8977, 9016, 9055, 9095, 9134, 9174, 9213,
    9253, 9293, 9334, 9374, 9415, 9456, 9497, 9538, 9579, 9621, 9662, 9704,
    9746, 9788, 9831, 9873, 9916, 9959, 10002, 10046, 10089, 10133, 10177,
    10221, 10265, 10310, 10354, 10399, 10444, 10490, 10535, 10581, 10627, 10673,
    10719, 10765, 10812, 10859, 10906, 10953, 11001, 11048, 11096, 11144, 11192,
    11241, 11290, 11339, 11388, 11437, 11487, 11536, 11586, 11637, 11687, 11738,
    11789, 11840, 11891, 11942, 11994, 12046, 12098, 12151, 12203, 12256, 12309,
    12363, 12416, 12470, 12524, 12578, 12633, 12688, 12743, 12798, 12853, 12909,
    12965, 13021, 13078, 13134, 13191, 13248, 13306, 13363, 13421, 13480, 13538,
    13597, 13655, 13715, 13774, 13834, 13894, 13954, 14014, 14075, 14136, 14197,
    14259, 14321, 14383, 14445, 14508, 14571, 14634, 14697, 14761, 14825, 14889,
    14954, 15018, 15083, 15149, 15214, 15280, 15347, 15413, 15480, 15547, 15614,
    15682, 15750, 15818, 15887, 15956, 16025, 16094, 16164, 16234, 16304,
  ],
  850000: [
    3010, 3036, 3061, 3086, 3112, 3138, 3164, 3190, 3217, 3244, 3271, 3298,
    3326, 3353, 3381, 3409, 3438, 3467, 3495, 3525, 3554, 3584, 3613, 3644,
    3666, 3689, 3712, 3735, 3759, 3782, 3806, 3830, 3854, 3878, 3902, 3926,
    3951, 3976, 4000, 4025, 4051, 4076, 4101, 4127, 4153, 4179, 4205, 4231,
    4250, 4268, 4286, 4305, 4324, 4342, 4361, 4380, 4399, 4418, 4437, 4457,
    4476, 4495, 4515, 4534, 4554, 4574, 4593, 4613, 4633, 4653, 4674, 4694,
    4714, 4735, 4755, 4776, 4796, 4817, 4838, 4859, 4880, 4901, 4923, 4944,
    4965, 4987, 5008, 5030, 5052, 5074, 5096, 5118, 5140, 5162, 5185, 5207,
    5230, 5252, 5275, 5298, 5321, 5344, 5367, 5390, 5414, 5437, 5461, 5484,
    5508, 5532, 5556, 5580, 5604, 5629, 5653, 5678, 5702, 5727, 5752, 5777,
    5802, 5827, 5852, 5877, 5903, 5928, 5954, 5980, 6006, 6032, 6058, 6084,
    6111, 6137, 6164, 6190, 6217, 6244, 6271, 6298, 6326, 6353, 6381, 6408,
    6436, 6464, 6492, 6520, 6548, 6577, 6605, 6634, 6663, 6691, 6720, 6750,
    6779, 6808, 6838, 6867, 6897, 6927, 6957, 6987, 7017, 7048, 7078, 7109,
    7140, 7171, 7202, 7233, 7264, 7296, 7327, 7359, 7391, 7423, 7455, 7488,
    7520, 7553, 7585, 7618, 7651, 7684, 7718, 7751, 7785, 7818, 7852, 7886,
    7921, 7955, 7989, 8024, 8059, 8094, 8129, 8164, 8199, 8235, 8271, 8306,
    8342, 8379, 8415, 8451, 8488, 8525, 8562, 8599, 8636, 8673, 8711, 8749,
    8787, 8825, 8863, 8901, 8940, 8979, 9018, 9057, 9096, 9135, 9175, 9215,
    9255, 9295, 9335, 9375, 9416, 9457, 9498, 9539, 9580, 9622, 9664, 9705,
    9747, 9790, 9832, 9875, 9918, 9961, 10004, 10047, 10091, 10134, 10178,
    10222, 10267, 10311, 10356, 10401, 10446, 10491, 10536, 10582, 10628, 10674,
    10720, 10767, 10813, 10860, 10907, 10955, 11002, 11050, 11098, 11146, 11194,
    11243, 11291, 11340, 11389, 11439, 11488, 11538, 11588, 11638, 11689, 11739,
    11790, 11841, 11893, 11944, 11996, 12048, 12100, 12152, 12205, 12258, 12311,
    12364, 12418, 12472, 12526, 12580, 12635, 12689, 12744, 12800, 12855, 12911,
    12967, 13023, 13079, 13136, 13193, 13250, 13308, 13365, 13423, 13481, 13540,
    13598, 13657, 13717, 13776, 13836, 13896, 13956, 14016, 14077, 14138, 14199,
    14261, 14323, 14385, 14447, 14510, 14573, 14636, 14699, 14763, 14827, 14891,
    14956, 15020, 15085, 15151, 15216, 15282, 15349, 15415, 15482, 15549, 15616,
    15684, 15752, 15820, 15889, 15958, 16027, 16096, 16166, 16236, 16306,
  ],
  860000: [
    3010, 3035, 3060, 3086, 3112, 3138, 3164, 3190, 3217, 3243, 3270, 3298,
    3325, 3353, 3381, 3409, 3437, 3466, 3495, 3524, 3553, 3583, 3613, 3643,
    3666, 3689, 3712, 3735, 3758, 3782, 3805, 3829, 3853, 3877, 3901, 3926,
    3950, 3975, 4000, 4025, 4050, 4075, 4101, 4126, 4152, 4178, 4204, 4231,
    4249, 4267, 4286, 4304, 4323, 4342, 4361, 4380, 4398, 4418, 4437, 4456,
    4475, 4495, 4514, 4534, 4553, 4573, 4593, 4613, 4633, 4653, 4673, 4693,
    4714, 4734, 4755, 4775, 4796, 4817, 4837, 4858, 4879, 4901, 4922, 4943,
    4965, 4986, 5008, 5029, 5051, 5073, 5095, 5117, 5139, 5162, 5184, 5206,
    5229, 5252, 5274, 5297, 5320, 5343, 5366, 5390, 5413, 5437, 5460, 5484,
    5507, 5531, 5555, 5579, 5604, 5628, 5652, 5677, 5701, 5726, 5751, 5776,
    5801, 5826, 5851, 5877, 5902, 5928, 5953, 5979, 6005, 6031, 6057, 6083,
    6110, 6136, 6163, 6190, 6216, 6243, 6270, 6297, 6325, 6352, 6380, 6407,
    6435, 6463, 6491, 6519, 6547, 6576, 6604, 6633, 6662, 6690, 6719, 6749,
    6778, 6807, 6837, 6866, 6896, 6926, 6956, 6986, 7016, 7047, 7077, 7108,
    7139, 7170, 7201, 7232, 7263, 7295, 7326, 7358, 7390, 7422, 7454, 7487,
    7519, 7552, 7584, 7617, 7650, 7683, 7717, 7750, 7784, 7817, 7851, 7885,
    7919, 7954, 7988, 8023, 8058, 8093, 8128, 8163, 8198, 8234, 8269, 8305,
    8341, 8377, 8414, 8450, 8487, 8524, 8560, 8598, 8635, 8672, 8710, 8748,
    8785, 8824, 8862, 8900, 8939, 8977, 9016, 9055, 9095, 9134, 9174, 9213,
    9253, 9293, 9334, 9374, 9415, 9456, 9497, 9538, 9579, 9621, 9662, 9704,
    9746, 9788, 9831, 9873, 9916, 9959, 10002, 10046, 10089, 10133, 10177,
    10221, 10265, 10310, 10354, 10399, 10444, 10490, 10535, 10581, 10627, 10673,
    10719, 10765, 10812, 10859, 10906, 10953, 11001, 11048, 11096, 11144, 11192,
    11241, 11290, 11339, 11388, 11437, 11487, 11536, 11586, 11637, 11687, 11738,
    11789, 11840, 11891, 11942, 11994, 12046, 12098, 12151, 12203, 12256, 12309,
    12363, 12416, 12470, 12524, 12578, 12633, 12688, 12743, 12798, 12853, 12909,
    12965, 13021, 13078, 13134, 13191, 13248, 13306, 13363, 13421, 13480, 13538,
    13597, 13655, 13715, 13774, 13834, 13894, 13954, 14014, 14075, 14136, 14197,
    14259, 14321, 14383, 14445, 14508, 14571, 14634, 14697, 14761, 14825, 14889,
    14954, 15018, 15083, 15149, 15214, 15280, 15347, 15413, 15480, 15547, 15614,
    15682, 15750, 15818, 15887, 15956, 16025, 16094, 16164, 16234, 16304,
  ],
  870000: [
    3009, 3034, 3059, 3085, 3110, 3136, 3162, 3189, 3215, 3242, 3269, 3296,
    3324, 3352, 3379, 3408, 3436, 3465, 3493, 3523, 3552, 3582, 3611, 3642,
    3664, 3687, 3710, 3733, 3757, 3780, 3804, 3828, 3852, 3876, 3900, 3924,
    3949, 3973, 3998, 4023, 4048, 4074, 4099, 4125, 4151, 4176, 4203, 4229,
    4247, 4266, 4284, 4303, 4321, 4340, 4359, 4378, 4397, 4416, 4435, 4454,
    4473, 4493, 4512, 4532, 4551, 4571, 4591, 4611, 4631, 4651, 4671, 4691,
    4712, 4732, 4753, 4773, 4794, 4815, 4835, 4856, 4877, 4899, 4920, 4941,
    4963, 4984, 5006, 5027, 5049, 5071, 5093, 5115, 5137, 5159, 5182, 5204,
    5227, 5249, 5272, 5295, 5318, 5341, 5364, 5387, 5411, 5434, 5458, 5481,
    5505, 5529, 5553, 5577, 5601, 5626, 5650, 5674, 5699, 5724, 5748, 5773,
    5798, 5824, 5849, 5874, 5900, 5925, 5951, 5977, 6002, 6028, 6055, 6081,
    6107, 6134, 6160, 6187, 6214, 6241, 6268, 6295, 6322, 6350, 6377, 6405,
    6432, 6460, 6488, 6516, 6545, 6573, 6602, 6630, 6659, 6688, 6717, 6746,
    6775, 6804, 6834, 6863, 6893, 6923, 6953, 6983, 7013, 7044, 7074, 7105,
    7136, 7167, 7198, 7229, 7260, 7292, 7323, 7355, 7387, 7419, 7451, 7483,
    7516, 7548, 7581, 7614, 7647, 7680, 7713, 7747, 7780, 7814, 7848, 7882,
    7916, 7950, 7985, 8020, 8054, 8089, 8124, 8159, 8195, 8230, 8266, 8302,
    8338, 8374, 8410, 8447, 8483, 8520, 8557, 8594, 8631, 8669, 8706, 8744,
    8782, 8820, 8858, 8896, 8935, 8974, 9013, 9052, 9091, 9130, 9170, 9210,
    9249, 9290, 9330, 9370, 9411, 9452, 9493, 9534, 9575, 9617, 9658, 9700,
    9742, 9784, 9827, 9869, 9912, 9955, 9998, 10041, 10085, 10129, 10173, 10217,
    10261, 10305, 10350, 10395, 10440, 10485, 10531, 10576, 10622, 10668, 10714,
    10761, 10807, 10854, 10901, 10949, 10996, 11044, 11091, 11140, 11188, 11236,
    11285, 11334, 11383, 11432, 11482, 11532, 11582, 11632, 11682, 11733, 11784,
    11835, 11886, 11937, 11989, 12041, 12093, 12146, 12198, 12251, 12304, 12358,
    12411, 12465, 12519, 12573, 12628, 12682, 12737, 12793, 12848, 12904, 12960,
    13016, 13072, 13129, 13186, 13243, 13300, 13358, 13416, 13474, 13532, 13591,
    13650, 13709, 13768, 13828, 13888, 13948, 14009, 14069, 14130, 14191, 14253,
    14315, 14377, 14439, 14502, 14564, 14628, 14691, 14755, 14819, 14883, 14947,
    15012, 15077, 15142, 15208, 15274, 15340, 15407, 15473, 15540, 15608, 15675,
    15743, 15812, 15880, 15949, 16018, 16087, 16157, 16227, 16297,
  ],
  880000: [
    3007, 3032, 3057, 3082, 3108, 3134, 3160, 3187, 3213, 3240, 3267, 3294,
    3322, 3349, 3377, 3405, 3434, 3462, 3491, 3520, 3550, 3579, 3609, 3639,
    3662, 3685, 3708, 3731, 3754, 3778, 3801, 3825, 3849, 3873, 3897, 3921,
    3946, 3971, 3995, 4020, 4046, 4071, 4096, 4122, 4148, 4174, 4200, 4226,
    4244, 4263, 4281, 4300, 4318, 4337, 4356, 4375, 4394, 4413, 4432, 4451,
    4470, 4490, 4509, 4529, 4548, 4568, 4588, 4608, 4628, 4648, 4668, 4688,
    4708, 4729, 4749, 4770, 4790, 4811, 4832, 4853, 4874, 4895, 4916, 4938,
    4959, 4981, 5002, 5024, 5046, 5067, 5089, 5111, 5134, 5156, 5178, 5201,
    5223, 5246, 5269, 5291, 5314, 5337, 5360, 5384, 5407, 5430, 5454, 5478,
    5501, 5525, 5549, 5573, 5597, 5622, 5646, 5670, 5695, 5720, 5744, 5769,
    5794, 5819, 5845, 5870, 5895, 5921, 5947, 5972, 5998, 6024, 6050, 6077,
    6103, 6129, 6156, 6183, 6209, 6236, 6263, 6291, 6318, 6345, 6373, 6400,
    6428, 6456, 6484, 6512, 6540, 6568, 6597, 6626, 6654, 6683, 6712, 6741,
    6770, 6800, 6829, 6859, 6888, 6918, 6948, 6978, 7009, 7039, 7070, 7100,
    7131, 7162, 7193, 7224, 7255, 7287, 7318, 7350, 7382, 7414, 7446, 7478,
    7511, 7543, 7576, 7609, 7642, 7675, 7708, 7741, 7775, 7809, 7843, 7877,
    7911, 7945, 7979, 8014, 8049, 8084, 8119, 8154, 8189, 8225, 8260, 8296,
    8332, 8368, 8404, 8441, 8477, 8514, 8551, 8588, 8625, 8663, 8700, 8738,
    8776, 8814, 8852, 8890, 8929, 8968, 9006, 9045, 9085, 9124, 9164, 9203,
    9243, 9283, 9323, 9364, 9404, 9445, 9486, 9527, 9568, 9610, 9652, 9693,
    9735, 9778, 9820, 9862, 9905, 9948, 9991, 10035, 10078, 10122, 10166, 10210,
    10254, 10298, 10343, 10388, 10433, 10478, 10523, 10569, 10615, 10661, 10707,
    10753, 10800, 10847, 10894, 10941, 10988, 11036, 11084, 11132, 11180, 11229,
    11277, 11326, 11375, 11424, 11474, 11524, 11574, 11624, 11674, 11725, 11775,
    11827, 11878, 11929, 11981, 12033, 12085, 12137, 12190, 12243, 12296, 12349,
    12403, 12456, 12510, 12565, 12619, 12674, 12729, 12784, 12839, 12895, 12951,
    13007, 13063, 13120, 13177, 13234, 13291, 13349, 13406, 13465, 13523, 13582,
    13640, 13699, 13759, 13818, 13878, 13938, 13999, 14060, 14120, 14182, 14243,
    14305, 14367, 14429, 14492, 14554, 14617, 14681, 14744, 14808, 14872, 14937,
    15002, 15067, 15132, 15198, 15263, 15330, 15396, 15463, 15530, 15597, 15665,
    15732, 15801, 15869, 15938, 16007, 16076, 16146, 16216, 16286,
  ],
  890000: [
    3004, 3029, 3054, 3079, 3105, 3131, 3157, 3183, 3210, 3237, 3264, 3291,
    3318, 3346, 3374, 3402, 3430, 3459, 3488, 3517, 3546, 3576, 3605, 3635,
    3658, 3681, 3704, 3727, 3750, 3774, 3798, 3821, 3845, 3869, 3893, 3918,
    3942, 3967, 3992, 4017, 4042, 4067, 4092, 4118, 4144, 4170, 4196, 4222,
    4240, 4258, 4277, 4295, 4314, 4333, 4352, 4370, 4389, 4408, 4427, 4447,
    4466, 4485, 4505, 4524, 4544, 4564, 4583, 4603, 4623, 4643, 4663, 4683,
    4704, 4724, 4745, 4765, 4786, 4807, 4827, 4848, 4869, 4890, 4912, 4933,
    4954, 4976, 4997, 5019, 5041, 5063, 5085, 5107, 5129, 5151, 5173, 5196,
    5218, 5241, 5263, 5286, 5309, 5332, 5355, 5379, 5402, 5425, 5449, 5472,
    5496, 5520, 5544, 5568, 5592, 5616, 5641, 5665, 5689, 5714, 5739, 5764,
    5789, 5814, 5839, 5864, 5890, 5915, 5941, 5967, 5993, 6018, 6045, 6071,
    6097, 6123, 6150, 6177, 6203, 6230, 6257, 6284, 6312, 6339, 6366, 6394,
    6422, 6450, 6478, 6506, 6534, 6562, 6591, 6619, 6648, 6677, 6706, 6735,
    6764, 6793, 6823, 6852, 6882, 6912, 6942, 6972, 7002, 7032, 7063, 7093,
    7124, 7155, 7186, 7217, 7248, 7280, 7311, 7343, 7375, 7407, 7439, 7471,
    7503, 7536, 7569, 7601, 7634, 7667, 7701, 7734, 7767, 7801, 7835, 7869,
    7903, 7937, 7972, 8006, 8041, 8076, 8111, 8146, 8181, 8217, 8252, 8288,
    8324, 8360, 8396, 8433, 8469, 8506, 8543, 8580, 8617, 8654, 8692, 8729,
    8767, 8805, 8843, 8882, 8920, 8959, 8998, 9037, 9076, 9115, 9155, 9194,
    9234, 9274, 9314, 9355, 9395, 9436, 9477, 9518, 9559, 9601, 9642, 9684,
    9726, 9768, 9810, 9853, 9896, 9938, 9982, 10025, 10068, 10112, 10156, 10200,
    10244, 10288, 10333, 10378, 10423, 10468, 10513, 10559, 10604, 10650, 10697,
    10743, 10789, 10836, 10883, 10930, 10978, 11025, 11073, 11121, 11169, 11218,
    11266, 11315, 11364, 11413, 11463, 11512, 11562, 11612, 11663, 11713, 11764,
    11815, 11866, 11918, 11969, 12021, 12073, 12126, 12178, 12231, 12284, 12337,
    12391, 12444, 12498, 12552, 12607, 12661, 12716, 12771, 12827, 12882, 12938,
    12994, 13050, 13107, 13164, 13221, 13278, 13336, 13393, 13452, 13510, 13568,
    13627, 13686, 13745, 13805, 13865, 13925, 13985, 14046, 14107, 14168, 14229,
    14291, 14353, 14415, 14478, 14540, 14603, 14667, 14730, 14794, 14858, 14922,
    14987, 15052, 15117, 15183, 15249, 15315, 15381, 15448, 15515, 15582, 15649,
    15717, 15785, 15854, 15922, 15991, 16061, 16130, 16200, 16270,
  ],
  900000: [
    3000, 3025, 3050, 3076, 3101, 3127, 3153, 3179, 3206, 3233, 3260, 3287,
    3314, 3342, 3370, 3398, 3426, 3455, 3483, 3512, 3542, 3571, 3601, 3631,
    3654, 3676, 3699, 3723, 3746, 3769, 3793, 3816, 3840, 3864, 3888, 3913,
    3937, 3962, 3987, 4012, 4037, 4062, 4087, 4113, 4138, 4164, 4190, 4217,
    4235, 4253, 4272, 4290, 4309, 4327, 4346, 4365, 4384, 4403, 4422, 4441,
    4460, 4480, 4499, 4519, 4538, 4558, 4578, 4597, 4617, 4637, 4657, 4678,
    4698, 4718, 4739, 4759, 4780, 4801, 4821, 4842, 4863, 4884, 4905, 4927,
    4948, 4970, 4991, 5013, 5034, 5056, 5078, 5100, 5122, 5144, 5167, 5189,
    5212, 5234, 5257, 5280, 5303, 5326, 5349, 5372, 5395, 5418, 5442, 5466,
    5489, 5513, 5537, 5561, 5585, 5609, 5633, 5658, 5682, 5707, 5732, 5757,
    5782, 5807, 5832, 5857, 5882, 5908, 5933, 5959, 5985, 6011, 6037, 6063,
    6089, 6116, 6142, 6169, 6196, 6223, 6249, 6277, 6304, 6331, 6359, 6386,
    6414, 6442, 6469, 6497, 6526, 6554, 6582, 6611, 6639, 6668, 6697, 6726,
    6755, 6785, 6814, 6844, 6873, 6903, 6933, 6963, 6993, 7023, 7054, 7084,
    7115, 7146, 7177, 7208, 7239, 7271, 7302, 7334, 7366, 7397, 7429, 7462,
    7494, 7526, 7559, 7592, 7625, 7658, 7691, 7724, 7758, 7791, 7825, 7859,
    7893, 7927, 7962, 7996, 8031, 8066, 8101, 8136, 8171, 8206, 8242, 8278,
    8314, 8350, 8386, 8422, 8459, 8495, 8532, 8569, 8606, 8643, 8681, 8718,
    8756, 8794, 8832, 8871, 8909, 8948, 8986, 9025, 9064, 9104, 9143, 9183,
    9223, 9263, 9303, 9343, 9383, 9424, 9465, 9506, 9547, 9589, 9630, 9672,
    9714, 9756, 9798, 9841, 9883, 9926, 9969, 10012, 10056, 10099, 10143, 10187,
    10231, 10275, 10320, 10365, 10410, 10455, 10500, 10546, 10591, 10637, 10683,
    10729, 10776, 10823, 10870, 10917, 10964, 11011, 11059, 11107, 11155, 11204,
    11252, 11301, 11350, 11399, 11448, 11498, 11548, 11598, 11648, 11699, 11749,
    11800, 11851, 11903, 11954, 12006, 12058, 12110, 12163, 12216, 12269, 12322,
    12375, 12429, 12483, 12537, 12591, 12646, 12700, 12755, 12811, 12866, 12922,
    12978, 13034, 13091, 13147, 13204, 13262, 13319, 13377, 13435, 13493, 13551,
    13610, 13669, 13728, 13788, 13848, 13908, 13968, 14028, 14089, 14150, 14212,
    14273, 14335, 14397, 14459, 14522, 14585, 14648, 14712, 14775, 14840, 14904,
    14968, 15033, 15098, 15164, 15230, 15296, 15362, 15428, 15495, 15562, 15630,
    15698, 15766, 15834, 15903, 15971, 16041, 16110, 16180, 16250,
  ],
  910000: [
    2995, 3020, 3046, 3071, 3097, 3122, 3148, 3175, 3201, 3228, 3255, 3282,
    3309, 3337, 3364, 3392, 3421, 3449, 3478, 3507, 3536, 3566, 3595, 3625,
    3648, 3671, 3694, 3717, 3740, 3763, 3787, 3811, 3834, 3858, 3883, 3907,
    3931, 3956, 3981, 4005, 4030, 4056, 4081, 4106, 4132, 4158, 4184, 4210,
    4228, 4247, 4265, 4284, 4302, 4321, 4339, 4358, 4377, 4396, 4415, 4434,
    4454, 4473, 4492, 4512, 4531, 4551, 4571, 4590, 4610, 4630, 4650, 4670,
    4691, 4711, 4731, 4752, 4773, 4793, 4814, 4835, 4856, 4877, 4898, 4919,
    4941, 4962, 4983, 5005, 5027, 5049, 5070, 5092, 5114, 5137, 5159, 5181,
    5204, 5226, 5249, 5272, 5294, 5317, 5340, 5364, 5387, 5410, 5434, 5457,
    5481, 5505, 5528, 5552, 5576, 5601, 5625, 5649, 5674, 5698, 5723, 5748,
    5773, 5798, 5823, 5848, 5873, 5899, 5924, 5950, 5976, 6002, 6028, 6054,
    6080, 6106, 6133, 6160, 6186, 6213, 6240, 6267, 6294, 6321, 6349, 6376,
    6404, 6432, 6460, 6488, 6516, 6544, 6572, 6601, 6629, 6658, 6687, 6716,
    6745, 6774, 6804, 6833, 6863, 6892, 6922, 6952, 6982, 7013, 7043, 7074,
    7104, 7135, 7166, 7197, 7228, 7259, 7291, 7323, 7354, 7386, 7418, 7450,
    7483, 7515, 7548, 7580, 7613, 7646, 7679, 7713, 7746, 7780, 7813, 7847,
    7881, 7915, 7950, 7984, 8019, 8053, 8088, 8123, 8158, 8194, 8229, 8265,
    8301, 8337, 8373, 8409, 8446, 8482, 8519, 8556, 8593, 8630, 8668, 8705,
    8743, 8781, 8819, 8857, 8895, 8934, 8973, 9012, 9051, 9090, 9129, 9169,
    9209, 9248, 9288, 9329, 9369, 9410, 9451, 9491, 9533, 9574, 9615, 9657,
    9699, 9741, 9783, 9826, 9868, 9911, 9954, 9997, 10040, 10084, 10128, 10171,
    10215, 10260, 10304, 10349, 10394, 10439, 10484, 10529, 10575, 10621, 10667,
    10713, 10760, 10806, 10853, 10900, 10947, 10995, 11042, 11090, 11138, 11186,
    11235, 11284, 11333, 11382, 11431, 11481, 11530, 11580, 11630, 11681, 11731,
    11782, 11833, 11885, 11936, 11988, 12040, 12092, 12144, 12197, 12250, 12303,
    12356, 12410, 12464, 12518, 12572, 12626, 12681, 12736, 12791, 12847, 12902,
    12958, 13014, 13071, 13127, 13184, 13241, 13299, 13356, 13414, 13472, 13531,
    13589, 13648, 13707, 13767, 13826, 13886, 13947, 14007, 14068, 14129, 14190,
    14251, 14313, 14375, 14437, 14500, 14563, 14626, 14689, 14753, 14817, 14881,
    14946, 15010, 15075, 15141, 15206, 15272, 15338, 15405, 15472, 15539, 15606,
    15674, 15741, 15810, 15878, 15947, 16016, 16086, 16155, 16225,
  ],
  920000: [
    2990, 3015, 3040, 3065, 3091, 3117, 3143, 3169, 3195, 3222, 3249, 3276,
    3303, 3331, 3358, 3386, 3415, 3443, 3472, 3501, 3530, 3559, 3589, 3619,
    3641, 3664, 3687, 3710, 3733, 3757, 3780, 3804, 3828, 3851, 3876, 3900,
    3924, 3949, 3973, 3998, 4023, 4048, 4074, 4099, 4125, 4150, 4176, 4202,
    4221, 4239, 4257, 4276, 4294, 4313, 4332, 4350, 4369, 4388, 4407, 4426,
    4445, 4465, 4484, 4504, 4523, 4543, 4562, 4582, 4602, 4622, 4642, 4662,
    4682, 4703, 4723, 4743, 4764, 4785, 4805, 4826, 4847, 4868, 4889, 4910,
    4932, 4953, 4974, 4996, 5018, 5039, 5061, 5083, 5105, 5127, 5150, 5172,
    5194, 5217, 5239, 5262, 5285, 5308, 5331, 5354, 5377, 5400, 5424, 5447,
    5471, 5495, 5518, 5542, 5566, 5590, 5615, 5639, 5663, 5688, 5713, 5737,
    5762, 5787, 5812, 5837, 5863, 5888, 5914, 5939, 5965, 5991, 6017, 6043,
    6069, 6095, 6122, 6148, 6175, 6202, 6229, 6256, 6283, 6310, 6337, 6365,
    6392, 6420, 6448, 6476, 6504, 6532, 6560, 6589, 6617, 6646, 6675, 6704,
    6733, 6762, 6791, 6821, 6850, 6880, 6910, 6940, 6970, 7000, 7030, 7061,
    7091, 7122, 7153, 7184, 7215, 7246, 7278, 7309, 7341, 7373, 7405, 7437,
    7469, 7501, 7534, 7567, 7599, 7632, 7665, 7699, 7732, 7765, 7799, 7833,
    7867, 7901, 7935, 7970, 8004, 8039, 8074, 8109, 8144, 8179, 8214, 8250,
    8286, 8322, 8358, 8394, 8430, 8467, 8504, 8540, 8577, 8615, 8652, 8689,
    8727, 8765, 8803, 8841, 8879, 8918, 8956, 8995, 9034, 9073, 9113, 9152,
    9192, 9232, 9272, 9312, 9352, 9393, 9433, 9474, 9515, 9557, 9598, 9640,
    9681, 9723, 9765, 9808, 9850, 9893, 9936, 9979, 10022, 10066, 10109, 10153,
    10197, 10241, 10286, 10330, 10375, 10420, 10465, 10510, 10556, 10602, 10648,
    10694, 10740, 10787, 10833, 10880, 10927, 10975, 11022, 11070, 11118, 11166,
    11215, 11263, 11312, 11361, 11410, 11460, 11509, 11559, 11609, 11660, 11710,
    11761, 11812, 11863, 11915, 11966, 12018, 12070, 12122, 12175, 12228, 12281,
    12334, 12387, 12441, 12495, 12549, 12603, 12658, 12713, 12768, 12823, 12879,
    12935, 12991, 13047, 13104, 13160, 13217, 13275, 13332, 13390, 13448, 13506,
    13565, 13624, 13683, 13742, 13801, 13861, 13921, 13982, 14042, 14103, 14164,
    14226, 14287, 14349, 14411, 14474, 14536, 14599, 14663, 14726, 14790, 14854,
    14919, 14983, 15048, 15113, 15179, 15245, 15311, 15377, 15444, 15511, 15578,
    15645, 15713, 15781, 15850, 15918, 15987, 16056, 16126, 16196,
  ],
  930000: [
    2984, 3009, 3034, 3059, 3084, 3110, 3136, 3162, 3189, 3215, 3242, 3269,
    3296, 3324, 3351, 3379, 3407, 3436, 3464, 3493, 3522, 3552, 3581, 3611,
    3634, 3657, 3679, 3702, 3726, 3749, 3772, 3796, 3820, 3843, 3867, 3892,
    3916, 3940, 3965, 3990, 4015, 4040, 4065, 4090, 4116, 4142, 4168, 4194,
    4212, 4230, 4248, 4267, 4285, 4304, 4323, 4341, 4360, 4379, 4398, 4417,
    4436, 4455, 4475, 4494, 4514, 4533, 4553, 4573, 4592, 4612, 4632, 4652,
    4672, 4693, 4713, 4733, 4754, 4775, 4795, 4816, 4837, 4858, 4879, 4900,
    4921, 4943, 4964, 4986, 5007, 5029, 5051, 5073, 5095, 5117, 5139, 5161,
    5183, 5206, 5228, 5251, 5274, 5297, 5320, 5343, 5366, 5389, 5412, 5436,
    5459, 5483, 5507, 5531, 5555, 5579, 5603, 5627, 5652, 5676, 5701, 5725,
    5750, 5775, 5800, 5825, 5851, 5876, 5901, 5927, 5953, 5978, 6004, 6030,
    6056, 6083, 6109, 6136, 6162, 6189, 6216, 6243, 6270, 6297, 6324, 6351,
    6379, 6407, 6434, 6462, 6490, 6518, 6547, 6575, 6604, 6632, 6661, 6690,
    6719, 6748, 6777, 6806, 6836, 6866, 6895, 6925, 6955, 6985, 7016, 7046,
    7077, 7107, 7138, 7169, 7200, 7231, 7263, 7294, 7326, 7357, 7389, 7421,
    7453, 7486, 7518, 7551, 7583, 7616, 7649, 7682, 7716, 7749, 7783, 7817,
    7850, 7884, 7919, 7953, 7987, 8022, 8057, 8092, 8127, 8162, 8197, 8233,
    8268, 8304, 8340, 8376, 8413, 8449, 8486, 8523, 8560, 8597, 8634, 8671,
    8709, 8747, 8784, 8823, 8861, 8899, 8938, 8976, 9015, 9054, 9094, 9133,
    9173, 9212, 9252, 9292, 9333, 9373, 9414, 9455, 9495, 9537, 9578, 9619,
    9661, 9703, 9745, 9787, 9830, 9872, 9915, 9958, 10001, 10045, 10088, 10132,
    10176, 10220, 10264, 10309, 10353, 10398, 10443, 10488, 10534, 10579, 10625,
    10671, 10718, 10764, 10811, 10858, 10905, 10952, 10999, 11047, 11095, 11143,
    11191, 11240, 11288, 11337, 11386, 11436, 11485, 11535, 11585, 11635, 11686,
    11736, 11787, 11838, 11890, 11941, 11993, 12045, 12097, 12149, 12202, 12255,
    12308, 12361, 12415, 12469, 12523, 12577, 12632, 12686, 12741, 12796, 12852,
    12908, 12964, 13020, 13076, 13133, 13190, 13247, 13304, 13362, 13420, 13478,
    13536, 13595, 13654, 13713, 13773, 13832, 13892, 13952, 14013, 14074, 14135,
    14196, 14257, 14319, 14381, 14443, 14506, 14569, 14632, 14695, 14759, 14823,
    14887, 14952, 15017, 15082, 15147, 15213, 15279, 15345, 15411, 15478, 15545,
    15613, 15680, 15748, 15816, 15885, 15954, 16023, 16092, 16162,
  ],
  940000: [
    2977, 3001, 3026, 3052, 3077, 3103, 3129, 3155, 3181, 3208, 3234, 3261,
    3288, 3316, 3343, 3371, 3399, 3428, 3456, 3485, 3514, 3543, 3573, 3603,
    3625, 3648, 3671, 3694, 3717, 3740, 3763, 3787, 3810, 3834, 3858, 3882,
    3907, 3931, 3956, 3980, 4005, 4030, 4055, 4081, 4106, 4132, 4158, 4184,
    4202, 4220, 4238, 4257, 4275, 4294, 4312, 4331, 4350, 4369, 4388, 4407,
    4426, 4445, 4464, 4483, 4503, 4522, 4542, 4562, 4581, 4601, 4621, 4641,
    4661, 4682, 4702, 4722, 4743, 4763, 4784, 4805, 4825, 4846, 4867, 4888,
    4910, 4931, 4952, 4974, 4995, 5017, 5039, 5060, 5082, 5104, 5127, 5149,
    5171, 5194, 5216, 5239, 5261, 5284, 5307, 5330, 5353, 5376, 5400, 5423,
    5446, 5470, 5494, 5518, 5542, 5566, 5590, 5614, 5638, 5663, 5687, 5712,
    5737, 5761, 5786, 5811, 5837, 5862, 5887, 5913, 5938, 5964, 5990, 6016,
    6042, 6068, 6095, 6121, 6147, 6174, 6201, 6228, 6255, 6282, 6309, 6336,
    6364, 6391, 6419, 6447, 6475, 6503, 6531, 6559, 6588, 6616, 6645, 6674,
    6703, 6732, 6761, 6790, 6820, 6849, 6879, 6909, 6939, 6969, 6999, 7029,
    7060, 7090, 7121, 7152, 7183, 7214, 7245, 7277, 7308, 7340, 7372, 7404,
    7436, 7468, 7500, 7533, 7565, 7598, 7631, 7664, 7697, 7731, 7764, 7798,
    7832, 7866, 7900, 7934, 7968, 8003, 8038, 8072, 8107, 8143, 8178, 8213,
    8249, 8285, 8320, 8357, 8393, 8429, 8466, 8502, 8539, 8576, 8613, 8651,
    8688, 8726, 8764, 8802, 8840, 8878, 8917, 8955, 8994, 9033, 9072, 9111,
    9151, 9191, 9230, 9270, 9311, 9351, 9391, 9432, 9473, 9514, 9555, 9597,
    9638, 9680, 9722, 9764, 9806, 9849, 9892, 9934, 9977, 10021, 10064, 10108,
    10152, 10196, 10240, 10284, 10329, 10373, 10418, 10463, 10509, 10554, 10600,
    10646, 10692, 10739, 10785, 10832, 10879, 10926, 10973, 11021, 11069, 11116,
    11165, 11213, 11262, 11310, 11359, 11409, 11458, 11508, 11558, 11608, 11658,
    11708, 11759, 11810, 11861, 11913, 11964, 12016, 12068, 12121, 12173, 12226,
    12279, 12332, 12386, 12439, 12493, 12547, 12602, 12656, 12711, 12766, 12821,
    12877, 12933, 12989, 13045, 13102, 13158, 13215, 13273, 13330, 13388, 13446,
    13504, 13563, 13622, 13681, 13740, 13799, 13859, 13919, 13980, 14040, 14101,
    14162, 14223, 14285, 14347, 14409, 14472, 14534, 14597, 14661, 14724, 14788,
    14852, 14916, 14981, 15046, 15111, 15177, 15242, 15308, 15375, 15441, 15508,
    15575, 15643, 15711, 15779, 15847, 15916, 15985, 16054, 16124,
  ],
  950000: [
    3167, 3193, 3220, 3246, 3274, 3301, 3328, 3356, 3384, 3412, 3441, 3469,
    3498, 3527, 3557, 3586, 3616, 3646, 3677, 3707, 3738, 3770, 3801, 3833,
    3857, 3881, 3905, 3929, 3954, 3979, 4003, 4029, 4054, 4079, 4105, 4130,
    4156, 4182, 4208, 4234, 4261, 4287, 4314, 4341, 4368, 4396, 4423, 4451,
    4470, 4489, 4509, 4528, 4548, 4568, 4588, 4607, 4627, 4647, 4668, 4688,
    4708, 4729, 4749, 4770, 4790, 4811, 4832, 4853, 4874, 4895, 4916, 4938,
    4959, 4980, 5002, 5024, 5045, 5067, 5089, 5111, 5133, 5156, 5178, 5200,
    5223, 5246, 5268, 5291, 5314, 5337, 5360, 5384, 5407, 5430, 5454, 5477,
    5501, 5525, 5549, 5573, 5597, 5621, 5646, 5670, 5695, 5719, 5744, 5769,
    5794, 5819, 5844, 5870, 5895, 5921, 5946, 5972, 5998, 6024, 6050, 6076,
    6103, 6129, 6156, 6182, 6209, 6236, 6263, 6290, 6318, 6345, 6372, 6400,
    6428, 6456, 6484, 6512, 6540, 6568, 6597, 6625, 6654, 6683, 6712, 6741,
    6770, 6799, 6829, 6858, 6888, 6918, 6948, 6978, 7008, 7039, 7069, 7100,
    7131, 7162, 7193, 7224, 7255, 7286, 7318, 7350, 7382, 7414, 7446, 7478,
    7510, 7543, 7576, 7608, 7641, 7675, 7708, 7741, 7775, 7808, 7842, 7876,
    7910, 7945, 7979, 8014, 8048, 8083, 8118, 8153, 8189, 8224, 8260, 8296,
    8332, 8368, 8404, 8440, 8477, 8514, 8551, 8588, 8625, 8662, 8700, 8738,
    8775, 8813, 8852, 8890, 8928, 8967, 9006, 9045, 9084, 9124, 9163, 9203,
    9243, 9283, 9323, 9363, 9404, 9445, 9486, 9527, 9568, 9609, 9651, 9693,
    9735, 9777, 9820, 9862, 9905, 9948, 9991, 10034, 10078, 10121, 10165, 10209,
    10253, 10298, 10342, 10387, 10432, 10478, 10523, 10569, 10614, 10660, 10707,
    10753, 10799, 10846, 10893, 10940, 10988, 11036, 11083, 11131, 11180, 11228,
    11277, 11326, 11375, 11424, 11473, 11523, 11573, 11623, 11674, 11724, 11775,
    11826, 11877, 11929, 11980, 12032, 12084, 12137, 12189, 12242, 12295, 12349,
    12402, 12456, 12510, 12564, 12618, 12673, 12728, 12783, 12839, 12894, 12950,
    13006, 13063, 13119, 13176, 13233, 13291, 13348, 13406, 13464, 13522, 13581,
    13640, 13699, 13758, 13818, 13878, 13938, 13998, 14059, 14120, 14181, 14243,
    14304, 14366, 14428, 14491, 14554, 14617, 14680, 14744, 14808, 14872, 14936,
    15001, 15066, 15131, 15197, 15263, 15329, 15395, 15462, 15529, 15596, 15664,
    15732, 15800, 15868, 15937, 16006, 16076, 16145, 16215, 16286, 16356, 16427,
    16498, 16570, 16641, 16714, 16786, 16859, 16932, 17005, 17079, 17153,
  ],
  960000: [
    3200, 3227, 3254, 3281, 3308, 3336, 3363, 3391, 3420, 3448, 3477, 3506,
    3535, 3565, 3594, 3624, 3654, 3685, 3716, 3747, 3778, 3809, 3841, 3873,
    3897, 3922, 3946, 3971, 3996, 4021, 4046, 4071, 4096, 4122, 4148, 4174,
    4200, 4226, 4252, 4279, 4306, 4333, 4360, 4387, 4414, 4442, 4470, 4498,
    4517, 4537, 4556, 4576, 4596, 4616, 4636, 4656, 4676, 4696, 4717, 4737,
    4758, 4778, 4799, 4820, 4841, 4862, 4883, 4904, 4925, 4947, 4968, 4989,
    5011, 5033, 5055, 5077, 5099, 5121, 5143, 5165, 5187, 5210, 5233, 5255,
    5278, 5301, 5324, 5347, 5370, 5393, 5417, 5440, 5464, 5487, 5511, 5535,
    5559, 5583, 5607, 5632, 5656, 5681, 5705, 5730, 5755, 5780, 5805, 5830,
    5855, 5881, 5906, 5932, 5957, 5983, 6009, 6035, 6061, 6087, 6114, 6140,
    6167, 6194, 6221, 6247, 6275, 6302, 6329, 6356, 6384, 6412, 6439, 6467,
    6495, 6524, 6552, 6580, 6609, 6637, 6666, 6695, 6724, 6753, 6782, 6812,
    6841, 6871, 6901, 6931, 6961, 6991, 7021, 7052, 7082, 7113, 7144, 7175,
    7206, 7237, 7268, 7300, 7331, 7363, 7395, 7427, 7459, 7492, 7524, 7557,
    7589, 7622, 7655, 7689, 7722, 7755, 7789, 7823, 7857, 7891, 7925, 7959,
    7994, 8028, 8063, 8098, 8133, 8168, 8204, 8239, 8275, 8311, 8347, 8383,
    8419, 8456, 8492, 8529, 8566, 8603, 8641, 8678, 8716, 8753, 8791, 8829,
    8868, 8906, 8945, 8984, 9022, 9062, 9101, 9140, 9180, 9220, 9260, 9300,
    9340, 9380, 9421, 9462, 9503, 9544, 9585, 9627, 9669, 9711, 9753, 9795,
    9837, 9880, 9923, 9966, 10009, 10052, 10096, 10140, 10184, 10228, 10272,
    10317, 10361, 10406, 10451, 10497, 10542, 10588, 10634, 10680, 10726, 10773,
    10819, 10866, 10913, 10960, 11008, 11056, 11104, 11152, 11200, 11249, 11297,
    11346, 11395, 11445, 11494, 11544, 11594, 11644, 11695, 11746, 11796, 11848,
    11899, 11951, 12002, 12054, 12107, 12159, 12212, 12265, 12318, 12371, 12425,
    12479, 12533, 12587, 12642, 12696, 12751, 12807, 12862, 12918, 12974, 13030,
    13086, 13143, 13200, 13257, 13315, 13372, 13430, 13489, 13547, 13606, 13665,
    13724, 13783, 13843, 13903, 13963, 14024, 14085, 14146, 14207, 14269, 14330,
    14392, 14455, 14517, 14580, 14644, 14707, 14771, 14835, 14899, 14964, 15028,
    15094, 15159, 15225, 15291, 15357, 15423, 15490, 15557, 15625, 15693, 15761,
    15829, 15897, 15966, 16035, 16105, 16175, 16245, 16315, 16386, 16457, 16528,
    16600, 16672, 16744, 16817, 16889, 16963, 17036, 17110, 17184, 17259, 17333,
  ],
  970000: [
    3233, 3260, 3287, 3315, 3342, 3370, 3398, 3427, 3455, 3484, 3513, 3542,
    3572, 3602, 3632, 3662, 3692, 3723, 3754, 3786, 3817, 3849, 3881, 3913,
    3938, 3962, 3987, 4012, 4037, 4062, 4088, 4113, 4139, 4165, 4191, 4217,
    4243, 4270, 4297, 4324, 4351, 4378, 4405, 4433, 4460, 4488, 4516, 4545,
    4564, 4584, 4604, 4624, 4644, 4664, 4684, 4704, 4725, 4745, 4766, 4787,
    4807, 4828, 4849, 4870, 4891, 4912, 4934, 4955, 4976, 4998, 5020, 5041,
    5063, 5085, 5107, 5129, 5152, 5174, 5196, 5219, 5242, 5264, 5287, 5310,
    5333, 5356, 5379, 5403, 5426, 5450, 5473, 5497, 5521, 5545, 5569, 5593,
    5617, 5641, 5666, 5690, 5715, 5740, 5765, 5790, 5815, 5840, 5865, 5891,
    5916, 5942, 5968, 5993, 6019, 6045, 6072, 6098, 6124, 6151, 6178, 6204,
    6231, 6258, 6285, 6313, 6340, 6367, 6395, 6423, 6451, 6478, 6507, 6535,
    6563, 6591, 6620, 6649, 6678, 6706, 6736, 6765, 6794, 6824, 6853, 6883,
    6913, 6943, 6973, 7003, 7033, 7064, 7094, 7125, 7156, 7187, 7218, 7249,
    7281, 7312, 7344, 7376, 7408, 7440, 7472, 7504, 7537, 7570, 7602, 7635,
    7668, 7702, 7735, 7769, 7802, 7836, 7870, 7904, 7938, 7973, 8007, 8042,
    8077, 8112, 8147, 8182, 8218, 8253, 8289, 8325, 8361, 8397, 8434, 8470,
    8507, 8544, 8581, 8618, 8655, 8693, 8731, 8768, 8806, 8845, 8883, 8921,
    8960, 8999, 9038, 9077, 9116, 9156, 9196, 9235, 9275, 9316, 9356, 9397,
    9437, 9478, 9519, 9561, 9602, 9644, 9685, 9727, 9769, 9812, 9854, 9897,
    9940, 9983, 10026, 10070, 10113, 10157, 10201, 10245, 10290, 10334, 10379,
    10424, 10469, 10515, 10560, 10606, 10652, 10698, 10744, 10791, 10838, 10885,
    10932, 10979, 11027, 11075, 11123, 11171, 11219, 11268, 11317, 11366, 11415,
    11464, 11514, 11564, 11614, 11664, 11715, 11766, 11817, 11868, 11919, 11971,
    12023, 12075, 12127, 12180, 12233, 12286, 12339, 12392, 12446, 12500, 12554,
    12609, 12663, 12718, 12773, 12829, 12884, 12940, 12996, 13052, 13109, 13166,
    13223, 13280, 13338, 13395, 13453, 13512, 13570, 13629, 13688, 13747, 13807,
    13867, 13927, 13987, 14048, 14109, 14170, 14231, 14293, 14355, 14417, 14480,
    14542, 14605, 14669, 14732, 14796, 14860, 14925, 14989, 15054, 15119, 15185,
    15251, 15317, 15383, 15450, 15517, 15584, 15652, 15719, 15788, 15856, 15925,
    15994, 16063, 16133, 16203, 16273, 16343, 16414, 16485, 16557, 16628, 16700,
    16773, 16845, 16918, 16992, 17065, 17139, 17214, 17288, 17363, 17438, 17514,
  ],
  980000: [
    3267, 3294, 3321, 3349, 3377, 3405, 3433, 3462, 3491, 3520, 3549, 3579,
    3609, 3639, 3669, 3700, 3731, 3762, 3793, 3825, 3856, 3889, 3921, 3954,
    3978, 4003, 4028, 4053, 4079, 4104, 4130, 4156, 4182, 4208, 4234, 4261,
    4287, 4314, 4341, 4368, 4395, 4423, 4451, 4478, 4506, 4534, 4563, 4591,
    4611, 4631, 4651, 4671, 4692, 4712, 4732, 4753, 4774, 4794, 4815, 4836,
    4857, 4878, 4899, 4920, 4942, 4963, 4984, 5006, 5028, 5050, 5071, 5093,
    5115, 5138, 5160, 5182, 5205, 5227, 5250, 5273, 5296, 5318, 5342, 5365,
    5388, 5411, 5435, 5458, 5482, 5506, 5530, 5554, 5578, 5602, 5626, 5650,
    5675, 5699, 5724, 5749, 5774, 5799, 5824, 5849, 5875, 5900, 5926, 5951,
    5977, 6003, 6029, 6055, 6081, 6108, 6134, 6161, 6187, 6214, 6241, 6268,
    6295, 6323, 6350, 6378, 6405, 6433, 6461, 6489, 6517, 6545, 6574, 6602,
    6631, 6659, 6688, 6717, 6746, 6776, 6805, 6834, 6864, 6894, 6924, 6954,
    6984, 7014, 7045, 7075, 7106, 7136, 7167, 7198, 7230, 7261, 7292, 7324,
    7356, 7388, 7420, 7452, 7484, 7517, 7549, 7582, 7615, 7648, 7681, 7714,
    7748, 7781, 7815, 7849, 7883, 7917, 7951, 7986, 8020, 8055, 8090, 8125,
    8160, 8196, 8231, 8267, 8303, 8339, 8375, 8411, 8447, 8484, 8521, 8558,
    8595, 8632, 8669, 8707, 8745, 8783, 8821, 8859, 8897, 8936, 8975, 9013,
    9052, 9092, 9131, 9171, 9210, 9250, 9290, 9331, 9371, 9412, 9452, 9493,
    9535, 9576, 9617, 9659, 9701, 9743, 9785, 9828, 9870, 9913, 9956, 9999,
    10042, 10086, 10130, 10174, 10218, 10262, 10306, 10351, 10396, 10441, 10486,
    10532, 10577, 10623, 10669, 10715, 10762, 10808, 10855, 10902, 10950, 10997,
    11045, 11092, 11141, 11189, 11237, 11286, 11335, 11384, 11433, 11483, 11533,
    11583, 11633, 11683, 11734, 11785, 11836, 11887, 11939, 11990, 12042, 12094,
    12147, 12199, 12252, 12305, 12359, 12412, 12466, 12520, 12574, 12629, 12684,
    12739, 12794, 12849, 12905, 12961, 13017, 13073, 13130, 13187, 13244, 13301,
    13359, 13417, 13475, 13534, 13592, 13651, 13710, 13770, 13829, 13889, 13949,
    14010, 14071, 14132, 14193, 14254, 14316, 14378, 14440, 14503, 14566, 14629,
    14692, 14756, 14820, 14884, 14949, 15013, 15078, 15144, 15209, 15275, 15342,
    15408, 15475, 15542, 15609, 15677, 15745, 15813, 15882, 15950, 16019, 16089,
    16159, 16229, 16299, 16370, 16440, 16512, 16583, 16655, 16727, 16800, 16873,
    16946, 17019, 17093, 17167, 17241, 17316, 17391, 17466, 17542, 17618, 17695,
  ],
  990000: [
    3300, 3328, 3355, 3383, 3411, 3440, 3468, 3497, 3527, 3556, 3586, 3615,
    3646, 3676, 3707, 3737, 3769, 3800, 3832, 3864, 3896, 3928, 3961, 3994,
    4019, 4044, 4069, 4095, 4120, 4146, 4172, 4198, 4224, 4251, 4277, 4304,
    4331, 4358, 4385, 4413, 4440, 4468, 4496, 4524, 4552, 4581, 4609, 4638,
    4658, 4678, 4699, 4719, 4740, 4760, 4781, 4801, 4822, 4843, 4864, 4885,
    4906, 4928, 4949, 4970, 4992, 5014, 5035, 5057, 5079, 5101, 5123, 5145,
    5168, 5190, 5213, 5235, 5258, 5281, 5304, 5327, 5350, 5373, 5396, 5419,
    5443, 5467, 5490, 5514, 5538, 5562, 5586, 5610, 5634, 5659, 5683, 5708,
    5733, 5758, 5783, 5808, 5833, 5858, 5883, 5909, 5935, 5960, 5986, 6012,
    6038, 6064, 6091, 6117, 6143, 6170, 6197, 6224, 6251, 6278, 6305, 6332,
    6360, 6387, 6415, 6443, 6471, 6499, 6527, 6555, 6584, 6612, 6641, 6669,
    6698, 6727, 6757, 6786, 6815, 6845, 6874, 6904, 6934, 6964, 6994, 7025,
    7055, 7086, 7116, 7147, 7178, 7209, 7241, 7272, 7303, 7335, 7367, 7399,
    7431, 7463, 7495, 7528, 7560, 7593, 7626, 7659, 7692, 7726, 7759, 7793,
    7827, 7861, 7895, 7929, 7963, 7998, 8032, 8067, 8102, 8137, 8172, 8208,
    8243, 8279, 8315, 8351, 8387, 8424, 8460, 8497, 8534, 8571, 8608, 8645,
    8682, 8720, 8758, 8796, 8834, 8872, 8911, 8949, 8988, 9027, 9066, 9105,
    9145, 9184, 9224, 9264, 9304, 9345, 9385, 9426, 9467, 9508, 9549, 9590,
    9632, 9674, 9716, 9758, 9800, 9842, 9885, 9928, 9971, 10014, 10058, 10101,
    10145, 10189, 10233, 10277, 10322, 10367, 10411, 10457, 10502, 10547, 10593,
    10639, 10685, 10731, 10778, 10825, 10872, 10919, 10966, 11014, 11061, 11109,
    11157, 11206, 11254, 11303, 11352, 11401, 11451, 11500, 11550, 11600, 11650,
    11701, 11752, 11802, 11854, 11905, 11957, 12008, 12060, 12113, 12165, 12218,
    12271, 12324, 12377, 12431, 12485, 12539, 12593, 12648, 12703, 12758, 12813,
    12869, 12924, 12980, 13037, 13093, 13150, 13207, 13264, 13321, 13379, 13437,
    13495, 13554, 13613, 13672, 13731, 13790, 13850, 13910, 13970, 14031, 14092,
    14153, 14214, 14276, 14338, 14400, 14462, 14525, 14588, 14651, 14714, 14778,
    14842, 14907, 14971, 15036, 15101, 15167, 15232, 15298, 15365, 15431, 15498,
    15565, 15633, 15700, 15768, 15837, 15905, 15974, 16044, 16113, 16183, 16253,
    16323, 16394, 16465, 16537, 16608, 16680, 16753, 16825, 16898, 16971, 17045,
    17119, 17193, 17267, 17342, 17417, 17493, 17569, 17645, 17721, 17798, 17875,
  ],
  1000000: [
    3333, 3361, 3389, 3417, 3446, 3475, 3504, 3533, 3562, 3592, 3622, 3652,
    3682, 3713, 3744, 3775, 3807, 3838, 3870, 3903, 3935, 3968, 4001, 4034,
    4060, 4085, 4110, 4136, 4162, 4188, 4214, 4241, 4267, 4294, 4321, 4348,
    4375, 4402, 4430, 4457, 4485, 4513, 4541, 4570, 4598, 4627, 4656, 4685,
    4705, 4726, 4746, 4767, 4787, 4808, 4829, 4850, 4871, 4892, 4913, 4935,
    4956, 4977, 4999, 5021, 5042, 5064, 5086, 5108, 5130, 5153, 5175, 5197,
    5220, 5243, 5265, 5288, 5311, 5334, 5357, 5380, 5404, 5427, 5451, 5474,
    5498, 5522, 5546, 5570, 5594, 5618, 5642, 5667, 5691, 5716, 5741, 5766,
    5791, 5816, 5841, 5866, 5892, 5917, 5943, 5969, 5995, 6020, 6047, 6073,
    6099, 6126, 6152, 6179, 6206, 6232, 6259, 6287, 6314, 6341, 6369, 6396,
    6424, 6452, 6480, 6508, 6536, 6564, 6593, 6621, 6650, 6679, 6708, 6737,
    6766, 6795, 6825, 6854, 6884, 6914, 6944, 6974, 7004, 7035, 7065, 7096,
    7126, 7157, 7188, 7219, 7251, 7282, 7314, 7345, 7377, 7409, 7441, 7474,
    7506, 7538, 7571, 7604, 7637, 7670, 7703, 7737, 7770, 7804, 7838, 7872,
    7906, 7940, 7974, 8009, 8044, 8078, 8113, 8149, 8184, 8219, 8255, 8291,
    8327, 8363, 8399, 8435, 8472, 8509, 8546, 8583, 8620, 8657, 8695, 8732,
    8770, 8808, 8846, 8885, 8923, 8962, 9001, 9040, 9079, 9118, 9158, 9197,
    9237, 9277, 9317, 9358, 9398, 9439, 9480, 9521, 9562, 9604, 9645, 9687,
    9729, 9771, 9814, 9856, 9899, 9942, 9985, 10028, 10072, 10115, 10159, 10203,
    10247, 10292, 10336, 10381, 10426, 10471, 10517, 10562, 10608, 10654, 10700,
    10747, 10793, 10840, 10887, 10934, 10981, 11029, 11077, 11125, 11173, 11221,
    11270, 11319, 11368, 11417, 11467, 11516, 11566, 11616, 11667, 11717, 11768,
    11819, 11870, 11922, 11973, 12025, 12077, 12130, 12182, 12235, 12288, 12341,
    12395, 12448, 12502, 12557, 12611, 12666, 12721, 12776, 12831, 12887, 12942,
    12999, 13055, 13111, 13168, 13225, 13283, 13340, 13398, 13456, 13514, 13573,
    13632, 13691, 13750, 13810, 13870, 13930, 13990, 14051, 14112, 14173, 14234,
    14296, 14358, 14420, 14482, 14545, 14608, 14672, 14735, 14799, 14863, 14927,
    14992, 15057, 15122, 15188, 15254, 15320, 15386, 15453, 15520, 15587, 15655,
    15722, 15791, 15859, 15928, 15997, 16066, 16136, 16206, 16276, 16346, 16417,
    16488, 16560, 16632, 16704, 16776, 16849, 16922, 16995, 17069, 17143, 17217,
    17292, 17366, 17442, 17517, 17593, 17669, 17746, 17823, 17900, 17978, 18056,
  ],
  1010000: [
    3325, 3352, 3380, 3408, 3437, 3465, 3494, 3523, 3553, 3582, 3612, 3642,
    3673, 3703, 3734, 3765, 3797, 3828, 3860, 3892, 3925, 3958, 3991, 4024,
    4049, 4074, 4100, 4125, 4151, 4177, 4203, 4229, 4256, 4282, 4309, 4336,
    4363, 4391, 4418, 4446, 4473, 4501, 4529, 4558, 4586, 4615, 4644, 4673,
    4693, 4713, 4734, 4754, 4775, 4796, 4816, 4837, 4858, 4879, 4900, 4922,
    4943, 4964, 4986, 5007, 5029, 5051, 5073, 5095, 5117, 5139, 5161, 5184,
    5206, 5229, 5251, 5274, 5297, 5320, 5343, 5366, 5389, 5413, 5436, 5460,
    5483, 5507, 5531, 5555, 5579, 5603, 5628, 5652, 5676, 5701, 5726, 5751,
    5775, 5801, 5826, 5851, 5876, 5902, 5927, 5953, 5979, 6005, 6031, 6057,
    6083, 6109, 6136, 6163, 6189, 6216, 6243, 6270, 6297, 6324, 6352, 6379,
    6407, 6435, 6463, 6491, 6519, 6547, 6575, 6604, 6633, 6661, 6690, 6719,
    6748, 6778, 6807, 6836, 6866, 6896, 6926, 6956, 6986, 7016, 7046, 7077,
    7108, 7138, 7169, 7200, 7232, 7263, 7294, 7326, 7358, 7390, 7422, 7454,
    7486, 7519, 7551, 7584, 7617, 7650, 7683, 7716, 7750, 7783, 7817, 7851,
    7885, 7919, 7953, 7988, 8022, 8057, 8092, 8127, 8162, 8198, 8233, 8269,
    8305, 8341, 8377, 8413, 8450, 8486, 8523, 8560, 8597, 8634, 8672, 8709,
    8747, 8785, 8823, 8861, 8900, 8938, 8977, 9016, 9055, 9094, 9134, 9173,
    9213, 9253, 9293, 9333, 9374, 9414, 9455, 9496, 9537, 9579, 9620, 9662,
    9704, 9746, 9788, 9830, 9873, 9916, 9959, 10002, 10045, 10089, 10132, 10176,
    10220, 10265, 10309, 10354, 10399, 10444, 10489, 10535, 10580, 10626, 10672,
    10718, 10765, 10811, 10858, 10905, 10953, 11000, 11048, 11096, 11144, 11192,
    11240, 11289, 11338, 11387, 11437, 11486, 11536, 11586, 11636, 11686, 11737,
    11788, 11839, 11890, 11942, 11994, 12046, 12098, 12150, 12203, 12256, 12309,
    12362, 12416, 12470, 12524, 12578, 12632, 12687, 12742, 12797, 12853, 12908,
    12964, 13021, 13077, 13134, 13191, 13248, 13305, 13363, 13421, 13479, 13537,
    13596, 13655, 13714, 13773, 13833, 13893, 13953, 14014, 14074, 14135, 14197,
    14258, 14320, 14382, 14444, 14507, 14570, 14633, 14696, 14760, 14824, 14888,
    14953, 15018, 15083, 15148, 15214, 15280, 15346, 15412, 15479, 15546, 15614,
    15681, 15749, 15817, 15886, 15955, 16024, 16093, 16163, 16233, 16303, 16374,
    16445, 16516, 16588, 16660, 16732, 16804, 16877, 16950, 17024, 17098, 17172,
    17246, 17321, 17396, 17471, 17547, 17623, 17699, 17776, 17853, 17931, 18008,
  ],
  1020000: [
    3315, 3343, 3370, 3399, 3427, 3455, 3484, 3513, 3543, 3572, 3602, 3632,
    3662, 3693, 3723, 3754, 3786, 3817, 3849, 3881, 3914, 3946, 3979, 4012,
    4037, 4062, 4088, 4113, 4139, 4165, 4191, 4217, 4244, 4270, 4297, 4324,
    4351, 4378, 4405, 4433, 4460, 4488, 4516, 4545, 4573, 4602, 4630, 4659,
    4679, 4700, 4720, 4741, 4761, 4782, 4802, 4823, 4844, 4865, 4886, 4907,
    4929, 4950, 4972, 4993, 5015, 5036, 5058, 5080, 5102, 5124, 5146, 5169,
    5191, 5214, 5236, 5259, 5282, 5305, 5328, 5351, 5374, 5397, 5421, 5444,
    5468, 5491, 5515, 5539, 5563, 5587, 5611, 5636, 5660, 5685, 5709, 5734,
    5759, 5784, 5809, 5834, 5859, 5885, 5910, 5936, 5962, 5987, 6013, 6039,
    6066, 6092, 6118, 6145, 6171, 6198, 6225, 6252, 6279, 6306, 6334, 6361,
    6389, 6416, 6444, 6472, 6500, 6528, 6557, 6585, 6613, 6642, 6671, 6700,
    6729, 6758, 6787, 6817, 6846, 6876, 6906, 6936, 6966, 6996, 7026, 7057,
    7087, 7118, 7149, 7180, 7211, 7242, 7273, 7305, 7337, 7368, 7400, 7432,
    7465, 7497, 7529, 7562, 7595, 7628, 7661, 7694, 7727, 7761, 7794, 7828,
    7862, 7896, 7930, 7965, 7999, 8034, 8069, 8104, 8139, 8174, 8210, 8245,
    8281, 8317, 8353, 8389, 8425, 8462, 8499, 8535, 8572, 8610, 8647, 8684,
    8722, 8760, 8798, 8836, 8874, 8913, 8951, 8990, 9029, 9068, 9107, 9147,
    9186, 9226, 9266, 9306, 9347, 9387, 9428, 9469, 9510, 9551, 9592, 9634,
    9676, 9718, 9760, 9802, 9844, 9887, 9930, 9973, 10016, 10060, 10103, 10147,
    10191, 10235, 10279, 10324, 10369, 10414, 10459, 10504, 10550, 10595, 10641,
    10687, 10734, 10780, 10827, 10874, 10921, 10968, 11016, 11064, 11112, 11160,
    11208, 11257, 11305, 11354, 11404, 11453, 11503, 11552, 11603, 11653, 11703,
    11754, 11805, 11856, 11907, 11959, 12011, 12063, 12115, 12168, 12220, 12273,
    12327, 12380, 12434, 12488, 12542, 12596, 12651, 12705, 12760, 12816, 12871,
    12927, 12983, 13039, 13096, 13153, 13210, 13267, 13324, 13382, 13440, 13498,
    13557, 13615, 13674, 13734, 13793, 13853, 13913, 13973, 14034, 14095, 14156,
    14217, 14279, 14341, 14403, 14465, 14528, 14591, 14654, 14718, 14781, 14845,
    14910, 14974, 15039, 15104, 15170, 15236, 15302, 15368, 15434, 15501, 15569,
    15636, 15704, 15772, 15840, 15909, 15978, 16047, 16116, 16186, 16256, 16327,
    16398, 16469, 16540, 16612, 16684, 16756, 16829, 16902, 16975, 17048, 17122,
    17196, 17271, 17346, 17421, 17496, 17572, 17648, 17725, 17802, 17879, 17956,
  ],
  1030000: [
    3305, 3332, 3360, 3388, 3416, 3445, 3473, 3502, 3531, 3561, 3591, 3620,
    3651, 3681, 3712, 3743, 3774, 3805, 3837, 3869, 3901, 3934, 3966, 4000,
    4025, 4050, 4075, 4100, 4126, 4152, 4178, 4204, 4230, 4257, 4283, 4310,
    4337, 4364, 4391, 4419, 4446, 4474, 4502, 4530, 4559, 4587, 4616, 4645,
    4665, 4685, 4705, 4726, 4746, 4767, 4787, 4808, 4829, 4850, 4871, 4892,
    4913, 4935, 4956, 4977, 4999, 5021, 5042, 5064, 5086, 5108, 5130, 5153,
    5175, 5197, 5220, 5242, 5265, 5288, 5311, 5334, 5357, 5380, 5404, 5427,
    5450, 5474, 5498, 5522, 5546, 5570, 5594, 5618, 5642, 5667, 5691, 5716,
    5741, 5766, 5791, 5816, 5841, 5866, 5892, 5917, 5943, 5969, 5994, 6020,
    6046, 6073, 6099, 6125, 6152, 6179, 6205, 6232, 6259, 6286, 6314, 6341,
    6369, 6396, 6424, 6452, 6480, 6508, 6536, 6564, 6593, 6621, 6650, 6679,
    6708, 6737, 6766, 6795, 6825, 6854, 6884, 6914, 6944, 6974, 7004, 7034,
    7065, 7096, 7126, 7157, 7188, 7219, 7251, 7282, 7314, 7345, 7377, 7409,
    7441, 7473, 7506, 7538, 7571, 7604, 7637, 7670, 7703, 7736, 7770, 7804,
    7837, 7871, 7906, 7940, 7974, 8009, 8043, 8078, 8113, 8148, 8184, 8219,
    8255, 8291, 8327, 8363, 8399, 8435, 8472, 8509, 8545, 8582, 8620, 8657,
    8695, 8732, 8770, 8808, 8846, 8885, 8923, 8962, 9001, 9040, 9079, 9118,
    9158, 9197, 9237, 9277, 9317, 9358, 9398, 9439, 9480, 9521, 9562, 9604,
    9645, 9687, 9729, 9771, 9814, 9856, 9899, 9942, 9985, 10028, 10071, 10115,
    10159, 10203, 10247, 10292, 10336, 10381, 10426, 10471, 10517, 10562, 10608,
    10654, 10700, 10746, 10793, 10840, 10887, 10934, 10981, 11029, 11077, 11125,
    11173, 11221, 11270, 11319, 11368, 11417, 11466, 11516, 11566, 11616, 11667,
    11717, 11768, 11819, 11870, 11921, 11973, 12025, 12077, 12129, 12182, 12235,
    12288, 12341, 12395, 12448, 12502, 12556, 12611, 12665, 12720, 12775, 12831,
    12886, 12942, 12998, 13055, 13111, 13168, 13225, 13282, 13340, 13398, 13456,
    13514, 13573, 13632, 13691, 13750, 13810, 13869, 13929, 13990, 14050, 14111,
    14172, 14234, 14296, 14358, 14420, 14482, 14545, 14608, 14671, 14735, 14799,
    14863, 14927, 14992, 15057, 15122, 15188, 15253, 15320, 15386, 15453, 15520,
    15587, 15654, 15722, 15790, 15859, 15928, 15997, 16066, 16135, 16205, 16276,
    16346, 16417, 16488, 16560, 16631, 16703, 16776, 16848, 16921, 16995, 17068,
    17142, 17217, 17291, 17366, 17441, 17517, 17593, 17669, 17746, 17823, 17900,
  ],
  1040000: [
    3293, 3321, 3348, 3376, 3404, 3433, 3461, 3490, 3519, 3549, 3578, 3608,
    3638, 3669, 3699, 3730, 3761, 3792, 3824, 3856, 3888, 3920, 3953, 3986,
    4011, 4036, 4061, 4087, 4112, 4138, 4164, 4190, 4216, 4242, 4269, 4295,
    4322, 4349, 4376, 4404, 4431, 4459, 4487, 4515, 4543, 4572, 4600, 4629,
    4649, 4669, 4689, 4710, 4730, 4750, 4771, 4792, 4813, 4833, 4854, 4875,
    4896, 4918, 4939, 4960, 4982, 5003, 5025, 5047, 5069, 5091, 5113, 5135,
    5157, 5180, 5202, 5225, 5247, 5270, 5293, 5316, 5339, 5362, 5385, 5408,
    5432, 5455, 5479, 5503, 5527, 5551, 5575, 5599, 5623, 5647, 5672, 5697,
    5721, 5746, 5771, 5796, 5821, 5846, 5872, 5897, 5923, 5948, 5974, 6000,
    6026, 6052, 6078, 6105, 6131, 6158, 6184, 6211, 6238, 6265, 6292, 6319,
    6347, 6374, 6402, 6430, 6458, 6486, 6514, 6542, 6570, 6599, 6627, 6656,
    6685, 6714, 6743, 6772, 6801, 6831, 6861, 6890, 6920, 6950, 6980, 7010,
    7041, 7071, 7102, 7133, 7164, 7195, 7226, 7257, 7289, 7320, 7352, 7384,
    7416, 7448, 7480, 7513, 7545, 7578, 7611, 7644, 7677, 7710, 7744, 7777,
    7811, 7845, 7879, 7913, 7947, 7981, 8016, 8051, 8086, 8121, 8156, 8191,
    8227, 8262, 8298, 8334, 8370, 8407, 8443, 8480, 8516, 8553, 8590, 8628,
    8665, 8702, 8740, 8778, 8816, 8854, 8893, 8931, 8970, 9009, 9048, 9087,
    9126, 9166, 9206, 9246, 9286, 9326, 9366, 9407, 9448, 9489, 9530, 9571,
    9612, 9654, 9696, 9738, 9780, 9823, 9865, 9908, 9951, 9994, 10037, 10081,
    10124, 10168, 10212, 10257, 10301, 10346, 10390, 10435, 10481, 10526, 10572,
    10618, 10664, 10710, 10756, 10803, 10850, 10897, 10944, 10991, 11039, 11087,
    11135, 11183, 11231, 11280, 11329, 11378, 11427, 11477, 11527, 11577, 11627,
    11677, 11728, 11779, 11830, 11881, 11932, 11984, 12036, 12088, 12141, 12193,
    12246, 12299, 12352, 12406, 12460, 12514, 12568, 12622, 12677, 12732, 12787,
    12843, 12898, 12954, 13010, 13067, 13123, 13180, 13237, 13295, 13352, 13410,
    13468, 13527, 13585, 13644, 13703, 13762, 13822, 13882, 13942, 14003, 14063,
    14124, 14185, 14247, 14309, 14371, 14433, 14495, 14558, 14621, 14685, 14748,
    14812, 14876, 14941, 15006, 15071, 15136, 15202, 15267, 15334, 15400, 15467,
    15534, 15601, 15669, 15737, 15805, 15873, 15942, 16011, 16081, 16150, 16220,
    16290, 16361, 16432, 16503, 16575, 16647, 16719, 16791, 16864, 16937, 17010,
    17084, 17158, 17232, 17307, 17382, 17457, 17533, 17609, 17685, 17762, 17839,
  ],
  1050000: [
    3281, 3309, 3336, 3364, 3392, 3420, 3449, 3478, 3506, 3536, 3565, 3595,
    3625, 3655, 3686, 3716, 3747, 3778, 3810, 3842, 3874, 3906, 3938, 3971,
    3996, 4021, 4046, 4072, 4097, 4123, 4148, 4174, 4200, 4227, 4253, 4280,
    4306, 4333, 4360, 4388, 4415, 4443, 4470, 4498, 4526, 4555, 4583, 4612,
    4632, 4652, 4672, 4692, 4713, 4733, 4754, 4774, 4795, 4816, 4837, 4857,
    4879, 4900, 4921, 4942, 4964, 4985, 5007, 5028, 5050, 5072, 5094, 5116,
    5138, 5161, 5183, 5205, 5228, 5251, 5273, 5296, 5319, 5342, 5365, 5389,
    5412, 5435, 5459, 5483, 5506, 5530, 5554, 5578, 5602, 5627, 5651, 5676,
    5700, 5725, 5750, 5775, 5800, 5825, 5850, 5875, 5901, 5926, 5952, 5978,
    6004, 6030, 6056, 6082, 6109, 6135, 6162, 6188, 6215, 6242, 6269, 6296,
    6324, 6351, 6378, 6406, 6434, 6462, 6490, 6518, 6546, 6574, 6603, 6632,
    6660, 6689, 6718, 6747, 6777, 6806, 6835, 6865, 6895, 6925, 6955, 6985,
    7015, 7045, 7076, 7107, 7137, 7168, 7199, 7231, 7262, 7293, 7325, 7357,
    7389, 7421, 7453, 7485, 7518, 7550, 7583, 7616, 7649, 7682, 7715, 7749,
    7782, 7816, 7850, 7884, 7918, 7952, 7987, 8021, 8056, 8091, 8126, 8161,
    8197, 8232, 8268, 8304, 8340, 8376, 8412, 8448, 8485, 8522, 8559, 8596,
    8633, 8671, 8708, 8746, 8784, 8822, 8860, 8898, 8937, 8976, 9015, 9054,
    9093, 9132, 9172, 9212, 9252, 9292, 9332, 9372, 9413, 9454, 9495, 9536,
    9577, 9619, 9660, 9702, 9744, 9786, 9829, 9871, 9914, 9957, 10000, 10044,
    10087, 10131, 10175, 10219, 10263, 10308, 10352, 10397, 10442, 10488, 10533,
    10579, 10624, 10670, 10717, 10763, 10810, 10857, 10904, 10951, 10998, 11046,
    11094, 11142, 11190, 11239, 11287, 11336, 11385, 11435, 11484, 11534, 11584,
    11634, 11685, 11735, 11786, 11837, 11889, 11940, 11992, 12044, 12096, 12148,
    12201, 12254, 12307, 12360, 12414, 12468, 12522, 12576, 12631, 12685, 12740,
    12795, 12851, 12907, 12962, 13019, 13075, 13132, 13189, 13246, 13303, 13361,
    13419, 13477, 13535, 13594, 13653, 13712, 13771, 13831, 13891, 13951, 14012,
    14072, 14133, 14195, 14256, 14318, 14380, 14442, 14505, 14568, 14631, 14694,
    14758, 14822, 14886, 14951, 15015, 15080, 15146, 15211, 15277, 15344, 15410,
    15477, 15544, 15611, 15679, 15747, 15815, 15884, 15952, 16022, 16091, 16161,
    16231, 16301, 16372, 16443, 16514, 16585, 16657, 16729, 16802, 16875, 16948,
    17021, 17095, 17169, 17244, 17318, 17393, 17469, 17544, 17620, 17697, 17774,
  ],
  1060000: [
    3268, 3296, 3323, 3351, 3379, 3407, 3435, 3464, 3493, 3522, 3551, 3581,
    3611, 3641, 3671, 3702, 3732, 3764, 3795, 3827, 3858, 3891, 3923, 3956,
    3980, 4005, 4030, 4056, 4081, 4106, 4132, 4158, 4184, 4210, 4236, 4263,
    4289, 4316, 4343, 4370, 4398, 4425, 4453, 4481, 4509, 4537, 4565, 4594,
    4614, 4634, 4654, 4674, 4694, 4714, 4735, 4755, 4776, 4797, 4817, 4838,
    4859, 4880, 4902, 4923, 4944, 4966, 4987, 5009, 5030, 5052, 5074, 5096,
    5118, 5140, 5163, 5185, 5207, 5230, 5253, 5275, 5298, 5321, 5344, 5367,
    5391, 5414, 5438, 5461, 5485, 5509, 5532, 5556, 5580, 5605, 5629, 5653,
    5678, 5702, 5727, 5752, 5777, 5802, 5827, 5852, 5878, 5903, 5929, 5954,
    5980, 6006, 6032, 6058, 6084, 6111, 6137, 6164, 6191, 6217, 6244, 6271,
    6299, 6326, 6353, 6381, 6409, 6436, 6464, 6492, 6520, 6549, 6577, 6605,
    6634, 6663, 6692, 6721, 6750, 6779, 6808, 6838, 6868, 6897, 6927, 6957,
    6987, 7018, 7048, 7079, 7109, 7140, 7171, 7202, 7233, 7265, 7296, 7328,
    7360, 7391, 7423, 7456, 7488, 7520, 7553, 7586, 7619, 7652, 7685, 7718,
    7752, 7785, 7819, 7853, 7887, 7921, 7955, 7990, 8024, 8059, 8094, 8129,
    8164, 8200, 8235, 8271, 8307, 8343, 8379, 8415, 8452, 8488, 8525, 8562,
    8599, 8636, 8674, 8711, 8749, 8787, 8825, 8863, 8902, 8940, 8979, 9018,
    9057, 9096, 9136, 9175, 9215, 9255, 9295, 9335, 9376, 9417, 9457, 9498,
    9539, 9581, 9622, 9664, 9706, 9748, 9790, 9833, 9875, 9918, 9961, 10004,
    10048, 10091, 10135, 10179, 10223, 10267, 10312, 10356, 10401, 10446, 10491,
    10537, 10583, 10628, 10675, 10721, 10767, 10814, 10861, 10908, 10955, 11003,
    11050, 11098, 11146, 11195, 11243, 11292, 11341, 11390, 11439, 11489, 11539,
    11589, 11639, 11689, 11740, 11791, 11842, 11893, 11945, 11996, 12048, 12101,
    12153, 12206, 12259, 12312, 12365, 12419, 12472, 12527, 12581, 12635, 12690,
    12745, 12800, 12856, 12911, 12967, 13024, 13080, 13137, 13194, 13251, 13308,
    13366, 13424, 13482, 13540, 13599, 13658, 13717, 13777, 13836, 13896, 13957,
    14017, 14078, 14139, 14200, 14262, 14323, 14385, 14448, 14510, 14573, 14636,
    14700, 14764, 14827, 14892, 14956, 15021, 15086, 15152, 15217, 15283, 15349,
    15416, 15483, 15550, 15617, 15685, 15753, 15821, 15890, 15958, 16028, 16097,
    16167, 16237, 16307, 16378, 16449, 16520, 16592, 16664, 16736, 16808, 16881,
    16954, 17028, 17102, 17176, 17250, 17325, 17400, 17475, 17551, 17627, 17704,
  ],
  1070000: [
    3255, 3282, 3309, 3337, 3364, 3392, 3421, 3449, 3478, 3507, 3536, 3566,
    3595, 3625, 3656, 3686, 3717, 3748, 3779, 3810, 3842, 3874, 3906, 3939,
    3964, 3988, 4013, 4038, 4064, 4089, 4115, 4140, 4166, 4192, 4218, 4245,
    4271, 4298, 4325, 4352, 4379, 4407, 4434, 4462, 4490, 4518, 4546, 4574,
    4594, 4614, 4634, 4654, 4674, 4695, 4715, 4735, 4756, 4777, 4797, 4818,
    4839, 4860, 4881, 4902, 4923, 4945, 4966, 4988, 5009, 5031, 5053, 5075,
    5097, 5119, 5141, 5163, 5185, 5208, 5231, 5253, 5276, 5299, 5322, 5345,
    5368, 5391, 5415, 5438, 5462, 5485, 5509, 5533, 5557, 5581, 5605, 5629,
    5654, 5678, 5703, 5728, 5753, 5777, 5802, 5828, 5853, 5878, 5904, 5929,
    5955, 5981, 6007, 6033, 6059, 6085, 6112, 6138, 6165, 6191, 6218, 6245,
    6272, 6299, 6327, 6354, 6382, 6409, 6437, 6465, 6493, 6521, 6549, 6578,
    6606, 6635, 6664, 6692, 6721, 6751, 6780, 6809, 6839, 6868, 6898, 6928,
    6958, 6988, 7018, 7049, 7079, 7110, 7141, 7172, 7203, 7234, 7265, 7297,
    7329, 7360, 7392, 7424, 7456, 7489, 7521, 7554, 7587, 7619, 7652, 7686,
    7719, 7752, 7786, 7820, 7854, 7888, 7922, 7956, 7991, 8025, 8060, 8095,
    8130, 8165, 8201, 8236, 8272, 8308, 8344, 8380, 8416, 8453, 8489, 8526,
    8563, 8600, 8637, 8675, 8712, 8750, 8788, 8826, 8864, 8903, 8941, 8980,
    9019, 9058, 9097, 9137, 9176, 9216, 9256, 9296, 9336, 9377, 9418, 9458,
    9499, 9540, 9582, 9623, 9665, 9707, 9749, 9791, 9834, 9876, 9919, 9962,
    10005, 10049, 10092, 10136, 10180, 10224, 10268, 10313, 10357, 10402, 10447,
    10493, 10538, 10584, 10630, 10676, 10722, 10768, 10815, 10862, 10909, 10956,
    11004, 11051, 11099, 11147, 11196, 11244, 11293, 11342, 11391, 11440, 11490,
    11540, 11590, 11640, 11690, 11741, 11792, 11843, 11894, 11946, 11998, 12050,
    12102, 12154, 12207, 12260, 12313, 12366, 12420, 12474, 12528, 12582, 12637,
    12691, 12746, 12802, 12857, 12913, 12969, 13025, 13081, 13138, 13195, 13252,
    13310, 13367, 13425, 13483, 13542, 13601, 13659, 13719, 13778, 13838, 13898,
    13958, 14019, 14079, 14140, 14202, 14263, 14325, 14387, 14449, 14512, 14575,
    14638, 14701, 14765, 14829, 14893, 14958, 15023, 15088, 15153, 15219, 15285,
    15351, 15418, 15484, 15551, 15619, 15687, 15754, 15823, 15891, 15960, 16029,
    16099, 16169, 16239, 16309, 16380, 16451, 16522, 16594, 16665, 16738, 16810,
    16883, 16956, 17030, 17103, 17178, 17252, 17327, 17402, 17477, 17553, 17629,
  ],
  1080000: [
    3240, 3267, 3294, 3322, 3349, 3377, 3405, 3434, 3462, 3491, 3520, 3550,
    3579, 3609, 3639, 3669, 3700, 3731, 3762, 3793, 3825, 3857, 3889, 3921,
    3946, 3971, 3995, 4020, 4045, 4071, 4096, 4122, 4148, 4173, 4200, 4226,
    4252, 4279, 4306, 4332, 4360, 4387, 4414, 4442, 4470, 4497, 4526, 4554,
    4574, 4593, 4613, 4633, 4653, 4674, 4694, 4714, 4735, 4755, 4776, 4796,
    4817, 4838, 4859, 4880, 4901, 4922, 4944, 4965, 4987, 5008, 5030, 5052,
    5074, 5096, 5118, 5140, 5162, 5185, 5207, 5230, 5252, 5275, 5298, 5321,
    5344, 5367, 5390, 5414, 5437, 5461, 5484, 5508, 5532, 5556, 5580, 5604,
    5629, 5653, 5677, 5702, 5727, 5752, 5776, 5802, 5827, 5852, 5877, 5903,
    5928, 5954, 5980, 6006, 6032, 6058, 6084, 6111, 6137, 6164, 6190, 6217,
    6244, 6271, 6298, 6326, 6353, 6381, 6408, 6436, 6464, 6492, 6520, 6548,
    6577, 6605, 6634, 6662, 6691, 6720, 6749, 6779, 6808, 6838, 6867, 6897,
    6927, 6957, 6987, 7017, 7048, 7078, 7109, 7140, 7171, 7202, 7233, 7264,
    7296, 7327, 7359, 7391, 7423, 7455, 7488, 7520, 7553, 7585, 7618, 7651,
    7684, 7718, 7751, 7785, 7818, 7852, 7886, 7920, 7955, 7989, 8024, 8059,
    8094, 8129, 8164, 8199, 8235, 8270, 8306, 8342, 8378, 8415, 8451, 8488,
    8525, 8562, 8599, 8636, 8673, 8711, 8749, 8787, 8825, 8863, 8901, 8940,
    8979, 9017, 9057, 9096, 9135, 9175, 9215, 9255, 9295, 9335, 9375, 9416,
    9457, 9498, 9539, 9580, 9622, 9663, 9705, 9747, 9790, 9832, 9875, 9917,
    9960, 10004, 10047, 10090, 10134, 10178, 10222, 10266, 10311, 10356, 10401,
    10446, 10491, 10536, 10582, 10628, 10674, 10720, 10767, 10813, 10860, 10907,
    10954, 11002, 11050, 11097, 11146, 11194, 11242, 11291, 11340, 11389, 11439,
    11488, 11538, 11588, 11638, 11688, 11739, 11790, 11841, 11892, 11944, 11996,
    12048, 12100, 12152, 12205, 12258, 12311, 12364, 12418, 12472, 12526, 12580,
    12635, 12689, 12744, 12800, 12855, 12911, 12967, 13023, 13079, 13136, 13193,
    13250, 13307, 13365, 13423, 13481, 13540, 13598, 13657, 13716, 13776, 13836,
    13895, 13956, 14016, 14077, 14138, 14199, 14261, 14322, 14385, 14447, 14509,
    14572, 14636, 14699, 14763, 14827, 14891, 14955, 15020, 15085, 15151, 15216,
    15282, 15348, 15415, 15482, 15549, 15616, 15684, 15752, 15820, 15889, 15958,
    16027, 16096, 16166, 16236, 16306, 16377, 16448, 16519, 16591, 16663, 16735,
    16807, 16880, 16953, 17027, 17101, 17175, 17249, 17324, 17399, 17474, 17550,
  ],
  1090000: [
    3225, 3251, 3279, 3306, 3333, 3361, 3389, 3417, 3446, 3475, 3504, 3533,
    3562, 3592, 3622, 3652, 3682, 3713, 3744, 3775, 3807, 3838, 3870, 3903,
    3927, 3952, 3976, 4001, 4026, 4051, 4077, 4102, 4128, 4154, 4180, 4206,
    4232, 4258, 4285, 4312, 4339, 4366, 4393, 4421, 4448, 4476, 4504, 4532,
    4552, 4572, 4591, 4611, 4631, 4651, 4671, 4692, 4712, 4732, 4753, 4774,
    4794, 4815, 4836, 4857, 4878, 4899, 4920, 4942, 4963, 4985, 5006, 5028,
    5050, 5071, 5093, 5116, 5138, 5160, 5182, 5205, 5227, 5250, 5273, 5296,
    5319, 5342, 5365, 5388, 5411, 5435, 5458, 5482, 5506, 5530, 5554, 5578,
    5602, 5626, 5650, 5675, 5700, 5724, 5749, 5774, 5799, 5824, 5849, 5875,
    5900, 5926, 5951, 5977, 6003, 6029, 6055, 6081, 6108, 6134, 6161, 6188,
    6214, 6241, 6268, 6295, 6323, 6350, 6378, 6405, 6433, 6461, 6489, 6517,
    6545, 6574, 6602, 6631, 6659, 6688, 6717, 6746, 6776, 6805, 6835, 6864,
    6894, 6924, 6954, 6984, 7014, 7045, 7075, 7106, 7137, 7167, 7199, 7230,
    7261, 7292, 7324, 7356, 7388, 7420, 7452, 7484, 7517, 7549, 7582, 7615,
    7648, 7681, 7714, 7748, 7781, 7815, 7849, 7883, 7917, 7951, 7986, 8020,
    8055, 8090, 8125, 8160, 8196, 8231, 8267, 8303, 8339, 8375, 8411, 8447,
    8484, 8521, 8558, 8595, 8632, 8669, 8707, 8745, 8783, 8821, 8859, 8897,
    8936, 8975, 9013, 9053, 9092, 9131, 9171, 9210, 9250, 9290, 9331, 9371,
    9412, 9453, 9494, 9535, 9576, 9617, 9659, 9701, 9743, 9785, 9828, 9870,
    9913, 9956, 9999, 10042, 10086, 10130, 10174, 10218, 10262, 10306, 10351,
    10396, 10441, 10486, 10532, 10577, 10623, 10669, 10715, 10762, 10808, 10855,
    10902, 10950, 10997, 11045, 11093, 11141, 11189, 11237, 11286, 11335, 11384,
    11433, 11483, 11533, 11583, 11633, 11683, 11734, 11785, 11836, 11887, 11939,
    11990, 12042, 12095, 12147, 12200, 12252, 12306, 12359, 12412, 12466, 12520,
    12574, 12629, 12684, 12739, 12794, 12849, 12905, 12961, 13017, 13073, 13130,
    13187, 13244, 13302, 13359, 13417, 13475, 13534, 13592, 13651, 13710, 13770,
    13829, 13889, 13949, 14010, 14071, 14132, 14193, 14254, 14316, 14378, 14440,
    14503, 14566, 14629, 14692, 14756, 14820, 14884, 14949, 15013, 15079, 15144,
    15210, 15275, 15342, 15408, 15475, 15542, 15609, 15677, 15745, 15813, 15882,
    15950, 16020, 16089, 16159, 16229, 16299, 16370, 16441, 16512, 16583, 16655,
    16727, 16800, 16873, 16946, 17019, 17093, 17167, 17241, 17316, 17391, 17467,
  ],
  1100000: [
    3208, 3235, 3262, 3289, 3317, 3344, 3372, 3400, 3429, 3457, 3486, 3515,
    3544, 3574, 3604, 3634, 3664, 3694, 3725, 3756, 3788, 3819, 3851, 3883,
    3907, 3932, 3956, 3981, 4006, 4031, 4056, 4082, 4107, 4133, 4159, 4185,
    4211, 4237, 4263, 4290, 4317, 4344, 4371, 4398, 4426, 4454, 4481, 4509,
    4529, 4549, 4568, 4588, 4608, 4628, 4648, 4668, 4688, 4709, 4729, 4750,
    4770, 4791, 4812, 4832, 4853, 4874, 4895, 4917, 4938, 4959, 4981, 5002,
    5024, 5046, 5068, 5090, 5112, 5134, 5156, 5179, 5201, 5224, 5246, 5269,
    5292, 5315, 5338, 5361, 5384, 5407, 5431, 5454, 5478, 5502, 5526, 5550,
    5574, 5598, 5622, 5646, 5671, 5695, 5720, 5745, 5770, 5795, 5820, 5845,
    5870, 5896, 5921, 5947, 5973, 5999, 6025, 6051, 6077, 6103, 6130, 6156,
    6183, 6210, 6237, 6264, 6291, 6318, 6346, 6373, 6401, 6428, 6456, 6484,
    6512, 6541, 6569, 6597, 6626, 6655, 6683, 6712, 6742, 6771, 6800, 6830,
    6859, 6889, 6919, 6949, 6979, 7009, 7039, 7070, 7101, 7131, 7162, 7193,
    7224, 7256, 7287, 7319, 7350, 7382, 7414, 7446, 7479, 7511, 7544, 7576,
    7609, 7642, 7675, 7709, 7742, 7775, 7809, 7843, 7877, 7911, 7945, 7980,
    8014, 8049, 8084, 8119, 8154, 8190, 8225, 8261, 8297, 8332, 8369, 8405,
    8441, 8478, 8515, 8551, 8589, 8626, 8663, 8701, 8738, 8776, 8814, 8852,
    8891, 8929, 8968, 9007, 9046, 9085, 9125, 9164, 9204, 9244, 9284, 9324,
    9364, 9405, 9446, 9487, 9528, 9569, 9610, 9652, 9694, 9736, 9778, 9821,
    9863, 9906, 9949, 9992, 10035, 10079, 10122, 10166, 10210, 10254, 10299,
    10344, 10388, 10433, 10479, 10524, 10570, 10615, 10661, 10708, 10754, 10801,
    10847, 10894, 10942, 10989, 11037, 11084, 11132, 11181, 11229, 11278, 11327,
    11376, 11425, 11475, 11524, 11574, 11624, 11675, 11725, 11776, 11827, 11878,
    11930, 11982, 12034, 12086, 12138, 12191, 12243, 12297, 12350, 12403, 12457,
    12511, 12565, 12620, 12674, 12729, 12785, 12840, 12896, 12951, 13008, 13064,
    13121, 13177, 13234, 13292, 13349, 13407, 13465, 13524, 13582, 13641, 13700,
    13760, 13819, 13879, 13939, 14000, 14060, 14121, 14183, 14244, 14306, 14368,
    14430, 14492, 14555, 14618, 14682, 14745, 14809, 14873, 14938, 15003, 15068,
    15133, 15198, 15264, 15330, 15397, 15464, 15531, 15598, 15666, 15733, 15802,
    15870, 15939, 16008, 16077, 16147, 16217, 16287, 16358, 16429, 16500, 16571,
    16643, 16715, 16788, 16860, 16933, 17007, 17081, 17155, 17229, 17304, 17379,
  ],
  1110000: [
    3191, 3218, 3245, 3272, 3299, 3326, 3354, 3382, 3410, 3439, 3467, 3496,
    3525, 3555, 3584, 3614, 3644, 3675, 3705, 3736, 3767, 3799, 3830, 3862,
    3887, 3911, 3935, 3960, 3985, 4010, 4035, 4060, 4085, 4111, 4136, 4162,
    4188, 4214, 4241, 4267, 4294, 4321, 4348, 4375, 4402, 4430, 4457, 4485,
    4505, 4524, 4544, 4564, 4583, 4603, 4623, 4643, 4663, 4684, 4704, 4724,
    4745, 4765, 4786, 4807, 4827, 4848, 4869, 4891, 4912, 4933, 4954, 4976,
    4997, 5019, 5041, 5063, 5085, 5107, 5129, 5151, 5173, 5196, 5218, 5241,
    5264, 5286, 5309, 5332, 5355, 5379, 5402, 5425, 5449, 5472, 5496, 5520,
    5544, 5568, 5592, 5616, 5641, 5665, 5690, 5714, 5739, 5764, 5789, 5814,
    5839, 5864, 5890, 5915, 5941, 5967, 5993, 6019, 6045, 6071, 6097, 6124,
    6150, 6177, 6204, 6230, 6257, 6285, 6312, 6339, 6367, 6394, 6422, 6450,
    6478, 6506, 6534, 6562, 6591, 6619, 6648, 6677, 6706, 6735, 6764, 6793,
    6823, 6852, 6882, 6912, 6942, 6972, 7002, 7032, 7063, 7093, 7124, 7155,
    7186, 7217, 7248, 7280, 7311, 7343, 7375, 7407, 7439, 7471, 7504, 7536,
    7569, 7601, 7634, 7668, 7701, 7734, 7768, 7801, 7835, 7869, 7903, 7937,
    7972, 8006, 8041, 8076, 8111, 8146, 8181, 8217, 8252, 8288, 8324, 8360,
    8396, 8433, 8469, 8506, 8543, 8580, 8617, 8654, 8692, 8730, 8767, 8805,
    8843, 8882, 8920, 8959, 8998, 9037, 9076, 9115, 9155, 9194, 9234, 9274,
    9314, 9355, 9395, 9436, 9477, 9518, 9559, 9601, 9642, 9684, 9726, 9768,
    9811, 9853, 9896, 9939, 9982, 10025, 10068, 10112, 10156, 10200, 10244,
    10288, 10333, 10378, 10423, 10468, 10513, 10559, 10605, 10651, 10697, 10743,
    10790, 10836, 10883, 10930, 10978, 11025, 11073, 11121, 11169, 11218, 11266,
    11315, 11364, 11413, 11463, 11513, 11563, 11613, 11663, 11713, 11764, 11815,
    11866, 11918, 11969, 12021, 12073, 12126, 12178, 12231, 12284, 12337, 12391,
    12444, 12498, 12553, 12607, 12662, 12716, 12772, 12827, 12882, 12938, 12994,
    13051, 13107, 13164, 13221, 13278, 13336, 13394, 13452, 13510, 13569, 13627,
    13686, 13746, 13805, 13865, 13925, 13986, 14046, 14107, 14168, 14230, 14291,
    14353, 14415, 14478, 14541, 14604, 14667, 14730, 14794, 14858, 14923, 14987,
    15052, 15118, 15183, 15249, 15315, 15381, 15448, 15515, 15582, 15650, 15717,
    15786, 15854, 15923, 15992, 16061, 16131, 16200, 16271, 16341, 16412, 16483,
    16554, 16626, 16698, 16771, 16843, 16916, 16990, 17063, 17137, 17211, 17286,
  ],
  1120000: [
    3173, 3200, 3226, 3253, 3280, 3308, 3335, 3363, 3391, 3419, 3448, 3477,
    3506, 3535, 3564, 3594, 3624, 3654, 3685, 3715, 3746, 3777, 3809, 3841,
    3865, 3889, 3913, 3938, 3962, 3987, 4012, 4037, 4062, 4088, 4113, 4139,
    4165, 4191, 4217, 4243, 4270, 4297, 4323, 4350, 4378, 4405, 4432, 4460,
    4480, 4499, 4518, 4538, 4558, 4577, 4597, 4617, 4637, 4657, 4677, 4698,
    4718, 4739, 4759, 4780, 4800, 4821, 4842, 4863, 4884, 4905, 4927, 4948,
    4969, 4991, 5013, 5034, 5056, 5078, 5100, 5122, 5144, 5167, 5189, 5211,
    5234, 5257, 5279, 5302, 5325, 5348, 5372, 5395, 5418, 5442, 5465, 5489,
    5513, 5537, 5561, 5585, 5609, 5633, 5658, 5682, 5707, 5732, 5756, 5781,
    5806, 5832, 5857, 5882, 5908, 5933, 5959, 5985, 6011, 6037, 6063, 6089,
    6116, 6142, 6169, 6195, 6222, 6249, 6276, 6304, 6331, 6358, 6386, 6413,
    6441, 6469, 6497, 6525, 6554, 6582, 6611, 6639, 6668, 6697, 6726, 6755,
    6784, 6814, 6843, 6873, 6903, 6933, 6963, 6993, 7023, 7054, 7084, 7115,
    7146, 7177, 7208, 7239, 7270, 7302, 7333, 7365, 7397, 7429, 7461, 7494,
    7526, 7559, 7592, 7624, 7657, 7691, 7724, 7757, 7791, 7825, 7859, 7893,
    7927, 7961, 7996, 8031, 8065, 8100, 8135, 8171, 8206, 8242, 8277, 8313,
    8349, 8385, 8422, 8458, 8495, 8532, 8569, 8606, 8643, 8681, 8718, 8756,
    8794, 8832, 8870, 8909, 8947, 8986, 9025, 9064, 9103, 9143, 9182, 9222,
    9262, 9302, 9343, 9383, 9424, 9465, 9506, 9547, 9588, 9630, 9671, 9713,
    9755, 9798, 9840, 9883, 9926, 9969, 10012, 10055, 10099, 10143, 10187,
    10231, 10275, 10320, 10364, 10409, 10454, 10500, 10545, 10591, 10637, 10683,
    10729, 10776, 10822, 10869, 10916, 10964, 11011, 11059, 11107, 11155, 11203,
    11252, 11300, 11349, 11399, 11448, 11498, 11547, 11597, 11648, 11698, 11749,
    11800, 11851, 11902, 11954, 12006, 12058, 12110, 12162, 12215, 12268, 12321,
    12375, 12428, 12482, 12536, 12590, 12645, 12700, 12755, 12810, 12866, 12921,
    12977, 13034, 13090, 13147, 13204, 13261, 13318, 13376, 13434, 13492, 13551,
    13610, 13669, 13728, 13787, 13847, 13907, 13967, 14028, 14089, 14150, 14211,
    14273, 14334, 14396, 14459, 14522, 14584, 14648, 14711, 14775, 14839, 14903,
    14968, 15033, 15098, 15163, 15229, 15295, 15361, 15428, 15495, 15562, 15629,
    15697, 15765, 15833, 15902, 15971, 16040, 16109, 16179, 16249, 16320, 16391,
    16462, 16533, 16605, 16676, 16749, 16821, 16894, 16967, 17041, 17115, 17189,
  ],
  1130000: [
    3155, 3181, 3207, 3234, 3261, 3288, 3316, 3343, 3371, 3399, 3428, 3456,
    3485, 3514, 3543, 3573, 3603, 3633, 3663, 3693, 3724, 3755, 3786, 3818,
    3842, 3866, 3890, 3914, 3939, 3963, 3988, 4013, 4038, 4063, 4089, 4114,
    4140, 4166, 4192, 4218, 4245, 4271, 4298, 4325, 4352, 4379, 4406, 4434,
    4453, 4472, 4492, 4511, 4531, 4550, 4570, 4590, 4610, 4630, 4650, 4670,
    4690, 4711, 4731, 4751, 4772, 4793, 4813, 4834, 4855, 4876, 4897, 4919,
    4940, 4961, 4983, 5004, 5026, 5048, 5070, 5092, 5114, 5136, 5158, 5181,
    5203, 5226, 5248, 5271, 5294, 5317, 5340, 5363, 5386, 5410, 5433, 5457,
    5480, 5504, 5528, 5552, 5576, 5600, 5624, 5649, 5673, 5698, 5722, 5747,
    5772, 5797, 5822, 5847, 5873, 5898, 5924, 5949, 5975, 6001, 6027, 6053,
    6079, 6106, 6132, 6159, 6186, 6212, 6239, 6266, 6293, 6321, 6348, 6376,
    6403, 6431, 6459, 6487, 6515, 6543, 6572, 6600, 6629, 6657, 6686, 6715,
    6744, 6773, 6803, 6832, 6862, 6892, 6921, 6951, 6982, 7012, 7042, 7073,
    7103, 7134, 7165, 7196, 7227, 7259, 7290, 7322, 7353, 7385, 7417, 7449,
    7482, 7514, 7547, 7579, 7612, 7645, 7678, 7712, 7745, 7779, 7812, 7846,
    7880, 7914, 7949, 7983, 8018, 8052, 8087, 8122, 8158, 8193, 8228, 8264,
    8300, 8336, 8372, 8408, 8445, 8481, 8518, 8555, 8592, 8629, 8667, 8704,
    8742, 8780, 8818, 8856, 8894, 8933, 8972, 9011, 9050, 9089, 9128, 9168,
    9207, 9247, 9287, 9328, 9368, 9409, 9449, 9490, 9532, 9573, 9614, 9656,
    9698, 9740, 9782, 9824, 9867, 9910, 9953, 9996, 10039, 10083, 10126, 10170,
    10214, 10259, 10303, 10348, 10393, 10438, 10483, 10528, 10574, 10620, 10666,
    10712, 10758, 10805, 10852, 10899, 10946, 10993, 11041, 11089, 11137, 11185,
    11234, 11282, 11331, 11380, 11430, 11479, 11529, 11579, 11629, 11679, 11730,
    11781, 11832, 11883, 11935, 11986, 12038, 12091, 12143, 12196, 12248, 12301,
    12355, 12408, 12462, 12516, 12570, 12625, 12680, 12734, 12790, 12845, 12901,
    12957, 13013, 13069, 13126, 13183, 13240, 13297, 13355, 13413, 13471, 13529,
    13588, 13647, 13706, 13765, 13825, 13885, 13945, 14005, 14066, 14127, 14188,
    14250, 14311, 14373, 14436, 14498, 14561, 14624, 14688, 14751, 14815, 14879,
    14944, 15009, 15074, 15139, 15205, 15270, 15337, 15403, 15470, 15537, 15604,
    15672, 15740, 15808, 15876, 15945, 16014, 16084, 16153, 16223, 16294, 16364,
    16435, 16506, 16578, 16650, 16722, 16794, 16867, 16940, 17014, 17087,
  ],
  1140000: [
    3135, 3161, 3187, 3214, 3241, 3268, 3295, 3323, 3350, 3378, 3406, 3435,
    3463, 3492, 3521, 3551, 3580, 3610, 3640, 3670, 3701, 3732, 3763, 3794,
    3818, 3842, 3866, 3890, 3914, 3939, 3963, 3988, 4013, 4038, 4063, 4089,
    4114, 4140, 4166, 4192, 4218, 4245, 4271, 4298, 4325, 4352, 4379, 4406,
    4425, 4445, 4464, 4483, 4503, 4522, 4542, 4561, 4581, 4601, 4621, 4641,
    4661, 4681, 4702, 4722, 4742, 4763, 4784, 4804, 4825, 4846, 4867, 4888,
    4909, 4931, 4952, 4973, 4995, 5017, 5038, 5060, 5082, 5104, 5126, 5148,
    5171, 5193, 5216, 5238, 5261, 5284, 5307, 5330, 5353, 5376, 5399, 5423,
    5446, 5470, 5493, 5517, 5541, 5565, 5589, 5614, 5638, 5662, 5687, 5711,
    5736, 5761, 5786, 5811, 5836, 5862, 5887, 5912, 5938, 5964, 5990, 6016,
    6042, 6068, 6094, 6121, 6147, 6174, 6200, 6227, 6254, 6281, 6309, 6336,
    6363, 6391, 6419, 6447, 6474, 6503, 6531, 6559, 6587, 6616, 6645, 6673,
    6702, 6731, 6761, 6790, 6819, 6849, 6879, 6908, 6938, 6968, 6999, 7029,
    7059, 7090, 7121, 7151, 7182, 7214, 7245, 7276, 7308, 7339, 7371, 7403,
    7435, 7467, 7500, 7532, 7565, 7598, 7631, 7664, 7697, 7730, 7764, 7797,
    7831, 7865, 7899, 7934, 7968, 8002, 8037, 8072, 8107, 8142, 8177, 8213,
    8248, 8284, 8320, 8356, 8392, 8429, 8465, 8502, 8539, 8576, 8613, 8650,
    8688, 8725, 8763, 8801, 8839, 8877, 8916, 8955, 8993, 9032, 9072, 9111,
    9150, 9190, 9230, 9270, 9310, 9350, 9391, 9431, 9472, 9513, 9555, 9596,
    9638, 9679, 9721, 9763, 9806, 9848, 9891, 9934, 9977, 10020, 10063, 10107,
    10151, 10195, 10239, 10283, 10328, 10373, 10418, 10463, 10508, 10554, 10599,
    10645, 10691, 10738, 10784, 10831, 10878, 10925, 10973, 11020, 11068, 11116,
    11164, 11212, 11261, 11310, 11359, 11408, 11457, 11507, 11557, 11607, 11657,
    11708, 11758, 11809, 11861, 11912, 11964, 12015, 12068, 12120, 12172, 12225,
    12278, 12331, 12385, 12438, 12492, 12546, 12601, 12655, 12710, 12765, 12821,
    12876, 12932, 12988, 13044, 13101, 13158, 13215, 13272, 13329, 13387, 13445,
    13503, 13562, 13621, 13680, 13739, 13799, 13858, 13918, 13979, 14039, 14100,
    14161, 14223, 14284, 14346, 14408, 14471, 14533, 14596, 14660, 14723, 14787,
    14851, 14915, 14980, 15045, 15110, 15176, 15241, 15307, 15374, 15440, 15507,
    15574, 15642, 15710, 15778, 15846, 15915, 15984, 16053, 16123, 16193, 16263,
    16333, 16404, 16475, 16546, 16618, 16690, 16762, 16835, 16908, 16981,
  ],
  1150000: [
    3115, 3141, 3167, 3193, 3220, 3247, 3274, 3301, 3328, 3356, 3384, 3412,
    3441, 3469, 3498, 3527, 3557, 3586, 3616, 3647, 3677, 3708, 3738, 3770,
    3793, 3817, 3841, 3865, 3889, 3913, 3938, 3962, 3987, 4012, 4037, 4062,
    4088, 4113, 4139, 4165, 4191, 4217, 4243, 4270, 4297, 4323, 4350, 4378,
    4397, 4416, 4435, 4454, 4473, 4493, 4512, 4532, 4551, 4571, 4591, 4611,
    4631, 4651, 4671, 4691, 4712, 4732, 4752, 4773, 4794, 4814, 4835, 4856,
    4877, 4898, 4920, 4941, 4962, 4984, 5006, 5027, 5049, 5071, 5093, 5115,
    5137, 5159, 5182, 5204, 5227, 5249, 5272, 5295, 5318, 5341, 5364, 5387,
    5411, 5434, 5458, 5481, 5505, 5529, 5553, 5577, 5601, 5625, 5650, 5674,
    5699, 5724, 5748, 5773, 5798, 5823, 5849, 5874, 5899, 5925, 5951, 5976,
    6002, 6028, 6054, 6081, 6107, 6134, 6160, 6187, 6214, 6241, 6268, 6295,
    6322, 6349, 6377, 6405, 6432, 6460, 6488, 6516, 6545, 6573, 6601, 6630,
    6659, 6688, 6717, 6746, 6775, 6804, 6834, 6863, 6893, 6923, 6953, 6983,
    7013, 7044, 7074, 7105, 7136, 7167, 7198, 7229, 7260, 7292, 7323, 7355,
    7387, 7419, 7451, 7483, 7516, 7548, 7581, 7614, 7647, 7680, 7713, 7747,
    7780, 7814, 7848, 7882, 7916, 7950, 7985, 8019, 8054, 8089, 8124, 8159,
    8195, 8230, 8266, 8302, 8338, 8374, 8410, 8446, 8483, 8520, 8557, 8594,
    8631, 8668, 8706, 8744, 8782, 8820, 8858, 8896, 8935, 8974, 9012, 9051,
    9091, 9130, 9170, 9209, 9249, 9289, 9330, 9370, 9411, 9451, 9492, 9534,
    9575, 9616, 9658, 9700, 9742, 9784, 9827, 9869, 9912, 9955, 9998, 10041,
    10085, 10128, 10172, 10216, 10261, 10305, 10350, 10395, 10440, 10485, 10530,
    10576, 10622, 10668, 10714, 10761, 10807, 10854, 10901, 10948, 10996, 11043,
    11091, 11139, 11188, 11236, 11285, 11334, 11383, 11432, 11482, 11531, 11581,
    11632, 11682, 11733, 11783, 11834, 11886, 11937, 11989, 12041, 12093, 12145,
    12198, 12251, 12304, 12357, 12411, 12465, 12519, 12573, 12627, 12682, 12737,
    12792, 12848, 12903, 12959, 13016, 13072, 13129, 13185, 13243, 13300, 13358,
    13415, 13474, 13532, 13591, 13650, 13709, 13768, 13828, 13888, 13948, 14008,
    14069, 14130, 14191, 14253, 14314, 14376, 14439, 14501, 14564, 14627, 14691,
    14754, 14818, 14882, 14947, 15012, 15077, 15142, 15208, 15274, 15340, 15406,
    15473, 15540, 15607, 15675, 15743, 15811, 15880, 15949, 16018, 16087, 16157,
    16227, 16297, 16368, 16439, 16510, 16581, 16653, 16725, 16798, 16871,
  ],
  1160000: [
    3142, 3168, 3194, 3221, 3248, 3275, 3302, 3330, 3357, 3385, 3414, 3442,
    3471, 3500, 3529, 3558, 3588, 3618, 3648, 3678, 3709, 3740, 3771, 3802,
    3826, 3850, 3874, 3898, 3923, 3947, 3972, 3997, 4022, 4047, 4072, 4098,
    4123, 4149, 4175, 4201, 4227, 4254, 4280, 4307, 4334, 4361, 4388, 4416,
    4435, 4454, 4473, 4493, 4512, 4532, 4551, 4571, 4591, 4611, 4631, 4651,
    4671, 4691, 4712, 4732, 4752, 4773, 4794, 4815, 4835, 4856, 4877, 4899,
    4920, 4941, 4962, 4984, 5006, 5027, 5049, 5071, 5093, 5115, 5137, 5159,
    5182, 5204, 5227, 5249, 5272, 5295, 5318, 5341, 5364, 5387, 5411, 5434,
    5458, 5481, 5505, 5529, 5553, 5577, 5601, 5625, 5650, 5674, 5699, 5724,
    5748, 5773, 5798, 5823, 5849, 5874, 5899, 5925, 5951, 5977, 6002, 6028,
    6055, 6081, 6107, 6134, 6160, 6187, 6214, 6241, 6268, 6295, 6322, 6349,
    6377, 6405, 6432, 6460, 6488, 6516, 6545, 6573, 6601, 6630, 6659, 6688,
    6717, 6746, 6775, 6804, 6834, 6863, 6893, 6923, 6953, 6983, 7013, 7044,
    7074, 7105, 7136, 7167, 7198, 7229, 7260, 7292, 7323, 7355, 7387, 7419,
    7451, 7483, 7516, 7548, 7581, 7614, 7647, 7680, 7713, 7747, 7780, 7814,
    7848, 7882, 7916, 7950, 7985, 8019, 8054, 8089, 8124, 8159, 8195, 8230,
    8266, 8302, 8338, 8374, 8410, 8447, 8483, 8520, 8557, 8594, 8631, 8669,
    8706, 8744, 8782, 8820, 8858, 8896, 8935, 8974, 9013, 9052, 9091, 9130,
    9170, 9209, 9249, 9289, 9330, 9370, 9411, 9452, 9493, 9534, 9575, 9616,
    9658, 9700, 9742, 9784, 9827, 9869, 9912, 9955, 9998, 10041, 10085, 10129,
    10172, 10217, 10261, 10305, 10350, 10395, 10440, 10485, 10531, 10576, 10622,
    10668, 10714, 10761, 10807, 10854, 10901, 10948, 10996, 11043, 11091, 11139,
    11188, 11236, 11285, 11334, 11383, 11432, 11482, 11531, 11581, 11632, 11682,
    11733, 11784, 11835, 11886, 11937, 11989, 12041, 12093, 12146, 12198, 12251,
    12304, 12358, 12411, 12465, 12519, 12573, 12628, 12682, 12737, 12792, 12848,
    12904, 12959, 13016, 13072, 13129, 13186, 13243, 13300, 13358, 13416, 13474,
    13532, 13591, 13650, 13709, 13768, 13828, 13888, 13948, 14008, 14069, 14130,
    14191, 14253, 14315, 14377, 14439, 14501, 14564, 14627, 14691, 14754, 14818,
    14883, 14947, 15012, 15077, 15142, 15208, 15274, 15340, 15406, 15473, 15540,
    15608, 15675, 15743, 15811, 15880, 15949, 16018, 16087, 16157, 16227, 16297,
    16368, 16439, 16510, 16582, 16653, 16726, 16798, 16871, 16944, 17017,
  ],
  1170000: [
    3169, 3195, 3222, 3249, 3276, 3303, 3331, 3358, 3386, 3414, 3443, 3472,
    3501, 3530, 3559, 3589, 3619, 3649, 3679, 3710, 3741, 3772, 3803, 3835,
    3859, 3883, 3908, 3932, 3957, 3981, 4006, 4031, 4056, 4082, 4107, 4133,
    4159, 4185, 4211, 4237, 4264, 4290, 4317, 4344, 4371, 4399, 4426, 4454,
    4473, 4492, 4512, 4531, 4551, 4571, 4591, 4610, 4630, 4651, 4671, 4691,
    4711, 4732, 4752, 4773, 4793, 4814, 4835, 4856, 4877, 4898, 4919, 4941,
    4962, 4984, 5005, 5027, 5049, 5071, 5093, 5115, 5137, 5159, 5181, 5204,
    5226, 5249, 5272, 5295, 5318, 5341, 5364, 5387, 5410, 5434, 5457, 5481,
    5505, 5529, 5553, 5577, 5601, 5625, 5649, 5674, 5699, 5723, 5748, 5773,
    5798, 5823, 5848, 5874, 5899, 5925, 5950, 5976, 6002, 6028, 6054, 6080,
    6107, 6133, 6160, 6186, 6213, 6240, 6267, 6294, 6322, 6349, 6377, 6404,
    6432, 6460, 6488, 6516, 6544, 6573, 6601, 6630, 6658, 6687, 6716, 6745,
    6775, 6804, 6833, 6863, 6893, 6923, 6953, 6983, 7013, 7043, 7074, 7105,
    7135, 7166, 7197, 7228, 7260, 7291, 7323, 7355, 7386, 7418, 7451, 7483,
    7515, 7548, 7581, 7613, 7646, 7680, 7713, 7746, 7780, 7814, 7847, 7881,
    7916, 7950, 7984, 8019, 8054, 8089, 8124, 8159, 8194, 8230, 8265, 8301,
    8337, 8373, 8410, 8446, 8483, 8519, 8556, 8593, 8631, 8668, 8706, 8743,
    8781, 8819, 8857, 8896, 8934, 8973, 9012, 9051, 9090, 9130, 9169, 9209,
    9249, 9289, 9329, 9370, 9410, 9451, 9492, 9533, 9574, 9616, 9657, 9699,
    9741, 9784, 9826, 9869, 9911, 9954, 9997, 10041, 10084, 10128, 10172, 10216,
    10260, 10305, 10349, 10394, 10439, 10484, 10530, 10575, 10621, 10667, 10714,
    10760, 10807, 10853, 10900, 10948, 10995, 11043, 11091, 11139, 11187, 11235,
    11284, 11333, 11382, 11431, 11481, 11531, 11581, 11631, 11681, 11732, 11783,
    11834, 11885, 11937, 11988, 12040, 12092, 12145, 12197, 12250, 12303, 12357,
    12410, 12464, 12518, 12572, 12627, 12681, 12736, 12792, 12847, 12903, 12959,
    13015, 13071, 13128, 13185, 13242, 13299, 13357, 13415, 13473, 13531, 13590,
    13649, 13708, 13767, 13827, 13887, 13947, 14008, 14068, 14129, 14190, 14252,
    14314, 14376, 14438, 14501, 14563, 14627, 14690, 14754, 14817, 14882, 14946,
    15011, 15076, 15141, 15207, 15273, 15339, 15405, 15472, 15539, 15607, 15674,
    15742, 15810, 15879, 15948, 16017, 16086, 16156, 16226, 16296, 16367, 16438,
    16509, 16581, 16652, 16725, 16797, 16870, 16943, 17016, 17090, 17164,
  ],
  1180000: [
    3196, 3222, 3249, 3276, 3304, 3331, 3359, 3387, 3415, 3444, 3472, 3501,
    3530, 3560, 3590, 3619, 3650, 3680, 3711, 3742, 3773, 3804, 3836, 3868,
    3892, 3916, 3941, 3966, 3990, 4015, 4040, 4066, 4091, 4117, 4142, 4168,
    4194, 4220, 4247, 4273, 4300, 4327, 4354, 4381, 4409, 4436, 4464, 4492,
    4511, 4531, 4550, 4570, 4590, 4610, 4630, 4650, 4670, 4690, 4711, 4731,
    4752, 4772, 4793, 4814, 4834, 4855, 4876, 4898, 4919, 4940, 4961, 4983,
    5005, 5026, 5048, 5070, 5092, 5114, 5136, 5158, 5181, 5203, 5226, 5248,
    5271, 5294, 5317, 5340, 5363, 5386, 5410, 5433, 5457, 5480, 5504, 5528,
    5552, 5576, 5600, 5624, 5649, 5673, 5698, 5722, 5747, 5772, 5797, 5822,
    5848, 5873, 5898, 5924, 5950, 5975, 6001, 6027, 6053, 6080, 6106, 6132,
    6159, 6186, 6212, 6239, 6266, 6294, 6321, 6348, 6376, 6403, 6431, 6459,
    6487, 6515, 6543, 6572, 6600, 6629, 6657, 6686, 6715, 6744, 6774, 6803,
    6832, 6862, 6892, 6922, 6952, 6982, 7012, 7042, 7073, 7104, 7134, 7165,
    7196, 7227, 7259, 7290, 7322, 7354, 7385, 7417, 7450, 7482, 7514, 7547,
    7580, 7612, 7645, 7679, 7712, 7745, 7779, 7812, 7846, 7880, 7914, 7949,
    7983, 8018, 8053, 8087, 8122, 8158, 8193, 8229, 8264, 8300, 8336, 8372,
    8408, 8445, 8481, 8518, 8555, 8592, 8629, 8667, 8704, 8742, 8780, 8818,
    8856, 8895, 8933, 8972, 9011, 9050, 9089, 9128, 9168, 9208, 9248, 9288,
    9328, 9368, 9409, 9450, 9491, 9532, 9573, 9615, 9656, 9698, 9740, 9782,
    9825, 9867, 9910, 9953, 9996, 10039, 10083, 10127, 10170, 10214, 10259,
    10303, 10348, 10393, 10438, 10483, 10528, 10574, 10620, 10666, 10712, 10758,
    10805, 10852, 10899, 10946, 10994, 11041, 11089, 11137, 11185, 11234, 11283,
    11331, 11381, 11430, 11479, 11529, 11579, 11629, 11680, 11730, 11781, 11832,
    11883, 11935, 11987, 12039, 12091, 12143, 12196, 12249, 12302, 12355, 12409,
    12462, 12516, 12571, 12625, 12680, 12735, 12790, 12845, 12901, 12957, 13013,
    13069, 13126, 13183, 13240, 13297, 13355, 13413, 13471, 13529, 13588, 13647,
    13706, 13765, 13825, 13885, 13945, 14006, 14066, 14127, 14188, 14250, 14312,
    14374, 14436, 14499, 14561, 14624, 14688, 14752, 14815, 14880, 14944, 15009,
    15074, 15139, 15205, 15271, 15337, 15403, 15470, 15537, 15604, 15672, 15740,
    15808, 15877, 15946, 16015, 16084, 16154, 16224, 16294, 16365, 16436, 16507,
    16578, 16650, 16722, 16795, 16868, 16941, 17014, 17088, 17162, 17236, 17311,
  ],
  1190000: [
    3223, 3250, 3277, 3304, 3332, 3359, 3387, 3416, 3444, 3473, 3502, 3531,
    3560, 3590, 3620, 3650, 3681, 3711, 3742, 3773, 3805, 3837, 3868, 3901,
    3925, 3950, 3974, 3999, 4024, 4049, 4075, 4100, 4126, 4151, 4177, 4204,
    4230, 4256, 4283, 4310, 4337, 4364, 4391, 4418, 4446, 4474, 4502, 4530,
    4549, 4569, 4589, 4609, 4629, 4649, 4669, 4689, 4710, 4730, 4751, 4771,
    4792, 4813, 4833, 4854, 4875, 4897, 4918, 4939, 4960, 4982, 5004, 5025,
    5047, 5069, 5091, 5113, 5135, 5157, 5180, 5202, 5225, 5247, 5270, 5293,
    5316, 5339, 5362, 5385, 5409, 5432, 5455, 5479, 5503, 5527, 5551, 5575,
    5599, 5623, 5648, 5672, 5697, 5721, 5746, 5771, 5796, 5821, 5846, 5872,
    5897, 5923, 5948, 5974, 6000, 6026, 6052, 6078, 6105, 6131, 6158, 6184,
    6211, 6238, 6265, 6292, 6319, 6347, 6374, 6402, 6430, 6458, 6486, 6514,
    6542, 6570, 6599, 6627, 6656, 6685, 6714, 6743, 6772, 6802, 6831, 6861,
    6890, 6920, 6950, 6980, 7011, 7041, 7071, 7102, 7133, 7164, 7195, 7226,
    7257, 7289, 7320, 7352, 7384, 7416, 7448, 7480, 7513, 7545, 7578, 7611,
    7644, 7677, 7710, 7744, 7777, 7811, 7845, 7879, 7913, 7947, 7982, 8016,
    8051, 8086, 8121, 8156, 8191, 8227, 8262, 8298, 8334, 8370, 8407, 8443,
    8480, 8516, 8553, 8590, 8628, 8665, 8703, 8740, 8778, 8816, 8854, 8893,
    8931, 8970, 9009, 9048, 9087, 9126, 9166, 9206, 9246, 9286, 9326, 9366,
    9407, 9448, 9489, 9530, 9571, 9612, 9654, 9696, 9738, 9780, 9823, 9865,
    9908, 9951, 9994, 10037, 10081, 10124, 10168, 10212, 10257, 10301, 10346,
    10391, 10436, 10481, 10526, 10572, 10618, 10664, 10710, 10756, 10803, 10850,
    10897, 10944, 10991, 11039, 11087, 11135, 11183, 11232, 11280, 11329, 11378,
    11427, 11477, 11527, 11577, 11627, 11677, 11728, 11779, 11830, 11881, 11932,
    11984, 12036, 12088, 12141, 12193, 12246, 12299, 12352, 12406, 12460, 12514,
    12568, 12622, 12677, 12732, 12787, 12843, 12898, 12954, 13010, 13067, 13123,
    13180, 13237, 13295, 13352, 13410, 13468, 13527, 13585, 13644, 13703, 13763,
    13822, 13882, 13942, 14003, 14063, 14124, 14186, 14247, 14309, 14371, 14433,
    14496, 14558, 14621, 14685, 14748, 14812, 14877, 14941, 15006, 15071, 15136,
    15202, 15268, 15334, 15400, 15467, 15534, 15601, 15669, 15737, 15805, 15873,
    15942, 16011, 16081, 16150, 16220, 16291, 16361, 16432, 16503, 16575, 16647,
    16719, 16791, 16864, 16937, 17010, 17084, 17158, 17233, 17307, 17382, 17458,
  ],
  1200000: [
    3250, 3277, 3304, 3332, 3360, 3388, 3416, 3444, 3473, 3502, 3531, 3561,
    3590, 3620, 3650, 3681, 3711, 3742, 3774, 3805, 3837, 3869, 3901, 3933,
    3958, 3983, 4008, 4033, 4058, 4083, 4109, 4135, 4160, 4186, 4213, 4239,
    4265, 4292, 4319, 4346, 4373, 4400, 4428, 4455, 4483, 4511, 4540, 4568,
    4588, 4608, 4628, 4648, 4668, 4688, 4708, 4729, 4749, 4770, 4790, 4811,
    4832, 4853, 4874, 4895, 4916, 4938, 4959, 4981, 5002, 5024, 5046, 5067,
    5089, 5111, 5134, 5156, 5178, 5201, 5223, 5246, 5269, 5291, 5314, 5337,
    5360, 5384, 5407, 5430, 5454, 5478, 5501, 5525, 5549, 5573, 5597, 5622,
    5646, 5670, 5695, 5720, 5744, 5769, 5794, 5819, 5845, 5870, 5895, 5921,
    5947, 5972, 5998, 6024, 6050, 6077, 6103, 6129, 6156, 6183, 6209, 6236,
    6263, 6290, 6318, 6345, 6373, 6400, 6428, 6456, 6484, 6512, 6540, 6568,
    6597, 6625, 6654, 6683, 6712, 6741, 6770, 6800, 6829, 6859, 6888, 6918,
    6948, 6978, 7009, 7039, 7069, 7100, 7131, 7162, 7193, 7224, 7255, 7287,
    7318, 7350, 7382, 7414, 7446, 7478, 7511, 7543, 7576, 7609, 7642, 7675,
    7708, 7741, 7775, 7809, 7842, 7876, 7911, 7945, 7979, 8014, 8049, 8083,
    8119, 8154, 8189, 8225, 8260, 8296, 8332, 8368, 8404, 8441, 8477, 8514,
    8551, 8588, 8625, 8663, 8700, 8738, 8776, 8814, 8852, 8890, 8929, 8967,
    9006, 9045, 9085, 9124, 9163, 9203, 9243, 9283, 9323, 9364, 9404, 9445,
    9486, 9527, 9568, 9610, 9651, 9693, 9735, 9777, 9820, 9862, 9905, 9948,
    9991, 10034, 10078, 10122, 10165, 10210, 10254, 10298, 10343, 10388, 10433,
    10478, 10523, 10569, 10615, 10661, 10707, 10753, 10800, 10847, 10894, 10941,
    10988, 11036, 11084, 11132, 11180, 11228, 11277, 11326, 11375, 11424, 11474,
    11524, 11573, 11624, 11674, 11725, 11775, 11826, 11878, 11929, 11981, 12033,
    12085, 12137, 12190, 12243, 12296, 12349, 12403, 12456, 12510, 12564, 12619,
    12674, 12728, 12784, 12839, 12895, 12951, 13007, 13063, 13120, 13176, 13234,
    13291, 13349, 13406, 13464, 13523, 13581, 13640, 13699, 13759, 13818, 13878,
    13938, 13999, 14059, 14120, 14182, 14243, 14305, 14367, 14429, 14491, 14554,
    14617, 14681, 14744, 14808, 14872, 14937, 15002, 15067, 15132, 15197, 15263,
    15329, 15396, 15463, 15530, 15597, 15664, 15732, 15800, 15869, 15938, 16007,
    16076, 16146, 16216, 16286, 16357, 16427, 16499, 16570, 16642, 16714, 16787,
    16859, 16932, 17006, 17079, 17153, 17228, 17302, 17377, 17453, 17528, 17604,
  ],
  1210000: [
    3277, 3304, 3332, 3360, 3388, 3416, 3444, 3473, 3502, 3531, 3561, 3590,
    3620, 3650, 3681, 3711, 3742, 3774, 3805, 3837, 3869, 3901, 3933, 3966,
    3991, 4016, 4041, 4066, 4092, 4117, 4143, 4169, 4195, 4221, 4248, 4274,
    4301, 4328, 4355, 4382, 4409, 4437, 4465, 4493, 4521, 4549, 4577, 4606,
    4626, 4646, 4666, 4686, 4707, 4727, 4748, 4768, 4789, 4810, 4830, 4851,
    4872, 4893, 4915, 4936, 4957, 4979, 5000, 5022, 5044, 5066, 5088, 5110,
    5132, 5154, 5176, 5199, 5221, 5244, 5267, 5290, 5312, 5335, 5359, 5382,
    5405, 5429, 5452, 5476, 5499, 5523, 5547, 5571, 5595, 5620, 5644, 5668,
    5693, 5718, 5742, 5767, 5792, 5817, 5843, 5868, 5893, 5919, 5945, 5970,
    5996, 6022, 6048, 6074, 6101, 6127, 6154, 6180, 6207, 6234, 6261, 6288,
    6316, 6343, 6370, 6398, 6426, 6454, 6482, 6510, 6538, 6566, 6595, 6623,
    6652, 6681, 6710, 6739, 6768, 6797, 6827, 6856, 6886, 6916, 6946, 6976,
    7006, 7036, 7067, 7098, 7128, 7159, 7190, 7221, 7253, 7284, 7316, 7347,
    7379, 7411, 7443, 7476, 7508, 7541, 7573, 7606, 7639, 7672, 7705, 7739,
    7772, 7806, 7840, 7874, 7908, 7942, 7977, 8011, 8046, 8081, 8116, 8151,
    8186, 8222, 8257, 8293, 8329, 8365, 8401, 8438, 8474, 8511, 8548, 8585,
    8622, 8660, 8697, 8735, 8773, 8811, 8849, 8887, 8926, 8964, 9003, 9042,
    9081, 9121, 9160, 9200, 9240, 9280, 9320, 9360, 9401, 9442, 9483, 9524,
    9565, 9606, 9648, 9690, 9732, 9774, 9816, 9859, 9902, 9945, 9988, 10031,
    10074, 10118, 10162, 10206, 10250, 10295, 10339, 10384, 10429, 10474, 10520,
    10565, 10611, 10657, 10703, 10749, 10796, 10843, 10890, 10937, 10984, 11032,
    11080, 11128, 11176, 11224, 11273, 11322, 11371, 11420, 11470, 11520, 11569,
    11620, 11670, 11720, 11771, 11822, 11874, 11925, 11977, 12029, 12081, 12133,
    12186, 12238, 12291, 12345, 12398, 12452, 12506, 12560, 12614, 12669, 12724,
    12779, 12835, 12890, 12946, 13002, 13058, 13115, 13172, 13229, 13286, 13344,
    13402, 13460, 13518, 13577, 13635, 13695, 13754, 13814, 13873, 13934, 13994,
    14055, 14115, 14177, 14238, 14300, 14362, 14424, 14486, 14549, 14612, 14676,
    14739, 14803, 14867, 14932, 14996, 15061, 15127, 15192, 15258, 15324, 15390,
    15457, 15524, 15591, 15659, 15727, 15795, 15863, 15932, 16001, 16071, 16140,
    16210, 16280, 16351, 16422, 16493, 16564, 16636, 16708, 16781, 16853, 16926,
    17000, 17073, 17147, 17222, 17296, 17371, 17447, 17522, 17598, 17674, 17751,
  ],
  1220000: [
    3304, 3332, 3359, 3387, 3416, 3444, 3473, 3502, 3531, 3560, 3590, 3620,
    3650, 3681, 3711, 3742, 3773, 3805, 3837, 3868, 3901, 3933, 3966, 3999,
    4024, 4049, 4075, 4100, 4126, 4151, 4177, 4203, 4230, 4256, 4283, 4310,
    4336, 4364, 4391, 4418, 4446, 4474, 4502, 4530, 4558, 4587, 4615, 4644,
    4664, 4684, 4705, 4725, 4746, 4766, 4787, 4808, 4828, 4849, 4870, 4891,
    4913, 4934, 4955, 4977, 4998, 5020, 5042, 5064, 5086, 5108, 5130, 5152,
    5174, 5197, 5219, 5242, 5264, 5287, 5310, 5333, 5356, 5380, 5403, 5426,
    5450, 5473, 5497, 5521, 5545, 5569, 5593, 5617, 5642, 5666, 5691, 5715,
    5740, 5765, 5790, 5815, 5840, 5865, 5891, 5916, 5942, 5968, 5994, 6020,
    6046, 6072, 6098, 6125, 6151, 6178, 6205, 6232, 6259, 6286, 6313, 6340,
    6368, 6395, 6423, 6451, 6479, 6507, 6535, 6563, 6592, 6620, 6649, 6678,
    6707, 6736, 6765, 6794, 6824, 6853, 6883, 6913, 6943, 6973, 7003, 7034,
    7064, 7095, 7125, 7156, 7187, 7218, 7250, 7281, 7313, 7344, 7376, 7408,
    7440, 7472, 7505, 7537, 7570, 7603, 7636, 7669, 7702, 7735, 7769, 7803,
    7836, 7870, 7905, 7939, 7973, 8008, 8042, 8077, 8112, 8147, 8183, 8218,
    8254, 8290, 8326, 8362, 8398, 8434, 8471, 8507, 8544, 8581, 8619, 8656,
    8693, 8731, 8769, 8807, 8845, 8883, 8922, 8961, 8999, 9038, 9078, 9117,
    9156, 9196, 9236, 9276, 9316, 9357, 9397, 9438, 9479, 9520, 9561, 9602,
    9644, 9686, 9728, 9770, 9812, 9855, 9898, 9940, 9983, 10027, 10070, 10114,
    10158, 10202, 10246, 10290, 10335, 10380, 10425, 10470, 10515, 10561, 10607,
    10652, 10699, 10745, 10792, 10838, 10885, 10932, 10980, 11027, 11075, 11123,
    11171, 11220, 11268, 11317, 11366, 11416, 11465, 11515, 11565, 11615, 11665,
    11716, 11766, 11817, 11869, 11920, 11972, 12024, 12076, 12128, 12180, 12233,
    12286, 12340, 12393, 12447, 12501, 12555, 12609, 12664, 12719, 12774, 12829,
    12885, 12941, 12997, 13053, 13110, 13166, 13223, 13281, 13338, 13396, 13454,
    13512, 13571, 13630, 13689, 13748, 13808, 13868, 13928, 13988, 14049, 14110,
    14171, 14232, 14294, 14356, 14418, 14480, 14543, 14606, 14669, 14733, 14797,
    14861, 14925, 14990, 15055, 15120, 15186, 15252, 15318, 15384, 15451, 15518,
    15585, 15652, 15720, 15788, 15857, 15926, 15995, 16064, 16133, 16203, 16274,
    16344, 16415, 16486, 16557, 16629, 16701, 16774, 16846, 16919, 16993, 17066,
    17140, 17215, 17289, 17364, 17439, 17515, 17591, 17667, 17744, 17820, 17898,
  ],
  1230000: [
    3331, 3359, 3387, 3415, 3444, 3472, 3501, 3530, 3560, 3590, 3619, 3650,
    3680, 3711, 3742, 3773, 3804, 3836, 3868, 3900, 3933, 3965, 3999, 4032,
    4057, 4082, 4108, 4134, 4159, 4185, 4212, 4238, 4264, 4291, 4318, 4345,
    4372, 4399, 4427, 4454, 4482, 4510, 4539, 4567, 4595, 4624, 4653, 4682,
    4702, 4723, 4743, 4764, 4784, 4805, 4826, 4847, 4868, 4889, 4910, 4931,
    4953, 4974, 4996, 5018, 5039, 5061, 5083, 5105, 5127, 5149, 5172, 5194,
    5217, 5239, 5262, 5285, 5308, 5331, 5354, 5377, 5400, 5424, 5447, 5471,
    5494, 5518, 5542, 5566, 5590, 5615, 5639, 5663, 5688, 5712, 5737, 5762,
    5787, 5812, 5837, 5863, 5888, 5914, 5939, 5965, 5991, 6017, 6043, 6069,
    6095, 6122, 6148, 6175, 6202, 6228, 6255, 6283, 6310, 6337, 6365, 6392,
    6420, 6448, 6476, 6504, 6532, 6560, 6589, 6617, 6646, 6675, 6704, 6733,
    6762, 6791, 6821, 6850, 6880, 6910, 6940, 6970, 7000, 7030, 7061, 7091,
    7122, 7153, 7184, 7215, 7246, 7278, 7309, 7341, 7373, 7405, 7437, 7469,
    7501, 7534, 7566, 7599, 7632, 7665, 7698, 7732, 7765, 7799, 7833, 7867,
    7901, 7935, 7969, 8004, 8039, 8073, 8108, 8144, 8179, 8214, 8250, 8286,
    8321, 8358, 8394, 8430, 8467, 8503, 8540, 8577, 8614, 8652, 8689, 8727,
    8765, 8803, 8841, 8879, 8918, 8956, 8995, 9034, 9073, 9112, 9152, 9192,
    9231, 9271, 9312, 9352, 9393, 9433, 9474, 9515, 9556, 9598, 9639, 9681,
    9723, 9765, 9808, 9850, 9893, 9936, 9979, 10022, 10065, 10109, 10153, 10197,
    10241, 10285, 10330, 10375, 10420, 10465, 10510, 10556, 10601, 10647, 10693,
    10740, 10786, 10833, 10880, 10927, 10975, 11022, 11070, 11118, 11166, 11214,
    11263, 11312, 11361, 11410, 11459, 11509, 11559, 11609, 11659, 11710, 11761,
    11812, 11863, 11914, 11966, 12018, 12070, 12122, 12175, 12227, 12280, 12334,
    12387, 12441, 12495, 12549, 12603, 12658, 12713, 12768, 12823, 12879, 12934,
    12990, 13047, 13103, 13160, 13217, 13274, 13332, 13390, 13448, 13506, 13564,
    13623, 13682, 13742, 13801, 13861, 13921, 13981, 14042, 14103, 14164, 14225,
    14287, 14349, 14411, 14473, 14536, 14599, 14662, 14726, 14790, 14854, 14918,
    14983, 15048, 15113, 15178, 15244, 15310, 15377, 15443, 15510, 15577, 15645,
    15713, 15781, 15849, 15918, 15987, 16056, 16126, 16195, 16266, 16336, 16407,
    16478, 16549, 16621, 16693, 16766, 16838, 16911, 16984, 17058, 17132, 17206,
    17281, 17356, 17431, 17506, 17582, 17658, 17735, 17812, 17889, 17966, 18044,
  ],
  1240000: [
    3358, 3386, 3415, 3443, 3472, 3501, 3530, 3559, 3589, 3619, 3649, 3679,
    3710, 3741, 3772, 3804, 3835, 3867, 3899, 3932, 3965, 3998, 4031, 4065,
    4090, 4116, 4141, 4167, 4193, 4219, 4246, 4272, 4299, 4326, 4353, 4380,
    4408, 4435, 4463, 4491, 4519, 4547, 4575, 4604, 4633, 4662, 4691, 4720,
    4741, 4761, 4782, 4803, 4823, 4844, 4865, 4886, 4908, 4929, 4950, 4972,
    4993, 5015, 5036, 5058, 5080, 5102, 5124, 5147, 5169, 5191, 5214, 5236,
    5259, 5282, 5305, 5328, 5351, 5374, 5397, 5421, 5444, 5468, 5491, 5515,
    5539, 5563, 5587, 5611, 5636, 5660, 5685, 5709, 5734, 5759, 5784, 5809,
    5834, 5859, 5885, 5910, 5936, 5962, 5987, 6013, 6039, 6066, 6092, 6118,
    6145, 6171, 6198, 6225, 6252, 6279, 6306, 6334, 6361, 6389, 6416, 6444,
    6472, 6500, 6528, 6557, 6585, 6614, 6642, 6671, 6700, 6729, 6758, 6787,
    6817, 6846, 6876, 6906, 6936, 6966, 6996, 7026, 7057, 7087, 7118, 7149,
    7180, 7211, 7242, 7274, 7305, 7337, 7369, 7400, 7433, 7465, 7497, 7530,
    7562, 7595, 7628, 7661, 7694, 7727, 7761, 7795, 7828, 7862, 7896, 7931,
    7965, 7999, 8034, 8069, 8104, 8139, 8174, 8210, 8245, 8281, 8317, 8353,
    8389, 8425, 8462, 8499, 8536, 8572, 8610, 8647, 8684, 8722, 8760, 8798,
    8836, 8874, 8913, 8951, 8990, 9029, 9068, 9107, 9147, 9187, 9226, 9266,
    9307, 9347, 9387, 9428, 9469, 9510, 9551, 9593, 9634, 9676, 9718, 9760,
    9802, 9845, 9887, 9930, 9973, 10016, 10060, 10103, 10147, 10191, 10235,
    10280, 10324, 10369, 10414, 10459, 10504, 10550, 10596, 10641, 10688, 10734,
    10780, 10827, 10874, 10921, 10968, 11016, 11064, 11112, 11160, 11208, 11257,
    11306, 11355, 11404, 11453, 11503, 11553, 11603, 11653, 11703, 11754, 11805,
    11856, 11908, 11959, 12011, 12063, 12115, 12168, 12221, 12274, 12327, 12380,
    12434, 12488, 12542, 12596, 12651, 12706, 12761, 12816, 12871, 12927, 12983,
    13040, 13096, 13153, 13210, 13267, 13324, 13382, 13440, 13498, 13557, 13616,
    13675, 13734, 13793, 13853, 13913, 13974, 14034, 14095, 14156, 14217, 14279,
    14341, 14403, 14465, 14528, 14591, 14654, 14718, 14782, 14846, 14910, 14975,
    15039, 15105, 15170, 15236, 15302, 15368, 15435, 15502, 15569, 15636, 15704,
    15772, 15840, 15909, 15978, 16047, 16117, 16187, 16257, 16327, 16398, 16469,
    16540, 16612, 16684, 16756, 16829, 16902, 16975, 17049, 17123, 17197, 17271,
    17346, 17421, 17497, 17573, 17649, 17725, 17802, 17879, 17957, 18034, 18113,
    18191,
  ],
  1250000: [
    3385, 3414, 3442, 3471, 3500, 3529, 3558, 3588, 3618, 3648, 3678, 3709,
    3740, 3771, 3803, 3834, 3866, 3898, 3931, 3964, 3997, 4030, 4064, 4097,
    4123, 4149, 4175, 4201, 4227, 4253, 4280, 4307, 4334, 4361, 4388, 4415,
    4443, 4471, 4499, 4527, 4555, 4584, 4612, 4641, 4670, 4699, 4729, 4758,
    4779, 4800, 4820, 4841, 4862, 4883, 4904, 4926, 4947, 4969, 4990, 5012,
    5033, 5055, 5077, 5099, 5121, 5143, 5166, 5188, 5211, 5233, 5256, 5279,
    5301, 5324, 5348, 5371, 5394, 5417, 5441, 5464, 5488, 5512, 5536, 5560,
    5584, 5608, 5632, 5657, 5681, 5706, 5731, 5755, 5780, 5805, 5831, 5856,
    5881, 5907, 5932, 5958, 5984, 6010, 6036, 6062, 6088, 6115, 6141, 6168,
    6194, 6221, 6248, 6275, 6302, 6330, 6357, 6385, 6412, 6440, 6468, 6496,
    6524, 6553, 6581, 6609, 6638, 6667, 6696, 6725, 6754, 6783, 6813, 6842,
    6872, 6902, 6931, 6961, 6992, 7022, 7052, 7083, 7114, 7144, 7175, 7207,
    7238, 7269, 7301, 7332, 7364, 7396, 7428, 7460, 7492, 7525, 7558, 7590,
    7623, 7656, 7689, 7723, 7756, 7790, 7824, 7857, 7892, 7926, 7960, 7995,
    8029, 8064, 8099, 8134, 8169, 8205, 8240, 8276, 8312, 8348, 8384, 8420,
    8457, 8493, 8530, 8567, 8604, 8642, 8679, 8717, 8754, 8792, 8830, 8869,
    8907, 8946, 8985, 9023, 9063, 9102, 9141, 9181, 9221, 9261, 9301, 9341,
    9382, 9422, 9463, 9504, 9545, 9587, 9628, 9670, 9712, 9754, 9796, 9839,
    9881, 9924, 9967, 10010, 10054, 10097, 10141, 10185, 10229, 10273, 10318,
    10363, 10407, 10453, 10498, 10543, 10589, 10635, 10681, 10727, 10774, 10820,
    10867, 10914, 10962, 11009, 11057, 11105, 11153, 11201, 11250, 11299, 11348,
    11397, 11446, 11496, 11546, 11596, 11646, 11696, 11747, 11798, 11849, 11900,
    11952, 12004, 12056, 12108, 12160, 12213, 12266, 12319, 12373, 12426, 12480,
    12534, 12588, 12643, 12698, 12753, 12808, 12864, 12919, 12975, 13031, 13088,
    13145, 13202, 13259, 13316, 13374, 13432, 13490, 13549, 13607, 13666, 13726,
    13785, 13845, 13905, 13965, 14025, 14086, 14147, 14209, 14270, 14332, 14394,
    14456, 14519, 14582, 14645, 14709, 14772, 14836, 14901, 14965, 15030, 15095,
    15161, 15226, 15292, 15359, 15425, 15492, 15559, 15627, 15694, 15762, 15831,
    15899, 15968, 16037, 16107, 16177, 16247, 16317, 16388, 16459, 16530, 16602,
    16674, 16746, 16819, 16891, 16965, 17038, 17112, 17186, 17261, 17335, 17411,
    17486, 17562, 17638, 17714, 17791, 17868, 17946, 18023, 18101, 18180, 18259,
    18338,
  ],
  1260000: [
    3360, 3388, 3416, 3445, 3473, 3502, 3532, 3561, 3591, 3621, 3651, 3681,
    3712, 3743, 3774, 3805, 3837, 3869, 3901, 3934, 3967, 4000, 4033, 4067,
    4092, 4118, 4143, 4169, 4195, 4222, 4248, 4274, 4301, 4328, 4355, 4382,
    4410, 4437, 4465, 4493, 4521, 4549, 4578, 4606, 4635, 4664, 4693, 4723,
    4743, 4764, 4784, 4805, 4826, 4847, 4868, 4889, 4910, 4931, 4953, 4974,
    4996, 5017, 5039, 5061, 5083, 5105, 5127, 5149, 5171, 5194, 5216, 5239,
    5262, 5284, 5307, 5330, 5353, 5377, 5400, 5423, 5447, 5470, 5494, 5518,
    5542, 5566, 5590, 5614, 5639, 5663, 5688, 5712, 5737, 5762, 5787, 5812,
    5837, 5862, 5888, 5913, 5939, 5965, 5990, 6016, 6042, 6069, 6095, 6121,
    6148, 6175, 6201, 6228, 6255, 6282, 6309, 6337, 6364, 6392, 6420, 6447,
    6475, 6503, 6532, 6560, 6588, 6617, 6646, 6674, 6703, 6732, 6761, 6791,
    6820, 6850, 6879, 6909, 6939, 6969, 6999, 7030, 7060, 7091, 7122, 7152,
    7183, 7215, 7246, 7277, 7309, 7340, 7372, 7404, 7436, 7468, 7501, 7533,
    7566, 7599, 7632, 7665, 7698, 7731, 7765, 7798, 7832, 7866, 7900, 7935,
    7969, 8003, 8038, 8073, 8108, 8143, 8178, 8214, 8249, 8285, 8321, 8357,
    8393, 8430, 8466, 8503, 8540, 8577, 8614, 8651, 8689, 8726, 8764, 8802,
    8840, 8879, 8917, 8956, 8995, 9034, 9073, 9112, 9151, 9191, 9231, 9271,
    9311, 9351, 9392, 9433, 9474, 9515, 9556, 9597, 9639, 9681, 9723, 9765,
    9807, 9850, 9892, 9935, 9978, 10021, 10065, 10108, 10152, 10196, 10240,
    10285, 10329, 10374, 10419, 10464, 10510, 10555, 10601, 10647, 10693, 10739,
    10786, 10832, 10879, 10927, 10974, 11021, 11069, 11117, 11165, 11214, 11262,
    11311, 11360, 11409, 11459, 11508, 11558, 11608, 11659, 11709, 11760, 11811,
    11862, 11914, 11965, 12017, 12069, 12121, 12174, 12227, 12280, 12333, 12386,
    12440, 12494, 12548, 12602, 12657, 12712, 12767, 12822, 12878, 12934, 12990,
    13046, 13103, 13159, 13216, 13274, 13331, 13389, 13447, 13505, 13564, 13622,
    13681, 13741, 13800, 13860, 13920, 13980, 14041, 14102, 14163, 14224, 14286,
    14348, 14410, 14473, 14535, 14598, 14662, 14725, 14789, 14853, 14917, 14982,
    15047, 15112, 15178, 15243, 15309, 15376, 15442, 15509, 15576, 15644, 15712,
    15780, 15848, 15917, 15986, 16055, 16125, 16195, 16265, 16335, 16406, 16477,
    16549, 16620, 16692, 16765, 16837, 16910, 16983, 17057, 17131, 17205, 17280,
    17355, 17430, 17505, 17581, 17657, 17734, 17811, 17888, 17966, 18043, 18122,
    18200,
  ],
  1270000: [
    3334, 3362, 3390, 3418, 3446, 3475, 3504, 3533, 3563, 3592, 3622, 3652,
    3683, 3714, 3744, 3776, 3807, 3839, 3871, 3903, 3936, 3968, 4002, 4035,
    4060, 4085, 4111, 4137, 4163, 4189, 4215, 4241, 4268, 4294, 4321, 4348,
    4375, 4403, 4430, 4458, 4486, 4514, 4542, 4570, 4599, 4628, 4657, 4686,
    4706, 4726, 4747, 4767, 4788, 4809, 4830, 4851, 4872, 4893, 4914, 4935,
    4957, 4978, 5000, 5021, 5043, 5065, 5087, 5109, 5131, 5153, 5176, 5198,
    5221, 5243, 5266, 5289, 5312, 5335, 5358, 5381, 5404, 5428, 5451, 5475,
    5499, 5522, 5546, 5570, 5595, 5619, 5643, 5668, 5692, 5717, 5742, 5766,
    5791, 5817, 5842, 5867, 5892, 5918, 5944, 5969, 5995, 6021, 6047, 6074,
    6100, 6126, 6153, 6180, 6206, 6233, 6260, 6287, 6315, 6342, 6369, 6397,
    6425, 6453, 6481, 6509, 6537, 6565, 6594, 6622, 6651, 6680, 6709, 6738,
    6767, 6796, 6826, 6855, 6885, 6915, 6945, 6975, 7005, 7035, 7066, 7097,
    7127, 7158, 7189, 7220, 7252, 7283, 7315, 7346, 7378, 7410, 7442, 7474,
    7507, 7539, 7572, 7605, 7638, 7671, 7704, 7738, 7771, 7805, 7839, 7873,
    7907, 7941, 7975, 8010, 8045, 8079, 8114, 8150, 8185, 8220, 8256, 8292,
    8328, 8364, 8400, 8436, 8473, 8510, 8547, 8584, 8621, 8658, 8696, 8733,
    8771, 8809, 8847, 8886, 8924, 8963, 9002, 9041, 9080, 9119, 9159, 9199,
    9238, 9278, 9319, 9359, 9400, 9440, 9481, 9522, 9564, 9605, 9647, 9688,
    9730, 9773, 9815, 9857, 9900, 9943, 9986, 10029, 10073, 10117, 10160, 10204,
    10249, 10293, 10338, 10382, 10427, 10473, 10518, 10564, 10609, 10655, 10701,
    10748, 10794, 10841, 10888, 10935, 10983, 11030, 11078, 11126, 11174, 11223,
    11271, 11320, 11369, 11419, 11468, 11518, 11568, 11618, 11668, 11719, 11769,
    11820, 11872, 11923, 11975, 12027, 12079, 12131, 12184, 12237, 12290, 12343,
    12396, 12450, 12504, 12558, 12613, 12667, 12722, 12777, 12833, 12888, 12944,
    13000, 13056, 13113, 13170, 13227, 13284, 13342, 13400, 13458, 13516, 13575,
    13633, 13693, 13752, 13811, 13871, 13931, 13992, 14052, 14113, 14174, 14236,
    14298, 14360, 14422, 14484, 14547, 14610, 14673, 14737, 14801, 14865, 14929,
    14994, 15059, 15124, 15190, 15256, 15322, 15388, 15455, 15522, 15589, 15657,
    15724, 15793, 15861, 15930, 15999, 16068, 16138, 16208, 16278, 16348, 16419,
    16490, 16562, 16634, 16706, 16778, 16851, 16924, 16997, 17071, 17145, 17219,
    17294, 17369, 17444, 17520, 17595, 17672, 17748, 17825, 17902, 17980, 18058,
  ],
  1280000: [
    3307, 3334, 3362, 3390, 3418, 3447, 3475, 3504, 3534, 3563, 3593, 3623,
    3653, 3683, 3714, 3745, 3776, 3808, 3839, 3871, 3904, 3936, 3969, 4002,
    4027, 4052, 4078, 4103, 4129, 4155, 4180, 4207, 4233, 4259, 4286, 4313,
    4340, 4367, 4394, 4422, 4449, 4477, 4505, 4533, 4562, 4590, 4619, 4648,
    4668, 4688, 4708, 4729, 4749, 4770, 4790, 4811, 4832, 4853, 4874, 4895,
    4916, 4938, 4959, 4980, 5002, 5024, 5046, 5067, 5089, 5111, 5134, 5156,
    5178, 5201, 5223, 5246, 5268, 5291, 5314, 5337, 5360, 5384, 5407, 5430,
    5454, 5478, 5501, 5525, 5549, 5573, 5597, 5622, 5646, 5670, 5695, 5720,
    5744, 5769, 5794, 5819, 5845, 5870, 5895, 5921, 5947, 5972, 5998, 6024,
    6050, 6077, 6103, 6129, 6156, 6183, 6209, 6236, 6263, 6290, 6318, 6345,
    6373, 6400, 6428, 6456, 6484, 6512, 6540, 6568, 6597, 6625, 6654, 6683,
    6712, 6741, 6770, 6800, 6829, 6859, 6888, 6918, 6948, 6978, 7008, 7039,
    7069, 7100, 7131, 7162, 7193, 7224, 7255, 7287, 7318, 7350, 7382, 7414,
    7446, 7478, 7511, 7543, 7576, 7609, 7642, 7675, 7708, 7741, 7775, 7809,
    7842, 7876, 7911, 7945, 7979, 8014, 8049, 8083, 8118, 8154, 8189, 8224,
    8260, 8296, 8332, 8368, 8404, 8441, 8477, 8514, 8551, 8588, 8625, 8662,
    8700, 8738, 8776, 8814, 8852, 8890, 8929, 8967, 9006, 9045, 9084, 9124,
    9163, 9203, 9243, 9283, 9323, 9364, 9404, 9445, 9486, 9527, 9568, 9610,
    9651, 9693, 9735, 9777, 9820, 9862, 9905, 9948, 9991, 10034, 10078, 10121,
    10165, 10209, 10254, 10298, 10343, 10388, 10433, 10478, 10523, 10569, 10615,
    10661, 10707, 10753, 10800, 10847, 10894, 10941, 10988, 11036, 11084, 11132,
    11180, 11228, 11277, 11326, 11375, 11424, 11474, 11523, 11573, 11623, 11674,
    11724, 11775, 11826, 11878, 11929, 11981, 12033, 12085, 12137, 12190, 12243,
    12296, 12349, 12402, 12456, 12510, 12564, 12619, 12673, 12728, 12784, 12839,
    12895, 12950, 13007, 13063, 13119, 13176, 13233, 13291, 13348, 13406, 13464,
    13523, 13581, 13640, 13699, 13759, 13818, 13878, 13938, 13999, 14059, 14120,
    14181, 14243, 14305, 14367, 14429, 14491, 14554, 14617, 14681, 14744, 14808,
    14872, 14937, 15001, 15066, 15132, 15197, 15263, 15329, 15396, 15462, 15529,
    15597, 15664, 15732, 15800, 15869, 15938, 16007, 16076, 16146, 16216, 16286,
    16356, 16427, 16499, 16570, 16642, 16714, 16786, 16859, 16932, 17006, 17079,
    17153, 17228, 17302, 17377, 17452, 17528, 17604, 17680, 17757, 17834, 17911,
  ],
  1290000: [
    3279, 3306, 3334, 3361, 3389, 3418, 3446, 3475, 3504, 3533, 3562, 3592,
    3622, 3652, 3683, 3713, 3744, 3776, 3807, 3839, 3871, 3903, 3935, 3968,
    3993, 4018, 4043, 4068, 4094, 4119, 4145, 4171, 4197, 4223, 4250, 4276,
    4303, 4330, 4357, 4384, 4412, 4439, 4467, 4495, 4523, 4551, 4580, 4608,
    4628, 4648, 4669, 4689, 4709, 4729, 4750, 4771, 4791, 4812, 4833, 4854,
    4875, 4896, 4917, 4938, 4960, 4981, 5003, 5025, 5046, 5068, 5090, 5112,
    5134, 5157, 5179, 5201, 5224, 5247, 5269, 5292, 5315, 5338, 5361, 5385,
    5408, 5431, 5455, 5478, 5502, 5526, 5550, 5574, 5598, 5622, 5647, 5671,
    5696, 5721, 5745, 5770, 5795, 5820, 5846, 5871, 5896, 5922, 5948, 5973,
    5999, 6025, 6051, 6078, 6104, 6130, 6157, 6184, 6210, 6237, 6264, 6291,
    6319, 6346, 6374, 6401, 6429, 6457, 6485, 6513, 6541, 6569, 6598, 6627,
    6655, 6684, 6713, 6742, 6771, 6801, 6830, 6860, 6889, 6919, 6949, 6979,
    7010, 7040, 7071, 7101, 7132, 7163, 7194, 7225, 7256, 7288, 7319, 7351,
    7383, 7415, 7447, 7479, 7512, 7544, 7577, 7610, 7643, 7676, 7709, 7743,
    7776, 7810, 7844, 7878, 7912, 7946, 7981, 8015, 8050, 8085, 8120, 8155,
    8190, 8226, 8261, 8297, 8333, 8369, 8406, 8442, 8479, 8515, 8552, 8589,
    8627, 8664, 8701, 8739, 8777, 8815, 8853, 8892, 8930, 8969, 9008, 9047,
    9086, 9125, 9165, 9205, 9244, 9285, 9325, 9365, 9406, 9447, 9487, 9529,
    9570, 9611, 9653, 9695, 9737, 9779, 9821, 9864, 9907, 9950, 9993, 10036,
    10080, 10123, 10167, 10211, 10255, 10300, 10344, 10389, 10434, 10480, 10525,
    10571, 10616, 10662, 10709, 10755, 10802, 10848, 10895, 10943, 10990, 11038,
    11085, 11133, 11182, 11230, 11279, 11328, 11377, 11426, 11476, 11525, 11575,
    11625, 11676, 11726, 11777, 11828, 11880, 11931, 11983, 12035, 12087, 12139,
    12192, 12245, 12298, 12351, 12404, 12458, 12512, 12566, 12621, 12676, 12731,
    12786, 12841, 12897, 12953, 13009, 13065, 13122, 13179, 13236, 13293, 13351,
    13409, 13467, 13525, 13584, 13642, 13702, 13761, 13821, 13880, 13941, 14001,
    14062, 14123, 14184, 14245, 14307, 14369, 14431, 14494, 14557, 14620, 14683,
    14747, 14811, 14875, 14939, 15004, 15069, 15134, 15200, 15266, 15332, 15398,
    15465, 15532, 15599, 15667, 15735, 15803, 15871, 15940, 16009, 16079, 16148,
    16218, 16289, 16359, 16430, 16501, 16573, 16645, 16717, 16789, 16862, 16935,
    17008, 17082, 17156, 17230, 17305, 17380, 17455, 17531, 17607, 17683, 17760,
  ],
  1300000: [
    3250, 3277, 3304, 3332, 3360, 3388, 3416, 3444, 3473, 3502, 3531, 3561,
    3590, 3620, 3650, 3681, 3711, 3742, 3774, 3805, 3837, 3869, 3901, 3933,
    3958, 3983, 4008, 4033, 4058, 4083, 4109, 4135, 4160, 4186, 4213, 4239,
    4265, 4292, 4319, 4346, 4373, 4400, 4428, 4455, 4483, 4511, 4540, 4568,
    4588, 4608, 4628, 4648, 4668, 4688, 4708, 4729, 4749, 4770, 4790, 4811,
    4832, 4853, 4874, 4895, 4916, 4938, 4959, 4981, 5002, 5024, 5046, 5067,
    5089, 5111, 5134, 5156, 5178, 5201, 5223, 5246, 5269, 5291, 5314, 5337,
    5360, 5384, 5407, 5430, 5454, 5478, 5501, 5525, 5549, 5573, 5597, 5622,
    5646, 5670, 5695, 5720, 5744, 5769, 5794, 5819, 5845, 5870, 5895, 5921,
    5947, 5972, 5998, 6024, 6050, 6077, 6103, 6129, 6156, 6183, 6209, 6236,
    6263, 6290, 6318, 6345, 6373, 6400, 6428, 6456, 6484, 6512, 6540, 6568,
    6597, 6625, 6654, 6683, 6712, 6741, 6770, 6800, 6829, 6859, 6888, 6918,
    6948, 6978, 7009, 7039, 7069, 7100, 7131, 7162, 7193, 7224, 7255, 7287,
    7318, 7350, 7382, 7414, 7446, 7478, 7511, 7543, 7576, 7609, 7642, 7675,
    7708, 7741, 7775, 7809, 7842, 7876, 7911, 7945, 7979, 8014, 8049, 8083,
    8119, 8154, 8189, 8225, 8260, 8296, 8332, 8368, 8404, 8441, 8477, 8514,
    8551, 8588, 8625, 8663, 8700, 8738, 8776, 8814, 8852, 8890, 8929, 8967,
    9006, 9045, 9085, 9124, 9163, 9203, 9243, 9283, 9323, 9364, 9404, 9445,
    9486, 9527, 9568, 9610, 9651, 9693, 9735, 9777, 9820, 9862, 9905, 9948,
    9991, 10034, 10078, 10122, 10165, 10210, 10254, 10298, 10343, 10388, 10433,
    10478, 10523, 10569, 10615, 10661, 10707, 10753, 10800, 10847, 10894, 10941,
    10988, 11036, 11084, 11132, 11180, 11228, 11277, 11326, 11375, 11424, 11474,
    11524, 11573, 11624, 11674, 11725, 11775, 11826, 11878, 11929, 11981, 12033,
    12085, 12137, 12190, 12243, 12296, 12349, 12403, 12456, 12510, 12564, 12619,
    12674, 12728, 12784, 12839, 12895, 12951, 13007, 13063, 13120, 13176, 13234,
    13291, 13349, 13406, 13464, 13523, 13581, 13640, 13699, 13759, 13818, 13878,
    13938, 13999, 14059, 14120, 14182, 14243, 14305, 14367, 14429, 14491, 14554,
    14617, 14681, 14744, 14808, 14872, 14937, 15002, 15067, 15132, 15197, 15263,
    15329, 15396, 15463, 15530, 15597, 15664, 15732, 15800, 15869, 15938, 16007,
    16076, 16146, 16216, 16286, 16357, 16427, 16499, 16570, 16642, 16714, 16787,
    16859, 16932, 17006, 17079, 17153, 17228, 17302, 17377, 17453, 17528, 17604,
  ],
  1310000: [
    3275, 3302, 3330, 3358, 3386, 3414, 3442, 3471, 3500, 3529, 3558, 3588,
    3618, 3648, 3678, 3709, 3740, 3771, 3803, 3834, 3866, 3899, 3931, 3964,
    3989, 4013, 4039, 4064, 4089, 4115, 4140, 4166, 4192, 4219, 4245, 4271,
    4298, 4325, 4352, 4379, 4407, 4434, 4462, 4490, 4518, 4546, 4574, 4603,
    4623, 4643, 4663, 4683, 4704, 4724, 4745, 4765, 4786, 4806, 4827, 4848,
    4869, 4890, 4912, 4933, 4954, 4976, 4997, 5019, 5041, 5062, 5084, 5106,
    5129, 5151, 5173, 5196, 5218, 5241, 5263, 5286, 5309, 5332, 5355, 5378,
    5402, 5425, 5449, 5472, 5496, 5520, 5544, 5568, 5592, 5616, 5640, 5665,
    5689, 5714, 5739, 5764, 5789, 5814, 5839, 5864, 5890, 5915, 5941, 5967,
    5992, 6018, 6044, 6071, 6097, 6123, 6150, 6177, 6203, 6230, 6257, 6284,
    6312, 6339, 6366, 6394, 6422, 6449, 6477, 6505, 6534, 6562, 6590, 6619,
    6648, 6676, 6705, 6734, 6764, 6793, 6822, 6852, 6882, 6911, 6941, 6971,
    7002, 7032, 7062, 7093, 7124, 7155, 7186, 7217, 7248, 7280, 7311, 7343,
    7375, 7407, 7439, 7471, 7503, 7536, 7568, 7601, 7634, 7667, 7700, 7734,
    7767, 7801, 7835, 7869, 7903, 7937, 7971, 8006, 8041, 8076, 8111, 8146,
    8181, 8216, 8252, 8288, 8324, 8360, 8396, 8432, 8469, 8506, 8542, 8579,
    8617, 8654, 8692, 8729, 8767, 8805, 8843, 8881, 8920, 8959, 8997, 9036,
    9076, 9115, 9154, 9194, 9234, 9274, 9314, 9354, 9395, 9436, 9477, 9518,
    9559, 9600, 9642, 9684, 9726, 9768, 9810, 9853, 9895, 9938, 9981, 10025,
    10068, 10112, 10155, 10199, 10244, 10288, 10333, 10377, 10422, 10468, 10513,
    10558, 10604, 10650, 10696, 10743, 10789, 10836, 10883, 10930, 10977, 11025,
    11073, 11121, 11169, 11217, 11266, 11315, 11364, 11413, 11463, 11512, 11562,
    11612, 11662, 11713, 11764, 11815, 11866, 11917, 11969, 12021, 12073, 12125,
    12178, 12231, 12284, 12337, 12390, 12444, 12498, 12552, 12606, 12661, 12716,
    12771, 12826, 12882, 12938, 12994, 13050, 13107, 13164, 13221, 13278, 13335,
    13393, 13451, 13509, 13568, 13627, 13686, 13745, 13805, 13865, 13925, 13985,
    14046, 14106, 14168, 14229, 14291, 14353, 14415, 14477, 14540, 14603, 14666,
    14730, 14794, 14858, 14922, 14987, 15052, 15117, 15182, 15248, 15314, 15381,
    15447, 15514, 15581, 15649, 15717, 15785, 15853, 15922, 15991, 16060, 16130,
    16200, 16270, 16341, 16411, 16482, 16554, 16626, 16698, 16770, 16843, 16916,
    16989, 17063, 17137, 17211, 17285, 17360, 17435, 17511, 17587, 17663, 17740,
  ],
  1320000: [
    3300, 3328, 3355, 3383, 3411, 3440, 3468, 3497, 3527, 3556, 3586, 3615,
    3646, 3676, 3707, 3737, 3769, 3800, 3832, 3864, 3896, 3928, 3961, 3994,
    4019, 4044, 4069, 4095, 4120, 4146, 4172, 4198, 4224, 4251, 4277, 4304,
    4331, 4358, 4385, 4413, 4440, 4468, 4496, 4524, 4552, 4581, 4609, 4638,
    4658, 4678, 4699, 4719, 4740, 4760, 4781, 4801, 4822, 4843, 4864, 4885,
    4906, 4928, 4949, 4970, 4992, 5014, 5035, 5057, 5079, 5101, 5123, 5145,
    5168, 5190, 5213, 5235, 5258, 5281, 5304, 5327, 5350, 5373, 5396, 5419,
    5443, 5467, 5490, 5514, 5538, 5562, 5586, 5610, 5634, 5659, 5683, 5708,
    5733, 5758, 5783, 5808, 5833, 5858, 5883, 5909, 5935, 5960, 5986, 6012,
    6038, 6064, 6091, 6117, 6143, 6170, 6197, 6224, 6251, 6278, 6305, 6332,
    6360, 6387, 6415, 6443, 6471, 6499, 6527, 6555, 6584, 6612, 6641, 6669,
    6698, 6727, 6757, 6786, 6815, 6845, 6874, 6904, 6934, 6964, 6994, 7025,
    7055, 7086, 7116, 7147, 7178, 7209, 7241, 7272, 7303, 7335, 7367, 7399,
    7431, 7463, 7495, 7528, 7560, 7593, 7626, 7659, 7692, 7726, 7759, 7793,
    7827, 7861, 7895, 7929, 7963, 7998, 8032, 8067, 8102, 8137, 8172, 8208,
    8243, 8279, 8315, 8351, 8387, 8424, 8460, 8497, 8534, 8571, 8608, 8645,
    8682, 8720, 8758, 8796, 8834, 8872, 8911, 8949, 8988, 9027, 9066, 9105,
    9145, 9184, 9224, 9264, 9304, 9345, 9385, 9426, 9467, 9508, 9549, 9590,
    9632, 9674, 9716, 9758, 9800, 9842, 9885, 9928, 9971, 10014, 10058, 10101,
    10145, 10189, 10233, 10277, 10322, 10367, 10411, 10457, 10502, 10547, 10593,
    10639, 10685, 10731, 10778, 10825, 10872, 10919, 10966, 11014, 11061, 11109,
    11157, 11206, 11254, 11303, 11352, 11401, 11451, 11500, 11550, 11600, 11650,
    11701, 11752, 11802, 11854, 11905, 11957, 12008, 12060, 12113, 12165, 12218,
    12271, 12324, 12377, 12431, 12485, 12539, 12593, 12648, 12703, 12758, 12813,
    12869, 12924, 12980, 13037, 13093, 13150, 13207, 13264, 13321, 13379, 13437,
    13495, 13554, 13613, 13672, 13731, 13790, 13850, 13910, 13970, 14031, 14092,
    14153, 14214, 14276, 14338, 14400, 14462, 14525, 14588, 14651, 14714, 14778,
    14842, 14907, 14971, 15036, 15101, 15167, 15232, 15298, 15365, 15431, 15498,
    15565, 15633, 15700, 15768, 15837, 15905, 15974, 16044, 16113, 16183, 16253,
    16323, 16394, 16465, 16537, 16608, 16680, 16753, 16825, 16898, 16971, 17045,
    17119, 17193, 17267, 17342, 17417, 17493, 17569, 17645, 17721, 17798, 17875,
  ],
  1330000: [
    3325, 3353, 3381, 3409, 3437, 3466, 3495, 3524, 3553, 3583, 3613, 3643,
    3673, 3704, 3735, 3766, 3797, 3829, 3861, 3893, 3925, 3958, 3991, 4024,
    4049, 4075, 4100, 4126, 4152, 4178, 4204, 4230, 4256, 4283, 4310, 4337,
    4364, 4391, 4419, 4446, 4474, 4502, 4530, 4558, 4587, 4615, 4644, 4673,
    4694, 4714, 4734, 4755, 4775, 4796, 4817, 4838, 4859, 4880, 4901, 4922,
    4944, 4965, 4987, 5008, 5030, 5052, 5073, 5095, 5118, 5140, 5162, 5184,
    5207, 5229, 5252, 5275, 5298, 5321, 5344, 5367, 5390, 5413, 5437, 5460,
    5484, 5508, 5532, 5556, 5580, 5604, 5628, 5653, 5677, 5702, 5726, 5751,
    5776, 5801, 5826, 5852, 5877, 5902, 5928, 5954, 5980, 6005, 6031, 6058,
    6084, 6110, 6137, 6163, 6190, 6217, 6244, 6271, 6298, 6325, 6353, 6380,
    6408, 6436, 6464, 6492, 6520, 6548, 6576, 6605, 6633, 6662, 6691, 6720,
    6749, 6778, 6808, 6837, 6867, 6897, 6927, 6957, 6987, 7017, 7047, 7078,
    7109, 7139, 7170, 7201, 7233, 7264, 7295, 7327, 7359, 7391, 7423, 7455,
    7487, 7520, 7552, 7585, 7618, 7651, 7684, 7717, 7751, 7784, 7818, 7852,
    7886, 7920, 7954, 7989, 8023, 8058, 8093, 8128, 8163, 8199, 8234, 8270,
    8306, 8342, 8378, 8414, 8451, 8487, 8524, 8561, 8598, 8635, 8673, 8710,
    8748, 8786, 8824, 8862, 8901, 8939, 8978, 9017, 9056, 9095, 9135, 9174,
    9214, 9254, 9294, 9334, 9375, 9416, 9456, 9497, 9538, 9580, 9621, 9663,
    9705, 9747, 9789, 9832, 9874, 9917, 9960, 10003, 10046, 10090, 10134, 10178,
    10222, 10266, 10310, 10355, 10400, 10445, 10490, 10536, 10581, 10627, 10673,
    10720, 10766, 10813, 10860, 10907, 10954, 11001, 11049, 11097, 11145, 11193,
    11242, 11291, 11339, 11389, 11438, 11488, 11537, 11587, 11638, 11688, 11739,
    11789, 11841, 11892, 11943, 11995, 12047, 12099, 12152, 12204, 12257, 12310,
    12364, 12417, 12471, 12525, 12579, 12634, 12689, 12744, 12799, 12854, 12910,
    12966, 13022, 13079, 13135, 13192, 13249, 13307, 13364, 13422, 13481, 13539,
    13598, 13657, 13716, 13775, 13835, 13895, 13955, 14016, 14076, 14137, 14198,
    14260, 14322, 14384, 14446, 14509, 14572, 14635, 14698, 14762, 14826, 14890,
    14955, 15019, 15085, 15150, 15216, 15282, 15348, 15414, 15481, 15548, 15615,
    15683, 15751, 15819, 15888, 15957, 16026, 16095, 16165, 16235, 16306, 16376,
    16447, 16518, 16590, 16662, 16734, 16807, 16879, 16953, 17026, 17100, 17174,
    17248, 17323, 17398, 17474, 17549, 17625, 17702, 17778, 17855, 17933, 18010,
  ],
  1340000: [
    3350, 3378, 3406, 3434, 3463, 3492, 3521, 3550, 3580, 3610, 3640, 3670,
    3701, 3732, 3763, 3794, 3826, 3858, 3890, 3922, 3955, 3988, 4021, 4055,
    4080, 4105, 4131, 4157, 4183, 4209, 4235, 4262, 4288, 4315, 4342, 4369,
    4397, 4424, 4452, 4480, 4508, 4536, 4564, 4593, 4621, 4650, 4679, 4708,
    4729, 4749, 4770, 4791, 4811, 4832, 4853, 4874, 4895, 4917, 4938, 4959,
    4981, 5002, 5024, 5046, 5068, 5090, 5112, 5134, 5156, 5178, 5201, 5223,
    5246, 5269, 5292, 5314, 5338, 5361, 5384, 5407, 5431, 5454, 5478, 5502,
    5525, 5549, 5573, 5598, 5622, 5646, 5671, 5695, 5720, 5745, 5770, 5795,
    5820, 5845, 5870, 5896, 5921, 5947, 5973, 5998, 6024, 6051, 6077, 6103,
    6130, 6156, 6183, 6210, 6237, 6264, 6291, 6318, 6345, 6373, 6400, 6428,
    6456, 6484, 6512, 6540, 6569, 6597, 6626, 6654, 6683, 6712, 6741, 6771,
    6800, 6829, 6859, 6889, 6919, 6948, 6979, 7009, 7039, 7070, 7100, 7131,
    7162, 7193, 7224, 7256, 7287, 7319, 7350, 7382, 7414, 7446, 7478, 7511,
    7543, 7576, 7609, 7642, 7675, 7708, 7742, 7775, 7809, 7843, 7877, 7911,
    7945, 7980, 8014, 8049, 8084, 8119, 8154, 8189, 8225, 8260, 8296, 8332,
    8368, 8405, 8441, 8478, 8514, 8551, 8588, 8625, 8663, 8700, 8738, 8776,
    8814, 8852, 8891, 8929, 8968, 9007, 9046, 9085, 9124, 9164, 9203, 9243,
    9283, 9324, 9364, 9405, 9445, 9486, 9527, 9569, 9610, 9652, 9694, 9736,
    9778, 9820, 9863, 9905, 9948, 9992, 10035, 10078, 10122, 10166, 10210,
    10254, 10299, 10343, 10388, 10433, 10478, 10524, 10569, 10615, 10661, 10707,
    10754, 10800, 10847, 10894, 10941, 10989, 11036, 11084, 11132, 11180, 11229,
    11277, 11326, 11375, 11425, 11474, 11524, 11574, 11624, 11674, 11725, 11776,
    11827, 11878, 11930, 11981, 12033, 12085, 12138, 12190, 12243, 12296, 12349,
    12403, 12457, 12511, 12565, 12619, 12674, 12729, 12784, 12840, 12895, 12951,
    13007, 13064, 13120, 13177, 13234, 13291, 13349, 13407, 13465, 13523, 13582,
    13641, 13700, 13759, 13819, 13879, 13939, 13999, 14060, 14121, 14182, 14244,
    14305, 14367, 14429, 14492, 14555, 14618, 14681, 14745, 14809, 14873, 14937,
    15002, 15067, 15132, 15198, 15264, 15330, 15396, 15463, 15530, 15597, 15665,
    15733, 15801, 15870, 15938, 16007, 16077, 16146, 16216, 16287, 16357, 16428,
    16499, 16571, 16643, 16715, 16787, 16860, 16933, 17006, 17080, 17154, 17228,
    17303, 17378, 17453, 17529, 17605, 17681, 17758, 17835, 17912, 17990, 18068,
    18146,
  ],
  1350000: [
    3375, 3403, 3431, 3460, 3489, 3518, 3547, 3577, 3607, 3637, 3667, 3698,
    3728, 3759, 3791, 3822, 3854, 3886, 3919, 3951, 3984, 4018, 4051, 4085,
    4110, 4136, 4162, 4188, 4214, 4240, 4267, 4294, 4320, 4347, 4375, 4402,
    4429, 4457, 4485, 4513, 4541, 4570, 4598, 4627, 4656, 4685, 4714, 4744,
    4764, 4785, 4806, 4826, 4847, 4868, 4889, 4911, 4932, 4953, 4975, 4996,
    5018, 5040, 5061, 5083, 5105, 5128, 5150, 5172, 5195, 5217, 5240, 5262,
    5285, 5308, 5331, 5354, 5377, 5401, 5424, 5448, 5471, 5495, 5519, 5543,
    5567, 5591, 5615, 5639, 5664, 5688, 5713, 5738, 5763, 5788, 5813, 5838,
    5863, 5888, 5914, 5940, 5965, 5991, 6017, 6043, 6069, 6096, 6122, 6149,
    6175, 6202, 6229, 6256, 6283, 6310, 6338, 6365, 6393, 6420, 6448, 6476,
    6504, 6532, 6561, 6589, 6618, 6646, 6675, 6704, 6733, 6762, 6792, 6821,
    6851, 6880, 6910, 6940, 6970, 7000, 7031, 7061, 7092, 7122, 7153, 7184,
    7215, 7247, 7278, 7310, 7341, 7373, 7405, 7437, 7469, 7502, 7534, 7567,
    7600, 7633, 7666, 7699, 7732, 7766, 7799, 7833, 7867, 7901, 7936, 7970,
    8004, 8039, 8074, 8109, 8144, 8179, 8215, 8250, 8286, 8322, 8358, 8394,
    8431, 8467, 8504, 8541, 8578, 8615, 8652, 8690, 8728, 8765, 8803, 8841,
    8880, 8918, 8957, 8996, 9035, 9074, 9113, 9153, 9192, 9232, 9272, 9312,
    9353, 9393, 9434, 9475, 9516, 9557, 9599, 9640, 9682, 9724, 9766, 9808,
    9851, 9893, 9936, 9979, 10023, 10066, 10110, 10154, 10198, 10242, 10286,
    10331, 10375, 10420, 10466, 10511, 10556, 10602, 10648, 10694, 10741, 10787,
    10834, 10881, 10928, 10975, 11023, 11071, 11119, 11167, 11215, 11264, 11313,
    11362, 11411, 11460, 11510, 11560, 11610, 11660, 11711, 11762, 11813, 11864,
    11915, 11967, 12019, 12071, 12123, 12176, 12228, 12281, 12334, 12388, 12442,
    12496, 12550, 12604, 12659, 12714, 12769, 12824, 12880, 12935, 12991, 13048,
    13104, 13161, 13218, 13275, 13333, 13391, 13449, 13507, 13565, 13624, 13683,
    13743, 13802, 13862, 13922, 13982, 14043, 14104, 14165, 14226, 14288, 14350,
    14412, 14474, 14537, 14600, 14663, 14727, 14791, 14855, 14919, 14984, 15049,
    15114, 15180, 15245, 15311, 15378, 15444, 15511, 15579, 15646, 15714, 15782,
    15850, 15919, 15988, 16057, 16127, 16197, 16267, 16337, 16408, 16479, 16551,
    16622, 16694, 16767, 16839, 16912, 16986, 17059, 17133, 17207, 17282, 17357,
    17432, 17508, 17584, 17660, 17736, 17813, 17890, 17968, 18046, 18124, 18202,
    18281,
  ],
  1360000: [
    3400, 3428, 3457, 3486, 3515, 3544, 3574, 3603, 3633, 3664, 3694, 3725,
    3756, 3787, 3819, 3851, 3883, 3915, 3948, 3981, 4014, 4047, 4081, 4115,
    4141, 4167, 4193, 4219, 4245, 4272, 4298, 4325, 4352, 4380, 4407, 4434,
    4462, 4490, 4518, 4546, 4575, 4603, 4632, 4661, 4690, 4720, 4749, 4779,
    4799, 4820, 4841, 4862, 4883, 4904, 4926, 4947, 4968, 4990, 5012, 5033,
    5055, 5077, 5099, 5121, 5143, 5166, 5188, 5210, 5233, 5256, 5278, 5301,
    5324, 5347, 5371, 5394, 5417, 5441, 5464, 5488, 5512, 5536, 5560, 5584,
    5608, 5632, 5657, 5681, 5706, 5730, 5755, 5780, 5805, 5830, 5856, 5881,
    5907, 5932, 5958, 5984, 6010, 6036, 6062, 6088, 6114, 6141, 6168, 6194,
    6221, 6248, 6275, 6302, 6330, 6357, 6385, 6412, 6440, 6468, 6496, 6524,
    6552, 6581, 6609, 6638, 6667, 6696, 6725, 6754, 6783, 6812, 6842, 6872,
    6901, 6931, 6961, 6991, 7022, 7052, 7083, 7113, 7144, 7175, 7206, 7238,
    7269, 7300, 7332, 7364, 7396, 7428, 7460, 7492, 7525, 7557, 7590, 7623,
    7656, 7689, 7723, 7756, 7790, 7823, 7857, 7891, 7926, 7960, 7994, 8029,
    8064, 8099, 8134, 8169, 8204, 8240, 8276, 8312, 8348, 8384, 8420, 8457,
    8493, 8530, 8567, 8604, 8641, 8679, 8716, 8754, 8792, 8830, 8869, 8907,
    8946, 8984, 9023, 9062, 9102, 9141, 9181, 9220, 9260, 9301, 9341, 9381,
    9422, 9463, 9504, 9545, 9586, 9628, 9670, 9712, 9754, 9796, 9838, 9881,
    9924, 9967, 10010, 10053, 10097, 10141, 10185, 10229, 10273, 10318, 10362,
    10407, 10452, 10498, 10543, 10589, 10635, 10681, 10727, 10773, 10820, 10867,
    10914, 10961, 11009, 11057, 11105, 11153, 11201, 11250, 11298, 11347, 11396,
    11446, 11495, 11545, 11595, 11645, 11696, 11747, 11798, 11849, 11900, 11952,
    12003, 12055, 12108, 12160, 12213, 12266, 12319, 12372, 12426, 12480, 12534,
    12588, 12643, 12697, 12752, 12808, 12863, 12919, 12975, 13031, 13088, 13144,
    13201, 13258, 13316, 13374, 13432, 13490, 13548, 13607, 13666, 13725, 13785,
    13844, 13904, 13965, 14025, 14086, 14147, 14208, 14270, 14332, 14394, 14456,
    14519, 14582, 14645, 14708, 14772, 14836, 14900, 14965, 15030, 15095, 15160,
    15226, 15292, 15358, 15425, 15492, 15559, 15626, 15694, 15762, 15830, 15899,
    15968, 16037, 16106, 16176, 16246, 16317, 16387, 16458, 16530, 16601, 16673,
    16746, 16818, 16891, 16964, 17038, 17112, 17186, 17260, 17335, 17410, 17486,
    17561, 17637, 17714, 17791, 17868, 17945, 18023, 18101, 18179, 18258, 18337,
    18417,
  ],
  1370000: [
    3425, 3454, 3482, 3511, 3541, 3570, 3600, 3630, 3660, 3691, 3721, 3752,
    3784, 3815, 3847, 3879, 3911, 3944, 3977, 4010, 4043, 4077, 4111, 4145,
    4171, 4197, 4224, 4250, 4276, 4303, 4330, 4357, 4384, 4412, 4439, 4467,
    4495, 4523, 4551, 4580, 4608, 4637, 4666, 4695, 4725, 4754, 4784, 4814,
    4835, 4856, 4877, 4898, 4919, 4940, 4962, 4983, 5005, 5027, 5048, 5070,
    5092, 5114, 5136, 5159, 5181, 5204, 5226, 5249, 5271, 5294, 5317, 5340,
    5363, 5387, 5410, 5433, 5457, 5481, 5504, 5528, 5552, 5576, 5600, 5625,
    5649, 5674, 5698, 5723, 5748, 5773, 5798, 5823, 5848, 5873, 5899, 5924,
    5950, 5976, 6002, 6028, 6054, 6080, 6106, 6133, 6159, 6186, 6213, 6240,
    6267, 6294, 6321, 6349, 6376, 6404, 6432, 6459, 6487, 6516, 6544, 6572,
    6601, 6629, 6658, 6687, 6716, 6745, 6774, 6803, 6833, 6863, 6892, 6922,
    6952, 6982, 7012, 7043, 7073, 7104, 7135, 7166, 7197, 7228, 7259, 7291,
    7322, 7354, 7386, 7418, 7450, 7482, 7515, 7547, 7580, 7613, 7646, 7679,
    7712, 7746, 7779, 7813, 7847, 7881, 7915, 7949, 7984, 8018, 8053, 8088,
    8123, 8158, 8194, 8229, 8265, 8301, 8337, 8373, 8409, 8445, 8482, 8519,
    8556, 8593, 8630, 8667, 8705, 8743, 8781, 8819, 8857, 8895, 8934, 8972,
    9011, 9050, 9090, 9129, 9169, 9208, 9248, 9288, 9329, 9369, 9410, 9450,
    9491, 9532, 9574, 9615, 9657, 9699, 9741, 9783, 9825, 9868, 9911, 9954,
    9997, 10040, 10084, 10127, 10171, 10215, 10259, 10304, 10349, 10393, 10438,
    10484, 10529, 10575, 10621, 10667, 10713, 10759, 10806, 10853, 10900, 10947,
    10994, 11042, 11090, 11138, 11186, 11235, 11283, 11332, 11381, 11431, 11480,
    11530, 11580, 11630, 11681, 11731, 11782, 11833, 11884, 11936, 11988, 12039,
    12092, 12144, 12197, 12249, 12303, 12356, 12409, 12463, 12517, 12571, 12626,
    12681, 12736, 12791, 12846, 12902, 12958, 13014, 13070, 13127, 13184, 13241,
    13298, 13356, 13414, 13472, 13530, 13589, 13648, 13707, 13766, 13826, 13886,
    13946, 14007, 14067, 14128, 14189, 14251, 14313, 14375, 14437, 14500, 14562,
    14626, 14689, 14753, 14816, 14881, 14945, 15010, 15075, 15140, 15206, 15272,
    15338, 15404, 15471, 15538, 15606, 15673, 15741, 15809, 15878, 15947, 16016,
    16085, 16155, 16225, 16295, 16366, 16437, 16508, 16579, 16651, 16723, 16796,
    16869, 16942, 17015, 17089, 17163, 17237, 17312, 17387, 17462, 17538, 17614,
    17690, 17767, 17844, 17921, 17999, 18077, 18155, 18234, 18313, 18392, 18472,
    18552,
  ],
  1380000: [
    3450, 3479, 3508, 3537, 3566, 3596, 3626, 3656, 3687, 3718, 3749, 3780,
    3811, 3843, 3875, 3907, 3940, 3973, 4006, 4039, 4073, 4107, 4141, 4176,
    4202, 4228, 4254, 4281, 4308, 4335, 4362, 4389, 4416, 4444, 4472, 4500,
    4528, 4556, 4585, 4613, 4642, 4671, 4700, 4730, 4759, 4789, 4819, 4849,
    4870, 4891, 4912, 4934, 4955, 4976, 4998, 5020, 5041, 5063, 5085, 5107,
    5129, 5152, 5174, 5196, 5219, 5242, 5264, 5287, 5310, 5333, 5356, 5379,
    5403, 5426, 5450, 5473, 5497, 5521, 5545, 5569, 5593, 5617, 5641, 5666,
    5690, 5715, 5740, 5765, 5790, 5815, 5840, 5865, 5891, 5916, 5942, 5968,
    5993, 6019, 6045, 6072, 6098, 6124, 6151, 6178, 6204, 6231, 6258, 6285,
    6313, 6340, 6367, 6395, 6423, 6451, 6478, 6507, 6535, 6563, 6592, 6620,
    6649, 6678, 6707, 6736, 6765, 6794, 6824, 6853, 6883, 6913, 6943, 6973,
    7003, 7033, 7064, 7094, 7125, 7156, 7187, 7218, 7249, 7281, 7312, 7344,
    7376, 7408, 7440, 7472, 7504, 7537, 7570, 7602, 7635, 7668, 7702, 7735,
    7769, 7802, 7836, 7870, 7904, 7938, 7973, 8007, 8042, 8077, 8112, 8147,
    8182, 8218, 8253, 8289, 8325, 8361, 8397, 8434, 8470, 8507, 8544, 8581,
    8618, 8655, 8693, 8731, 8768, 8806, 8845, 8883, 8921, 8960, 8999, 9038,
    9077, 9116, 9156, 9196, 9235, 9275, 9316, 9356, 9397, 9437, 9478, 9519,
    9561, 9602, 9644, 9685, 9727, 9769, 9812, 9854, 9897, 9940, 9983, 10026,
    10070, 10113, 10157, 10201, 10245, 10290, 10334, 10379, 10424, 10469, 10515,
    10560, 10606, 10652, 10698, 10744, 10791, 10838, 10885, 10932, 10979, 11027,
    11075, 11123, 11171, 11219, 11268, 11317, 11366, 11415, 11464, 11514, 11564,
    11614, 11664, 11715, 11766, 11817, 11868, 11919, 11971, 12023, 12075, 12127,
    12180, 12233, 12286, 12339, 12392, 12446, 12500, 12554, 12609, 12663, 12718,
    12773, 12829, 12884, 12940, 12996, 13052, 13109, 13166, 13223, 13280, 13338,
    13395, 13453, 13512, 13570, 13629, 13688, 13748, 13807, 13867, 13927, 13987,
    14048, 14109, 14170, 14231, 14293, 14355, 14417, 14480, 14542, 14605, 14669,
    14732, 14796, 14860, 14925, 14989, 15054, 15119, 15185, 15251, 15317, 15383,
    15450, 15517, 15584, 15652, 15719, 15788, 15856, 15925, 15994, 16063, 16133,
    16203, 16273, 16343, 16414, 16485, 16557, 16628, 16700, 16773, 16846, 16918,
    16992, 17065, 17139, 17214, 17288, 17363, 17438, 17514, 17590, 17666, 17743,
    17820, 17897, 17974, 18052, 18130, 18209, 18288, 18367, 18447, 18527, 18607,
    18688,
  ],
  1390000: [
    3475, 3504, 3533, 3563, 3592, 3622, 3652, 3683, 3714, 3744, 3776, 3807,
    3839, 3871, 3903, 3936, 3968, 4002, 4035, 4068, 4102, 4137, 4171, 4206,
    4232, 4259, 4285, 4312, 4339, 4366, 4393, 4421, 4448, 4476, 4504, 4532,
    4561, 4589, 4618, 4647, 4676, 4705, 4734, 4764, 4794, 4824, 4854, 4884,
    4905, 4927, 4948, 4969, 4991, 5013, 5034, 5056, 5078, 5100, 5122, 5144,
    5167, 5189, 5211, 5234, 5257, 5280, 5302, 5325, 5348, 5372, 5395, 5418,
    5442, 5465, 5489, 5513, 5537, 5561, 5585, 5609, 5633, 5658, 5682, 5707,
    5732, 5756, 5781, 5806, 5832, 5857, 5882, 5908, 5933, 5959, 5985, 6011,
    6037, 6063, 6089, 6116, 6142, 6169, 6195, 6222, 6249, 6276, 6304, 6331,
    6358, 6386, 6414, 6441, 6469, 6497, 6525, 6554, 6582, 6611, 6639, 6668,
    6697, 6726, 6755, 6784, 6814, 6843, 6873, 6903, 6933, 6963, 6993, 7023,
    7054, 7084, 7115, 7146, 7177, 7208, 7239, 7270, 7302, 7334, 7365, 7397,
    7429, 7461, 7494, 7526, 7559, 7592, 7625, 7658, 7691, 7724, 7758, 7791,
    7825, 7859, 7893, 7927, 7961, 7996, 8031, 8065, 8100, 8135, 8171, 8206,
    8242, 8277, 8313, 8349, 8385, 8422, 8458, 8495, 8532, 8569, 8606, 8643,
    8681, 8718, 8756, 8794, 8832, 8870, 8909, 8947, 8986, 9025, 9064, 9103,
    9143, 9183, 9222, 9262, 9302, 9343, 9383, 9424, 9465, 9506, 9547, 9588,
    9630, 9672, 9713, 9756, 9798, 9840, 9883, 9926, 9969, 10012, 10055, 10099,
    10143, 10187, 10231, 10275, 10320, 10364, 10409, 10454, 10500, 10545, 10591,
    10637, 10683, 10729, 10776, 10822, 10869, 10916, 10964, 11011, 11059, 11107,
    11155, 11203, 11252, 11301, 11350, 11399, 11448, 11498, 11548, 11598, 11648,
    11698, 11749, 11800, 11851, 11902, 11954, 12006, 12058, 12110, 12163, 12215,
    12268, 12321, 12375, 12428, 12482, 12536, 12591, 12645, 12700, 12755, 12810,
    12866, 12922, 12978, 13034, 13090, 13147, 13204, 13261, 13319, 13376, 13434,
    13492, 13551, 13610, 13669, 13728, 13787, 13847, 13907, 13967, 14028, 14089,
    14150, 14211, 14273, 14334, 14397, 14459, 14522, 14585, 14648, 14711, 14775,
    14839, 14903, 14968, 15033, 15098, 15163, 15229, 15295, 15361, 15428, 15495,
    15562, 15629, 15697, 15765, 15833, 15902, 15971, 16040, 16110, 16179, 16250,
    16320, 16391, 16462, 16533, 16605, 16677, 16749, 16821, 16894, 16968, 17041,
    17115, 17189, 17264, 17338, 17414, 17489, 17565, 17641, 17717, 17794, 17871,
    17949, 18026, 18105, 18183, 18262, 18341, 18420, 18500, 18580, 18661, 18742,
    18823,
  ],
  1400000: [
    3500, 3529, 3559, 3588, 3618, 3648, 3679, 3709, 3740, 3771, 3803, 3835,
    3866, 3899, 3931, 3964, 3997, 4030, 4064, 4098, 4132, 4166, 4201, 4236,
    4263, 4289, 4316, 4343, 4370, 4397, 4425, 4453, 4480, 4508, 4537, 4565,
    4593, 4622, 4651, 4680, 4709, 4739, 4768, 4798, 4828, 4858, 4889, 4919,
    4941, 4962, 4984, 5005, 5027, 5049, 5070, 5092, 5115, 5137, 5159, 5181,
    5204, 5226, 5249, 5272, 5295, 5317, 5341, 5364, 5387, 5410, 5434, 5457,
    5481, 5505, 5528, 5552, 5577, 5601, 5625, 5649, 5674, 5698, 5723, 5748,
    5773, 5798, 5823, 5848, 5874, 5899, 5925, 5950, 5976, 6002, 6028, 6054,
    6080, 6107, 6133, 6160, 6186, 6213, 6240, 6267, 6294, 6322, 6349, 6376,
    6404, 6432, 6460, 6488, 6516, 6544, 6572, 6601, 6629, 6658, 6687, 6716,
    6745, 6774, 6804, 6833, 6863, 6893, 6922, 6952, 6983, 7013, 7043, 7074,
    7104, 7135, 7166, 7197, 7228, 7260, 7291, 7323, 7354, 7386, 7418, 7450,
    7483, 7515, 7548, 7580, 7613, 7646, 7679, 7713, 7746, 7780, 7813, 7847,
    7881, 7915, 7950, 7984, 8019, 8053, 8088, 8123, 8159, 8194, 8229, 8265,
    8301, 8337, 8373, 8409, 8446, 8482, 8519, 8556, 8593, 8630, 8668, 8705,
    8743, 8781, 8819, 8857, 8896, 8934, 8973, 9012, 9051, 9090, 9129, 9169,
    9209, 9249, 9289, 9329, 9369, 9410, 9451, 9492, 9533, 9574, 9616, 9657,
    9699, 9741, 9783, 9826, 9868, 9911, 9954, 9997, 10040, 10084, 10128, 10172,
    10216, 10260, 10304, 10349, 10394, 10439, 10484, 10530, 10575, 10621, 10667,
    10713, 10760, 10806, 10853, 10900, 10947, 10995, 11042, 11090, 11138, 11187,
    11235, 11284, 11333, 11382, 11431, 11481, 11530, 11580, 11631, 11681, 11732,
    11782, 11834, 11885, 11936, 11988, 12040, 12092, 12145, 12197, 12250, 12303,
    12356, 12410, 12464, 12518, 12572, 12626, 12681, 12736, 12791, 12847, 12902,
    12958, 13014, 13071, 13128, 13184, 13242, 13299, 13357, 13414, 13473, 13531,
    13590, 13648, 13708, 13767, 13827, 13887, 13947, 14007, 14068, 14129, 14190,
    14252, 14313, 14375, 14438, 14500, 14563, 14626, 14690, 14753, 14817, 14881,
    14946, 15011, 15076, 15141, 15207, 15272, 15339, 15405, 15472, 15539, 15606,
    15674, 15742, 15810, 15878, 15947, 16016, 16086, 16156, 16226, 16296, 16366,
    16437, 16509, 16580, 16652, 16724, 16797, 16869, 16942, 17016, 17090, 17164,
    17238, 17313, 17388, 17463, 17539, 17615, 17691, 17768, 17845, 17922, 18000,
    18078, 18156, 18235, 18314, 18393, 18473, 18553, 18633, 18714, 18795, 18877,
    18958,
  ],
  1410000: [
    3525, 3554, 3584, 3614, 3644, 3674, 3705, 3736, 3767, 3798, 3830, 3862,
    3894, 3927, 3959, 3992, 4026, 4059, 4093, 4127, 4161, 4196, 4231, 4266,
    4293, 4320, 4347, 4374, 4401, 4429, 4457, 4484, 4512, 4541, 4569, 4598,
    4626, 4655, 4684, 4714, 4743, 4773, 4802, 4832, 4863, 4893, 4924, 4954,
    4976, 4997, 5019, 5041, 5063, 5085, 5107, 5129, 5151, 5173, 5196, 5218,
    5241, 5264, 5286, 5309, 5332, 5355, 5379, 5402, 5425, 5449, 5473, 5496,
    5520, 5544, 5568, 5592, 5616, 5641, 5665, 5690, 5714, 5739, 5764, 5789,
    5814, 5839, 5865, 5890, 5915, 5941, 5967, 5993, 6019, 6045, 6071, 6097,
    6124, 6150, 6177, 6204, 6230, 6257, 6285, 6312, 6339, 6367, 6394, 6422,
    6450, 6478, 6506, 6534, 6562, 6591, 6619, 6648, 6677, 6706, 6735, 6764,
    6793, 6823, 6852, 6882, 6912, 6942, 6972, 7002, 7032, 7063, 7093, 7124,
    7155, 7186, 7217, 7249, 7280, 7311, 7343, 7375, 7407, 7439, 7471, 7504,
    7536, 7569, 7602, 7635, 7668, 7701, 7734, 7768, 7801, 7835, 7869, 7903,
    7938, 7972, 8006, 8041, 8076, 8111, 8146, 8181, 8217, 8252, 8288, 8324,
    8360, 8396, 8433, 8469, 8506, 8543, 8580, 8617, 8654, 8692, 8730, 8767,
    8805, 8844, 8882, 8920, 8959, 8998, 9037, 9076, 9115, 9155, 9195, 9234,
    9274, 9315, 9355, 9396, 9436, 9477, 9518, 9559, 9601, 9642, 9684, 9726,
    9768, 9811, 9853, 9896, 9939, 9982, 10025, 10069, 10112, 10156, 10200,
    10244, 10289, 10333, 10378, 10423, 10468, 10513, 10559, 10605, 10651, 10697,
    10743, 10790, 10837, 10884, 10931, 10978, 11026, 11073, 11121, 11170, 11218,
    11267, 11315, 11364, 11414, 11463, 11513, 11563, 11613, 11663, 11714, 11764,
    11815, 11867, 11918, 11970, 12022, 12074, 12126, 12179, 12231, 12284, 12338,
    12391, 12445, 12499, 12553, 12607, 12662, 12717, 12772, 12827, 12883, 12939,
    12995, 13051, 13107, 13164, 13221, 13279, 13336, 13394, 13452, 13510, 13569,
    13628, 13687, 13746, 13806, 13865, 13925, 13986, 14046, 14107, 14168, 14230,
    14291, 14353, 14416, 14478, 14541, 14604, 14667, 14731, 14794, 14859, 14923,
    14988, 15053, 15118, 15183, 15249, 15315, 15382, 15448, 15515, 15582, 15650,
    15718, 15786, 15854, 15923, 15992, 16061, 16131, 16201, 16271, 16341, 16412,
    16483, 16555, 16627, 16699, 16771, 16844, 16917, 16990, 17064, 17137, 17212,
    17286, 17361, 17436, 17512, 17588, 17664, 17741, 17818, 17895, 17972, 18050,
    18128, 18207, 18286, 18365, 18445, 18525, 18605, 18685, 18766, 18848, 18929,
    19011, 19094,
  ],
  1420000: [
    3550, 3580, 3609, 3639, 3670, 3700, 3731, 3762, 3794, 3825, 3857, 3889,
    3922, 3954, 3987, 4021, 4054, 4088, 4122, 4156, 4191, 4226, 4261, 4297,
    4323, 4350, 4378, 4405, 4433, 4460, 4488, 4516, 4544, 4573, 4601, 4630,
    4659, 4688, 4717, 4747, 4777, 4807, 4837, 4867, 4897, 4928, 4959, 4990,
    5011, 5033, 5055, 5077, 5099, 5121, 5143, 5165, 5188, 5210, 5233, 5255,
    5278, 5301, 5324, 5347, 5370, 5393, 5417, 5440, 5464, 5488, 5511, 5535,
    5559, 5583, 5607, 5632, 5656, 5681, 5705, 5730, 5755, 5780, 5805, 5830,
    5855, 5881, 5906, 5932, 5957, 5983, 6009, 6035, 6061, 6088, 6114, 6140,
    6167, 6194, 6221, 6248, 6275, 6302, 6329, 6357, 6384, 6412, 6440, 6468,
    6496, 6524, 6552, 6580, 6609, 6637, 6666, 6695, 6724, 6753, 6783, 6812,
    6841, 6871, 6901, 6931, 6961, 6991, 7021, 7052, 7082, 7113, 7144, 7175,
    7206, 7237, 7268, 7300, 7332, 7363, 7395, 7427, 7459, 7492, 7524, 7557,
    7590, 7622, 7656, 7689, 7722, 7755, 7789, 7823, 7857, 7891, 7925, 7959,
    7994, 8028, 8063, 8098, 8133, 8169, 8204, 8239, 8275, 8311, 8347, 8383,
    8420, 8456, 8493, 8529, 8566, 8604, 8641, 8678, 8716, 8754, 8792, 8830,
    8868, 8906, 8945, 8984, 9023, 9062, 9101, 9140, 9180, 9220, 9260, 9300,
    9340, 9381, 9421, 9462, 9503, 9544, 9586, 9627, 9669, 9711, 9753, 9795,
    9838, 9880, 9923, 9966, 10009, 10053, 10096, 10140, 10184, 10228, 10272,
    10317, 10362, 10406, 10452, 10497, 10542, 10588, 10634, 10680, 10726, 10773,
    10819, 10866, 10913, 10961, 11008, 11056, 11104, 11152, 11200, 11249, 11298,
    11346, 11396, 11445, 11495, 11544, 11594, 11645, 11695, 11746, 11797, 11848,
    11899, 11951, 12003, 12055, 12107, 12159, 12212, 12265, 12318, 12371, 12425,
    12479, 12533, 12587, 12642, 12697, 12752, 12807, 12862, 12918, 12974, 13030,
    13087, 13143, 13200, 13258, 13315, 13373, 13431, 13489, 13547, 13606, 13665,
    13724, 13784, 13843, 13903, 13964, 14024, 14085, 14146, 14207, 14269, 14331,
    14393, 14455, 14518, 14581, 14644, 14707, 14771, 14835, 14899, 14964, 15029,
    15094, 15159, 15225, 15291, 15357, 15424, 15491, 15558, 15625, 15693, 15761,
    15829, 15898, 15967, 16036, 16105, 16175, 16245, 16316, 16386, 16457, 16529,
    16600, 16672, 16744, 16817, 16890, 16963, 17037, 17110, 17185, 17259, 17334,
    17409, 17484, 17560, 17636, 17713, 17789, 17866, 17944, 18022, 18100, 18178,
    18257, 18336, 18415, 18495, 18575, 18656, 18737, 18818, 18900, 18981, 19064,
    19146, 19229,
  ],
  1430000: [
    3575, 3605, 3635, 3665, 3696, 3726, 3758, 3789, 3820, 3852, 3884, 3917,
    3949, 3982, 4015, 4049, 4083, 4117, 4151, 4186, 4220, 4256, 4291, 4327,
    4354, 4381, 4408, 4436, 4464, 4492, 4520, 4548, 4576, 4605, 4634, 4663,
    4692, 4721, 4751, 4780, 4810, 4840, 4871, 4901, 4932, 4963, 4994, 5025,
    5046, 5068, 5090, 5112, 5135, 5157, 5179, 5202, 5224, 5247, 5269, 5292,
    5315, 5338, 5361, 5385, 5408, 5431, 5455, 5479, 5502, 5526, 5550, 5574,
    5598, 5623, 5647, 5671, 5696, 5721, 5745, 5770, 5795, 5820, 5846, 5871,
    5896, 5922, 5948, 5973, 5999, 6025, 6051, 6078, 6104, 6130, 6157, 6184,
    6211, 6237, 6264, 6292, 6319, 6346, 6374, 6401, 6429, 6457, 6485, 6513,
    6541, 6570, 6598, 6627, 6655, 6684, 6713, 6742, 6772, 6801, 6830, 6860,
    6890, 6920, 6949, 6980, 7010, 7040, 7071, 7101, 7132, 7163, 7194, 7225,
    7257, 7288, 7320, 7351, 7383, 7415, 7447, 7480, 7512, 7545, 7577, 7610,
    7643, 7676, 7709, 7743, 7776, 7810, 7844, 7878, 7912, 7946, 7981, 8015,
    8050, 8085, 8120, 8155, 8191, 8226, 8262, 8297, 8333, 8370, 8406, 8442,
    8479, 8516, 8552, 8590, 8627, 8664, 8702, 8739, 8777, 8815, 8853, 8892,
    8930, 8969, 9008, 9047, 9086, 9126, 9165, 9205, 9245, 9285, 9325, 9365,
    9406, 9447, 9488, 9529, 9570, 9612, 9653, 9695, 9737, 9779, 9822, 9864,
    9907, 9950, 9993, 10036, 10080, 10123, 10167, 10211, 10256, 10300, 10345,
    10390, 10435, 10480, 10525, 10571, 10617, 10663, 10709, 10755, 10802, 10849,
    10896, 10943, 10990, 11038, 11086, 11134, 11182, 11230, 11279, 11328, 11377,
    11426, 11476, 11526, 11576, 11626, 11676, 11727, 11778, 11829, 11880, 11931,
    11983, 12035, 12087, 12139, 12192, 12245, 12298, 12351, 12405, 12459, 12513,
    12567, 12621, 12676, 12731, 12786, 12841, 12897, 12953, 13009, 13065, 13122,
    13179, 13236, 13293, 13351, 13409, 13467, 13525, 13584, 13643, 13702, 13761,
    13821, 13881, 13941, 14001, 14062, 14123, 14184, 14246, 14307, 14369, 14432,
    14494, 14557, 14620, 14683, 14747, 14811, 14875, 14940, 15004, 15069, 15135,
    15200, 15266, 15332, 15399, 15465, 15532, 15600, 15667, 15735, 15803, 15872,
    15941, 16010, 16079, 16149, 16219, 16289, 16360, 16430, 16502, 16573, 16645,
    16717, 16790, 16862, 16935, 17009, 17083, 17157, 17231, 17306, 17381, 17456,
    17531, 17607, 17684, 17760, 17837, 17915, 17992, 18070, 18149, 18227, 18306,
    18385, 18465, 18545, 18626, 18706, 18787, 18869, 18950, 19033, 19115, 19198,
    19281, 19365,
  ],
  1440000: [
    3600, 3630, 3660, 3691, 3722, 3753, 3784, 3815, 3847, 3879, 3912, 3944,
    3977, 4010, 4044, 4077, 4111, 4145, 4180, 4215, 4250, 4285, 4321, 4357,
    4384, 4412, 4439, 4467, 4495, 4523, 4551, 4580, 4608, 4637, 4666, 4695,
    4725, 4754, 4784, 4814, 4844, 4874, 4905, 4935, 4966, 4997, 5028, 5060,
    5082, 5104, 5126, 5148, 5170, 5193, 5215, 5238, 5261, 5283, 5306, 5329,
    5352, 5376, 5399, 5422, 5446, 5469, 5493, 5517, 5541, 5565, 5589, 5613,
    5637, 5662, 5686, 5711, 5736, 5761, 5786, 5811, 5836, 5861, 5887, 5912,
    5938, 5963, 5989, 6015, 6041, 6067, 6094, 6120, 6147, 6173, 6200, 6227,
    6254, 6281, 6308, 6336, 6363, 6391, 6418, 6446, 6474, 6502, 6530, 6559,
    6587, 6616, 6644, 6673, 6702, 6731, 6760, 6789, 6819, 6848, 6878, 6908,
    6938, 6968, 6998, 7028, 7059, 7089, 7120, 7151, 7182, 7213, 7244, 7276,
    7307, 7339, 7371, 7403, 7435, 7467, 7499, 7532, 7565, 7597, 7630, 7663,
    7696, 7730, 7763, 7797, 7831, 7865, 7899, 7933, 7967, 8002, 8037, 8071,
    8106, 8142, 8177, 8212, 8248, 8284, 8319, 8356, 8392, 8428, 8465, 8501,
    8538, 8575, 8612, 8650, 8687, 8725, 8763, 8800, 8839, 8877, 8915, 8954,
    8993, 9032, 9071, 9110, 9150, 9189, 9229, 9269, 9309, 9350, 9390, 9431,
    9472, 9513, 9554, 9595, 9637, 9679, 9721, 9763, 9805, 9848, 9890, 9933,
    9976, 10019, 10063, 10106, 10150, 10194, 10238, 10283, 10327, 10372, 10417,
    10462, 10508, 10553, 10599, 10645, 10691, 10737, 10784, 10830, 10877, 10924,
    10972, 11019, 11067, 11115, 11163, 11212, 11260, 11309, 11358, 11407, 11457,
    11506, 11556, 11606, 11657, 11707, 11758, 11809, 11860, 11911, 11963, 12015,
    12067, 12119, 12172, 12224, 12277, 12331, 12384, 12438, 12492, 12546, 12600,
    12655, 12709, 12765, 12820, 12875, 12931, 12987, 13043, 13100, 13157, 13214,
    13271, 13329, 13386, 13444, 13503, 13561, 13620, 13679, 13738, 13798, 13857,
    13918, 13978, 14038, 14099, 14160, 14222, 14283, 14345, 14407, 14470, 14533,
    14595, 14659, 14722, 14786, 14850, 14914, 14979, 15044, 15109, 15175, 15240,
    15306, 15373, 15439, 15506, 15574, 15641, 15709, 15777, 15845, 15914, 15983,
    16052, 16122, 16192, 16262, 16332, 16403, 16474, 16545, 16617, 16689, 16761,
    16834, 16907, 16980, 17054, 17128, 17202, 17277, 17351, 17427, 17502, 17578,
    17654, 17731, 17807, 17885, 17962, 18040, 18118, 18197, 18275, 18355, 18434,
    18514, 18594, 18675, 18756, 18837, 18919, 19001, 19083, 19166, 19249, 19332,
    19416, 19500,
  ],
  1450000: [
    3625, 3655, 3686, 3716, 3747, 3779, 3810, 3842, 3874, 3906, 3939, 3971,
    4005, 4038, 4072, 4106, 4140, 4174, 4209, 4244, 4279, 4315, 4351, 4387,
    4415, 4442, 4470, 4498, 4526, 4554, 4583, 4612, 4640, 4669, 4699, 4728,
    4758, 4787, 4817, 4847, 4878, 4908, 4939, 4970, 5001, 5032, 5063, 5095,
    5117, 5139, 5162, 5184, 5206, 5229, 5252, 5274, 5297, 5320, 5343, 5366,
    5390, 5413, 5436, 5460, 5484, 5507, 5531, 5555, 5579, 5603, 5628, 5652,
    5677, 5701, 5726, 5751, 5776, 5801, 5826, 5851, 5876, 5902, 5927, 5953,
    5979, 6005, 6031, 6057, 6083, 6110, 6136, 6163, 6189, 6216, 6243, 6270,
    6297, 6325, 6352, 6380, 6407, 6435, 6463, 6491, 6519, 6547, 6576, 6604,
    6633, 6662, 6690, 6719, 6748, 6778, 6807, 6837, 6866, 6896, 6926, 6956,
    6986, 7016, 7047, 7077, 7108, 7139, 7170, 7201, 7232, 7263, 7295, 7326,
    7358, 7390, 7422, 7454, 7486, 7519, 7551, 7584, 7617, 7650, 7683, 7717,
    7750, 7784, 7817, 7851, 7885, 7919, 7954, 7988, 8023, 8057, 8092, 8127,
    8163, 8198, 8234, 8269, 8305, 8341, 8377, 8414, 8450, 8487, 8523, 8560,
    8597, 8635, 8672, 8710, 8747, 8785, 8823, 8862, 8900, 8939, 8977, 9016,
    9055, 9095, 9134, 9174, 9213, 9253, 9293, 9334, 9374, 9415, 9455, 9496,
    9538, 9579, 9620, 9662, 9704, 9746, 9788, 9831, 9873, 9916, 9959, 10002,
    10045, 10089, 10133, 10177, 10221, 10265, 10310, 10354, 10399, 10444, 10489,
    10535, 10580, 10626, 10672, 10719, 10765, 10812, 10859, 10906, 10953, 11000,
    11048, 11096, 11144, 11192, 11241, 11289, 11338, 11388, 11437, 11486, 11536,
    11586, 11636, 11687, 11737, 11788, 11839, 11891, 11942, 11994, 12046, 12098,
    12151, 12203, 12256, 12309, 12363, 12416, 12470, 12524, 12578, 12633, 12688,
    12742, 12798, 12853, 12909, 12965, 13021, 13077, 13134, 13191, 13248, 13306,
    13363, 13421, 13479, 13538, 13596, 13655, 13714, 13774, 13834, 13894, 13954,
    14014, 14075, 14136, 14197, 14259, 14320, 14383, 14445, 14507, 14570, 14633,
    14697, 14761, 14824, 14889, 14953, 15018, 15083, 15148, 15214, 15280, 15346,
    15413, 15480, 15547, 15614, 15682, 15750, 15818, 15886, 15955, 16024, 16094,
    16164, 16234, 16304, 16375, 16446, 16517, 16588, 16660, 16732, 16805, 16878,
    16951, 17024, 17098, 17172, 17247, 17321, 17396, 17472, 17548, 17624, 17700,
    17777, 17854, 17931, 18009, 18087, 18165, 18244, 18323, 18402, 18482, 18562,
    18643, 18723, 18805, 18886, 18968, 19050, 19133, 19216, 19299, 19382, 19466,
    19551, 19635,
  ],
  1460000: [
    3650, 3680, 3711, 3742, 3773, 3805, 3836, 3868, 3901, 3933, 3966, 3999,
    4032, 4066, 4100, 4134, 4168, 4203, 4238, 4273, 4309, 4345, 4381, 4418,
    4445, 4473, 4501, 4529, 4557, 4586, 4615, 4643, 4672, 4702, 4731, 4761,
    4790, 4820, 4850, 4881, 4911, 4942, 4973, 5004, 5035, 5067, 5098, 5130,
    5152, 5175, 5197, 5220, 5242, 5265, 5288, 5311, 5334, 5357, 5380, 5403,
    5427, 5450, 5474, 5498, 5521, 5545, 5569, 5594, 5618, 5642, 5667, 5691,
    5716, 5741, 5765, 5790, 5816, 5841, 5866, 5891, 5917, 5943, 5968, 5994,
    6020, 6046, 6072, 6099, 6125, 6152, 6178, 6205, 6232, 6259, 6286, 6313,
    6341, 6368, 6396, 6424, 6451, 6479, 6507, 6536, 6564, 6592, 6621, 6650,
    6679, 6707, 6737, 6766, 6795, 6824, 6854, 6884, 6914, 6944, 6974, 7004,
    7034, 7065, 7095, 7126, 7157, 7188, 7219, 7250, 7282, 7313, 7345, 7377,
    7409, 7441, 7473, 7506, 7538, 7571, 7604, 7636, 7670, 7703, 7736, 7770,
    7803, 7837, 7871, 7905, 7940, 7974, 8008, 8043, 8078, 8113, 8148, 8184,
    8219, 8255, 8290, 8326, 8362, 8399, 8435, 8472, 8508, 8545, 8582, 8619,
    8657, 8694, 8732, 8770, 8808, 8846, 8884, 8923, 8961, 9000, 9039, 9078,
    9118, 9157, 9197, 9237, 9277, 9317, 9357, 9398, 9439, 9480, 9521, 9562,
    9603, 9645, 9687, 9729, 9771, 9813, 9856, 9898, 9941, 9984, 10028, 10071,
    10115, 10159, 10203, 10247, 10291, 10336, 10381, 10426, 10471, 10516, 10562,
    10607, 10653, 10700, 10746, 10793, 10839, 10886, 10933, 10981, 11028, 11076,
    11124, 11172, 11221, 11269, 11318, 11367, 11417, 11466, 11516, 11566, 11616,
    11666, 11717, 11767, 11818, 11870, 11921, 11973, 12025, 12077, 12129, 12182,
    12234, 12287, 12341, 12394, 12448, 12502, 12556, 12610, 12665, 12720, 12775,
    12830, 12886, 12942, 12998, 13054, 13111, 13168, 13225, 13282, 13340, 13397,
    13455, 13514, 13572, 13631, 13690, 13749, 13809, 13869, 13929, 13989, 14050,
    14111, 14172, 14233, 14295, 14357, 14419, 14482, 14544, 14607, 14671, 14734,
    14798, 14862, 14927, 14991, 15056, 15122, 15187, 15253, 15319, 15385, 15452,
    15519, 15586, 15654, 15722, 15790, 15858, 15927, 15996, 16065, 16135, 16205,
    16275, 16346, 16416, 16488, 16559, 16631, 16703, 16775, 16848, 16921, 16994,
    17068, 17142, 17216, 17291, 17366, 17441, 17516, 17592, 17669, 17745, 17822,
    17899, 17977, 18055, 18133, 18212, 18290, 18370, 18449, 18529, 18610, 18690,
    18771, 18853, 18934, 19016, 19099, 19181, 19265, 19348, 19432, 19516, 19601,
    19686, 19771,
  ],
  1470000: [
    3675, 3706, 3737, 3768, 3799, 3831, 3863, 3895, 3927, 3960, 3993, 4026,
    4060, 4094, 4128, 4162, 4197, 4232, 4267, 4303, 4339, 4375, 4411, 4448,
    4476, 4504, 4532, 4560, 4589, 4617, 4646, 4675, 4704, 4734, 4763, 4793,
    4823, 4853, 4884, 4914, 4945, 4976, 5007, 5038, 5070, 5101, 5133, 5165,
    5188, 5210, 5233, 5255, 5278, 5301, 5324, 5347, 5370, 5394, 5417, 5440,
    5464, 5488, 5511, 5535, 5559, 5583, 5608, 5632, 5656, 5681, 5705, 5730,
    5755, 5780, 5805, 5830, 5855, 5881, 5906, 5932, 5957, 5983, 6009, 6035,
    6061, 6088, 6114, 6141, 6167, 6194, 6221, 6248, 6275, 6302, 6329, 6357,
    6384, 6412, 6440, 6468, 6496, 6524, 6552, 6580, 6609, 6638, 6666, 6695,
    6724, 6753, 6783, 6812, 6842, 6871, 6901, 6931, 6961, 6991, 7021, 7052,
    7082, 7113, 7144, 7175, 7206, 7237, 7269, 7300, 7332, 7363, 7395, 7427,
    7460, 7492, 7524, 7557, 7590, 7623, 7656, 7689, 7722, 7756, 7789, 7823,
    7857, 7891, 7925, 7959, 7994, 8029, 8063, 8098, 8133, 8169, 8204, 8240,
    8275, 8311, 8347, 8383, 8420, 8456, 8493, 8530, 8567, 8604, 8641, 8678,
    8716, 8754, 8792, 8830, 8868, 8906, 8945, 8984, 9023, 9062, 9101, 9141,
    9180, 9220, 9260, 9300, 9340, 9381, 9421, 9462, 9503, 9544, 9586, 9627,
    9669, 9711, 9753, 9795, 9838, 9880, 9923, 9966, 10009, 10053, 10096, 10140,
    10184, 10228, 10272, 10317, 10362, 10407, 10452, 10497, 10542, 10588, 10634,
    10680, 10726, 10773, 10820, 10866, 10914, 10961, 11008, 11056, 11104, 11152,
    11200, 11249, 11298, 11347, 11396, 11445, 11495, 11545, 11595, 11645, 11695,
    11746, 11797, 11848, 11899, 11951, 12003, 12055, 12107, 12159, 12212, 12265,
    12318, 12372, 12425, 12479, 12533, 12587, 12642, 12697, 12752, 12807, 12863,
    12918, 12974, 13030, 13087, 13144, 13201, 13258, 13315, 13373, 13431, 13489,
    13548, 13606, 13665, 13724, 13784, 13844, 13904, 13964, 14024, 14085, 14146,
    14207, 14269, 14331, 14393, 14455, 14518, 14581, 14644, 14708, 14771, 14835,
    14900, 14964, 15029, 15094, 15160, 15225, 15291, 15357, 15424, 15491, 15558,
    15625, 15693, 15761, 15829, 15898, 15967, 16036, 16106, 16175, 16245, 16316,
    16387, 16458, 16529, 16600, 16672, 16745, 16817, 16890, 16963, 17037, 17111,
    17185, 17259, 17334, 17409, 17485, 17560, 17636, 17713, 17790, 17867, 17944,
    18022, 18100, 18178, 18257, 18336, 18416, 18496, 18576, 18656, 18737, 18818,
    18900, 18982, 19064, 19147, 19230, 19313, 19397, 19481, 19565, 19650, 19735,
    19820, 19906,
  ],
  1480000: [
    3700, 3731, 3762, 3793, 3825, 3857, 3889, 3921, 3954, 3987, 4020, 4054,
    4087, 4122, 4156, 4190, 4225, 4261, 4296, 4332, 4368, 4404, 4441, 4478,
    4506, 4534, 4563, 4591, 4620, 4649, 4678, 4707, 4736, 4766, 4796, 4826,
    4856, 4886, 4917, 4948, 4978, 5010, 5041, 5072, 5104, 5136, 5168, 5200,
    5223, 5246, 5268, 5291, 5314, 5337, 5360, 5383, 5407, 5430, 5454, 5477,
    5501, 5525, 5549, 5573, 5597, 5621, 5646, 5670, 5695, 5719, 5744, 5769,
    5794, 5819, 5844, 5870, 5895, 5921, 5946, 5972, 5998, 6024, 6050, 6076,
    6103, 6129, 6156, 6182, 6209, 6236, 6263, 6290, 6317, 6345, 6372, 6400,
    6428, 6456, 6484, 6512, 6540, 6568, 6597, 6625, 6654, 6683, 6712, 6741,
    6770, 6799, 6829, 6858, 6888, 6918, 6948, 6978, 7008, 7039, 7069, 7100,
    7131, 7161, 7192, 7224, 7255, 7286, 7318, 7350, 7382, 7414, 7446, 7478,
    7510, 7543, 7576, 7608, 7641, 7674, 7708, 7741, 7775, 7808, 7842, 7876,
    7910, 7945, 7979, 8014, 8048, 8083, 8118, 8153, 8189, 8224, 8260, 8296,
    8332, 8368, 8404, 8440, 8477, 8514, 8551, 8588, 8625, 8662, 8700, 8737,
    8775, 8813, 8852, 8890, 8928, 8967, 9006, 9045, 9084, 9124, 9163, 9203,
    9243, 9283, 9323, 9363, 9404, 9445, 9486, 9527, 9568, 9609, 9651, 9693,
    9735, 9777, 9819, 9862, 9905, 9948, 9991, 10034, 10078, 10121, 10165, 10209,
    10253, 10298, 10342, 10387, 10432, 10477, 10523, 10568, 10614, 10660, 10706,
    10753, 10799, 10846, 10893, 10940, 10988, 11035, 11083, 11131, 11179, 11228,
    11277, 11325, 11375, 11424, 11473, 11523, 11573, 11623, 11673, 11724, 11775,
    11826, 11877, 11929, 11980, 12032, 12084, 12137, 12189, 12242, 12295, 12348,
    12402, 12456, 12510, 12564, 12618, 12673, 12728, 12783, 12839, 12894, 12950,
    13006, 13062, 13119, 13176, 13233, 13290, 13348, 13406, 13464, 13522, 13581,
    13640, 13699, 13758, 13818, 13878, 13938, 13998, 14059, 14120, 14181, 14242,
    14304, 14366, 14428, 14491, 14554, 14617, 14680, 14744, 14808, 14872, 14936,
    15001, 15066, 15131, 15197, 15263, 15329, 15395, 15462, 15529, 15596, 15664,
    15732, 15800, 15868, 15937, 16006, 16075, 16145, 16215, 16285, 16356, 16427,
    16498, 16569, 16641, 16713, 16786, 16859, 16932, 17005, 17079, 17153, 17227,
    17302, 17377, 17452, 17528, 17604, 17680, 17756, 17833, 17911, 17988, 18066,
    18144, 18223, 18302, 18381, 18461, 18541, 18621, 18702, 18783, 18865, 18946,
    19028, 19111, 19194, 19277, 19360, 19444, 19528, 19613, 19698, 19783, 19869,
    19955, 20042,
  ],
  1490000: [
    3725, 3756, 3787, 3819, 3851, 3883, 3915, 3948, 3981, 4014, 4047, 4081,
    4115, 4149, 4184, 4219, 4254, 4289, 4325, 4361, 4398, 4434, 4471, 4508,
    4537, 4565, 4593, 4622, 4651, 4680, 4709, 4739, 4768, 4798, 4828, 4858,
    4889, 4919, 4950, 4981, 5012, 5043, 5075, 5107, 5139, 5171, 5203, 5236,
    5258, 5281, 5304, 5327, 5350, 5373, 5396, 5420, 5443, 5467, 5491, 5514,
    5538, 5562, 5586, 5611, 5635, 5659, 5684, 5708, 5733, 5758, 5783, 5808,
    5833, 5859, 5884, 5909, 5935, 5961, 5987, 6012, 6039, 6065, 6091, 6117,
    6144, 6171, 6197, 6224, 6251, 6278, 6305, 6333, 6360, 6388, 6415, 6443,
    6471, 6499, 6527, 6556, 6584, 6613, 6641, 6670, 6699, 6728, 6757, 6786,
    6816, 6845, 6875, 6905, 6935, 6965, 6995, 7025, 7056, 7086, 7117, 7148,
    7179, 7210, 7241, 7272, 7304, 7336, 7367, 7399, 7431, 7464, 7496, 7528,
    7561, 7594, 7627, 7660, 7693, 7726, 7760, 7793, 7827, 7861, 7895, 7929,
    7964, 7998, 8033, 8068, 8103, 8138, 8173, 8208, 8244, 8280, 8316, 8352,
    8388, 8424, 8461, 8497, 8534, 8571, 8608, 8646, 8683, 8721, 8759, 8796,
    8835, 8873, 8911, 8950, 8989, 9028, 9067, 9106, 9146, 9185, 9225, 9265,
    9305, 9345, 9386, 9427, 9467, 9508, 9550, 9591, 9633, 9674, 9716, 9758,
    9801, 9843, 9886, 9929, 9972, 10015, 10058, 10102, 10146, 10190, 10234,
    10278, 10323, 10367, 10412, 10457, 10503, 10548, 10594, 10640, 10686, 10732,
    10779, 10825, 10872, 10919, 10967, 11014, 11062, 11110, 11158, 11206, 11255,
    11304, 11353, 11402, 11451, 11501, 11551, 11601, 11651, 11702, 11752, 11803,
    11854, 11906, 11957, 12009, 12061, 12114, 12166, 12219, 12272, 12325, 12378,
    12432, 12486, 12540, 12594, 12649, 12704, 12759, 12814, 12869, 12925, 12981,
    13038, 13094, 13151, 13208, 13265, 13322, 13380, 13438, 13496, 13555, 13614,
    13673, 13732, 13791, 13851, 13911, 13971, 14032, 14093, 14154, 14215, 14277,
    14339, 14401, 14463, 14526, 14589, 14652, 14716, 14779, 14843, 14908, 14972,
    15037, 15102, 15168, 15233, 15299, 15366, 15432, 15499, 15566, 15634, 15702,
    15770, 15838, 15907, 15976, 16045, 16114, 16184, 16254, 16325, 16395, 16466,
    16538, 16609, 16681, 16754, 16826, 16899, 16972, 17046, 17120, 17194, 17269,
    17343, 17419, 17494, 17570, 17646, 17722, 17799, 17876, 17954, 18032, 18110,
    18188, 18267, 18346, 18426, 18506, 18586, 18666, 18747, 18828, 18910, 18992,
    19074, 19157, 19240, 19323, 19407, 19491, 19576, 19660, 19746, 19831, 19917,
    20003, 20090, 20177,
  ],
  1500000: [
    3750, 3781, 3813, 3845, 3877, 3909, 3941, 3974, 4007, 4041, 4074, 4108,
    4143, 4177, 4212, 4247, 4282, 4318, 4354, 4390, 4427, 4464, 4501, 4539,
    4567, 4596, 4624, 4653, 4682, 4712, 4741, 4771, 4800, 4830, 4861, 4891,
    4922, 4952, 4983, 5014, 5046, 5077, 5109, 5141, 5173, 5205, 5238, 5271,
    5294, 5316, 5340, 5363, 5386, 5409, 5433, 5456, 5480, 5504, 5527, 5551,
    5575, 5600, 5624, 5648, 5673, 5697, 5722, 5747, 5772, 5797, 5822, 5847,
    5872, 5898, 5923, 5949, 5975, 6001, 6027, 6053, 6079, 6105, 6132, 6158,
    6185, 6212, 6239, 6266, 6293, 6320, 6348, 6375, 6403, 6431, 6458, 6486,
    6515, 6543, 6571, 6600, 6628, 6657, 6686, 6715, 6744, 6773, 6802, 6832,
    6861, 6891, 6921, 6951, 6981, 7011, 7042, 7072, 7103, 7134, 7165, 7196,
    7227, 7258, 7290, 7321, 7353, 7385, 7417, 7449, 7481, 7514, 7546, 7579,
    7612, 7645, 7678, 7711, 7745, 7778, 7812, 7846, 7880, 7914, 7948, 7983,
    8017, 8052, 8087, 8122, 8157, 8192, 8228, 8264, 8299, 8335, 8371, 8408,
    8444, 8481, 8517, 8554, 8591, 8629, 8666, 8704, 8741, 8779, 8817, 8855,
    8894, 8932, 8971, 9010, 9049, 9088, 9128, 9167, 9207, 9247, 9287, 9327,
    9368, 9408, 9449, 9490, 9531, 9572, 9614, 9655, 9697, 9739, 9781, 9824,
    9866, 9909, 9952, 9995, 10039, 10082, 10126, 10170, 10214, 10258, 10302,
    10347, 10392, 10437, 10482, 10528, 10573, 10619, 10665, 10711, 10758, 10804,
    10851, 10898, 10945, 10993, 11040, 11088, 11136, 11185, 11233, 11282, 11331,
    11380, 11429, 11479, 11528, 11578, 11628, 11679, 11729, 11780, 11831, 11883,
    11934, 11986, 12038, 12090, 12142, 12195, 12248, 12301, 12354, 12408, 12461,
    12515, 12570, 12624, 12679, 12734, 12789, 12844, 12900, 12956, 13012, 13068,
    13125, 13182, 13239, 13296, 13354, 13412, 13470, 13528, 13587, 13646, 13705,
    13764, 13824, 13884, 13944, 14005, 14065, 14126, 14187, 14249, 14311, 14373,
    14435, 14497, 14560, 14623, 14687, 14750, 14814, 14878, 14943, 15008, 15073,
    15138, 15204, 15270, 15336, 15402, 15469, 15536, 15603, 15671, 15739, 15807,
    15875, 15944, 16013, 16083, 16152, 16222, 16293, 16363, 16434, 16505, 16577,
    16649, 16721, 16793, 16866, 16939, 17013, 17086, 17160, 17235, 17309, 17384,
    17460, 17535, 17611, 17688, 17764, 17841, 17919, 17996, 18074, 18153, 18231,
    18310, 18390, 18469, 18549, 18630, 18711, 18792, 18873, 18955, 19037, 19119,
    19202, 19285, 19369, 19453, 19537, 19622, 19707, 19792, 19878, 19964, 20051,
    20138, 20225, 20313,
  ],
};
