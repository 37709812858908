import moment from "moment";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { View } from "react-native";
import { Line } from "react-chartjs-2";
import { useRef, useEffect } from "react";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

//Function
import { MAIN_COLORS } from "../../../Utility/Colors";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import {
  formatDollar,
  externalTooltipHandler,
  externalTooltipHandlerNew,
} from "../../../Utility/common";

ChartJS.register(ArcElement, Tooltip, Legend);

export const GrowthChart = ({ width, height, result = [] }) => {
  const chartRef = useRef(null);

  const minY = Math.min(...result?.map(({ y }) => y));
  const maxY = Math.max(...result?.map(({ y }) => y));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        cornerRadius: 20,
        callbacks: { label: (value) => formatDollar(value.raw.y) },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { left: 8, right: 8, top: 0, bottom: 0 },
            "46px"
          ),
      },
    },
    scales: {
      x: {
        ticks: {
          align: "center",
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          color: MAIN_COLORS.TEXT_LIGHT,
        },
        font: { family: MAIN_FONT.regular, size: 12 },
        grid: { display: false },
        border: { color: "#3F4448" },
      },
      y: {
        border: { dash: [4, 4], color: "#3F4448" },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#3F4448";
          },
        },
        beginAtZero: false,
        min: minY,
        max: maxY + maxY * 0.1,
        ticks: {
          display: false,
          maxTicksLimit: 5,
          stepSize: (maxY + maxY * 0.1 - minY) / 5,
        },
      },
      y1: {
        stacked: true,
        position: "right",
        grid: { display: false },
        ticks: { display: false },
        border: { color: "#3F4448" },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: result,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.15)",
            "rgba(250, 191, 1, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderWidth: 5.5,
        pointBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBorderColor: MAIN_COLORS.BACKGROUND_WHITE,
        pointRadius: () => 0,
        pointHoverRadius: 6,
        lineTension: 0.7,
        borderWidth: 1,
        fill: true,
        hoverBorderWidth: 2,
        spanGaps: true,
        tension: 0.1,
        clip: false,
      },
    ],
  };

  const plugins = {
    afterDraw: function (chart) {
      if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.chart.tooltip.caretY + 10;
        const bottomY = chart.scales.y.bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = MAIN_COLORS.PRIMARY_COLOR;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  useEffect(() => {
    const chart = chartRef.current;
    chart?.tooltip?.setActiveElements([]);
    chart?.update();
  }, []);

  return (
    <View style={{ width: width, height: height }}>
      <Line
        options={options}
        data={data}
        ref={chartRef}
        plugins={[plugins]}
        style={{ marginLeft: -6, marginRight: -6 }}
      />
    </View>
  );
};
