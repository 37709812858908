import React, { useState } from "react";
import { Sidebar } from "react-pro-sidebar";
import { View, Image, Text, StyleSheet, TouchableOpacity } from "react-native";

//Function
import { MAIN_COLORS } from "../../Utility/Colors";
import { MAIN_FONT, FONT_SIZE, FONT_WEIGHT } from "../../Utility/Fonts/font";
import { ORIGIN } from "../../Utility/common";

//Component
import FeaturePermissionWrapper from "../../components/FeaturePermissionWrapper";

const data = [
  {
    display: "Home",
    display2: "ProjectDetails",
    feature: "homeScreen",
    icons: {
      focused: require("../../assets/Bulk/Home.png"),
      unfocused: require("../../assets/Light/Home.svg"),
    },
  },
  {
    display: "Portfolio",
    feature: "portfolioScreen",
    icons: {
      focused: require("../../assets/Bulk/Activity.png"),
      unfocused: require("../../assets/Light/Activity.svg"),
    },
  },
  {
    display: "Notifications",
    feature: "notificationScreen",
    icons: {
      focused: require("../../assets/Bulk/Notification.png"),
      unfocused: require("../../assets/Light/Notification.svg"),
    },
  },
  {
    display: "Roadmap",
    feature: "portfolioRoadmap",
    icons: {
      focused: require("../../assets/Bulk/Roadmap.png"),
      unfocused: require("../../assets/Light/Roadmap.svg"),
    },
    id: "portfolio_roadmap",
  },
];

const handleActiveItem = ({ screen, icons }) => {
  return {
    button: {
      borderRightWidth: screen ? 4 : 0,
      backgroundColor: screen
        ? "rgba(250, 192, 0, 0.1)"
        : "rgba(250, 192, 0, 0)",
    },
    text: {
      color: screen ? MAIN_COLORS.PRIMARY_COLOR : "#A3A3A3",
    },
    icon: screen ? icons?.focused : icons?.unfocused,
  };
};

const Panel = ({ setPropScreen, setStateScreen, screen }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sidebar
      collapsed={collapsed}
      style={{ borderWidth: 0, width: collapsed ? 64 : 256 }}
    >
      <View style={styles.container}>
        <View style={[styles.rowCenter, styles.header]}>
          <Image
            style={{ height: 40, width: collapsed ? 43 : 126 }}
            resizeMode="contain"
            source={
              collapsed
                ? require("../../assets/home/logo_mini.png")
                : require("../../assets/home/logo.png")
            }
          />
          <TouchableOpacity onPress={() => setCollapsed(!collapsed)}>
            {!collapsed && (
              <Image
                style={{ height: 20, width: 20 }}
                resizeMode="contain"
                source={require("../../assets/home/arrow_square_left.png")}
              />
            )}
          </TouchableOpacity>
        </View>
        <View style={styles.content}>
          {data.map((item, index) => {
            return (
              <FeaturePermissionWrapper feature={item.feature} key={index}>
                <PanelItem
                  screen={screen}
                  icons={item.icons}
                  text={item.display}
                  text2={item?.display2}
                  collapsed={collapsed}
                  onPress={() => {
                    window.history.replaceState(null, "", ORIGIN);
                    setStateScreen(item.display);
                    setPropScreen(item.display);
                  }}
                  id={item?.id}
                />
              </FeaturePermissionWrapper>
            );
          })}
        </View>
        <View style={styles.contentBottom}>
          {collapsed && (
            <TouchableOpacity
              onPress={() => setCollapsed(false)}
              style={{ alignItems: "center", marginBottom: 24 }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../../assets/home/arrow_square_right.svg")}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Sidebar>
  );
};

const PanelItem = ({ text, text2, icons, collapsed, screen, onPress, id }) => {
  let prop = {};
  if (id) prop.id = id;
  return (
    <div {...prop}>
      <TouchableOpacity
        style={[
          styles.navItem,
          collapsed && styles.alignItems,
          handleActiveItem({ screen: screen === text || screen === text2 })
            .button,
        ]}
        onPress={onPress}
      >
        <Image
          resizeMode="contain"
          source={
            handleActiveItem({
              screen: screen === text || screen === text2,
              icons,
            }).icon
          }
          style={styles.iconItem}
        />
        {!collapsed && (
          <Text
            style={[
              styles.textItem,
              handleActiveItem({ screen: screen === text || screen === text2 })
                .text,
            ]}
          >
            {text.toUpperCase()}
          </Text>
        )}
      </TouchableOpacity>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    width: "100%",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    shadowColor: "rgba(0, 0, 0, 0.15)",
    shadowOffset: {
      width: 1,
      height: 0,
    },
    shadowOpacity: 0.65,
    shadowRadius: 15,
    elevation: 5,
    borderColor: "rgba(0, 0, 0, 0.15)",
    position: "relative",
    height: "100%",
  },

  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  alignItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  header: {
    paddingHorizontal: 20,
    paddingVertical: 26,
    width: "100%",
    marginBottom: 26,
    height: 95,
  },

  content: {
    display: "flex",
    width: "100%",
    minHeight: "60%",
    gap: 16,
  },

  contentBottom: {
    position: "absolute",
    bottom: 0,
    gap: 16,
    width: "100%",
  },

  navItem: {
    height: 56,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 15,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 16,
    borderRightColor: MAIN_COLORS.PRIMARY_COLOR,
  },

  textItem: {
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.large,
    lineHeight: 24,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    color: "#A3A3A3",
  },

  iconItem: {
    width: 24,
    height: 24,
  },
});

export { Panel };
