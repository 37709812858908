import { EState } from "./stamp-duty-constant";
import {
  STAMP_DUTY_BY_STATE,
  NT_END,
  NT_FIXED,
  NT_FIXED_2,
  NSW_MINIMUM,
  WA_TRANFER_FEES,
} from "./stamp-duty-constant";

const calcRemainingValue = (price, overValue) => {
  return price - overValue;
};

const isInCondition = (start, end, price) => {
  return price > start && price <= end;
};

export const calcStampDuty = (price, state) => {
  let result = 0;
  const stateInfo = STAMP_DUTY_BY_STATE.find((item) => item.state === state);
  if (stateInfo) {
    const { isPercent, forEach, stampDuty } = stateInfo;

    const data = stampDuty.find((item) =>
      isInCondition(item.start, item.end, price)
    );

    if (!data) return 0;

    const overValueData =
      typeof data.overValue === "number" ? data.overValue : data.start;
    const forEachData = data.forEach || forEach;
    const remainingPerUnit = Math.ceil(
      calcRemainingValue(price, overValueData) / forEachData
    );

    if (isPercent) {
      // Specific for NT
      if (price > 0 && price <= NT_END && state === EState.NT) {
        result =
          NT_FIXED * (price / 1000) * (price / 1000) +
          NT_FIXED_2 * (price / 1000);
      } else {
        result = Math.round(
          (remainingPerUnit * data.multipleBy) / 100 + data.minCharge
        );
      }
    } else {
      result = Math.round(remainingPerUnit * data.multipleBy + data.minCharge);
    }

    if (state === EState.NSW && result < NSW_MINIMUM) {
      result = NSW_MINIMUM;
    }
  }

  return result;
};

export const calcGorvermentFees = (price, state) => {
  let mortgage = 0;
  let transfer = 0;
  const stateInfo = STAMP_DUTY_BY_STATE.find((item) => item.state === state);

  if (stateInfo) {
    const { stampDuty, mortgageRegistration, transferFee } = stateInfo;

    const data = stampDuty.find((item) =>
      isInCondition(item.start, item.end, price)
    );

    if (!data) return 0;

    mortgage = mortgageRegistration;

    if (state === EState.VIC) {
      transfer = Math.floor((2.34 * price) / 1000) + 96;
      transfer = transfer <= 3607 ? transfer : 3607;
    } else if (state === EState.QLD) {
      transfer = 224.32 + Math.ceil((price - 180000) / 10000) * 42.13;
      transfer = price > 180000 ? transfer : 224.32;
    } else if (state === EState.SA) {
      if (price <= 5000) {
        transfer = 187;
      } else if (price <= 20000) {
        transfer = 209;
      } else if (price <= 40000) {
        transfer = 230;
      } else if (price <= 50000) {
        transfer = 323;
      } else {
        transfer = 323 + Math.ceil((price - 50000) / 10000) * 96;
      }
    } else if (state === EState.WA) {
      if (price <= 2000000) {
        const waTransferFees = WA_TRANFER_FEES.find((item) =>
          isInCondition(item.start, item.end, price)
        );
        transfer = waTransferFees ? waTransferFees.transfer_fee : 0;
      } else {
        transfer = 593 + Math.ceil((price - 2000000) / 100000) * 20;
      }
    } else {
      transfer = transferFee;
    }
  }
  return mortgage + transfer;
};
