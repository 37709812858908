import React, { memo, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  Animated,
  TouchableOpacity,
} from "react-native";

import { format } from "../../../Zoho/Tasks";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { capitalizeWords, formatDollar } from "../../../Utility/common";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

const Associated = ({ properties }) => {
  return (
    <View style={styles.container}>
      {properties.map((item, index) => {
        const title =
          index === 0
            ? "Purchase Cost"
            : index === 1
            ? "Expenses"
            : "Potential Results";
        return <Box title={title} properties={item} />;
      })}
    </View>
  );
};

const Box = ({ properties, title }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const animationHeight = useRef(new Animated.Value(383)).current;

  const handleShowDetail = () => {
    Animated.timing(animationHeight, {
      toValue: 383,
      duration: 400,
      useNativeDriver: true,
    }).start();
    setIsExpanded(true);
  };

  const handleHideDetail = () => {
    Animated.timing(animationHeight, {
      toValue: 0,
      duration: 400,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished) setIsExpanded(false);
    });
  };

  return (
    <View style={[styles.box, { height: !isExpanded && "max-content" }]}>
      <View style={styles.rowCenterBetween}>
        <Text style={styles.boxTitle}>{title}</Text>
        {isExpanded ? (
          <TouchableOpacity onPress={handleHideDetail}>
            <Image
              style={[styles.boxArrow, { transform: [{ rotate: "180deg" }] }]}
              source={require("../../../assets/home/arrow_down.svg")}
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={handleShowDetail}>
            <Image
              style={styles.boxArrow}
              source={require("../../../assets/home/arrow_down.svg")}
            />
          </TouchableOpacity>
        )}
      </View>

      {isExpanded && (
        <Animated.View
          style={[styles.boxContent, { maxHeight: animationHeight, flex: 1 }]}
        >
          <View style={styles.divider} />
          {properties.slice(0, -1).map((item, index, array) => {
            return (
              <React.Fragment key={item.title}>
                <View style={styles.rowCenterBetween}>
                  <Text
                    style={[
                      styles.boxText,
                      item.highlight && { color: MAIN_COLORS.PRIMARY_COLOR },
                    ]}
                  >
                    {item.title}
                  </Text>
                  <Text style={[styles.boxText, styles.boxValue]}>
                    {!item.title.includes("yield") && "$"}
                    {format(item.value ?? 0, false)}
                    {item.title.includes("yield") && "%"}
                  </Text>
                </View>
                {title === "Potential Results" &&
                  index === array.length - 5 && <View style={styles.divider} />}
              </React.Fragment>
            );
          })}
        </Animated.View>
      )}

      <View style={[styles.divider]} />
      <View style={styles.rowCenterBetween}>
        <Text style={styles.boxText}>
          {capitalizeWords(properties[properties.length - 1].title)}
        </Text>
        <Text style={[styles.boxText, styles.boxActive]}>
          {!properties[properties.length - 1].title.includes("yield") && "$"}
          {format(properties[properties.length - 1].value, false)}
          {properties[properties.length - 1].title.includes("yield") && "%"}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },

  box: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    overflow: "hidden",
    display: "flex",
    flex: 1,
    gap: 12,
  },

  rowCenterBetween: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    gap: 6,
  },

  boxTitle: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lightHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  boxArrow: {
    width: 20,
    height: 20,
  },

  boxContent: {
    display: "flex",
    gap: 12,
    overflow: "hidden",
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#4B555D99",
  },

  boxText: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  boxValue: {
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  boxActive: {
    color: MAIN_COLORS.PRIMARY_COLOR,
    fontWeight: FONT_WEIGHT.bold,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(Associated);
