import React from "react";
import Toast, { InfoToast } from "react-native-toast-message";
import {
  View,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  Linking,
} from "react-native";

import { MAIN_COLORS } from "../../Colors";
import avatarDefault from "../../../assets/home/user.svg";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Fonts/font";

const ContactItem = ({ style, item }) => {
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return;

    const cleanedPhone = phoneNumber
      .replace(/\s/g, "")
      .replace(/\b0+/g, "")
      .replace(/^\+61\s*/, "");
    if (cleanedPhone.length === 9) {
      return cleanedPhone.replace(/^(\d)(\d{4})(\d{4})$/, "+61 $1 $2 $3");
    }

    return cleanedPhone.replace(/^(\d{4})(\d{3})(\d{3})$/, "+61 $1 $2 $3");
  };

  const onCall = () => {
    const phoneNumber = item?.phone;
    navigator.clipboard.writeText(formatPhoneNumber(phoneNumber));
    onCopyPhone();
  };

  const onEmail = () => {
    item?.email && Linking.openURL(`mailto:${item?.email}`);
  };

  const onCopyPhone = () => {
    Toast.hide();
    setTimeout(() => {
      Toast.show({
        type: "info",
        text1: "Phone number copied!",
        visibilityTime: 3000,
      });
    }, 300);
  };

  return (
    <View style={[styles.container, style]}>
      {item?.avatar ? (
        <View style={styles.avatar}>
          <Image
            style={{ width: 46, height: 46 }}
            source={{ uri: item?.avatar }}
          />
        </View>
      ) : (
        <Image style={{ width: 46, height: 46 }} source={avatarDefault} />
      )}
      <View style={styles.info}>
        <Text style={styles.infoName}>{item?.name}</Text>
        <Text style={styles.infoJob}>{item?.type}</Text>
      </View>
      <TouchableOpacity onPress={onCall}>
        <View style={styles.boxIcon}>
          <Image
            style={styles.icon}
            source={require("../../../assets/home/phone.svg")}
          />
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={onEmail}>
        <View style={[styles.boxIcon, { backgroundColor: "#D3B10040" }]}>
          <Image
            style={styles.icon}
            source={require("../../../assets/home/mail.svg")}
          />
        </View>
      </TouchableOpacity>

      <View
        style={{
          position: "fixed",
          right: 120,
          top: 0,
          width: 187,
          zIndex: 9999,
        }}
      >
        <Toast
          config={{
            info: (props) => (
              <InfoToast
                {...props}
                style={{ borderLeftWidth: 0, width: 187 }}
                contentContainerStyle={{
                  backgroundColor: "#E9E9EB",
                  borderRadius: 6,
                }}
                text1Style={{
                  fontSize: FONT_SIZE.small,
                  lineHeight: 22,
                  color: MAIN_COLORS.TEXT_DARK,
                  fontFamily: MAIN_FONT.regular,
                }}
              />
            ),
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    padding: 12,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 6,
  },
  avatar: {
    width: 46,
    height: 46,
    borderRadius: 3,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: MAIN_COLORS.BORDER_COLOR,
  },
  info: { display: "flex", flex: 1, gap: 4 },
  infoName: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lightHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
  infoJob: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  icon: {
    width: 20,
    height: 20,
  },

  boxIcon: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF40",
    borderRadius: "50%",
  },
});

export default ContactItem;
