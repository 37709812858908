import { useSelector } from "react-redux";
import { Calendar } from "react-native-calendars";
import React, { memo, useRef, useEffect } from "react";
import { View, Text, StyleSheet, Image, Animated } from "react-native";

import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Fonts/font";
import { TouchableOpacity } from "react-native";

const CalendarCard = ({ markedDates, onPressDay, initialDate }) => {
  const { showCalendar } = useSelector((state) => state.PORTFOLIO_ROADMAP);

  const height = useRef(new Animated.Value(365)).current;

  useEffect(() => {
    if (showCalendar) {
      Animated.timing(height, {
        toValue: 365,
        duration: 200,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(height, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }
  }, [showCalendar]);

  return (
    <Animated.View style={{ height: height, overflow: "hidden" }}>
      <Calendar
        style={styles.calendar}
        accessibilityLabel="red"
        markingType={"custom"}
        headerStyle={styles.calendarHeader}
        theme={calendarTheme}
        initialDate={initialDate}
        renderArrow={(direction) => (
          <Image
            style={{ width: 22, height: 22 }}
            source={
              direction === "left"
                ? require("../../../assets/home/arrow_left.svg")
                : require("../../../assets/home/arrow_right.svg")
            }
          />
        )}
        dayComponent={({ date, state }) => {
          const dateString = date.dateString;
          const isDisabled = state === "disabled";
          if (dateString in markedDates) {
            return (
              <TouchableOpacity
                style={styles.calendarDay}
                onPress={() => onPressDay(dateString)}
                activeOpacity={0.5}
              >
                <Text style={[styles.calendarDay, styles.calendarDaySele]}>
                  {date.day}
                </Text>

                <View style={{ position: "absolute", top: -5, right: -5 }}>
                  <View style={styles.calendarBoxDot}>
                    <Text style={styles.calendarTextDot}>
                      {markedDates[dateString].quantity}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            );
          }
          return (
            <Text
              style={[
                styles.calendarDay,
                isDisabled && styles.calendarDayDisable,
              ]}
            >
              {date.day}
            </Text>
          );
        }}
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  calendar: {
    fontFamily: MAIN_FONT.regular,
    width: "100%",
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },

  calendarHeader: {
    marginBottom: 12,
  },

  calendarDay: {
    fontWeight: 500,
    color: MAIN_COLORS.BACKGROUND_WHITE,
    fontSize: 13,
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: MAIN_FONT.regular,
  },

  calendarDayDisable: {
    color: MAIN_COLORS.G600,
  },

  calendarDaySele: {
    backgroundColor: "#4B555D99",
    borderRadius: "50%",
  },

  calendarBoxDot: {
    width: 15,
    height: 15,
    borderRadius: 10,
    backgroundColor: "#D63300",
    justifyContent: "center",
    alignItems: "center",
  },

  calendarTextDot: {
    color: MAIN_COLORS.TEXT_LIGHT,
    fontSize: 8,
    fontWeight: FONT_WEIGHT.medium,
    fontFamily: MAIN_FONT.regular,
  },
});

const calendarTheme = {
  calendarBackground: MAIN_COLORS.BACKGROUND_MAIN,
  dayTextColor: MAIN_COLORS.TEXT_LIGHT,
  textDisabledColor: MAIN_COLORS.G600,
  textDayFontSize: 13,
  todayTextColor: MAIN_COLORS.PRIMARY_COLOR,
  textDayFontWeight: 500,
  "stylesheet.calendar.header": {
    header: {
      borderColor: MAIN_COLORS.PRIMARY_COLOR,
      borderWidth: 2,
      borderRadius: 6,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
      width: "94%",
      marginLeft: "3%",
    },
    monthText: {
      fontWeight: FONT_WEIGHT.bold,
      fontSize: FONT_SIZE.medium,
      lightHeight: 20,
      color: MAIN_COLORS.TEXT_LIGHT,
      fontFamily: MAIN_FONT.regular,
    },
    dayText: {
      fontWeight: 500,
      fontSize: FONT_SIZE.medium,
      lightHeight: 20,
      color: MAIN_COLORS.G600,
      fontFamily: MAIN_FONT.regular,
    },
  },
};

export default memo(CalendarCard);
