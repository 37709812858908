import React from "react";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { MAIN_COLOR } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";
import {
  externalTooltipHandler,
  formatDollar,
} from "../../../../../Utility/common";
import CardChart from "../../../components/CardChart";
import moment from "moment";

const CashFlow = ({ result, idxSkipped }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: {
      mode: "index",
      intersect: false,
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: MAIN_COLOR.G700,
        },
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: {
          label: (value) => {
            return formatDollar(value.raw.y);
          },
        },
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          stepSize: 1,
          displayFormats: {
            year: "yyyy",
          },
        },
        font: {
          family: MAIN_FONT.regular,
          size: 12,
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
          stepSize: 1,
          callback: function (value, index) {
            const isJanuary = moment(value).format("MM") === "01";
            if (isJanuary || index === 0) return moment(value).format("YYYY");
          },
        },
      },
      y: {
        border: { dash: [4, 1] },
        grid: {
          tickColor: "#E8E6E6",
          drawTicks: false,
        },
        beginAtZero: true,
        ticks: {
          font: {
            family: MAIN_FONT.regular,
            size: 12,
          },
          padding: 10,
          major: {
            enable: true,
          },
          maxTicksLimit: 7,
          callback: (value) => formatDollar(value),
        },
      },
    },
  };

  const grossCFTable = result?.map((item, index) => {
    const { grossCFValue, date, isBought } = item;
    return {
      x:
        isBought || index === result.length - 1
          ? moment(date).format("YYYY-MM")
          : undefined,
      y: grossCFValue,
    };
  });

  const netCFTable = result?.map((item, index) => {
    const { netCFValue, date, isBought } = item;
    return {
      x:
        isBought || index === result.length - 1
          ? moment(date).format("YYYY-MM")
          : undefined,
      y: netCFValue,
    };
  });

  const skipped = (ctx, value) => {
    return ctx.p0DataIndex >= idxSkipped ? value : [6, 0];
  };

  const data = {
    datasets: [
      {
        data: grossCFTable,
        borderColor: MAIN_COLOR.yellow,
        backgroundColor: null,
        lineTension: 0.5,
        borderWidth: 2.5,
        hoverBorderWidth: 2.5,
        fill: false,
        spanGaps: true,
        segment: {
          borderDash: (ctx) => skipped(ctx, [6, 6]),
        },
        pointHoverBackgroundColor: MAIN_COLOR.yellow,
        pointHoverBorderWidth: 2.5,
        pointHoverRadius: 6,
        pointHoverBorderColor: MAIN_COLOR.BACKGROUND_COLOR,
        pointBackgroundColor: MAIN_COLOR.yellow,
        pointBorderColor: MAIN_COLOR.BACKGROUND_COLOR,
        pointRadius: ({ index }) => {
          // if (result?.[index].isBought) return 6;
          return 0;
        },
      },
      {
        data: netCFTable,
        borderColor: "#33B21E",
        lineTension: 0.5,
        borderWidth: 2.5,
        hoverBorderWidth: 2.5,
        backgroundColor: null,
        fill: false,
        spanGaps: true,
        segment: {
          borderDash: (ctx) => skipped(ctx, [6, 6]),
        },
        pointRadius: ({ index }) => {
          // if (result?.[index].isBought) return 6;
          return 0;
        },
        pointHoverBackgroundColor: "#33B21E",
        pointHoverBorderWidth: 2.5,
        pointHoverRadius: 6,
        pointHoverBorderColor: MAIN_COLOR.BACKGROUND_COLOR,
        pointBackgroundColor: "#33B21E",
        pointBorderColor: MAIN_COLOR.BACKGROUND_COLOR,
      },
    ],
  };

  return (
    <CardChart
      title="Cash Flow"
      currentLabel="Current cash flow p/a:"
      currentValue={formatDollar(
        Math.round(result?.[idxSkipped - 1]?.grossCFValue)
      )}
      label1="Gross cash flow"
      color1={MAIN_COLOR.yellow}
      label2="Net cashflow"
      color2={"#33B21E"}
    >
      <Line
        options={options}
        data={data}
        plugins={[
          {
            afterDraw: function (chart, easing) {
              if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
                const activePoint = chart.tooltip._active[0];
                const ctx = chart.ctx;
                const x = activePoint.element.x;
                const topY = chart.scales.y.chart.tooltip.caretY + 10;
                const bottomY = chart.scales.y.bottom;

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 2;
                ctx.strokeStyle = MAIN_COLOR.G800;
                ctx.setLineDash([4, 4]);
                ctx.stroke();
                ctx.restore();
              }
            },
          },
        ]}
      />
    </CardChart>
  );
};

export default React.memo(CashFlow);
