import moment from "moment";
import { head, isEmpty } from "lodash";
import React, { memo, useState, useEffect, useRef } from "react";
import {
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  Image,
  FlatList,
} from "react-native";
import "chart.js/auto";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

import {
  MARKET_INSIGHT_FIELDS,
  MARKET_INSIGHT_LABELS,
} from "../../../Utility/Fields/index.js";
import {
  formatDollar,
  externalTooltipBarHandler,
} from "../../../Utility/common.js";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../Utility/Fonts/font.js";
import { styles } from "../index.v2.js";
import { getPublicMarketHistory } from "../../../Clickup/api";
import { MAIN_COLORS } from "../../../Utility/Colors/index.js";

import Carousel from "../../../components/FlatList.v2.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const SpecificData = ({ body_market }) => {
  const [marketHistories, setMarketHistories] = useState([]);
  const [loading, setLoading] = useState(false);

  const calcPercentageGrowth = (newValue, oldValue) => {
    const value = (((newValue - oldValue) / (oldValue || 1)) * 100).toFixed(1);
    return value > 0 ? `+ ${value}` : value;
  };

  const fetchData = async (name) => {
    try {
      setLoading(true);
      const response = await getPublicMarketHistory(name);
      if (!isEmpty(response.data)) {
        const result = MARKET_INSIGHT_FIELDS.map((one) => {
          return {
            key: one,
            data: response.data[one],
            ...MARKET_INSIGHT_LABELS[one],
          };
        });

        const data = result.map((market) => {
          return {
            title: market.title,
            subTitle: market.subTitle,
            formatter: market.formatter,
            yPrefix: market?.yPrefix,
            ySuffix: market?.ySuffix,
            unitCard: market?.unitCard,
            dataset: market?.data
              .filter((one, index) => !(index % 3))
              .map((item) => ({
                x: item.label,
                y: item.value,
              })),
          };
        });
        setMarketHistories(data);
      } else {
        const data = [0, 0, 0, 0].map(() => {
          return {
            title: "",
            subTitle: "",
            formatter: "",
            ySuffix: "",
            unitCard: "",
            dataset: [{ x: "", y: 0 }],
          };
        });
        setMarketHistories(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (body_market?.ssc_name) fetchData(body_market?.ssc_name);
  }, [body_market?.ssc_name]);

  return (
    <View
      style={{
        height: "max-content",
        minHeight: 200,
        width: "100%",
        position: "relative",
      }}
    >
      {loading ? (
        <ActivityIndicator
          color={MAIN_COLORS.PRIMARY_COLOR}
          size="large"
          style={{ margin: "auto" }}
        />
      ) : (
        <Carousel
          title={"Market Median 12 Month Data"}
          widthItem={368}
          styleContainer={{ gap: 16, height: 368 }}
          data={marketHistories}
          paginate={marketHistories?.length > 3}
          renderItem={({ item, index }) => {
            const value = calcPercentageGrowth(
              Number(item?.dataset?.slice(-1)?.[0]?.y ?? 0),
              Number(item?.dataset?.[0]?.y ?? 0)
            );

            return (
              <View style={[_styles.item]} key={index}>
                <View style={{ marginTop: 18, alignItems: "center" }}>
                  <Text style={[styles.subTitleSection]}>{item.title}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.textSection, { fontSize: 12 }]}>
                    {item.subTitle}
                  </Text>
                  <Text style={_styles.valueItem}>
                    {item?.yPrefix}
                    {item.formatter
                      ? item.formatter(item.dataset?.slice(-1)[0]?.y)
                      : item.subTitle?.includes("yield")
                      ? (item.dataset?.slice(-1)[0]?.y * 100).toFixed(2)
                      : item.dataset?.slice(-1)[0]?.y}
                    {item?.unitCard || item?.ySuffix}({value}%)
                  </Text>
                  <Text style={[styles.textSection, { fontSize: 12 }]}>
                    Change 12M
                  </Text>
                </View>
                <RenderChart width={"100%"} height={"100%"} result={item} />
              </View>
            );
          }}
        />
      )}
    </View>
  );
};

export const RenderChart = ({ width, result }) => {
  const chartRef = useRef(null);

  const isValueEqual = result?.dataset?.every(
    (item) => item?.y === result?.dataset?.[0]?.y
  );

  let minY = isValueEqual
    ? Math.min(...result?.dataset?.map(({ y }) => y)) * 0.95
    : Math.min(...result?.dataset?.map(({ y }) => y));
  if (minY < 0) minY = minY * 1.2;
  else minY = minY * 0.8;

  let maxY = Math.max(...result?.dataset?.map(({ y }) => y));
  maxY = maxY + maxY * 0.5;

  if (isValueEqual && minY === 0) {
    minY = -0.1;
    maxY = 10000;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    layout: {
      padding: {
        left: 10,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        cornerRadius: 20,
        callbacks: {
          label: (value) => {
            const suffix = result.unitCard || result.ySuffix;
            const prefix = result?.yPrefix || "";
            if (suffix) {
              if (result.subTitle && result.subTitle.includes("yield")) {
                return `${Math.floor((value.raw.y * 100).toFixed(2))}${suffix}`;
              }
              return `${prefix}${Math.floor(value.raw.y)}${suffix}`;
            }
            return formatDollar(Math.floor(value.raw.y));
          },
        },
        external: (context) => externalTooltipBarHandler(context),
      },
    },
    scales: {
      x: {
        font: { family: MAIN_FONT.regular, size: 12 },
        grid: { drawTicks: false, color: "#7D888F80" },
        border: { color: "#3F4448", dash: [3, 3] },
        ticks: {
          callback: (label, index) =>
            !result?.dataset?.[index]?.x
              ? ""
              : moment(result.dataset[index].x).format("MMM"),
        },
      },
      y: {
        border: { dash: [3, 3], color: "#3F4448" },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#3F4448";
          },
        },
        beginAtZero: false,
        min: minY,
        max: maxY,
        ticks: {
          display: false,
          maxTicksLimit: 5,
          stepSize: (maxY - minY) / 5,
        },
      },
      y1: {
        stacked: true,
        position: "right",
        grid: { display: false },
        ticks: { display: false },
        border: { color: "#3F4448" },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: result?.dataset,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(255, 255, 255, 0.3)",
            "rgba(255, 255, 255, 0.15)",
            "rgba(255, 255, 255, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0.1, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.BACKGROUND_WHITE,
        pointBorderWidth: 5.5,
        pointBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointRadius: ({ index }) => 0,
        pointHoverRadius: 6,
        lineTension: 0.5,
        borderWidth: 2,
        fill: true,
        spanGaps: true,
        clip: false,
      },
    ],
  };

  useEffect(() => {
    const chart = chartRef.current;
    chart?.tooltip?.setActiveElements([]);
    chart?.update();
  }, []);

  return (
    <View style={{ width: width, flex: 1 }}>
      <Line options={options} data={data} ref={chartRef} />
    </View>
  );
};

const _styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    height: 429,
  },

  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#4B555D99",
    paddingHorizontal: 4,
    paddingBottom: 22,
    height: "100%",
    width: 368,
  },

  valueItem: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    flexDirection: "row",
    gap: 5,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 36,
    borderWidth: 1,
    borderColor: "#4B555D99",
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.PRIMARY_COLOR,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(SpecificData);
