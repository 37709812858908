export const MAIN_FONT = {
  light: "Poppins-Light",
  regular: "Poppins-Regular",
  medium: "Poppins-Medium",
  semi: "Poppins-SemiBold",
  bold: "Poppins-Bold",
};

export const FONT_SIZE = {
  small: 12,
  medium: 14,
  large: 16,
};

export const FONT_WEIGHT = {
  medium: 400,
  bold: 600,
};
