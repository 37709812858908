import { StyleSheet } from "react-native";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../Utility/Colors";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    marginLeft: "auto",
    padding: 18,
    borderRadius: 12,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    shadowColor: "rgba(0, 0, 0, 0.2)",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.95,
    shadowRadius: 15,
    elevation: 5,
    overflow: "hidden",
    maxHeight: 580,
    position: "absolute",
    right: 32,
    top: 52,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  imageContainer: {
    borderRadius: 8,
    overflow: "hidden",
    // flex: 1,
    width: 235,
    height: 130,
  },
  nameWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  name: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.large,
    lineHeight: 24,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  address: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 18,
    color: MAIN_COLORS.G700,
    fontFamily: MAIN_FONT.regular,
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 2,
    gap: 8,
  },
  icon: {
    width: 24,
    height: 24,
  },
  btnGoBack: {
    width: 26,
    height: 26,
    borderRadius: 5,
    backgroundColor: "#7D888F26",
  },
  btnEdit: {
    width: 182,
    height: 37,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 5,
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
  },
  textEdit: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
  },
  params: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 16,
  },
  param: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#7D888F1A",
    gap: 8,
    flex: 1,
    borderRadius: 5,
    paddingVertical: 6,
  },
  paramValue: {
    fontSize: FONT_SIZE.small,
    lineHeight: 16,
    fontWeight: 500,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },
  topLeftSection: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderBottomRightRadius: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    position: "absolute",
    width: 97,
    height: 36,
    zIndex: 1,
    borderColor: "rgba(54, 65, 76, 0)",
    borderWidth: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textHouse: {
    fontSize: 14,
    fontWeight: 600,
    color: MAIN_COLOR.BACKGROUND_COLOR_DARK,
    fontFamily: MAIN_FONT.regular,
  },
  topRightSection: {
    display: "none",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
    position: "absolute",
    right: 0,
    top: 0,
    margin: 4,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  txtPurchaseBy: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 8,
    fontWeight: 400,
    color: MAIN_COLOR.G800,
    lineHeight: "120%",
  },
  txtAgency: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 10,
    fontWeight: 600,
    color: MAIN_COLOR.G800,
    lineHeight: "120%",
  },
  blur: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 8,
    zIndex: -1,
  },
});
