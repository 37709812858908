import React, { memo, useRef, useState } from "react";
import {
  FlatList,
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
  View,
} from "react-native";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../Utility/Fonts/font";
import { MAIN_COLORS } from "../Utility/Colors";

const Carousel = ({
  title,
  data = [],
  widthItem,
  styleTitle,
  itemOfPage = 3,
  paginate = true,
  horizontal = true,
  styleContainer,
  renderItem = () => {},
}) => {
  const refFlastList = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePaginate = (direction) => {
    if (direction === "left") {
      if (currentIndex - 1 >= 0) {
        setCurrentIndex(currentIndex - 1);
        refFlastList.current.scrollToIndex({
          animated: true,
          index: currentIndex - 1,
        });
      }
    }

    if (direction === "right") {
      if (currentIndex + 1 <= data.length - itemOfPage) {
        setCurrentIndex(currentIndex + 1);
        refFlastList.current.scrollToIndex({
          animated: true,
          index: currentIndex + 1,
        });
      }
    }
  };

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    if (viewableItems?.length) {
      setCurrentIndex(viewableItems[0].index);
    }
  }).current;

  return (
    <View>
      <View style={styles.header}>
        <Text style={[styles.title, styleTitle]}>{title}</Text>
        {paginate && (
          <View style={styles.paginante}>
            <TouchableOpacity onPress={() => handlePaginate("left")}>
              <Image
                style={{ width: 32, height: 32 }}
                source={require("../assets/home/arrow_left.svg")}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => handlePaginate("right")}>
              <Image
                style={{ width: 32, height: 32 }}
                source={require("../assets/home/arrow_right.svg")}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <View>
        <FlatList
          data={data}
          width={widthItem}
          ref={refFlastList}
          horizontal={horizontal}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={styleContainer}
          onViewableItemsChanged={onViewableItemsChanged}
          renderItem={renderItem}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },

  title: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
    textTransform: "capitalize",
  },

  paginante: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
});

export default memo(Carousel);
