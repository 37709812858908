import { StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  btn: {
    height: 42,
    width: 176,
    margin: "auto",
    marginVertical: 18,
    padding: 10,
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#D3B100",
  },
  btnText: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: MAIN_FONT.semi,
    color: "#FABF01",
  },
});
