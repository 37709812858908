import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyleSheet, Text, View } from "react-native";

import { MAIN_FONT } from "../Utility/Fonts/font";

import Input from "./TextInput";
import InputNumber from "./InputNumber";
import { MAIN_COLORS } from "../Utility/Colors";

const DatePickerCustom = ({
  label,
  value,
  required,
  prefixIcon,
  suffixIcon,
  onChange,
  ...props
}) => {
  return (
    <View style={styles.container}>
      {label && (
        <Text style={styles.label}>
          {label}
          {required && <Text style={{ color: "#FD4438" }}>*</Text>}
        </Text>
      )}
      <DatePicker
        selected={value}
        customInput={
          <Input
            suffixIcon={suffixIcon}
            styleSuffix={{ width: 18, height: 18 }}
            prefixIcon={prefixIcon}
          />
        }
        onSelect={onChange}
        {...props}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 20,
    color: MAIN_COLORS.TEXT_LIGHT,
    marginBottom: 8,
  },
  pickerStyle: {
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderRadius: 5,
    fontSize: 16,
    fontWeight: 400,
    paddingHorizontal: 14,
    fontFamily: MAIN_FONT.regular,
    height: 42,
  },
});

export default DatePickerCustom;
