import React, { useEffect, useRef } from "react";
import { Text, View, Animated } from "react-native";
import styles from "./style";
import { formatDollar } from "../../../../../Utility/common";

const Costs = ({ properties, result, loading }) => {
  const potentialValue =
    Number(properties["Current Potential Value (Old)"]) || 0;
  const animatedWidth = useRef(new Animated.Value(60)).current;

  useEffect(() => {
    const targetWidth = !loading && result === null ? 100 : 60;
    Animated.timing(animatedWidth, {
      toValue: targetWidth,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [loading, result]);

  const interpolatedWidth = animatedWidth.interpolate({
    inputRange: [0, 100],
    outputRange: ["0%", "100%"],
  });

  return (
    <Animated.View style={[styles.container, { width: interpolatedWidth }]}>
      <View>
        <Text style={styles.label}>Costs</Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
        }}
      >
        <View style={styles.info}>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Purchase Price
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {formatDollar(properties["Purchase Price"])}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Potential Value
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {formatDollar(Math.round(potentialValue))}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Potential Equity
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {potentialValue
                ? formatDollar(
                  Math.round(potentialValue - properties["Purchase Price"]) || 0
                )
                : "$0"}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Current rent
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {formatDollar(properties["APPRAISED RENTAL RATE P/W $"])} p/w
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Gross Cash flow
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {formatDollar(properties["Annual Gross Income"])} p/a
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Net Cash flow
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {formatDollar(
                Number(properties["Annual Net Cashflow"] ?? 0).toFixed(0)
              )}{" "}
              p/a
            </Text>
          </View>
        </View>
        <View style={styles.separator}></View>

        <View style={styles.info}>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Deposit
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {Number(properties["Deposit Percent"]).toFixed(2)}%
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Loan Type
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {"I/O"}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Interest Rate
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {"6.5%"}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Expenses p/a
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {formatDollar(
                Number(properties["Total Annual Expenses"] ?? 0).toFixed(0)
              )}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Gross Yield
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {Number(properties["Annual Gross Yield"]).toFixed(2)}%
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.name} numberOfLines={1}>
              Net Yield
            </Text>
            <Text style={styles.value} numberOfLines={1}>
              {Number(properties["Annual Net Yield %"]).toFixed(2)}%
            </Text>
          </View>
        </View>
      </View>
    </Animated.View>
  );
};

export default Costs;
