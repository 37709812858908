import React, { memo } from "react";
import { Text, View, Image, StyleSheet } from "react-native";
import moment from "moment";
import { FONT_SIZE, MAIN_FONT } from "../../../Utility/Fonts/font";
import { MAIN_COLORS } from "../../../Utility/Colors";

// Component
import Time from "../../../components/Time";

const Date = ({ task, title, time, status = "Due" }) => {
  const formattedDate = time ? moment(Number(time)).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");

  return (
    <View
      style={{
        flexDirection: "column",
        gap: 8,
        backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
        borderRadius: 12,
        padding: 12,
      }}
    >
      <Text
        style={{
          fontSize: 16,
          fontWeight: 600,
          color: MAIN_COLORS.TEXT_LIGHT,
          fontFamily: MAIN_FONT.regular,
          fontFamily: MAIN_FONT.semi,
        }}
      >
        {title}
      </Text>

      <View style={styles.rowCenter}>
        <View style={[styles.rowCenter, { gap: 4 }]}>
          {status && (
            <Text style={styles.text}>
              {status}
            </Text>
          )}
        </View>
        <View style={styles.divider} />
        <View style={[styles.rowCenter, { gap: 4 }]}>
          <Image
            style={styles.icon}
            source={require("../../../assets/home/calendar.svg")}
          />
          <Text style={styles.text}>
            {status === "Due" ? moment().format("DD/MM/YYYY") : formattedDate}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },
  divider: {
    width: 2,
    height: 12,
    borderRadius: 6,
    backgroundColor: MAIN_COLORS.TEXT_GRAY,
  },

  icon: {
    width: 18,
    height: 18,
  },

  text: {
    fontSize: FONT_SIZE.small,
    lineHeight: 16,
    fontWeight: 500,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(Date);
