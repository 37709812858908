import React, { useEffect, useMemo, useRef, useState } from "react";
import { Animated, Image, Text, TouchableOpacity, View } from "react-native";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { useMediaQuery } from "react-responsive";
import { formatDollar } from "../../../../Utility/common";
import CardValue from "../../components/CardValue";
import styles from "./style";
import { useSelector } from "react-redux";
import { listenArrayEvents } from "chart.js/helpers";
import { sumBy } from "lodash";
import { getListMarketHistory } from "../../../../Clickup/api";
import moment from "moment";
import { setLoading } from "../../../../Reducer/portfolio-roadmap";
import { calcProfileBar } from "../../../../Utility/profile-bar";

const Profile = ({ listPurchases }) => {
  const { scenarioSelected } = useSelector((state) => state.PORTFOLIO_ROADMAP);
  let { purchasedProperties } = useSelector((state) => state.PORTFOLIO_ROADMAP);

  const [showMore, setShowMore] = useState(false);
  const refContainer = useRef();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const toggleShowMore = () => setShowMore(!showMore);

  const profileValues = useMemo(() => {
    return calcProfileBar(purchasedProperties, scenarioSelected, listPurchases);
  }, [purchasedProperties, scenarioSelected]);

  const renderShowMore = () => {
    return (
      <Animated.View
        style={[
          styles.list,
          isTabletOrMobile ? styles.showMoreInTablet : styles.showMore,
        ]}
      >
        {isTabletOrMobile && (
          <>
            <CardValue
              label="Gross rental yield"
              value={`${profileValues.grossRetailYet}%`}
            />
            <CardValue
              label="Net rental yield"
              value={`${profileValues.netRetailYet}%`}
            />
            <CardValue
              label="Current ROI"
              value={`${profileValues.currentROI}%`}
            />
            <CardValue
              label="Approx time to target"
              value={`${profileValues.approxTimeTarget} ${
                profileValues.approxTimeTarget === 1 ? "Month" : "Months"
              }`}
              isHightLight
            />
          </>
        )}
      </Animated.View>
    );
  };

  return (
    <View style={{ flex: 1, minWidth: isTabletOrMobile ? 804 : 1177 }}>
      <OutsideClickHandler onOutsideClick={() => setShowMore(false)}>
        <View style={{ position: "relative", flexGrow: 1 }}>
          <View style={styles.container}>
            <Text style={styles.text}>Profile</Text>
            <View style={styles.list}>
              <View
                style={[styles.leftSection, { gap: isTabletOrMobile ? 9 : 19 }]}
                ref={refContainer}
              >
                <CardValue
                  label="Total gross income"
                  value={formatDollar(scenarioSelected?.totalGrossIncome)}
                  isHightLight
                />
                <CardValue
                  label="Approx portfolio value"
                  value={formatDollar(profileValues.approxPortfolioValue)}
                />
                <CardValue
                  label="Gross rental income p/a"
                  value={formatDollar(profileValues.grossRetailIncome)}
                />
                <CardValue
                  label="Net income target p/a"
                  value={formatDollar(profileValues.netIncomeTarget)}
                />
                {!isTabletOrMobile && (
                  <>
                    <CardValue
                      label="Gross rental yield"
                      value={`${profileValues.grossRetailYet}%`}
                    />
                    <CardValue
                      label="Net rental yield"
                      value={`${profileValues.netRetailYet}%`}
                    />
                    <CardValue
                      label="Current ROI"
                      value={`${profileValues.currentROI}%`}
                    />
                    <CardValue
                      label="Approx time to target"
                      value={`${profileValues.approxTimeTarget} ${
                        profileValues.approxTimeTarget === 1
                          ? "Month"
                          : "Months"
                      }`}
                      isHightLight
                    />
                  </>
                )}
              </View>
              {isTabletOrMobile && (
                <TouchableOpacity
                  style={{ marginTop: 12, marginRight: 6 }}
                  onPress={toggleShowMore}
                >
                  <Image
                    resizeMode="cover"
                    style={[styles.arrowDown]}
                    source={
                      showMore
                        ? require("../../../../assets/portfolio-roadmap/arrow-up.svg")
                        : require("../../../../assets/portfolio-roadmap/arrow-down.svg")
                    }
                  />
                </TouchableOpacity>
              )}
            </View>
            {showMore && isTabletOrMobile && (
              <View style={{ marginVertical: 16 }}>{renderShowMore()}</View>
            )}
          </View>
        </View>
      </OutsideClickHandler>
    </View>
  );
};

export default Profile;
