export const EState = Object.freeze({
  SA: "SA",
  WA: "WA",
  NSW: "NSW",
  QLD: "QLD",
  VIC: "VIC",
  TAS: "TAS",
  NT: "NT",
  ACT: "ACT",
});
export const STAMP_DUTY_BY_STATE = [
  {
    state: EState.NSW,
    isPercent: false,
    forEach: 100,
    mortgageRegistration: 165.4,
    transferFee: 165.4,
    stampDuty: [
      {
        start: 0,
        end: 14000,
        minCharge: 0,
        multipleBy: 1.25,
      },
      {
        start: 14000,
        end: 31000,
        minCharge: 175,
        multipleBy: 1.5,
      },
      {
        start: 31000,
        end: 83000,
        minCharge: 430,
        multipleBy: 1.75,
      },
      {
        start: 83000,
        end: 310000,
        minCharge: 1340,
        multipleBy: 3.5,
      },
      {
        start: 310000,
        end: 1033000,
        minCharge: 9285,
        multipleBy: 4.5,
      },
      {
        start: 1033000,
        end: 103300000000,
        minCharge: 41820,
        multipleBy: 5.5,
      },
    ],
  },
  {
    state: EState.VIC,
    isPercent: true,
    forEach: 1,
    mortgageRegistration: 118.9,
    transferFee: 0,
    stampDuty: [
      {
        start: 0,
        end: 25000,
        minCharge: 0,
        multipleBy: 1.4,
      },
      {
        start: 25000,
        end: 130000,
        minCharge: 350,
        multipleBy: 2.4,
      },
      {
        start: 130000,
        end: 960000,
        minCharge: 2870,
        multipleBy: 6,
      },
      {
        start: 960000,
        end: 96000000000,
        minCharge: 0,
        multipleBy: 5.5,
        overValue: 0,
      },
    ],
  },
  {
    state: EState.QLD,
    isPercent: false,
    forEach: 100,
    mortgageRegistration: 224.32,
    transferFee: 0,
    stampDuty: [
      {
        start: 0,
        end: 5000,
        minCharge: 0,
        multipleBy: 0,
      },
      {
        start: 5000,
        end: 75000,
        minCharge: 0,
        multipleBy: 1.5,
      },
      {
        start: 75000,
        end: 540000,
        minCharge: 1050,
        multipleBy: 3.5,
      },
      {
        start: 540000,
        end: 1000000,
        minCharge: 17325,
        multipleBy: 4.5,
      },
      {
        start: 1000000,
        end: 100000000000,
        minCharge: 38025,
        multipleBy: 5.75,
      },
    ],
  },
  {
    state: EState.TAS,
    isPercent: true,
    forEach: 1,
    mortgageRegistration: 152.19,
    transferFee: 233.18,
    stampDuty: [
      {
        start: 0,
        end: 3000,
        minCharge: 50,
        multipleBy: 0,
      },
      {
        start: 3000,
        end: 25000,
        minCharge: 50,
        multipleBy: 1.75,
      },
      {
        start: 25000,
        end: 75000,
        minCharge: 435,
        multipleBy: 2.25,
      },
      {
        start: 75000,
        end: 200000,
        minCharge: 1560,
        multipleBy: 3.5,
      },
      {
        start: 200000,
        end: 375000,
        minCharge: 5935,
        multipleBy: 4,
      },
      {
        start: 375000,
        end: 725000,
        minCharge: 12395,
        multipleBy: 4.25,
      },
      {
        start: 725000,
        end: 72500000000,
        minCharge: 27810,
        multipleBy: 4.5,
      },
    ],
  },
  {
    state: EState.WA,
    isPercent: true,
    forEach: 1,
    mortgageRegistration: 203,
    transferFee: 233.18,
    stampDuty: [
      {
        start: 0,
        end: 80000,
        minCharge: 0,
        multipleBy: 1.9,
      },
      {
        start: 80000,
        end: 100000,
        minCharge: 1520,
        multipleBy: 2.85,
      },
      {
        start: 100000,
        end: 250000,
        minCharge: 2090,
        multipleBy: 3.8,
      },
      {
        start: 250000,
        end: 500000,
        minCharge: 7790,
        multipleBy: 4.75,
      },
      {
        start: 500000,
        end: 50000000000,
        minCharge: 19665,
        multipleBy: 5.15,
      },
    ],
  },
  {
    state: EState.SA,
    isPercent: false,
    forEach: 100,
    mortgageRegistration: 187,
    transferFee: 0,
    stampDuty: [
      {
        start: 0,
        end: 12000,
        minCharge: 0,
        multipleBy: 1,
      },
      {
        start: 12000,
        end: 30000,
        minCharge: 120,
        multipleBy: 2,
      },
      {
        start: 30000,
        end: 50000,
        minCharge: 480,
        multipleBy: 3,
      },
      {
        start: 50000,
        end: 100000,
        minCharge: 1080,
        multipleBy: 3.5,
      },
      {
        start: 100000,
        end: 200000,
        minCharge: 2830,
        multipleBy: 4,
      },
      {
        start: 200000,
        end: 250000,
        minCharge: 6830,
        multipleBy: 4.25,
      },
      {
        start: 250000,
        end: 300000,
        minCharge: 8955,
        multipleBy: 4.75,
      },
      {
        start: 300000,
        end: 500000,
        minCharge: 11330,
        multipleBy: 5,
      },
      {
        start: 500000,
        end: 50000000000,
        minCharge: 21330,
        multipleBy: 5.5,
      },
    ],
  },
  {
    state: EState.ACT,
    isPercent: false,
    forEach: 100,
    mortgageRegistration: 166,
    transferFee: 446,
    stampDuty: [
      {
        start: 0,
        end: 200000,
        minCharge: 0,
        forEach: 1,
        multipleBy: 1.2,
      },
      {
        start: 200000,
        end: 300000,
        minCharge: 2400,
        multipleBy: 2.2,
      },
      {
        start: 300000,
        end: 500000,
        minCharge: 4600,
        multipleBy: 3.4,
      },
      {
        start: 500000,
        end: 750000,
        minCharge: 11400,
        multipleBy: 4.32,
      },
      {
        start: 750000,
        end: 1000000,
        minCharge: 22200,
        multipleBy: 5.9,
      },
      {
        start: 1000000,
        end: 1455000,
        minCharge: 36950,
        multipleBy: 6.4,
      },
      {
        start: 1455000,
        end: 145500000000,
        minCharge: 0,
        multipleBy: 4.54,
        overValue: 0,
      },
    ],
  },
  {
    state: EState.NT,
    isPercent: true,
    forEach: 1,
    mortgageRegistration: 165,
    transferFee: 165,
    stampDuty: [
      {
        start: 0,
        end: 525000,
        minCharge: 0,
        multipleBy: 1.2,
        overValue: 0,
      },
      {
        start: 525000,
        end: 3000000,
        minCharge: 0,
        multipleBy: 4.95,
        overValue: 0,
      },
      {
        start: 3000000,
        end: 5000000,
        minCharge: 0,
        multipleBy: 5.75,
        overValue: 0,
      },
      {
        start: 5000000,
        end: 500000000000,
        minCharge: 0,
        multipleBy: 5.95,
        overValue: 0,
      },
    ],
  },
];

export const NT_END = 525000;
export const NT_FIXED = 0.06571441;
export const NT_FIXED_2 = 15;

export const NSW_MINIMUM = 10;

export const WA_TRANFER_FEES = [
  {
    start: 0,
    end: 85000,
    transfer_fee: 203,
  },
  {
    start: 85000,
    end: 120000,
    transfer_fee: 213,
  },
  {
    start: 120000,
    end: 200000,
    transfer_fee: 233,
  },
  {
    start: 200000,
    end: 300000,
    transfer_fee: 253,
  },
  {
    start: 300000,
    end: 400000,
    transfer_fee: 273,
  },
  {
    start: 400000,
    end: 500000,
    transfer_fee: 293,
  },
  {
    start: 500000,
    end: 600000,
    transfer_fee: 313,
  },
  {
    start: 600000,
    end: 700000,
    transfer_fee: 333,
  },
  {
    start: 700000,
    end: 800000,
    transfer_fee: 353,
  },
  {
    start: 800000,
    end: 900000,
    transfer_fee: 373,
  },
  {
    start: 900000,
    end: 1000000,
    transfer_fee: 393,
  },
  {
    start: 1000000,
    end: 1100000,
    transfer_fee: 413,
  },
  {
    start: 1100000,
    end: 1200000,
    transfer_fee: 433,
  },
  {
    start: 1200000,
    end: 1300000,
    transfer_fee: 453,
  },
  {
    start: 1300000,
    end: 1400000,
    transfer_fee: 473,
  },
  {
    start: 1400000,
    end: 1500000,
    transfer_fee: 493,
  },
  {
    start: 1500000,
    end: 1600000,
    transfer_fee: 513,
  },
  {
    start: 1600000,
    end: 1700000,
    transfer_fee: 533,
  },
  {
    start: 1700000,
    end: 1800000,
    transfer_fee: 553,
  },
  {
    start: 1800000,
    end: 1900000,
    transfer_fee: 573,
  },
  {
    start: 1900000,
    end: 2000000,
    transfer_fee: 593,
  },
];
