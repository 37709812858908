import moment from "moment";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import React, { memo, useEffect, useState } from "react";

//Functions
import styles from "./style";

//Components
import Purchase from "../PurchaseOverview/Purchase/indexv2";
import PurchaseTarget from "../PurchaseOverview/PurchaseTarget/indexv2";
import PurchaseTargetModal from "../PurchaseOverview/PurchaseTargetEdit";

const ListPurchases = ({ purchaseSelected, setPurchaseSelected }) => {
  const { purchasedProperties, scenarioSelected } = useSelector(
    (state) => state.PORTFOLIO_ROADMAP
  );

  const [index, setIndex] = useState(0);
  const [indexChange, setIndexChange] = useState(0);
  const [purchaseTargetSelected, setPurchaseTargetSelected] = useState(null);
  const [disabledClickOutside, setDisabledClickOutside] = useState(false);
  const [nextPurchaseTarget, setNextPurchaseTarget] = useState(null);
  const [dateCurrentTarget, setDateCurrentTarget] = useState(null);
  const [incomeTypeCurrent, setIncomeTypeCurrent] = useState("single");
  const [visiblePurchaseTargetModal, setVisiblePurchaseTargetModal] =
    useState(false);

  useEffect(() => {
    setDisabledClickOutside(visiblePurchaseTargetModal);
  }, [visiblePurchaseTargetModal]);

  const purchaseTargets = scenarioSelected?.purchaseTargets?.filter((item) => {
    return (
      Number(moment(item.purchaseDate).format("YYYY")) <=
      scenarioSelected?.yearsOfResult
    );
  });

  const purchased = purchasedProperties?.sort(
    (a, b) =>
      moment(Number(a["Purchase date "])) - moment(Number(b["Purchase date "]))
  );

  return (
    <View style={styles.listPurchase}>
      {purchased && <Text style={styles.title}>Purchase Overview</Text>}
      {purchased?.length &&
        purchased.map((item) => (
          <Purchase
            key={item.id}
            properties={item}
            purchaseSelected={purchaseSelected?.data}
            onSelect={setPurchaseSelected}
          />
        ))}

      {purchaseTargets && <Text style={styles.title}>Purchase Target</Text>}
      {purchaseTargets?.length &&
        purchaseTargets.map((item, index) => (
          <PurchaseTarget
            setVisibleModalEditTarget={() => {
              setPurchaseTargetSelected(item);
              setVisiblePurchaseTargetModal(true);
              setIndex(index);
            }}
            setVisibleModalAddTarget={() => {
              setDateCurrentTarget(item.purchaseDate);
              setIndexChange(index + 1);
              setVisiblePurchaseTargetModal(true);
              setIndex(index);
              setNextPurchaseTarget(purchaseTargets?.[index + 1]);
              setIncomeTypeCurrent(item.incomeType);
            }}
            disabledClickOutside={disabledClickOutside}
            purchaseTargets={scenarioSelected.purchaseTargets}
            purchaseSelected={purchaseSelected?.data}
            onSelect={setPurchaseSelected}
            properties={item}
            index={index}
            key={item.id}
            type="HOUSE"
            isViewEdit={scenarioSelected?.type === "scenarios"}
          />
        ))}
      {visiblePurchaseTargetModal && (
        <PurchaseTargetModal
          properties={purchaseTargetSelected}
          onClose={() => {
            setVisiblePurchaseTargetModal(false);
            setPurchaseTargetSelected(null);
            setNextPurchaseTarget(null);
            setDateCurrentTarget(null);
          }}
          nextPurchaseTarget={nextPurchaseTarget}
          indexChange={indexChange}
          index={index}
          dateCurrentTarget={dateCurrentTarget}
          typeIncomeCurrentTarget={incomeTypeCurrent}
        />
      )}
    </View>
  );
};

export default memo(ListPurchases);
