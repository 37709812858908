import { Dimensions, StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 22,
    flex: 1,
    position: "relative",
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    color: "#FFFFFF",
    fontFamily: MAIN_FONT.semi,
  },
  listPurchase: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    flex: 1,
  },
  content: {
    marginTop: 16,
    position: "relative",
    flex: 1,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    gap: 21,
  },
  blurView: {
    height: "100vh",
    width: "110%",
    marginTop: -12,
    marginRight: -32,
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
});
