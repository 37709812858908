import React, { memo } from "react";
import { View, StyleSheet, Image, Text } from "react-native";

import { MAIN_COLOR } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

const EmptyContent = ({ title, subTitle }) => {
  return (
    <View style={styles.container}>
      <Image
        style={{ height: 150, width: 150 }}
        resizeMode="contain"
        source={require("../assets/home/content.svg")}
      />
      <Text style={styles.viewEmptyTitle}>{title}</Text>
      <Text style={styles.viewEmptySubTitle}>{subTitle}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  viewEmptyTitle: {
    fontSize: 20,
    fontWeight: 600,
    lightHeight: 30,
    textAlign: "center",
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.semi,
    textAlign: "center",
    marginTop: 20,
  },

  viewEmptySubTitle: {
    fontSize: 16,
    fontWeight: 400,
    lightHeight: 24,
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    marginTop: 10,
  },
});

export default memo(EmptyContent);
