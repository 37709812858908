import React, { memo } from "react";
import { Text, View } from "react-native";
import { MAIN_FONT } from "../../../Utility/Fonts/font";
import fields from "../../../Utility/Fields";
import CarouselComp from "../../../components/Carousel";

const groupItems = (data, itemsPerPage) => {
  const groupedData = [];
  for (let i = 0; i < data.length; i += itemsPerPage) {
    const group = [];
    const currentGroup = data.slice(i, i + itemsPerPage);
    currentGroup.forEach((video, index) => {
      group.push([i + index, video]);
    });
    groupedData.push(group);
  }
  return groupedData;
};

const Videos = ({ task, videos, links, renderVideos }) => {
  const visibleVideos = 3;

  const listVideos = [
    ...(videos["Walk Through Videos"] ? videos["Walk Through Videos"].split("\n") : []),
    ...(videos["Pre Settlement Inspection Videos"] ? videos["Pre Settlement Inspection Videos"].split("\n") : [])
  ];

  const displayVideos = listVideos.length
    ? groupItems(listVideos, visibleVideos)
    : [];

  return (
    <View>
      {Object.keys(videos).length ? (
        links["Market Preso URL"] ? (
          <Text
            style={{
              fontSize: 12,
              color: "#272D37",
              fontFamily: MAIN_FONT.regular,
              marginVertical: 20,
              textAlign: "justify",
            }}
          >
            The data you see above reflects the current and past conditions of
            the suburb where we have found the property. We will explain these
            data points to you very soon, along with your property presentation.
          </Text>
        ) : (
          <View style={{ width: "100%" }}>
            <Text
              style={{
                fontSize: 16,
                color: "#FFFFFF",
                fontFamily: MAIN_FONT.semi,
                marginBottom: 16,
              }}
            >
              {task[fields.appStageName] === "Property Walk-Through"
                ? "Inspection Videos"
                : task[fields.appStageName] ===
                  "Contract, Building & Pest and Property Manager"
                ? "Why is this a good Option?"
                : "Pre-settlement Videos"}
            </Text>

            <CarouselComp
              data={displayVideos}
              renderItem={renderVideos}
              visibleItems={visibleVideos}
              listVideos={listVideos}
            />
          </View>
        )
      ) : null}
    </View>
  );
};

export default memo(Videos);
