import React, { memo, useEffect, useState } from "react";
import { LinearGradient } from "expo-linear-gradient";
import {
  View,
  Image,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
} from "react-native";

//Functions
import { styles } from "../index.v2";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, MAIN_FONT } from "../../../Utility/Fonts/font";

//Components
import ViewGallery from "../../../components/ViewGallery";

const { height: screenHeight } = Dimensions.get("window");

const OverView = ({ presentation }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [heightContent, setHeightContent] = useState(0);
  const [heightContainer, setHeightContainer] = useState(0);
  const [isExpandedDetail, setIsExpandedDetail] = useState(false);

  useEffect(() => {
    if (
      heightContent &&
      heightContainer &&
      heightContent > heightContainer - 80
    )
      setIsExpanded(true);
  }, [heightContainer, heightContent]);

  return (
    <View
      style={_styles.container}
      onLayout={(e) => {
        setHeightContainer(e.nativeEvent.layout.height);
      }}
    >
      <Image
        style={_styles.image}
        source={{ uri: presentation?.["Market Image URL"] }}
      />
      <View style={_styles.overviewRight}>
        <View style={{ gap: 4 }}>
          <Text style={styles.textSection}>The region of</Text>
          <Text style={styles.subTitleSection}>
            {presentation?.["Region/Council/LGA"]}{" "}
            {presentation?.status?.status}
          </Text>
        </View>
        <Text
          style={styles.textSection}
          onLayout={(e) => {
            setHeightContent(e.nativeEvent.layout.height);
          }}
        >
          {presentation["About the market"]}
        </Text>

        {isExpanded && (
          <View style={_styles.footer}>
            <LinearGradient
              colors={["rgba(18, 15, 14, 0)", "#000000"]}
              locations={[0.13, 0.745]}
              style={{
                width: "100%",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            ></LinearGradient>
          </View>
        )}

        <TouchableOpacity
          activeOpacity={1}
          style={_styles.seeMoreWrap}
          onPress={() => setIsExpandedDetail(true)}
        >
          <Text style={_styles.textSeeMore}>See more</Text>
        </TouchableOpacity>
      </View>
      <ViewGallery
        open={isExpandedDetail}
        styleBox={_styles.modelDetail}
        onClose={() => setIsExpandedDetail(false)}
      >
        <Image
          style={{ width: "100%", height: 234, borderRadius: 6 }}
          source={{ uri: presentation?.["Market Image URL"] }}
        />
        <View style={{ gap: 4, marginTop: 20, marginBottom: 17 }}>
          <Text style={[styles.textSection, { marginBottom: 4 }]}>
            The region of
          </Text>
          <Text style={styles.subTitleSection}>
            {presentation?.["Region/Council/LGA"]}{" "}
            {presentation?.status?.status}
          </Text>
        </View>
        <Text style={styles.textSection}>
          {presentation["About the market"]}
        </Text>
      </ViewGallery>
    </View>
  );
};

const _styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    maxHeight: 353,
  },
  image: {
    flex: 1,
    height: "100%",
    borderRadius: 12,
  },

  overviewRight: {
    flex: 1,
    display: "flex",
    gap: 17,
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },

  footer: {
    position: "absolute",
    bottom: 6,
    left: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transperant",
    height: 80,
  },

  seeMoreWrap: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    height: 18,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
  },

  textSeeMore: {
    fontWeight: 500,
    fontSize: FONT_SIZE.small,
    lightHeight: 18,
    color: MAIN_COLORS.PRIMARY_COLOR,
    textDecorationLine: "underline",
    fontFamily: MAIN_FONT.regular,
  },

  modelDetail: {
    width: 667,
    borderWidth: 0,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    padding: 24,
    display: "flex",
    height: "max-content",
    aspectRatio: "none",
    maxHeight: screenHeight * 0.8,
  },
});

export default memo(OverView);
