import { Dimensions, StyleSheet } from "react-native";
import {
  MAIN_COLOR,
  MAIN_COLORS,
  TAB_BAR_COLOR,
} from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    width: "100%",
    borderRadius: 10,
    backgroundColor: "#0E0C0C",
    paddingVertical: 12,
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLOR.BACKGROUND_COLOR,
  },
  subTitle: {
    color: MAIN_COLORS.G600,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    fontWeight: 400,
  },
  investTimeLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 12,
  },
  canBorrow: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: MAIN_FONT.semi,
    color: "#FFFFFF",
    textAlign: "center",
    margin: "auto",
    width: "80%",
  },
  showBorrow: {
    backgroundColor: MAIN_COLOR.yellow,
    paddingVertical: 5,
  },
  showBorrowBtn: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: MAIN_FONT.semi,
    color: "#200E32",
    textAlign: "center",
    margin: "auto",
    width: "80%",
  },
  basedOn: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.G600,
    textAlign: "center",
  },
  btn: {
    height: 42,
    margin: 12,
    marginTop: 18,
    padding: 10,
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D3B100",
  },
  btnText: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: MAIN_FONT.semi,
    color: "#200E32",
  },
});
