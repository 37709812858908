import moment from "moment";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  View,
  StyleSheet,
  Image,
  Text,
  ScrollView,
  TouchableOpacity,
  Linking,
} from "react-native";
import { withNavigation } from "react-navigation";
import OutsideClickHandler from "react-outside-click-handler";

//Function
import { MAIN_COLORS } from "../../Utility/Colors";
import { setCurrentScreen } from "../../Reducer/tia";
import { setShowCalendar } from "../../Reducer/portfolio-roadmap";
import { FONT_WEIGHT, FONT_SIZE, MAIN_FONT } from "../../Utility/Fonts/font";

const Menu = ({ children, navigation }) => {
  const dayOfWeek = moment().format("dddd");
  const today = moment().format("MMMM DD, YYYY");
  const [open, setOpen] = useState(false);

  const { currentScreen, client } = useSelector((state) => state.TIA);
  const { showCalendar } = useSelector((state) => state.PORTFOLIO_ROADMAP);

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  const dispatch = useDispatch();

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View>
          <Text style={styles.textDay}>{dayOfWeek}</Text>
          <Text style={styles.textMonthAndYear}>{today}</Text>
        </View>
        <View style={styles.headerRight}>
          {(currentScreen === "Home" || currentScreen === "Notifications") && (
            <>
              <TouchableOpacity
                onPress={() => dispatch(setShowCalendar(!showCalendar))}
              >
                <Image
                  style={styles.icon}
                  source={require("../../assets/home/calendar_dot.svg")}
                />
              </TouchableOpacity>
              <View style={styles.headerDivider} />
            </>
          )}

          <TouchableOpacity
            onPress={() => dispatch(setCurrentScreen("Notifications"))}
          >
            <Image
              style={styles.icon}
              source={require("../../assets/Light/Notification.svg")}
            />
          </TouchableOpacity>
          <View style={styles.headerDivider} />
          <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
            <TouchableOpacity
              onPress={() => setOpen(!open)}
              style={[styles.avatarCircle, { width: 42, height: 42 }]}
            >
              <Text style={[styles.textAvatarCircle, { fontSize: 18 }]}>
                {getInitial(client?.name)}
              </Text>
            </TouchableOpacity>
            <View style={[styles.boxPopup, !open && { display: "none" }]}>
              <View style={styles.avatarCircle}>
                <Text style={styles.textAvatarCircle}>{getInitial(client?.name)}</Text>
              </View>
              <Text style={styles.textEmail}>{client?.email}</Text>
              <View style={styles.divider} />
              <TouchableOpacity
                style={styles.btnHelpCenter}
                onPress={() => {
                  Linking.openURL("mailto:hello@theinvestorsagency.com.au");
                }}
              >
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/home/help-center.svg")}
                />
                <Text style={styles.textLogout}>Help center</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.btnLogout}
                onPress={() => {
                  navigation.navigate("LoginScreen");
                  dispatch(setCurrentScreen("Home"));
                }}
              >
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/home/logout.svg")}
                />
                <Text style={styles.textLogout}>Logout</Text>
              </TouchableOpacity>
            </View>
          </OutsideClickHandler>
        </View>
      </View>
      <ScrollView showsVerticalScrollIndicator={false} style={{ zIndex: -1 }}>
        <View style={styles.content}>{children}</View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 423,
    borderLeftWidth: 1,
    borderLeftColor: MAIN_COLORS.BORDER_COLOR,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
  },
  header: {
    height: 95,
    paddingHorizontal: 32,
    paddingVertical: 25,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 18,
  },
  content: { paddingHorizontal: 32, paddingVertical: 22 },
  textDay: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },
  textMonthAndYear: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },
  icon: {
    width: 30,
    height: 30,
  },

  headerAvatar: {
    width: 42,
    height: 42,
    borderRadius: "50%",
    overflow: "hidden",
  },

  headerDivider: {
    width: 1,
    height: 32,
    backgroundColor: MAIN_COLORS.BORDER_COLOR,
  },

  boxPopup: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 16,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    position: "absolute",
    top: 60,
    right: 0,
    width: 358,
    shadowColor: "#000",
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.7,
    shadowRadius: 15,
    elevation: 10,
  },

  avatarCircle: {
    justifyContent: "center",
    alignItems: "center",
    width: 64,
    height: 64,
    borderRadius: "50%",
    backgroundColor: "#D3B100",
  },

  textAvatarCircle: {
    fontSize: 27.43,
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontWeight: 600,
    fontFamily: MAIN_FONT.semi,
  },

  textEmail: {
    fontSize: 16,
    fontWeight: 600,
    color: MAIN_COLORS.TEXT_LIGHT,
    marginTop: 12,
    fontFamily: MAIN_FONT.semi,
  },

  divider: {
    height: 1,
    backgroundColor: MAIN_COLORS.BORDER_COLOR,
    width: "100%",
    marginVertical: 16,
  },

  btnHelpCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingVertical: 11,
    paddingHorizontal: 11,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#FFFFFF",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    marginBottom: 16,
  },

  btnLogout: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingVertical: 11,
    paddingHorizontal: 11,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#FD4438",
    backgroundColor: "#FD4438",
  },

  textLogout: {
    fontSize: 14,
    fontWeight: 600,
    color: "#FFFFFF",
    marginLeft: 2,
    fontFamily: MAIN_FONT.semi,
  },
});

export default memo(withNavigation(Menu));
