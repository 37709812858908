import {
  View,
  Text,
  Image,
  Linking,
  Keyboard,
  Platform,
  TextInput,
  Dimensions,
  BackHandler,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "react-native-flash-message";
import { RFValue } from "react-native-responsive-fontsize";
import { MaterialIndicator } from "react-native-indicators";

//Function
import styles from "../Auth/style";
import { MAIN_COLORS } from "../../Utility/Colors";
import { addToken, signIn } from "../../Clickup/api";
import { reset, setClient, setRole } from "../../Reducer/tia";

//Component
import AuthBanner from "../Auth/AuthBanner";
import ReCAPTCHA from "react-google-recaptcha";
import LOADER from "../../Utility/ComponentCard/Loader";
import stylesCommonComponents from "../../Utility/ComponentStyle/style";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      signing: false,
      popupTypeId: 0,
      responseToken: null,
      showPassword: false,
      isFocus: null,
      token: { data: "" },
      popupIconName: "",
      popupButtonText: "",
      popupMessageText: "",
      popupMessageTitle: "",
      showPopupMessageBox: false,
      window: Dimensions.get("window"),
      isPC: Dimensions.get("window").width > 1024 ? true : false,
      isSmall: Dimensions.get("window").height < 900 ? true : false,
    };
  }

  _onBlurr = () => {
    BackHandler.removeEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _onFocus = () => {
    BackHandler.addEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _handleBackButtonClick = () => {
    BackHandler.exitApp();
    return true;
  };

  componentDidMount() {
    Dimensions.addEventListener("change", ({ window }) => {
      this.setState({
        isPC: window.width > 1000 ? true : false,
        isSmall: window.height < 900 ? true : false,
      });
    });

    this.props.reset({});

    Linking.getInitialURL()
      .then((url) => {
        if (
          url.includes(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email="
          )
        ) {
          url = url.replace(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email=",
            ""
          );
          this.props.navigation.navigate("ResetPasswordScreen", { email: url });
        }
      })
      .catch(console.warn);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email"))
      this.props.navigation.navigate("SignUpScreen");
  }

  onResponseTokenReceived = async () => {
    const { email, password, token, responseToken } = this.state;

    this.setState({ signing: true });

    signIn(email, password, responseToken)
      .then((result) => {
        if (result.tasks?.length) {
          const r = result.tasks[0].custom_fields.filter(
            (item, index) => item.id === "08208191-53bd-4985-97f1-2dd9b9327797"
          );
          if (r[0].value !== password) {
            showMessage({
              message: "Email or Password is incorrect.",
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            });
            this.setState({ signing: false });
            return;
          }
          this.props.setClient({ ...result.tasks[0], email: email });
          this.props.navigation.navigate("MainScreen");
          addToken(result.tasks[0], token.data);
        } else {
          showMessage({
            message: "Something went wrong, please try again later.",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          });
          this.setState({ signing: false });
        }
      })
      .catch((error) => {
        showMessage({
          message: error.message,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        });
        this.setState({ signing: false });
      });
  };

  onPressLogin = async () => {
    const { email, password } = this.state;

    !Boolean(email) ? this.setState({ emailRequired: true }) : null;
    !Boolean(password) ? this.setState({ passwordRequired: true }) : null;

    if (Boolean(email) && Boolean(password)) {
      // this.ReCaptchaRef.execute();
      signIn({ email, password })
        .then((response) => {
          let result = response.data;
          if (result) {
            const passwordId =
              result.role == "partner"
                ? "6712e7e6-5104-4223-8553-055a90d0b38f"
                : "08208191-53bd-4985-97f1-2dd9b9327797";
            const r = result.custom_fields.filter(
              (item, index) => item.id === passwordId
            );
            if (r[0].value !== password) {
              showMessage({
                message: "Email or Password is incorrect.",
                type: "info",
                color: "#FFF",
                backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
              });
              this.setState({ signing: false });
              return;
            }
            this.props.setClient({ ...result, email: email });
            this.props.navigation.navigate("MainScreen");

            addToken(result, email);
          } else {
            showMessage({
              message: "Email or Password is incorrect.",
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            });
            this.setState({ signing: false });
          }
        })
        .catch((error) => {
          showMessage({
            message: "Email or password is incorrect.",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          });
          this.setState({ signing: false });
        });
    } else {
      this.setState({ signing: false });
      showMessage({
        message: "Username or Password can't be empty.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
      });
    }
  };

  render() {
    const { isPC, isFocus, signing, responseToken } = this.state;
    return (
      <SafeAreaView
        style={styles.container}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      >
        <AuthBanner />
        <View
          onPress={Keyboard.dismiss}
          accessible={false}
          style={{
            width: isPC ? "50%" : "100%",
            backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
          }}
        >
          <View style={styles.boxLogoTIA} />
          <View style={[styles.scrollViewContentView, styles.sectionContent]}>
            <View>
              <Text style={styles.sectionTitle}>Hey! Sign in here</Text>
              <Text style={styles.sectionSubTitle}>
                Please sign in here to continue!
              </Text>
            </View>

            <View style={styles.sectionCenterView}>
              <View style={styles.sectionForm}>
                <Text style={styles.labelForm}>Email</Text>
                <div id="input_email">
                  <TextInput
                    ref={(input) => (this.emailTextInput = input)}
                    keyboardType="web-search"
                    autoCorrect={false}
                    style={[
                      styles.inputForm,
                      { borderColor: isFocus === "email" ? "#fff" : "#34393D" },
                    ]}
                    placeholderTextColor="#ADB9C7"
                    value={this.state.email}
                    underlineColorAndroid="transparent"
                    placeholder="Enter Your Email"
                    returnKeyType={"next"}
                    blurOnSubmit={false}
                    autoCapitalize="none"
                    onFocus={() => this.setState({ isFocus: "email" })}
                    onBlur={() => this.setState({ isFocus: null })}
                    onSubmitEditing={() => this.passwordTextInput.focus()}
                    onChangeText={(email) => {
                      !signing &&
                        this.setState({ email, emailRequired: false });
                    }}
                  />
                </div>
              </View>

              <View style={styles.sectionForm}>
                <Text style={styles.labelForm}>Password</Text>
                <View
                  style={[
                    styles.inputForm,
                    styles.rowCenter,
                    {
                      borderColor: isFocus === "password" ? "#fff" : "#34393D",
                    },
                  ]}
                >
                  <div id="input_password" style={{ flex: 1 }}>
                    <TextInput
                      autoCorrect={false}
                      keyboardType="default"
                      returnKeyType={"done"}
                      value={this.state.password}
                      style={styles.inputPassword}
                      placeholderTextColor="#ADB9C7"
                      placeholder="Enter Your Password"
                      underlineColorAndroid="transparent"
                      onSubmitEditing={this.onPressLogin}
                      onFocus={() => this.setState({ isFocus: "password" })}
                      onBlur={() => this.setState({ isFocus: null })}
                      secureTextEntry={!this.state.showPassword}
                      ref={(input) => (this.passwordTextInput = input)}
                      onChangeText={(password) =>
                        !signing &&
                        this.setState({ password, passwordRequired: false })
                      }
                    />
                  </div>

                  <TouchableOpacity
                    onPress={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  >
                    <Image
                      style={{ height: 20, width: 20, resizeMode: "contain" }}
                      source={
                        this.state.showPassword
                          ? require("../../assets/eye.png")
                          : require("../../assets/hide_eye.svg")
                      }
                    />
                  </TouchableOpacity>
                </View>
              </View>

              {/* <ReCAPTCHA
                size="invisible"
                style={{ display: "none" }}
                badge={"bottomright"}
                sitekey="6LfOX_shAAAAAHN_GM6S1mUGcBhzcPJptDEoZW-n"
                ref={(ReCaptchaRef) => (this.ReCaptchaRef = ReCaptchaRef)}
                onChange={(responseToken) =>
                  this.setState({ responseToken }, () => {
                    this.ReCaptchaRef.reset();
                    this.onResponseTokenReceived();
                  })
                }
                onErrored={() => {
                  this.setState({ responseToken: null, signing: false }),
                    () => this.ReCaptchaRef.reset();
                }}
                onExpired={() => {
                  this.setState({ responseToken: null, signing: false });
                }}
              /> */}
            </View>

            {/* TouchableOpacity Login */}
            <div id="btn_sign_in">
              <TouchableOpacity
                style={[stylesCommonComponents.button, styles.btnSubmit]}
                onPress={() => this.onPressLogin()}
              >
                {signing && responseToken ? (
                  <MaterialIndicator
                    size={RFValue(16.75)}
                    color={MAIN_COLORS.BACKGROUND_WHITE}
                  />
                ) : (
                  <Text style={styles.textButton}>Sign In</Text>
                )}
              </TouchableOpacity>
            </div>
          </View>
        </View>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setClient,
      setRole,
      reset,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
