import React, { memo, useEffect, useRef, useState } from "react";
import { Image, Text, View, Pressable } from "react-native";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import styles from "./style";
import { TouchableOpacity } from "react-native-gesture-handler";
import { BlurView } from "expo-blur";

const Purchase = ({ properties, purchaseSelected, onSelect }) => {
  const isHightLighted = properties.id === purchaseSelected?.id;
  const purchaseRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  const toggleShowDetails = () => {
    setTimeout(() => {
      onSelect(
        isHightLighted ? null : { type: "purchase-details", data: properties }
      );
    }, 100);
  };

  useEffect(() => {
    if (isHightLighted) {
      purchaseRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isHightLighted]);

  const imageSize = properties["Purchased By"] ? "600x515" : "250x162";
  const image_url = properties["Image IDs"]
    ? `https://insights.proptrack.com/imagery/${imageSize}/${
        properties["Image IDs"].split(",")[0].split(".")[0]
      }/image.jpg`
    : properties["PROPERTY IMAGE URL"];

  return (
    <Pressable style={({ hovered }) => setIsHovered(hovered)}>
      <View style={styles.cardContainer}>
        {isHovered && (
          <View style={styles.btnView}>
            <TouchableOpacity
              style={{ width: 26, height: 26 }}
              onPress={toggleShowDetails}
            >
              <Image
                resizeMode="contain"
                style={{ height: 14, width: 14, margin: "auto", marginBottom: 7, marginRight: 8 }}
                source={
                  isHightLighted
                    ? require("../../../../assets/portfolio-roadmap/arrow-right.png")
                    : require("../../../../assets/portfolio-roadmap/arrow-left.png")
                }
              />
            </TouchableOpacity>
          </View>
        )}
        <View
          style={[styles.container, isHightLighted && styles.active]}
          ref={purchaseRef}
        >
          <View style={styles.imageOverview}>
            <View style={styles.topLeftSection}>
              <BlurView intensity={10} style={styles.blur}>
                <Image
                  resizeMode="contain"
                  style={{
                    width: 24,
                    height: 24,
                    tintColor: MAIN_COLOR.G800,
                  }}
                  source={
                    properties["Purchased By"]
                      ? require("../../../../assets/portfolio-roadmap/house_option2.png")
                      : require("../../../../assets/portfolio-roadmap/house_option.png")
                  }
                />
              </BlurView>
            </View>
            <Image
              style={image_url ? styles.image : styles.no_image}
              resizeMode="contain"
              source={
                image_url
                  ? image_url
                  : require("../../../../assets/portfolio-roadmap/no_img.png")
              }
            />
            <View style={styles.topRightSection}>
              <BlurView intensity={10} style={styles.blur} />
              <Text style={styles.txtPurchaseBy}>Purchased by</Text>
              <Text style={styles.txtAgency}>
                {properties["Purchased By"]
                  ? "Independently"
                  : "The Investors Agency"}
              </Text>
            </View>
          </View>
          <View style={styles.description}>
            <BlurView intensity={6} style={styles.blur} />
            <View style={styles.content}>
              <View style={{ width: "100%" }}>
                <Text style={styles.name}>
                  {properties["PROPERTY STREET ADDRESS"]}
                </Text>
                <View style={styles.center}>
                  <Image
                    style={styles.icon}
                    resizeMode="contain"
                    source={require("../../../../assets/location.svg")}
                  />
                  <Text style={styles.address}>
                    {properties["PROPERTY SUBURB"]}{" "}
                    {properties["PROPERTY STATE"]}{" "}
                    {properties["PROPERTY POSTCODE"]}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.params}>
              <View style={styles.param}>
                <Image
                  style={styles.icon}
                  resizeMode="contain"
                  source={require("../../../../assets/portfolio-roadmap/bed.svg")}
                />
                <Text style={styles.value}>
                  {properties["NO. OF BEDROOMS"]} Bed
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  style={styles.icon}
                  resizeMode="contain"
                  source={require("../../../../assets/portfolio-roadmap/bath.svg")}
                />
                <Text style={styles.value}>
                  {properties["NO. OF BATHROOMS"]} Bath
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  style={styles.icon}
                  resizeMode="contain"
                  source={require("../../../../assets/portfolio-roadmap/car.svg")}
                />
                <Text style={styles.value}>
                  {properties["# OF PARKING"]} Car
                </Text>
              </View>
              <View style={styles.param}>
                <Image
                  style={styles.icon}
                  resizeMode="contain"
                  source={require("../../../../assets/portfolio-roadmap/area.svg")}
                />
                <Text style={styles.value}>{properties["AREA (M2)"]} m2</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

export default memo(Purchase);
