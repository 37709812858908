import { Modal, View, TouchableOpacity, StyleSheet, Image, ScrollView } from "react-native";
import React, { memo } from "react";
import { BlurView } from "expo-blur";
import Toast from "react-native-toast-message";
import { MAIN_COLOR } from "../Utility/Colors";

import { toastConfig } from "../configs/toastConfig";

const ViewGallery = ({
  open,
  styleBox,
  children,
  isPagination,
  isFullScreen,
  hidePrev,
  hideNext,
  onClose,
  onPrev,
  onNext,
}) => {
  return (
    <Modal animationType="fade" transparent visible={open}>
      <BlurView
        tint="dark"
        intensity={12}
        style={{ position: "absolute", height: "100%", width: "100%" }}
      >
        <View style={{ backgroundColor: "#000000BF", flex: 1 }}>
          <View style={styles.content}>
            {isPagination && (
              <TouchableOpacity
                onPress={onPrev}
                disabled={hidePrev}
                style={{
                  opacity: hidePrev ? 0 : 1,
                  display: isFullScreen ? "none" : "unset",
                }}
              >
                <Image
                  style={{ width: 72, height: 72 }}
                  source={require("../assets/prev-video.svg")}
                />
              </TouchableOpacity>
            )}

            <View
              style={[styles.box, isFullScreen && styles.fullScreen, styleBox]}
            >
              <TouchableOpacity style={styles.btnClose} onPress={onClose}>
                <Image
                  style={{ width: 50, height: 50 }}
                  source={require("../assets/home/close.svg")}
                />
              </TouchableOpacity>

              {children && (
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{ flexGrow: 1, width: "100%", height: "100%" }}
                  style={{ flex: 1 }}
                >
                  {children}
                </ScrollView>
              )}
            </View>

            {isPagination && (
              <TouchableOpacity
                onPress={onNext}
                disabled={hideNext}
                style={{
                  opacity: hideNext ? 0 : 1,
                  display: isFullScreen ? "none" : "unset",
                }}
              >
                <Image
                  style={{ width: 72, height: 72 }}
                  source={require("../assets/next-video.svg")}
                />
              </TouchableOpacity>
            )}
          </View>
          <Toast config={toastConfig} />
        </View>
      </BlurView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignSelf: "center",
    flex: 1,
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
  },

  fullScreen: {
    borderRadius: 0,
    width: "100%",
    height: "100vh",
    borderWidth: 0,
  },

  box: {
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
    width: "70%",
    aspectRatio: 2 / 1,
    borderWidth: 6,
    borderColor: "#34393D",
    borderRadius: 20,
    borderStyle: "solid",
    position: "relative",
    overflow: "hidden",
  },

  btnClose: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 50,
    height: 50,
    zIndex: 100,
  },
});

export default memo(ViewGallery);
