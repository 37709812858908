import React from "react";
import moment from "moment";
import "@firebase/firestore";
import firebase from "firebase";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "react-native-flash-message";
import { Animated, Platform, Dimensions, SafeAreaView } from "react-native";

//Function
import { ROLES } from "../../hooks/usePermission";
import { MAIN_COLORS } from "../../Utility/Colors";
import { user as setUser } from "../../Reducer/user";
import { setShowCalendar } from "../../Reducer/portfolio-roadmap";
import {
  getBrandingByLeadSourceValue,
  getLeads,
  getProperty,
  getTIATeamForApp,
} from "../../Clickup/api";
import {
  setLoader,
  setOpenDetails,
  setCurrentScreen,
  setOpportunities,
  setNotifications,
  setNotificationsObject,
  setTeamTIAData,
} from "../../Reducer/tia";

//Component
import Portfolio from "../Folio/index.v2";
import HomeComp from "./components/index.v2";
import { Panel } from "../../Layout/components/Panel";
import ProjectDetails from "../ProjectDetails/index.v2";
import LOADER from "../../Utility/ComponentCard/Loader";
import NotificationsScreen from "../Notifications/indexv2";
import PortfolioRoadmap from "../PortfolioRoadmap/index.v2";
import PortfolioRoadmapOld from "../PortfolioRoadmap";
import { ORIGIN } from "../../Utility/common";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      monthly: false,
      week: [],
      markedDates: {},
      checklists: [],
      clickUpStatusList: [],
      refreshing: false,
      opacity: new Animated.Value(1),
      currentScreen:
        this.props.TIA.role === "Partner"
          ? "Roadmap"
          : this.props.TIA.currentScreen,
      height: new Animated.Value(0),
      email: this.props.TIA.client.email,
      password: null,
      leads: [],
      teamTIAData: [],
      mountInHome: false,
      notificationIndex: 0,
      version: new URLSearchParams(window.location.search).get("version"),
      deal: null,
    };
  }

  setValue = (key, newValue) => {
    this.setState({ [key]: newValue });
  };

  getNextSevenDays = () => {
    var curr = new Date();
    var first = curr.getDate() - curr.getDay();

    let arr = [];
    for (let i = 0; i < 7; i++) arr.push(new Date(curr.setDate(first + i)));

    this.setState({ week: arr, selected: new Date().getDate() });
  };

  componentWillUnmount() {}

  componentDidUpdate(prevProp, prevState) {
    if (prevState.currentScreen !== this.state.currentScreen) {
      this.props.setCurrentScreen(this.state.currentScreen);
    }

    if (
      prevState.currentScreen !== "Home" &&
      this.state.currentScreen === "Home"
    ) {
      if (!this.state.mountInHome) {
        this.props.setLoader(true);
        this.refresh();
      }
    }

    if (prevProp.TIA.currentScreen !== this.props.TIA.currentScreen) {
      this.setState({ currentScreen: this.props.TIA.currentScreen });
      this.props.setShowCalendar(true);
    }
  }

  getNotifications() {
    var db = firebase.firestore();
    let allLeadsId = "d22875df-8bfe-430f-8ab9-c33736223f47";
    let leads = this.props.TIA.client.custom_fields?.filter(
      (item) => item.id === allLeadsId
    );
    let leadsIds = {};

    if (leads?.[0]?.value) {
      leads?.[0]?.value.map((item) => {
        leadsIds[item.id] = true;
      });
    }

    db.collection("notifications")
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          if (leadsIds[doc.id]) {
            this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
            arr = arr.concat(Object.values(doc.data()));
          }
          arr.sort(function (a, b) {
            return (
              new Date(b.dateUpdated.seconds * 1000) -
              new Date(a.dateUpdated.seconds * 1000)
            );
          });

          this.getMarkedDates(arr);
          this.props.setNotifications(arr);
        });

        db.collection("notifications").onSnapshot((querySnapshot) => {
          arr = [];
          querySnapshot.forEach((doc) => {
            if (leadsIds[doc.id]) {
              this.props.setNotificationsObject({
                id: doc.id,
                data: doc.data(),
              });
              arr = arr.concat(Object.values(doc.data()));
            }
          });
          arr.sort(function (a, b) {
            return (
              new Date(b.dateUpdated.seconds * 1000) -
              new Date(a.dateUpdated.seconds * 1000)
            );
          });
          this.props.setNotifications(arr);
          this.getMarkedDates(arr);
          this.getOpportunities();
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  async fetchPropertyData(id) {
    try {
      const propertyData = await getProperty(id);
      if (propertyData?.err) return null;
      return {
        ...propertyData?.custom_fields?.reduce(
          (fields, field) => ({
            ...fields,
            [field.name]: field.value,
          }),
          {}
        ),
        id: propertyData.id,
        date_updated: propertyData.date_updated,
      };
    } catch (error) {
      return null;
    }
  }

  async getOpportunities() {
    let allLeadsId = "d22875df-8bfe-430f-8ab9-c33736223f47";
    let allLeads = [];
    let leads = this.props.TIA.client.custom_fields?.filter(
      (item) => item.id === allLeadsId
    );

    if (leads?.[0]?.value) {
      leads?.[0]?.value.map((item) => {
        getLeads(item.id)
          .then(async (result) => {
            const checklists = result?.checklists ?? [];
            this.setState({
              checklists: this.state.checklists.concat([...checklists]),
              clickUpStatusList: this.state.clickUpStatusList.concat([
                { taskId: result?.id, status: result?.status?.status ?? "" },
              ]),
            });
            var object = result?.custom_fields?.reduce(
              (obj, item) => Object.assign(obj, { [item.name]: item.value }),
              {}
            );

            let purchase_connected_address;
            let purchase_connected_image;
            if (object?.["Proposed Property"]?.length > 0) {
              const purchaseIdConnected = object["Proposed Property"]?.[0]?.id;
              const purchase = await this.fetchPropertyData(
                purchaseIdConnected
              );

              const address = purchase?.["PROPERTY STREET ADDRESS"];
              const suburb = purchase?.["PROPERTY SUBURB"];
              const state = purchase?.["PROPERTY STATE"];
              const postcode = purchase?.["PROPERTY POSTCODE"];
              purchase_connected_address = `${address}, ${suburb} ${state} ${postcode}`;

              purchase_connected_image = purchase?.["Image IDs"]
                ? `https://insights.proptrack.com/imagery/350x265/${
                    purchase["Image IDs"].split(",")[0].split(".")[0]
                  }/image.jpg`
                : purchase?.["PROPERTY IMAGE URL"];
            }

            let co_labeling_logo;
            if (!!object?.["Lead Source"]) {
              const response = await getBrandingByLeadSourceValue(
                object["Lead Source"]
              );
              co_labeling_logo = response?.tasks?.[0]?.custom_fields.find(
                (one) => one.name === "Co-labeling logo"
              )?.value?.[0].url;
            }

            result["custom_fields"] = null;
            allLeads.push({
              ...result,
              ...object,
              co_labeling_logo,
              purchase_connected_image,
              purchase_connected_address,
            });
            if (allLeads.length === leads[0]?.value.length) {
              this.props.setLoader(false);
              this.setState({ refreshing: false, leads: allLeads });

              this.props.setOpportunities(allLeads);
            }
          })
          .catch((error) => {
            this.props.setLoader(false);
            alert(error.message);
            showMessage({
              message: error.message,
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            });
          });
      });
    } else {
      this.props.setLoader(false);
    }
  }

  fetchTIATeamData = async () => {
    try {
      const data = await getTIATeamForApp();
      const teamTIAData = data?.tasks?.length
        ? data.tasks.map((task) => {
            const customFields = task.custom_fields.reduce((acc, field) => {
              if (field.name === "Phone") acc.phone = field.value;
              if (field.name === "Email") acc.email = field.value;
              if (field.name === "Type") acc.type = field.value;

              if (field.name === "Avatar" && Array.isArray(field.value)) {
                const urlObject = field.value.find((obj) => obj.url);
                acc.avatar = urlObject ? urlObject.url : null;
              }
              return acc;
            }, {});

            customFields.name = task.name;
            return customFields;
          })
        : [];

      this.setState({ teamTIAData });
      this.props.setTeamTIAData({ teamTIAData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  async refresh() {
    this.getOpportunities();
    this.getNotifications();
  }

  componentDidMount() {
    const { role } = this.props.TIA;

    this.dimensionsSubscription = Dimensions.addEventListener(
      "change",
      this.onChange
    );

    this.fetchTIATeamData();

    this.getNextSevenDays();
    if (role.toLowerCase() === ROLES.CLIENT) {
      if (this.state.currentScreen === "Home") {
        this.props.setLoader(true);
        this.refresh();
        this.setState({ mountInHome: true });
      }
    } else {
      this.setState({ currentScreen: "Roadmap" });
    }

    this.props.setOpenDetails("");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email")) {
      this.props.navigation.navigate("SignUpScreen");
    }
    if (
      window.location.pathname.indexOf("scenario-detail") !== -1 &&
      params.get("scenario_id")
    ) {
      this.setState({ currentScreen: "Roadmap" });
    }

    window.history.replaceState({}, "", ORIGIN);
  }

  aggregateByDate(obj) {
    const result = {};

    for (const key in obj) {
      const date = obj[key].date;

      if (result[date]) {
        result[date].quantity += 1;
      } else {
        result[date] = { ...obj[key], quantity: 1 };
      }
    }

    return result;
  }

  getMarkedDates(notifications) {
    let markedDates = {};
    notifications.map((item, index) => {
      markedDates[index] = {
        taskId: item.taskId,
        statusName: item.statusName,
        date: moment(item.dateUpdated.seconds * 1000).format("YYYY-MM-DD"),
      };
    });
    const result = this.aggregateByDate(markedDates);

    this.setState({ markedDates: result });
  }

  render() {
    const { markedDates, version } = this.state;

    const goToProjectDetails = (id, statusName) => {
      const _statusName = statusName
        ? statusName === "MARKET UPDATES"
          ? "SETTLED"
          : statusName
        : "PROFILE REVIEW";

      let arr = [
        "PROFILE REVIEW", // todo
        "SEARCH PROCESS",
        "MARKET SELECTION",
        "PROPERTY SELECTION",
        "PROPERTY WALK-THROUGH",
        "CONTRACT, B&P & PM",
        "BUILDING & PEST REPORTS",
        "UNCONDITIONAL",
        "SETTLED",
        "MARKET UPDATES",
      ];

      this.props.TIA.opportunities.map((item) => {
        if (item.id === id) {
          this.setState({
            currentScreen: "ProjectDetails",
            deal: item,
            notificationIndex: arr.indexOf(_statusName),
          });
        }
      });
    };

    return (
      <SafeAreaView
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: "#FFFFFF",
        }}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
      >
        <Panel
          screen={this.state.currentScreen}
          setPropScreen={this.props.setCurrentScreen}
          setStateScreen={(screen) => this.setState({ currentScreen: screen })}
          navigate={this.props.navigation.navigate}
        />

        {this.state.currentScreen === "Home" && (
          <HomeComp
            leads={this.state.leads}
            goToProjectDetails={goToProjectDetails}
            setStateScreen={(screen) =>
              this.setState({ currentScreen: screen })
            }
            setDeal={(deal) => this.setState({ deal })}
            markedDates={markedDates}
            navigation={this.navigation}
          />
        )}
        {this.state.currentScreen === "ProjectDetails" && (
          <ProjectDetails
            deal={this.state.deal}
            notificationIndex={this.state.notificationIndex}
            checklists={this.state.checklists}
            clickUpStatusList={this.state.clickUpStatusList}
            setStateScreen={(screen) =>
              this.setState({ currentScreen: screen })
            }
            teamTIAData={this.state.teamTIAData}
          />
        )}
        {this.state.currentScreen === "Portfolio" && <Portfolio />}
        {this.state.currentScreen === "Notifications" && (
          <NotificationsScreen
            goToProjectDetails={goToProjectDetails}
            markedDates={markedDates}
          />
        )}
        {this.state.currentScreen === "Roadmap" &&
          (version === "old" ? <PortfolioRoadmapOld /> : <PortfolioRoadmap />)}
        {this.props.TIA.loader ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setOpportunities,
      setNotifications,
      setNotificationsObject,
      setLoader,
      setUser,
      setOpenDetails,
      setCurrentScreen,
      setShowCalendar,
      setTeamTIAData,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
