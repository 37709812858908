import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  setScenarioSelected,
  setLoading,
} from "../../../../Reducer/portfolio-roadmap";
import { MAIN_COLOR } from "../../../../Utility/Colors";
import { formatDollar } from "../../../../Utility/common";
import SliderBase from "../../../../components/Slider";
import styles from "./style";
import {
  retirementTable,
  calcGrossCFValue,
  calcPortfolioValue,
} from "../../../../Utility/PortfolioRoadmapUtility/retirement-overview";
import { calcBuyingStructure } from "../../../../Utility/PortfolioRoadmapUtility/portfolio-roadmap";
import { setListPurchaseTarget } from "../../../../Reducer/portfolio-roadmap";
import Slider from "../../../../components/Slider";

var retirementScenarioValue;

const PortfolioResults = ({
  result,
  idxSkipped,
  setLastRetirementOverviewDraff,
}) => {
  const firstYearPurchase = Number(
    moment(result?.[0]?.date).format("YYYY") || moment().year()
  );
  const years = Array.from({ length: 30 }, (_, i) => firstYearPurchase + i + 1);
  const { scenarioSelected, newDataRedux, listPurchaseTarget, loading } =
    useSelector((state) => state.PORTFOLIO_ROADMAP);
  const dispatch = useDispatch();
  const flatListRef = useRef(null);
  const [adjustParameters, setAdjustParameters] = useState(null);

  const [portfolioValue, setPortfolioValue] = useState(0);
  const [maxPortfolioValue, setMaxPortfolioValue] = useState(0);
  const [grossCashFlow, setGrossCashFlow] = useState(100000);
  const [maxGrossCashFlow, setMaxGrossCashFlow] = useState(0);
  const [landmarkPortfolio, setLandmarkPorfolio] = useState(0);
  const [landmarkGrossCF, setLandmarkGrossCF] = useState(0);

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity
        style={styles.yearItem}
        onPress={() => handleSelectYear(item)}
      >
        <Text
          style={[
            styles.textYear,
            item === scenarioSelected?.yearsOfResult
              ? styles.selectedYearItem
              : null,
          ]}
        >
          {item}
        </Text>
      </TouchableOpacity>
    );
  };

  const getItemLayout = (data, index) => ({
    length: 30,
    offset: 30 * index,
    index,
  });

  const scrollToIndex = (index) => {
    flatListRef.current.scrollToIndex({
      animated: true,
      index,
      // viewPosition: 0.2,
    });
  };

  const handleSelectYear = async (item) => {
    try {
      if (item < scenarioSelected?.yearsOfResult) {
        dispatch(
          setScenarioSelected({
            ...scenarioSelected,
            yearsOfResult: item,
            investingTimeLine: item - firstYearPurchase,
          })
        );
        scrollToIndex(years.indexOf(item));
      } else {
        dispatch(setLoading(true));
        scrollToIndex(years.indexOf(item));
        const newYearItem =
          scenarioSelected?.investingTimeLine -
          (scenarioSelected?.yearsOfResult - item);

        const newData = {
          // ...lastPurchaseTarget,
          indexChange: listPurchaseTarget.length - 1,
          years: newYearItem + 1,
          customIncomes: true,
        };
        const dataRedux = scenarioSelected;
        const listPurchase = await calcBuyingStructure(
          {
            cashSavings: scenarioSelected?.cashSavings,
            availableEquity: scenarioSelected?.availableEquity,
            years: scenarioSelected?.investingTimeLine,
            widgetProperties: scenarioSelected?.widgetProperties,
            customIncomes: scenarioSelected?.customIncomes,
          },
          window?._wiwo,
          newData,
          dataRedux,
          newDataRedux,
          listPurchaseTarget
        );
        setTimeout(() => {
          const payload = {
            ...scenarioSelected,
            yearsOfResult: item,
            investingTimeLine: item - firstYearPurchase,
            purchaseTargets: listPurchase,
          };
          dispatch(setScenarioSelected(payload));
        }, 800);
        dispatch(setListPurchaseTarget(listPurchase));
      }
    } catch (error) {
      console.log(error);
    } finally {
      // dispatch(
      //   setScenarioSelected({
      //     ...scenarioSelected,
      //     yearsOfResult: item,
      //     investingTimeLine: item - firstYearPurchase,
      //   })
      // );
      dispatch(setLoading(false));
    }
  };

  const handleChangePortfolioValue = (value) => {
    if (value === adjustParameters?.portfoliovalue) return;
    const data = calcPortfolioValue(retirementScenarioValue, value);
    setPortfolioValue(value);
    setGrossCashFlow(data.grossCashFlow);
    setAdjustParameters(data);
    setLastRetirementOverviewDraff({
      value,
      equityValue: data.equityValue,
      grossCFValue: data.grossCashFlow,
    });
  };

  const handleChangeGrossCF = (value) => {
    if (value === adjustParameters?.grossCashFlow) return;
    const data = calcGrossCFValue(retirementScenarioValue, value);
    setGrossCashFlow(value);
    setAdjustParameters(data);
    setPortfolioValue(data.portfoliovalue);
    setLastRetirementOverviewDraff({
      value: data.portfoliovalue,
      equityValue: data.equityValue,
      grossCFValue: data.grossCashFlow,
    });
  };

  useEffect(() => {
    const yearsOfResult = scenarioSelected?.yearsOfResult;
    if (yearsOfResult) {
      const idx = years.indexOf(yearsOfResult);
      if (idx === -1) scrollToIndex(0);
      else scrollToIndex(idx);
    }
  }, [result]);

  useEffect(() => {
    const retirementScenarioTable = retirementTable(result, scenarioSelected);
    retirementScenarioValue = retirementScenarioTable?.slice(-1)[0];
    if (retirementScenarioValue) {
      setMaxPortfolioValue(retirementScenarioValue.value);
      const maxGrossCashFlow = calcPortfolioValue(
        retirementScenarioValue,
        retirementScenarioValue.value
      );
      setMaxGrossCashFlow(maxGrossCashFlow.grossCashFlow);
      handleChangePortfolioValue(retirementScenarioValue.value);
    }
  }, [result]);

  useEffect(() => {
    if (retirementScenarioValue) {
      const { value, debtRemainingValue, grossCashFlowPM } =
        retirementScenarioValue;
      const valuePortfolio = value - debtRemainingValue;
      const valueGrossCF = grossCashFlowPM * (1 - debtRemainingValue / value);
      setLandmarkPorfolio(valuePortfolio);
      setLandmarkGrossCF(valueGrossCF);
    }
  }, [retirementScenarioValue]);

  return (
    <View style={styles.container}>
      <View style={styles.wrapTitle}>
        <Text style={styles.title}>Portfolio Results for</Text>
        <FlatList
          ref={flatListRef}
          horizontal
          data={years}
          renderItem={renderItem}
          keyExtractor={(item) => item.toString()}
          showsHorizontalScrollIndicator={false}
          snapToInterval={80}
          getItemLayout={getItemLayout}
          initialNumToRender={30}
          decelerationRate={0.9}
          contentContainerStyle={styles.flatListContainer}
          style={{
            width: 80,
            paddingVertical: 6,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "#ADB9C74D",
          }}
        />
      </View>
      <View style={[styles.slider]}>
        <Slider
          styleContainer={{ flex: 1, alignItems: "flex-start" }}
          styleLabel={{ height: 30 }}
          label="Portfolio Value"
          value={portfolioValue}
          styleTippy={{ backgroundColor: MAIN_COLOR.yellow, color: "#fff" }}
          maximumValue={maxPortfolioValue}
          minimumValue={0.001}
          onChange={handleChangePortfolioValue}
          landmark={landmarkPortfolio}
          styleSlider={{ marginTop: 10 }}
          handleToMark={() => {
            const value =
              retirementScenarioValue?.value -
              retirementScenarioValue?.debtRemainingValue;
            handleChangePortfolioValue(value);
          }}
        />
        <Slider
          styleContainer={{ flex: 1, alignItems: "flex-start" }}
          styleLimit={{ flexDirection: "row-reverse" }}
          styleLabel={{ height: 30 }}
          label="Gross Cash Flow"
          value={grossCashFlow}
          styleTippy={{ backgroundColor: "#33B21E", color: "#fff" }}
          maximumValue={maxGrossCashFlow}
          minimumValue={0.001}
          onChange={handleChangeGrossCF}
          reverse
          landmark={landmarkGrossCF}
          styleSlider={{ marginTop: 10 }}
          handleToMark={() => {
            const { grossCashFlowPM, value, debtRemainingValue } =
              retirementScenarioValue;
            const valueGrossCF =
              grossCashFlowPM * (1 - debtRemainingValue / value);
            handleChangeGrossCF(valueGrossCF);
          }}
        />
      </View>

      <View style={styles.wrapPrameter}>
        <View style={styles.wrapGross}>
          <View style={styles.wrapTitle}>
            <Text style={styles.grossLabel}>Value reduce by:</Text>
            <Text style={styles.grossValue}>
              {adjustParameters?.reduceBy?.toFixed(2)}%
            </Text>
          </View>
          <View style={styles.wrapTitle}>
            <Text style={styles.grossLabel}>Debt remaining:</Text>
            <Text style={styles.grossValue}>
              {formatDollar(adjustParameters?.debtRemaining?.toFixed(0))}
            </Text>
          </View>
          <View style={styles.wrapTitle}>
            <Text style={styles.grossLabel}>Repayments p/m:</Text>
            <Text style={styles.grossValue}>
              {formatDollar(adjustParameters?.loanPayment?.toFixed(0))}
            </Text>
          </View>
          <View style={styles.wrapTitle}>
            <Text style={styles.grossLabel}>LVR:</Text>
            <Text style={styles.grossValue}>
              {adjustParameters?.lvr?.toFixed(2)}%
            </Text>
          </View>
          <View style={styles.wrapTitle}>
            <Text style={styles.grossLabel}>Liquidity:</Text>
            <Text style={styles.grossValue}>
              {formatDollar(adjustParameters?.liquidity?.toFixed(0))}
            </Text>
          </View>
        </View>
        <View style={styles.contentNet}>
          <View style={styles.wrapNet}>
            <Text style={styles.netLabel}>Net cash flow</Text>
            <Text style={styles.netValue}>
              {formatDollar(adjustParameters?.netCashFlow.toFixed(0))}
            </Text>
          </View>
          <View style={styles.wrapNet}>
            <Text style={styles.netLabel}>Equity</Text>
            <Text style={styles.netValue}>
              {formatDollar(adjustParameters?.equityValue.toFixed(0))}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PortfolioResults;
