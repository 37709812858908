import { min, sumBy } from "lodash";
import moment from "moment";
import React from "react";
import { Line } from "react-chartjs-2";
import { Text, View } from "react-native";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";
import {
  externalTooltipHandler,
  externalTooltipHandlerNew,
  formatDollar,
} from "../../../../../Utility/common";
import styles from "./style";
import { PropagateLoader } from "react-spinners";

const GrowthChart = ({ properties, result, loading }) => {
  const min =
    result?.sales && Math.min(...result?.sales.map(({ value }) => value));
  const max =
    result?.sales && Math.max(...result?.sales.map(({ value }) => value));

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: { mode: "index", intersect: false },
    tooltips: { mode: "index", intersect: false },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: { label: (value) => formatDollar(value.raw) },
        external: (context) =>
          externalTooltipHandlerNew(
            context,
            { top: 0, right: 8, bottom: 0, left: 8 },
            "46px"
          ),
      },
    },
    scales: {
      x: {
        border: { color: "rgba(125, 136, 143, 0.5)" },
        grid: { drawTicks: false },
        ticks: {
          padding: 10,
          color: MAIN_COLORS.G600,
          font: { family: MAIN_FONT.regular, size: 10 },
        },
      },
      y: {
        beginAtZero: true,
        border: { dash: [4, 4], color: "#3F4448" },
        grid: {
          drawTicks: false,
          color: function (context) {
            if (context.index === 3) return "rgba(0,0,0,0)";
            return "#3F4448";
          },
        },
        ticks: {
          maxTicksLimit: 5,
          major: { enable: true },
          callback: (value) => "",
          stepSize: (max + max * 0.1 - min) / 5,
        },
        min: min,
        max: max + max * 0.1,
      },
      y1: {
        beginAtZero: true,
        border: { color: "rgba(125, 136, 143, 0.5)" },
        position: "right",
        ticks: { callback: (value) => "" },
      },
    },
  };

  const labels =
    result?.sales?.map((one) => moment(one.date).format("MM/YY")) || [];

  const values = result?.sales?.map((one) => one.value) || [];

  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.2)",
            "rgba(250, 191, 1, 0.05)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLORS.PRIMARY_COLOR,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointBackgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        pointHoverBorderColor: MAIN_COLORS.BACKGROUND_WHITE,
        lineTension: 0.4,
        pointRadius: 0,
        fill: true,
        spanGaps: true,
        borderWidth: 1,
        clip: {
          left: false,
          right: false,
          top: false,
          bottom: false,
        },
      },
    ],
  };

  return (
    !loading && result === null ? null : (
      <View style={styles.container}>
        <Text style={styles.title}>Growth since purchase</Text>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={styles.currentName}>Percent growth :</Text>
          <Text style={styles.currentValue}>
            {result?.growthPercentage ? Math.floor(result?.growthPercentage) : 0}%
          </Text>
        </View>

        <View
          style={{
            display: "flex",
            alignItems: "center",
            minHeight: "80%",
            position: "relative",
            height: 150,
          }}
        >
          {loading ? (
            <PropagateLoader
              color="#FFFFFF"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <Line
              options={options}
              data={data}
              style={{ marginLeft: -12, marginRight: -12 }}
              plugins={[
                {
                  afterDraw: function (chart, easing) {
                    if (
                      chart?.tooltip?._active &&
                      chart?.tooltip?._active?.length
                    ) {
                      const activePoint = chart.tooltip._active[0];
                      const ctx = chart.ctx;
                      const x = activePoint.element.x;
                      const topY = chart.scales.y.chart.tooltip.caretY + 10;
                      const bottomY = chart.scales.y.bottom;

                      ctx.save();
                      ctx.beginPath();
                      ctx.moveTo(x, topY);
                      ctx.lineTo(x, bottomY);
                      ctx.lineWidth = 1;
                      ctx.strokeStyle = MAIN_COLORS.PRIMARY_COLOR;
                      ctx.stroke();
                      ctx.restore();
                    }
                  },
                },
              ]}
            />
          )}
        </View>
      </View>
    )
  );
};

export default GrowthChart;
