import React, { memo } from "react";
import { View, StyleSheet } from "react-native";

import { MAIN_COLORS, MAIN_COLOR } from "../../Utility/Colors";

const Header = ({ children }) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    height: 95,
    borderBottomWidth: 1,
    borderBottomColor: MAIN_COLORS.BORDER_COLOR,
    paddingHorizontal: 32,
    paddingVertical: 25,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    justifyContent: "center",
    zIndex: 1,
  },
});

export default memo(Header);
