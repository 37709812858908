import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { Animated, Image, Text, TouchableOpacity, View } from "react-native";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { formatDollar } from "../../../../Utility/common";
import styles from "./style";
import Tippy from "@tippyjs/react/headless";
import { usePermission } from "../../../../hooks/usePermission";

const PurchaseTarget = ({
  setVisibleModalAddTarget,
  setVisibleModalEditTarget,
  disabledClickOutside,
  properties,
  type,
  onSelect,
  purchaseSelected,
  isViewEdit,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const isHightLighted = properties.id === purchaseSelected?.id;

  const animationHeight = useRef(new Animated.Value(0)).current;

  const { isEdit } = usePermission("purchaseTarget");
  const purchaseRef = useRef(null);

  const purchaseTypeData = {
    HOUSE: {
      name: "House",
      icon: require("../../../../assets/portfolio-roadmap/house_option.svg"),
    },
    DUAL_OCCUPANCY: {
      name: "Dual Occupancy",
      icon: require("../../../../assets/portfolio-roadmap/house_option2.svg"),
    },
    SMSF: {
      name: "SMSF",
      icon: require("../../../../assets/portfolio-roadmap/house_option3.svg"),
    },
  };

  const purchase = purchaseTypeData[type];

  const toggleShowDetails = () => {
    onSelect(
      isHightLighted
        ? null
        : {
            type: "purchase-target-details",
            data: properties,
          }
    );
  };

  const openShowDetails = () => {
    Animated.timing(animationHeight, {
      toValue: 260,
      duration: 400,
      useNativeDriver: true,
    }).start();
    setShowDetails(true);
  };

  const closeShowDetails = () => {
    Animated.timing(animationHeight, {
      toValue: 0,
      duration: 600,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished) {
        setShowDetails(false);
      }
    });
  };

  useEffect(() => {
    if (isHightLighted) {
      openShowDetails();
      purchaseRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      closeShowDetails();
    }
  }, [isHightLighted]);

  return (
    <OutsideClickHandler
      onOutsideClick={
        isHightLighted && !disabledClickOutside
          ? () => {
              onSelect(null);
              closeShowDetails();
            }
          : () => {}
      }
    >
      <View style={styles.container} ref={purchaseRef}>
        <View style={[styles.content, showDetails && styles.active]}>
          <Animated.View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image
                resizeMode="contain"
                style={styles.iconType}
                source={purchase?.icon}
              />
              <View>
                <Text style={styles.title}>{purchase?.name}</Text>
                <View style={{ flexDirection: "row", gap: 4 }}>
                  <Image
                    resizeMode="contain"
                    style={{ width: 18, height: 18 }}
                    source={require("../../../../assets/portfolio-roadmap/calendar.svg")}
                  />
                  <Text style={styles.time}>
                    Approx.{" "}
                    {moment(properties.purchaseDate).format("MMMM YYYY")}
                  </Text>
                </View>
              </View>
            </View>

            {isEdit && isViewEdit && (
              <View style={{ flexDirection: "row", gap: 6 }}>
                <TouchableOpacity onPress={setVisibleModalEditTarget}>
                  <View style={styles.type}>
                    <Image
                      style={{ width: 20, height: 20 }}
                      source={require("../../../../assets/portfolio-roadmap/edit-primary.svg")}
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={setVisibleModalAddTarget}>
                  <View style={styles.type}>
                    <Image
                      style={{ width: 20, height: 20 }}
                      source={require("../../../../assets/portfolio-roadmap/add-circle.svg")}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            )}

            {/* {isEdit && isViewEdit && (
              <Tippy
                trigger="click"
                interactive
                placement="left-start"
                render={(attrs) => (
                  <View style={[styles.tippyContainer]}>
                    <TouchableOpacity
                      onPress={setVisibleModalEditTarget}
                      style={styles.tippyItem}
                    >
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../../../assets/portfolio-roadmap/edit.svg")}
                      />
                      <Text style={styles.tippyTitle}>
                        Purchase Target Edit
                      </Text>
                    </TouchableOpacity>
                    <View style={styles.tippyDriver} />
                    <TouchableOpacity
                      style={styles.tippyItem}
                      onPress={setVisibleModalAddTarget}
                    >
                      <Image
                        style={{ width: 20, height: 20 }}
                        source={require("../../../../assets/portfolio-roadmap/add-circle.svg")}
                      />
                      <Text style={styles.tippyTitle}>Add Purchase Target</Text>
                    </TouchableOpacity>
                  </View>
                )}
              >
                <TouchableOpacity>
                  <View style={styles.type}>
                    <Image
                      style={{ width: 20, height: 20 }}
                      source={require("../../../../assets/portfolio-roadmap/edit.svg")}
                    />
                  </View>
                </TouchableOpacity>
              </Tippy>
            )} */}
          </Animated.View>
          <View style={{ marginVertical: 12, paddingHorizontal: 12 }}>
            <TouchableOpacity
              style={[styles.btnView, { zIndex: 1 }]}
              onPress={toggleShowDetails}
            >
              <Image
                resizeMode="cover"
                style={{ height: 10, width: 10, margin: "auto" }}
                source={
                  showDetails
                    ? require("../../../../assets/portfolio-roadmap/arrow-up-a3a3a3.svg")
                    : require("../../../../assets/portfolio-roadmap/arrow-down-a3a3a3.svg")
                }
              />
            </TouchableOpacity>
            <View style={styles.divider} />
          </View>
          <View style={styles.info}>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>Target Price</Text>
              <Text style={styles.rowValue}>
                {formatDollar(Math.round(properties.propertyPrice))}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>Target Rent p/w</Text>
              <Text style={styles.rowValue}>
                {formatDollar(Math.round(properties.rentPW))}
              </Text>
            </View>

            {showDetails && (
              <Animated.View style={[{ maxHeight: animationHeight, gap: 8 }]}>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Income Type</Text>
                  <Text style={styles.rowValue}>{properties.incomeType}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Living Expenses p/m</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.expensesPM))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Total Income</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.totalIncome))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Deposit Size</Text>
                  <Text style={styles.rowValue}>
                    {properties.rateSelected}%
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Cash Contribution</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.cashContribute))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Loan Amount</Text>
                  <Text style={styles.rowValue}>
                    {formatDollar(Math.round(properties.loanAmount))}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Interest Rate</Text>
                  <Text style={styles.rowValue}>
                    {properties.interestRate}%
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.rowLabel}>Loan Type</Text>
                  <Text style={styles.rowValue}>I/O</Text>
                </View>
              </Animated.View>
            )}
          </View>
        </View>
      </View>
    </OutsideClickHandler>
  );
};

export default PurchaseTarget;
