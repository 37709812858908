import { View, StyleSheet, ScrollView } from "react-native";
import React from "react";

//Function
import { MAIN_COLORS } from "../../Utility/Colors";

//Component
import Header from "../components/Header";
import Menu from "../components/Menu";

const MainLayout = ({ children, childrenHeader, childrenMenu }) => {
  return (
    <>
      <View style={styles.container}>
        <View style={styles.centerView}>
          <Header>{childrenHeader}</Header>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              width: "100%",
              height: "100%",
            }}
          >
            {children}
          </ScrollView>
        </View>
      </View>
      <Menu>{childrenMenu}</Menu>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
  },
  centerView: {
    flex: 1,
    zIndex: -1,
  },
});
export default MainLayout;
