import React, { memo } from "react";
import { View, StyleSheet, Image, Text } from "react-native";

import { MAIN_COLOR } from "../../../Utility/Colors";
import { MAIN_FONT } from "../../../Utility/Fonts/font";

const statusTask = {
  complete: "Complete",
  progress: "In Progress",
  due: "Due",
};

const ButtonStatus = ({ status, style }) => {
  const handleStatusButton = (status) => {
    return {
      background: {
        backgroundColor:
          status === statusTask.complete
            ? "#D3B100"
            : status === statusTask.due
            ? "#7D888F40"
            : "#D3B10040",
      },
      icon:
        status === statusTask.complete
          ? require("../../../assets/home/check.svg")
          : status === statusTask.due
          ? require("../../../assets/home/warning.svg")
          : require("../../../assets/home/processing_time.svg"),

      text: {
        color:
          status === statusTask.complete
            ? MAIN_COLOR.BACKGROUND_COLOR
            : status === statusTask.due
            ? MAIN_COLOR.G600
            : "#D3B100",
      },
    };
  };

  return (
    <View style={[styles.button, handleStatusButton(status).background, style]}>
      <Image style={styles.icon} source={handleStatusButton(status).icon} />
      <Text style={[styles.textButton, handleStatusButton(status).text]}>
        {status}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    gap: 4,
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#D3B10040",
  },

  icon: { width: 18, height: 18 },

  textButton: {
    fontSize: 10,
    fontWeight: 500,
    lightHeight: 16,
    color: "#D3B100",
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(ButtonStatus);
