import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty, sumBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  setLoading,
  setScenarioSelected,
} from "../../../../../Reducer/portfolio-roadmap";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../../Utility/Colors";
import { formatDollar } from "../../../../../Utility/common";
import {
  MAX_RECOMMENDED_BORROWING,
  MIN_RECOMMENDED_BORROWING,
  calcBuyingStructure,
} from "../../../../../Utility/PortfolioRoadmapUtility/portfolio-roadmap";
import { budgetOptionsStep } from "../../../../../Utility/PortfolioRoadmapUtility/build-roadmap";
import InputNumber from "../../../../../components/InputNumber";
import Wiwo from "../Wiwo";
import styles from "./style";
import { setListPurchaseTarget } from "../../../../../Reducer/portfolio-roadmap";

const FormWidget = ({ open, toggleShowWidget, listPurchases }) => {
  const { width } = Dimensions.get("window");
  const { scenarioSelected, propertyFirstBought, listPurchaseTarget } =
    useSelector((state) => state.PORTFOLIO_ROADMAP);
  const dispatch = useDispatch();

  const schemaForm = Yup.object().shape({
    cashSavings: Yup.number().required(0),
    availableEquity: Yup.number().required(0),
    years: Yup.number().required(0),
  });

  const methods = useForm({
    defaultValues: {
      cashSavings: 0,
      availableEquity: 0,
      years: 0,
      widgetProperties: null,
      customIncomes: [],
    },
    resolver: yupResolver(schemaForm),
  });

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { isValid },
  } = methods;
  const [analyseResult, setAnalyseResult] = useState(null);

  const values = getValues();

  const onAnalyseStructure = (widgetProperties, currentBorrowingValue) => {
    const { cashSavings, availableEquity } = values;

    const totalSavingsAndEquity = cashSavings + availableEquity;
    let recommendedBorrowing =
      widgetProperties.dataList[0].input.bpResult.borrowingPowerMaximum;
    const shouldRecommendedBorrowing = recommendedBorrowing;
    if (recommendedBorrowing < MIN_RECOMMENDED_BORROWING) return;

    if (recommendedBorrowing)
      recommendedBorrowing =
        recommendedBorrowing <= MAX_RECOMMENDED_BORROWING
          ? recommendedBorrowing
          : MAX_RECOMMENDED_BORROWING;

    const result = budgetOptionsStep({
      recommendedBorrowing,
      totalSavingsAndEquity,
    });

    if (isEmpty(result)) return;

    setAnalyseResult({
      ...result,
      totalSavingsAndEquity,
      recommendedBorrowing,
      currentBorrowingValue,
      shouldRecommendedBorrowing,
    });
  };

  const getTotalPrimaryIncome = (data) => {
    const wiwoBorrowingPower = data?.widgetProperties?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );

    const incomes =
      wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
        (one) => one.incomeList
      );

    const primaryIncomes = incomes.map((incomeList) =>
      incomeList.find((income) => income.id === "primary")
    );

    return sumBy(primaryIncomes, "income");
  };

  const onSubmit = async (data) => {
    try {
      dispatch(setLoading(true));
      const totalPrimaryIncome = getTotalPrimaryIncome(data);
      const listPurchase = await calcBuyingStructure(data, window?._wiwo);
      dispatch(setListPurchaseTarget(listPurchase));
      setTimeout(() => {
        const payload = {
          ...scenarioSelected,
          purchaseTargets: listPurchase,
          investingTimeLine: data.years,
          cashSavings: data.cashSavings,
          availableEquity: data.availableEquity,
          widgetProperties: data.widgetProperties,
          customIncomes: data.customIncomes,
          totalGrossIncome:
            data.widgetProperties.dataList[0].output.keySummaryValues
              .annualSummary.grossPaygIncome +
            sumBy(data.customIncomes.flat(), "income") * 52,
          totalPrimaryIncome,
          yearsOfResult: moment(listPurchase?.[0]?.date).year() + data.years,
        };
        // const purchaseTarget = scenarioSelected?.purchaseTargets ?? [];
        dispatch(setScenarioSelected(payload));
      }, 800);
    } catch (error) {
      console.log(error);
    } finally {
      toggleShowWidget();
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      reset();
      setAnalyseResult(null);
    }
  }, [open]);

  return (
    <FormProvider {...methods}>
      <View
        style={[
          styles.container,
          {
            borderWidth: 1,
            borderColor: MAIN_COLOR.yellow,
            overflow: "hidden",
            bottom: 25,
            top: 129,
            height: "unset",
          },
          width < 1025 && { height: 1106 },
          !open && { display: "none" },
        ]}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingVertical: 12,
            backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
          }}
        >
          <View style={{ marginHorizontal: 12, gap: 10 }}>
            <Text style={styles.title}>Funds Available</Text>
            <Text style={styles.subTitle}>
              How much do you have in savings for your purchase costs?
            </Text>
            <View style={{ display: "flex", flexDirection: "row", gap: 19 }}>
              <div id="input_cash_savings">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputNumber
                      thousandSeparator
                      label="Cash savings"
                      value={value}
                      onChange={onChange}
                      size="small"
                      styleInput={{ fontWeight: 600 }}
                      styleContainer={{ flex: 1, fontSize: "12px" }}
                    />
                  )}
                  name="cashSavings"
                />
              </div>
              <div id="input_available_equity">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputNumber
                      thousandSeparator
                      label="Available equity"
                      value={value}
                      size="small"
                      onChange={onChange}
                      styleInput={{ fontWeight: 600 }}
                      styleContainer={{ flex: 1, fontSize: "12px" }}
                    />
                  )}
                  name="availableEquity"
                />
              </div>
            </View>
            <View style={styles.investTimeLine}>
              <View style={{ width: "70%", gap: 6 }}>
                <Text style={styles.title}>Investing Timeline</Text>
                <Text style={styles.subTitle}>
                  How many years do you want to invest for?
                </Text>
              </View>
              <div id="input_years">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputNumber
                      value={value}
                      size="small"
                      onChange={onChange}
                      showPrefixIcon={false}
                      styleContainer={{ width: 90 }}
                      styleInput={{ fontWeight: 600 }}
                      suffix=" Yrs"
                    />
                  )}
                  name="years"
                />
              </div>
            </View>
            <Wiwo onAnalyseStructure={onAnalyseStructure} open={open} />
          </View>
          {!!analyseResult && (
            <>
              <View style={{ gap: 12, marginTop: 12 }}>
                <Text style={styles.canBorrow}>
                  What you can borrow{" "}
                  {formatDollar(
                    Math.round(analyseResult?.shouldRecommendedBorrowing)
                  )}{" "}
                  <Text style={{ color: "#ADB9C7" }}>compared to..</Text>
                </Text>
                <View style={styles.showBorrow}>
                  <Text style={styles.showBorrowBtn}>
                    What you should borrow{" "}
                    {formatDollar(Math.round(analyseResult?.debtOnProperty))}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  gap: 6,
                  backgroundColor: "#F0F1F8",
                  paddingHorizontal: 18,
                  paddingVertical: 12,
                }}
              >
                <Text style={styles.canBorrow}>
                  Recommended buying structure
                </Text>
                <Text style={styles.basedOn}>
                  Based on{" "}
                  {formatDollar(
                    Math.round(analyseResult?.totalSavingsAndEquity)
                  )}{" "}
                  in available funds
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 19,
                    marginTop: 4,
                  }}
                >
                  <Controller
                    control={control}
                    render={({ field: { onChange } }) => (
                      <InputNumber
                        thousandSeparator
                        label="Purchase Price"
                        value={formatDollar(
                          Math.round(analyseResult?.propertyPrice)
                        )}
                        size="small"
                        onChange={onChange}
                        disabled
                        styleContainer={{ flex: 1 }}
                        styleInput={{ fontWeight: 600 }}
                        styleLabel={{ textAlign: "center", fontSize: "12px" }}
                      />
                    )}
                    name="purchasePrice"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange } }) => (
                      <InputNumber
                        thousandSeparator
                        label={`Deposit at ${analyseResult?.rateSelected}%`}
                        value={formatDollar(Math.round(analyseResult?.deposit))}
                        size="small"
                        onChange={onChange}
                        disabled
                        styleContainer={{ flex: 1 }}
                        styleInput={{ fontWeight: 600 }}
                        styleLabel={{ textAlign: "center", fontSize: "12px" }}
                      />
                    )}
                    name="depositAt12"
                  />
                </View>
              </View>
              <div id="btn_build_roadmap">
                <TouchableOpacity
                  style={styles.btn}
                  onPress={handleSubmit(onSubmit)}
                >
                  <Text style={styles.btnText}>Build Roadmap</Text>
                </TouchableOpacity>
              </div>
            </>
          )}
        </ScrollView>
      </View>
    </FormProvider>
  );
};

export default FormWidget;
