import { StyleSheet } from "react-native";
import { MAIN_COLOR, MAIN_COLORS, TAB_BAR_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    zIndex: 2,
    height: 102,
  },
  text: {
    fontFamily: MAIN_FONT.regular,
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 20,
  },
  list: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: 10,
    flex: 1,
  },
  leftSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: 12,
    flex: 1,
  },
  arrowDown: {
    width: 14,
    height: 12,
    display: "flex",
    alignSelf: "flex-start",
  },
  showMore: {
    borderRadius: 15,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 1,
    shadowColor: MAIN_COLOR.G700,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
    zIndex: 1,
    position: "absolute",
    right: 0,
    top: 56,
    paddingVertical: 28,
    paddingHorizontal: "2.5%",
    marginTop: 20,
    width: "81%",
    justifyContent: "center",
    paddingLeft: 20,
  },
  showMoreInTablet: {
    display: "grid",
    gridTemplateColumns: "25% 25% 25% 25%",
    gap: 0,
  },
});
