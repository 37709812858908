import { StyleSheet } from "react-native";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    paddingHorizontal: 22,
    paddingVertical: 22,
    borderWidth: 1,
    borderColor: "#ADB9C74D",
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    borderRadius: 10,
    minHeight: 385,
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  currentLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: MAIN_COLOR.G700,
    fontFamily: MAIN_FONT.regular,
  },
  currentValue: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.PRIMARY_COLOR,
  },
  notes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  noteItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  round: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    marginRight: 12,
    backgroundColor: MAIN_COLOR.yellow,
  },
  textLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
});
