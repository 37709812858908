import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import "chartjs-adapter-moment";
import "chart.js/auto";
import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { Text, View } from "react-native";
import { MAIN_COLOR } from "../../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../../Utility/Fonts/font";
import {
  externalTooltipHandler,
  formatDollar,
} from "../../../../../Utility/common";
import styles from "./style";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const Strategy = ({ result, idxSkipped, onSelect, purchaseSelected }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const chartRef = useRef(null);

  const { purchasedProperties, scenarioSelected } = useSelector(
    (state) => state.PORTFOLIO_ROADMAP
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    hover: {
      mode: "index",
      intersect: false,
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: MAIN_COLOR.G700,
        },
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: {
          label: (value) => {
            return formatDollar(value.raw.y);
          },
        },
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          stepSize: 1,
          displayFormats: {
            year: "yyyy",
          },
        },
        font: {
          family: MAIN_FONT.regular,
          size: 12,
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
          stepSize: 1,
          callback: function (value, index) {
            const isJanuary = moment(value).format("MM") === "01";
            if (isJanuary || index === 0) return moment(value).format("YYYY");
          },
        },
      },
      y: {
        border: { dash: [4, 4] },
        grid: {
          tickColor: "#E8E6E6",
          drawTicks: false,
        },
        beginAtZero: true,
        ticks: {
          max: 10,
          font: {
            family: MAIN_FONT.regular,
            size: 12,
          },
          padding: 10,
          major: {
            enable: true,
          },
          maxTicksLimit: 8,
          stepSize: 0.5,
          callback: (value) => formatDollar(value),
        },
      },
    },
  };

  const skipped = (ctx, value) =>
    ctx.p0DataIndex >= idxSkipped ? value : [6, 0];

  const mappingData = result?.map((item, index) => {
    const { value, date, isBought } = item;

    return {
      x:
        isBought || index === result.length - 1
          ? moment(date).format("YYYY-MM")
          : undefined,
      y: value,
      date,
      isBought,
    };
  });

  const data = {
    datasets: [
      {
        data: mappingData,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(250, 191, 1, 0.6)",
            "rgba(250, 191, 1, 0.3)",
            "rgba(250, 191, 1, 0.1)",
          ];
          if (!context.chart.chartArea) return;
          const {
            ctx,
            data,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);
          return gradientBg;
        },
        borderColor: MAIN_COLOR.yellow,
        pointBorderWidth: 2.5,
        pointBackgroundColor: MAIN_COLOR.yellow,
        pointBorderColor: MAIN_COLOR.BACKGROUND_COLOR,
        pointRadius: ({ index }) => {
          if (mappingData?.[index]?.isBought) return 8;
          return 0;
        },
        pointHoverRadius: 5,
        lineTension: 0.5,
        borderWidth: 3,
        fill: true,
        hoverBorderWidth: 2.5,
        segment: {
          borderDash: (ctx) => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
      },
    ],
  };

  const handleClick = (event) => {
    const chart = chartRef.current;
    if (chart) {
      const points = chart.getElementsAtEventForMode(
        event.nativeEvent,
        "nearest",
        { intersect: true },
        true
      );
      if (!!points.length) {
        const firstPoint = points[0];
        const value =
          chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

        const dateValue = moment(value.date).format("MM/YYYY");

        const itemPurchase = purchasedProperties?.find(
          (item) =>
            moment(Number(item["Purchase date "])).format("MM/YYYY") ===
            dateValue
        );
        const itemPurchaseTarget = scenarioSelected?.purchaseTargets?.find(
          (item) => {
            const [month, day, year] = item.purchaseDate.split("/");

            return `${month}/${year}` === dateValue;
          }
        );

        if (itemPurchase) {
          const isHightLighted = itemPurchase.id === purchaseSelected?.id;
          setTimeout(() => {
            onSelect(
              isHightLighted
                ? null
                : { type: "purchase-details", data: itemPurchase }
            );
          }, 100);
        }

        if (itemPurchaseTarget) {
          const isHightLighted = itemPurchaseTarget.id === purchaseSelected?.id;
          onSelect(
            isHightLighted
              ? null
              : {
                  type: "purchase-target-details",
                  data: itemPurchaseTarget,
                }
          );
        }
      }
    }
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (purchaseSelected !== null) {
      const { data, type } = purchaseSelected;
      if (type === "purchase-details") return;
      const [month, day, year] = data.purchaseDate.split("/");
      const purchaseDate = `${month}/${year}`;

      const pointIndex = mappingData.findIndex(
        (item) =>
          moment(Number(item.date)).format("MM/YYYY") === purchaseDate &&
          item.isBought
      );

      if (pointIndex !== -1) {
        const meta = chart.getDatasetMeta(0);
        const point = meta.data[pointIndex];
        if (point) {
          chart?.tooltip?.setActiveElements(
            [{ datasetIndex: 0, index: pointIndex }],
            { x: point.x, y: point.y }
          );
          chart?.update();
        }
      }
    } else {
      chart?.tooltip?.setActiveElements([]);
      chart?.update();
    }
  }, [purchaseSelected, result]);

  return (
    <View
      style={[
        styles.container,
        isTabletOrMobile ? { height: 400 } : { flex: 1 },
      ]}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: 16,
        }}
      >
        <Text style={styles.title}>Value</Text>
        <Text style={styles.currentLabel}>
          Growth since purchase:{" "}
          <Text style={styles.currentValue}>
            {formatDollar(Math.round(result?.[idxSkipped - 1]?.value))}
          </Text>
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          marginVertical: 6,
          flex: 1,
        }}
      >
        <Line
          options={options}
          data={data}
          ref={chartRef}
          plugins={[
            {
              afterDraw: function (chart, easing) {
                if (
                  chart?.tooltip?._active &&
                  chart?.tooltip?._active?.length
                ) {
                  const activePoint = chart.tooltip._active[0];
                  const ctx = chart.ctx;
                  const x = activePoint.element.x;
                  const topY = chart.scales.y.chart.tooltip.caretY + 10;
                  const bottomY = chart.scales.y.bottom;

                  ctx.save();
                  ctx.beginPath();
                  ctx.moveTo(x, topY);
                  ctx.lineTo(x, bottomY);
                  ctx.lineWidth = 2;
                  ctx.strokeStyle = MAIN_COLOR.G800;
                  ctx.setLineDash([4, 4]);
                  ctx.stroke();
                  ctx.restore();
                }
              },
            },
          ]}
          onClick={handleClick}
        />
      </View>
      <View style={styles.notes}>
        <View style={styles.noteItem}>
          <View style={[styles.round]} />
          <Text style={styles.textLabel}>Typical purchase</Text>
        </View>
        <View style={styles.noteItem}>
          <View
            style={[
              styles.round,
              { borderColor: MAIN_COLOR.green, borderWidth: 2 },
            ]}
          />
          <Text style={styles.textLabel}>Dual Occupancy</Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(Strategy);
