import React from "react";
import { View, Image, Text } from "react-native";
import Carousel from "react-native-reanimated-carousel";

//Functions
import styles from "./styles";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT } from "../../../Utility/Fonts/font";

function OverviewProperty({ property }) {
  if (!property) return null;

  const getLocation = (fullAddress) => {
    const addressArr = fullAddress.split(",");
    const codeState = addressArr[addressArr.length - 1];
    const subBurb = addressArr[addressArr.length - 2];
    const streetAddress = addressArr[0];

    return {
      streetAddress,
      lastAddress: `${subBurb}, ${codeState}`,
    };
  };

  const images = property.images.map((image) => image.sha);

  return (
    <View>
      <Text
        style={{
          fontSize: 20,
          fontWeight: 600,
          lightHeight: 30,
          color: MAIN_COLORS.TEXT_LIGHT,
          marginBottom: 22,
        }}
      >
        Add Property
      </Text>
      <View style={styles.titleModal}>
        <View style={styles.boxThumbnail}>
          <Carousel
            loop
            width={250}
            height={127}
            autoPlay={true}
            style={{ borderRadius: 8 }}
            data={images}
            scrollAnimationDuration={1000}
            pagingEnabled
            renderItem={({ index }) => (
              <Image
                style={{
                  height: "100%",
                  width: "100%",
                }}
                key={index}
                resizeMode="cover"
                source={`https://insights.proptrack.com/imagery/250x165/${images[index]}/image.jpg`}
              />
            )}
          />

          <Image
            style={styles.thumbnailIcon}
            source={require("../../../assets/portfolio-roadmap/house.png")}
          />
        </View>
        <View style={styles.boxInfoProperty}>
          <View style={{ gap: 8 }}>
            <Text
              style={{
                paddingRight: 33,
                fontSize: FONT_SIZE.large,
                fontWeight: FONT_WEIGHT.bold,
                flex: 1,
                color: MAIN_COLORS.BACKGROUND_WHITE,
              }}
            >
              {getLocation(property.address.fullAddress).streetAddress}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../../../assets/location.png")}
              />
              <Text
                style={{
                  fontSize: FONT_SIZE.medium,
                  color: MAIN_COLORS.G700,
                  fontWeight: FONT_WEIGHT.medium,
                }}
              >
                {getLocation(property.address.fullAddress).lastAddress}
              </Text>
            </View>
          </View>
          <View style={styles.flexRowBetween}>
            {property.attributes.bedrooms && (
              <View style={styles.sectionItemProperty}>
                <Image
                  style={{ width: 24, height: 19 }}
                  source={require("../../../assets/home/bed.svg")}
                />
                <Text style={styles.textItemProperty}>
                  {property.attributes.bedrooms || 0} Beds
                </Text>
              </View>
            )}
            <View style={styles.sectionItemProperty}>
              <Image
                style={{ width: 21, height: 21 }}
                source={require("../../../assets/home/bath.svg")}
              />
              <Text style={styles.textItemProperty}>
                {property.attributes.bathrooms || 0} Baths
              </Text>
            </View>
            <View style={styles.sectionItemProperty}>
              <Image
                style={{ width: 24, height: 24 }}
                source={require("../../../assets/home/car.svg")}
              />
              <Text style={styles.textItemProperty}>
                {property.attributes.carSpaces || 0} Cars
              </Text>
            </View>
            <View style={styles.sectionItemProperty}>
              <Image
                style={{ width: 24, height: 24 }}
                source={require("../../../assets/home/size.svg")}
              />
              <Text style={styles.textItemProperty}>
                {property.attributes.landArea || 0} m2
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

export default OverviewProperty;
