import { firstMasterLineObject, calcNewEquity } from "../../test/test";

export const firstSmallNumberFollowedByLarge = (value, numbers) => {
  return numbers.find(
    (number, index) =>
      number <= value &&
      index + 1 < numbers.length &&
      numbers[index + 1] > value
  );
};

export const findIndexFirstSmallNumberFollowedByLarge = (value, numbers) => {
  return numbers.findIndex(
    (number, index) =>
      number <= value &&
      index + 1 < numbers.length &&
      numbers[index + 1] > value
  );
};

export const calcMasterTimeLine = ({
  propertyPrice,
  rentPW,
  paymentPurchasePerMonth,
  debtOnProperty,
  availableEquity,
  months,
  growthPercent,
  rentPercent,
  deposit,
  interestRate,
  noOfMonth,
}) => {
  const currentMasterTimeLine = [];

  firstMasterLineObject(
    currentMasterTimeLine,
    propertyPrice,
    rentPW,
    paymentPurchasePerMonth,
    debtOnProperty,
    availableEquity,
    0,
    months,
    growthPercent,
    rentPercent,
    deposit,
    interestRate,
    noOfMonth
  );
  return currentMasterTimeLine;
};

export const updateWiwo = async (dataChanges, wiwo) => {
  return new Promise((resolve, reject) => {
    wiwo.push([
      "setData",
      "wiwo-begidubi",
      {
        data: dataChanges,
      },
      (e, result) => {
        if (result.success) {
          wiwo.push([
            "getData",
            "wiwo-begidubi",
            (e, result) => {
              if (result.success) {
                resolve(result.data);
              } else {
                reject(e);
              }
            },
          ]);
        } else {
          reject(e);
        }
      },
    ]);
  });
};
export const findAndCalcValueAndEquityTarget = (
  propertyPrice,
  valueAtTime,
  currentMasterTimeLine
) => {
  let closest = null;
  let closestIndex = -1;
  for (let index = 0; index < currentMasterTimeLine.length; index++) {
    let obj = currentMasterTimeLine[index];
    if (obj.value > valueAtTime) {
      closest = obj;
      closestIndex = index + 1;
      break;
    }
  }
  if (!closest) {
    return closest;
  }
  const newValueAtTime = closest?.value;
  const newEquityTarget = newValueAtTime - propertyPrice;
  return {
    newValueAtTime,
    newEquityTarget,
    closestIndex,
  };
};

export const reCalcMasterTimeline = ({ timeLine, rate, dataChange }) => {
  for (let index = 0; index < timeLine.length; index++) {
    if (dataChange?.interestRate && index >= dataChange?.indexMonth) {
      timeLine[index].interestPerMonth =
        (((timeLine[index].interestPerMonth * 12) / rate) *
          100 *
          dataChange?.interestRate) /
        12;

      timeLine[index].equityPullInterestPm =
        (((12 * timeLine[index].equityPullInterestPm) / 0.065) *
          dataChange?.interestRate) /
        12;

      timeLine[index].netCF =
        timeLine[index].grossCF -
        timeLine[index].grossCF * 0.25 -
        timeLine[index].interestPerMonth -
        timeLine[index].equityPullInterestPm;
    }
  }
  return timeLine;
};

export const getValueInput = (purchaseResult) => {
  return {
    valuesDeposit: purchaseResult?.valuesDeposit,
    livingExpenses: purchaseResult?.expensesPM,
    totalPrimaryIncome: purchaseResult?.salaryAtTime,
    recommendedBorrowing: purchaseResult?.recommendedBorrowing,
    interestPerMonth: purchaseResult?.interestPerMonth,
    years: purchaseResult?.years,
    income1: purchaseResult?.income1,
    income2: purchaseResult?.income2,
    widgetProperties: purchaseResult?.newWidgetProperty,
    totalSavingsAndEquity: purchaseResult?.totalSavingsAndEquity,
  };
};
