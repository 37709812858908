import moment from "moment";
import { BlurView } from "expo-blur";
import React, { memo, useState, useEffect } from "react";
import { View, StyleSheet, Text, Image, ActivityIndicator } from "react-native";

//Functions
import { MAIN_COLORS } from "../../../Utility/Colors";
import {
  formatDollar,
  TEST_STATE,
  TEST_SUBURB,
  TEST_SUBURB_REAL,
} from "../../../Utility/common";
import { getListMarketHistory } from "../../../Clickup/api";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

//Components
import { GrowthChart } from "./GrowthChart";
import LOADER from "../../../Utility/ComponentCard/Loader";
import ContactProperty from "../PropertyContacts/index.v2";
import DocumentProperty from "../PropertyDocuments/index";

const PortfolioItem = ({ portfolio, matchingOpportunity }) => {
  const [potentialValue, setPotentialValue] = useState(0);
  const [loadingValue, setLoadingValue] = useState(false);
  const [salesMedian, setSalesMedian] = useState([]);

  const [address, name] = portfolio?.Suburb.split(", ");

  const formatDate = (timestamp) => {
    return moment(timestamp).format("D MMMM YYYY");
  };

  useEffect(() => {
    const fetchMarketHistory = async () => {
      const core_suburb = portfolio?.core_suburb;
      const payload = {
        state: core_suburb === TEST_SUBURB ? TEST_STATE : portfolio?.state,
        property_suburb:
          core_suburb === TEST_SUBURB ? TEST_SUBURB_REAL : core_suburb,
        no_bed_room: portfolio.beds * 1,
        purchase_date: moment(portfolio.purchase_date * 1).format("DD-MM-YYYY"),
        purchase_price: portfolio.price * 1,
        id: portfolio.id,
      };

      setLoadingValue(true);
      const response = await getListMarketHistory(payload);
      setLoadingValue(false);

      if (response.status === 200) {
        // const potential_value =
        //   (portfolio.price / response.data.sales_median_12mths[0].avm_median) *
        //   response.data.sales_median_12mths[
        //     response.data.sales_median_12mths.length - 1
        //   ].avm_median;
        // const potential_value =
        //   response.data.sales_median_12mths[
        //     response.data.sales_median_12mths.length - 1
        //   ].avm_median;
        const purchasedAvm =
          response.data.sales_median_12mths[0].sales_median_12mths;
        const percentageAvm = payload["purchase_price"] / purchasedAvm;
        setSalesMedian(
          response.data.sales_median_12mths.map((item, index) => ({
            x:
              moment(item.end_date_tz).format("MM/YY") ||
              moment(item.end_date).format("MM/YY"),
            y:
              index === 0
                ? Number(payload.purchase_price)
                : item.sales_median_12mths * percentageAvm,
          }))
        );
        // setPotentialValue(potential_value);
      }
    };
    fetchMarketHistory();

    return () => {
      setSalesMedian([]);
      // setPotentialValue(0);
    };
  }, [portfolio]);

  return (
    <View style={styles.container}>
      <Text style={styles.name}>{address}</Text>
      <Text style={styles.text}>
        {name}, {portfolio?.state}
      </Text>
      <View style={styles.rowCenter}>
        <View style={styles.item}>
          <Image
            style={{
              width: "100%",
              height: "100%",
              transform: [{ scale: 2.8 }],
            }}
            resizeMode="contain"
            source={
              portfolio?.image
                ? { uri: portfolio?.image }
                : require("../../../assets/home/portfolio_image_default.png")
            }
          />
          <View style={styles.asset}>
            <View style={styles.assetItem}>
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../../../assets/home/bed.svg")}
              />
              <Text style={[styles.text, { color: MAIN_COLORS.TEXT_LIGHT }]}>
                {portfolio.beds} Beds
              </Text>
            </View>
            <View style={styles.assetItem}>
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../../../assets/home/bath.svg")}
              />
              <Text style={[styles.text, { color: MAIN_COLORS.TEXT_LIGHT }]}>
                {portfolio.baths} Baths
              </Text>
            </View>
            <View style={styles.assetItem}>
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../../../assets/home/car.svg")}
              />
              <Text style={[styles.text, { color: MAIN_COLORS.TEXT_LIGHT }]}>
                {portfolio.cars} Cars
              </Text>
            </View>
            <View style={styles.assetItem}>
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../../../assets/home/size.svg")}
              />
              <Text style={[styles.text, { color: MAIN_COLORS.TEXT_LIGHT }]}>
                {portfolio.area} m2
              </Text>
            </View>
          </View>

          <View style={styles.propertyType}>
            <BlurView
              intensity={3}
              style={{ flexDirection: "row", gap: 6, alignItems: "center" }}
            >
              <Image
                style={{ width: 18, height: 18 }}
                source={
                  portfolio.purchase_by
                    ? require("../../../assets/portfolio-roadmap/house_option2.png")
                    : require("../../../assets/portfolio-roadmap/house_option.png")
                }
              />
              <Text style={styles.propertyTypeText}>House</Text>
            </BlurView>
          </View>
        </View>

        {loadingValue ? (
          <View style={styles.item}>
            <View style={{ padding: 16, height: "100%", gap: 12 }}>
              <View style={{ gap: 8 }}>
                <Text style={styles.subName}>Equity Growth</Text>
                <View style={styles.rowCenterBetween}>
                  <Text style={styles.text}>Current Equity</Text>
                  <Text
                    style={[
                      styles.subName,
                      { color: MAIN_COLORS.PRIMARY_COLOR },
                    ]}
                  >
                    <Text
                      style={[
                        styles.value,
                        { color: MAIN_COLORS.PRIMARY_COLOR },
                      ]}
                    >
                      {portfolio.current_potential_value
                        ? formatDollar(
                            portfolio.current_potential_value -
                              portfolio.price * 1
                          )
                        : 0}
                    </Text>
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1, position: "relative" }}>
                <LOADER height="100%" />
              </View>
            </View>
          </View>
        ) : salesMedian.length > 0 ? (
          <View style={styles.item}>
            <View style={{ padding: 16, height: "100%", gap: 12 }}>
              <View style={{ gap: 8 }}>
                <Text style={styles.subName}>Equity Growth</Text>
                <View style={styles.rowCenterBetween}>
                  <Text style={styles.text}>Current Equity</Text>
                  <Text
                    style={[
                      styles.subName,
                      { color: MAIN_COLORS.PRIMARY_COLOR },
                    ]}
                  >
                    <Text
                      style={[
                        styles.value,
                        { color: MAIN_COLORS.PRIMARY_COLOR },
                      ]}
                    >
                      {portfolio.current_potential_value
                        ? formatDollar(
                            portfolio.current_potential_value -
                              portfolio.price * 1
                          )
                        : 0}
                    </Text>
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1, position: "relative" }}>
                <GrowthChart
                  width={"100%"}
                  height={"100%"}
                  result={salesMedian}
                />
              </View>
            </View>
          </View>
        ) : null}

        <View
          style={[
            styles.item,
            !loadingValue && salesMedian.length === 0 ? { flex: 2 } : {},
          ]}
        >
          <View style={{ padding: 16 }}>
            <Text style={[styles.subName, { marginBottom: 17 }]}>Property</Text>
            <View style={{ display: "flex", gap: 10 }}>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Purchase Date</Text>
                <Text
                  style={[styles.value, { color: MAIN_COLORS.PRIMARY_COLOR }]}
                >
                  {formatDate(portfolio?.purchase_date * 1)}
                </Text>
              </View>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Purchase Price</Text>
                <Text
                  style={[styles.value, { color: MAIN_COLORS.PRIMARY_COLOR }]}
                >
                  {formatDollar((portfolio.price * 1 ?? 0).toFixed(0))}
                </Text>
              </View>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Growth Since Purchase</Text>

                <Text
                  style={[styles.value, { color: MAIN_COLORS.PRIMARY_COLOR }]}
                >
                  {portfolio?.current_potential_value && portfolio?.price
                    ? (
                        ((portfolio.current_potential_value -
                          Number(portfolio.price)) *
                          100) /
                        Number(portfolio.price)
                      ).toFixed(2)
                    : 0}
                  %
                </Text>
              </View>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Current Potential Value</Text>

                <Text
                  style={[styles.value, { color: MAIN_COLORS.PRIMARY_COLOR }]}
                >
                  {formatDollar(
                    (portfolio.current_potential_value * 1 ?? 0).toFixed(0)
                  )}
                </Text>
              </View>

              <View style={styles.divider} />
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Rent Per Week</Text>
                <Text style={styles.value}>
                  {formatDollar((portfolio?.rent_per_week * 1 ?? 0).toFixed(0))}
                </Text>
              </View>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Gross Yield</Text>
                <Text style={styles.value}>
                  {portfolio?.gross_yield
                    ? `${Number(portfolio.gross_yield)?.toFixed(2)}%`
                    : "N/A"}
                </Text>
              </View>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Net Yield</Text>
                <Text style={styles.value}>
                  {portfolio?.net_yeild
                    ? `${Number(portfolio.net_yeild)?.toFixed(2)}%`
                    : "N/A"}
                </Text>
              </View>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Gross Cash Flow</Text>
                <Text style={styles.value}>
                  {formatDollar(
                    (portfolio?.gross_cash_flow * 1 ?? 0).toFixed(0)
                  )}
                </Text>
              </View>
              <View style={styles.rowCenterBetween}>
                <Text style={styles.text}>Net Cash Flow</Text>
                <Text style={styles.value}>
                  {formatDollar((portfolio?.net_cash_flow * 1 ?? 0).toFixed(0))}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {!portfolio.imported_properties && (
        <ContactProperty portfolio={portfolio} />
      )}
      {!portfolio.imported_properties && matchingOpportunity ? (
        <DocumentProperty opportunity={matchingOpportunity} />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    gap: 16,
    flex: 1,
    paddingHorizontal: 32,
    paddingVertical: 22,
    borderRadius: 0,
  },
  name: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: 18,
    lightHeight: 27,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  subName: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.large,
    lightHeight: 27,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  text: {
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.medium,
    lightHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  value: {
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    lightHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  rowCenter: {
    flexDirection: "row",
    gap: 16,
  },

  item: {
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#4B555D99",
    position: "relative",
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#4B555D99",
  },

  propertyType: {
    backgroundColor: "rgba(255,255,255,0.65)",
    position: "absolute",
    zIndex: 1,
    top: 16,
    left: 16,
    padding: 8,
    borderRadius: 6,
  },

  propertyTypeText: {
    fontSize: FONT_SIZE.medium,
    fontWeight: 500,
    lightHeight: 18,
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontFamily: MAIN_FONT.regular,
  },

  asset: {
    position: "absolute",
    bottom: 14,
    left: "50%",
    transform: [{ translateX: "-50%" }],
    flexDirection: "row",
    gap: 8,
    width: "max-content",
  },

  assetItem: {
    borderRadius: 5,
    padding: 6,
    flexDirection: "row",
    gap: 4,
    backgroundColor: "#19232BD9",
    alignItems: "center",
  },
});

export default memo(PortfolioItem);
