import React, { memo, useState } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";

import { MAIN_COLORS } from "../../../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

import ViewImage from "../../../components/ViewGallery";

const About = ({ property }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isExpandImage, setIsExpandImage] = useState(false);

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
        <View style={styles.list}>
          {property &&
            property.map((item, index) => {
              return (
                <TouchableOpacity
                  style={[
                    styles.item,
                    activeIndex === index && styles.itemActiveBorder,
                  ]}
                  onPress={() => setActiveIndex(index)}
                >
                  <View style={styles.itemHeader}>
                    <Text
                      style={[
                        styles.itemTitle,
                        activeIndex === index && styles.itemActiveTitle,
                      ]}
                    >
                      {item.title}
                    </Text>
                    <Image
                      style={styles.itemArrow}
                      source={
                        activeIndex === index
                          ? require("../../../assets/home/arrow_right.svg")
                          : require("../../../assets/home/arrow_down.svg")
                      }
                    />
                  </View>
                  {item.url && (
                    <View>
                      <Image
                        style={styles.itemImage}
                        source={{ uri: item.url }}
                      />
                    </View>
                  )}
                </TouchableOpacity>
              );
            })}
        </View>
      </ScrollView>

      <View style={styles.divider} />

      <View style={styles.detail}>
        <Text style={styles.detailTitle}>{property[activeIndex]?.title}</Text>
        <Text style={[styles.itemTitle, { color: MAIN_COLORS.PRIMARY_COLOR }]}>
          {property[activeIndex]?.description}
        </Text>
        {property[activeIndex]?.url && (
          <TouchableOpacity onPress={() => setIsExpandImage(true)}>
            <Image
              style={{ width: "100%", aspectRatio: 2 / 1, borderRadius: 6 }}
              source={{ uri: property[activeIndex]?.url }}
            />
          </TouchableOpacity>
        )}
        <Text style={styles.itemTitle}>
          {property[activeIndex]?.explanation}
        </Text>
      </View>
      <ViewImage open={isExpandImage} onClose={() => setIsExpandImage(false)}>
        <Image
          style={{ width: "100%", height: "100%" }}
          source={property[activeIndex].url}
        />
      </ViewImage>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    aspectRatio: 2 / 1,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    padding: 16,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },

  list: {
    flex: 1,
    display: "flex",
    gap: 16,
  },

  item: {
    padding: 12,
    borderRadius: 8,
    borderWidth: 1,
    display: "flex",
    gap: 9,
    borderColor: "#4B555D99",
  },

  itemHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  itemTitle: {
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  itemArrow: {
    width: 20,
    height: 20,
  },

  itemImage: {
    borderRadius: 6,
    width: "100%",
    aspectRatio: 3.8 / 1,
  },

  itemActiveBorder: {
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
  },

  itemActiveTitle: {
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  divider: {
    height: "100%",
    width: 1,
    backgroundColor: "#34393D",
  },

  detail: {
    flex: 1,
    display: "flex",
    gap: 8,
  },

  detailTitle: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lightHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
});

export default memo(About);
