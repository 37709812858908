import { StyleSheet } from "react-native";
import {
  MAIN_COLOR,
  MAIN_COLORS,
  TAB_BAR_COLOR,
} from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 16,
    borderWidth: 1,
    borderColor: "#4B555D99",
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    borderRadius: 10,
    flex: 1,
  },
  flatListContainer: {
    alignItems: "center",
  },
  yearItem: {
    marginHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textYear: {
    fontSize: 10,
    fontWeight: 400,
    color: MAIN_COLORS.G600,
  },
  selectedYearItem: {
    fontSize: 14,
    fontWeight: 600,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  wrapTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 27,
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
  wrapGross: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 8,
    flex: 1,
  },
  grossLabel: {
    fontWeight: 400,
    fontSize: 10,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.G600,
  },
  grossValue: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  netLabel: {
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontSize: 10,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
  },
  contentNet: {
    display: "flex",
    justifyContent: "flex-start",
    gap: 16,
    flex: 1,
  },
  wrapPrameter: {
    justifyContent: "space-between",
    flexDirection: "row",
    gap: 18,
  },
  wrapNet: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: MAIN_COLOR.yellow,
    borderRadius: 6,
    paddingHorizontal: 6,
    paddingVertical: 4,
    gap: 2,
    maxHeight: 46,
  },
  netValue: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLORS.BACKGROUND_MAIN,
  },
  divider: {
    width: 1,
    height: "100%",
    borderRadius: 1,
    backgroundColor: "rgba(173, 185, 199, 0.3)",
    marginHorizontal: 14,
  },
  track: {
    height: 14,
    borderRadius: 2,
    backgroundColor: "white",
    borderColor: "#9a9a9a",
    borderWidth: 1,
  },
  thumb: {
    width: 20,
    height: 20,
    borderRadius: 2,
    backgroundColor: "#eaeaea",
    borderColor: "#9a9a9a",
    borderWidth: 1,
  },
  slider: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    gap: 18,
  },
  // siderItem: {
  //   alignItems: "flex-start",
  // },
});
