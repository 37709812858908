import { StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";
import { MAIN_COLORS } from "../../../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexShrink: 1,
    zIndex: -1,
  },
  image: {
    height: 150,
    width: 150,
  },
  title: {
    fontSize: 20,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.bold,
    textAlign: "center",
    marginTop: 16,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 16,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
    textAlign: "center",
    marginTop: 8,
    fontWeight: 400,
  },
});
