import * as Yup from "yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import Toast from "react-native-toast-message";
import { usePermission } from "../../../../hooks/usePermission";

import styles from "./style";
import {
  addNewScenario,
  deleteScenario,
  getDetailsByScenarioId,
} from "../../../../Clickup/api";
import {
  setLoading,
  setScenarioSelected,
  setListPurchaseTarget,
} from "../../../../Reducer/portfolio-roadmap";

import ModalBase from "../../../../components/Modal";
import Input from "../../../../components/TextInput";
import Switch from "../../../../components/Switch";

import { MAIN_COLOR, MAIN_COLORS } from "../../../../Utility/Colors";
import { ORIGIN } from "../../../../Utility/common";

const SaveScenario = ({
  setDisabledClickOutside,
  userId,
  listScenarios,
  fetchData,
  setShowPopup,
  setAccessDenied,
}) => {
  const { scenarioSelected } = useSelector((state) => state.PORTFOLIO_ROADMAP);
  const dispatch = useDispatch();

  const schemaForm = Yup.object().shape({
    valueScenario: Yup.string()
      .required()
      .test((value) => value && value.trim().length > 0),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      valueScenario: "",
    },
    resolver: yupResolver(schemaForm),
  });

  const { isView } = usePermission("shareOther");

  const [scenario, setScenario] = useState(null);
  const [visibleAddScenarioModal, setVisibleAddScenarioModal] = useState(false);
  const [visibleRemoveScenarioModal, setVisibleRemoveScenarioModal] =
    useState(false);
  const [tab, setTab] = useState(() => {
    const isScenarios = listScenarios?.scenarios?.some(
      (item) => item?.id === scenarioSelected?.id
    );

    return isScenarios ? "scenarios" : "share_scenarios";
  });
  const [listOfScenario, setListOfScenario] = useState(
    tab === "scenarios" && isView
      ? listScenarios?.scenarios
      : listScenarios?.shared_scenarios
  );
  const [loadingAdd, setLoadingAdd] = useState(false);

  const handleSave = async ({ valueScenario }) => {
    if (valueScenario.trim() === "") return;
    try {
      setLoadingAdd(true);
      const payload = {
        name: valueScenario.trim(),
        user_id: userId,
        investing_timeline: scenarioSelected?.investingTimeLine,
        year_of_result: scenarioSelected?.yearsOfResult,
        cash_savings: scenarioSelected?.cashSavings,
        available_equity: scenarioSelected?.availableEquity,
        widget_properties: JSON.stringify(scenarioSelected?.widgetProperties),
        custom_income: JSON.stringify(scenarioSelected?.customIncomes),
        total_gross_income: scenarioSelected?.totalGrossIncome || 0,
        total_primary_income: scenarioSelected?.totalPrimaryIncome || 0,
        purchase_target:
          scenarioSelected?.purchaseTargets?.map((item) => ({
            target_price: Math.round(item.valueAtTime),
            target_ren_pw: Math.round(item.targetRentPW),
            type_properties: item.incomeType,
            living_expenses_pm: Math.round(item.expensesPM),
            total_income: Math.round(item.totalIncome),
            deposit_size: Math.round(item.rateSelected),
            cash_contribution: Math.round(item.cashContribute),
            loan_amount: Math.round(item.loanAmount),
            interest_rate: 6.5,
            loan_type: "io",
            type_properties: "house",
            income_type: item.incomeType.toLowerCase(),
            no_of_month: item.noOfMonth,
            recommended_borrowing: Math.round(item.recommendedBorrowing),
            years: item.years,
            interest_per_month: Math.round(item.interestPerMonth),
            property_price: Math.round(item.propertyPrice),
            equity_from_purchase: Math.round(item.equityFromPurchase),
            rent_pw_time_line: Math.round(item.rentPW),
            purchase_date: item.purchaseDate,
            dependant: item.dependant,
            income_1: Math.round(item?.income1),
            income_2: Math.round(item?.income2),
            deposit: Math.round(item?.deposit),
            values_deposit: item?.valuesDeposit,
            total_saving_and_equity: Math.round(item?.totalSavingsAndEquity),
            new_widget_property: item?.newWidgetProperty
              ? JSON.stringify(item?.newWidgetProperty)
              : null,
            salary_at_time: item?.salaryAtTime
              ? Math.round(item?.salaryAtTime)
              : null,
          })) || [],
      };
      await addNewScenario(payload);
      Toast.show({
        type: "success",
        text1: "Added Scenario Successfully!",
        position: "top",
        visibilityTime: 3000,
      });
      window.history.replaceState({}, "", ORIGIN);
      fetchData();
      dispatch(setListPurchaseTarget(scenarioSelected?.purchaseTargets));
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    }

    setVisibleAddScenarioModal(false);
    setLoadingAdd(false);
    reset();
  };
  const handleScenarioSelect = async (scenario) => {
    if (scenario.id === scenarioSelected?.id) return;
    try {
      let scenarioType = "scenarios";
      setAccessDenied(false);
      dispatch(setLoading(true));
      const response = await getDetailsByScenarioId(scenario.id, userId);

      if (response?.status === false) {
        throw new Error("You do not have permission to view this scenario");
      }

      if (
        listOfScenario.some((item) => item.id === response.id) &&
        tab === "share_scenarios"
      ) {
        scenarioType = "share_scenarios";
      }
      dispatch(setScenarioSelected({ type: scenarioType, ...response }));
      dispatch(setListPurchaseTarget(response.purchaseTargets));
      const params = new URLSearchParams(window.location.search);
      params.set("scenario_id", scenario.id);
      window.history.replaceState({}, "", `scenario-detail?${params}`);
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRemove = async () => {
    try {
      dispatch(setLoading(true));
      await deleteScenario(scenario.id, userId);
      Toast.show({
        type: "success",
        text1: "Removed Scenario Successfully!",
        position: "top",
        visibilityTime: 3000,
      });
      window.history.replaceState({}, "", ORIGIN);
      fetchData();
      setShowPopup(false);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
      setVisibleRemoveScenarioModal(false);
    }
  };

  const handleSetTab = (value) => {
    setTab(value);
    setListOfScenario(
      value === "scenarios"
        ? listScenarios?.scenarios
        : listScenarios?.shared_scenarios
    );
  };

  useEffect(() => {
    const value = visibleAddScenarioModal || visibleRemoveScenarioModal;
    setDisabledClickOutside(value);
  }, [visibleAddScenarioModal, visibleRemoveScenarioModal]);

  return (
    <>
      {isView && (
        <Switch
          styleContainer={{ height: "auto", marginBottom: 22 }}
          selectionMode={tab}
          label1="My scenarios"
          label2="Shared by others"
          option1={"scenarios"}
          option2={"share_scenarios"}
          onSelectSwitch={handleSetTab}
          selectionColor={MAIN_COLOR.yellow}
        />
      )}
      <View style={styles.container}>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.scenarioList}
        >
          {listOfScenario?.length &&
            listOfScenario.map((item, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => handleScenarioSelect(item)}
                style={[
                  styles.item,
                  item.id === scenarioSelected?.id && {
                    backgroundColor: "#7D888F1A",
                  },
                ]}
              >
                <View style={styles.flexRow}>
                  <Image
                    resizeMode="contain"
                    style={{ width: 30, height: 30, margin: "auto" }}
                    source={require("../../../../assets/portfolio-roadmap/status-up.svg")}
                  />
                  <View>
                    <Text style={styles.name} numberOfLines={1}>
                      {item.name}
                    </Text>
                    <Text style={styles.time}>
                      {moment(item.created_date).format("h.mm A • DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
                {tab === "scenarios" && isView && (
                  <TouchableOpacity
                    style={[styles.wrapIcon, { width: 32, height: 32 }]}
                    onPress={() => {
                      setScenario(item);
                      setVisibleRemoveScenarioModal(true);
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{ width: 16, height: 16, margin: "auto" }}
                      source={require("../../../../assets/portfolio-roadmap/trash.svg")}
                    />
                  </TouchableOpacity>
                )}
              </TouchableOpacity>
            ))}
        </ScrollView>
        {isView && tab === "scenarios" && (
          <div id="btn_modal_add_scenario">
            <TouchableOpacity
              style={styles.btnAdd}
              onPress={() => setVisibleAddScenarioModal(true)}
            >
              <Image
                resizeMode="contain"
                style={{ width: 24, height: 24 }}
                source={require("../../../../assets/portfolio-roadmap/archive-add.png")}
              />
              <Text style={styles.btnText}>Add Scenario</Text>
            </TouchableOpacity>
          </div>
        )}
      </View>
      <ModalBase
        open={visibleAddScenarioModal}
        title="Add Scenario"
        onClose={() => setVisibleAddScenarioModal(false)}
        btnCancel="Cancel"
        btnSave="Save"
        size="small"
        onSave={handleSubmit(handleSave)}
        disableSave={
          !isValid ||
          loadingAdd ||
          (!!listScenarios?.length &&
            listScenarios?.some(
              (scenario) => scenario.name === watch("valueScenario").trim()
            ))
        }
        styleModal={{ padding: 18, borderRadius: 12 }}
        styleBtnClose={{
          width: 28,
          height: 28,
          borderRadius: 5,
          padding: 4,
          backgroundColor: "#7D888F26",
        }}
        sizeBtnClose={{ width: 10, height: 10 }}
        closePrimary={true}
      >
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <div id="input_name_scenario">
              <Input
                label="Scenario Name"
                value={value}
                placeholder="Enter type"
                onChangeText={onChange}
              />
            </div>
          )}
          name="valueScenario"
        />
      </ModalBase>
      {visibleRemoveScenarioModal && (
        <ModalBase
          open={visibleRemoveScenarioModal}
          title="Remove Scenario"
          onClose={() => setVisibleRemoveScenarioModal(false)}
          btnCancel="Cancel"
          btnSave="Remove"
          size="small"
          onSave={handleRemove}
        >
          <Text
            style={{ fontSize: 16, fontWeight: 400, color: MAIN_COLORS.G600 }}
          >
            Are you sure you want to remove {scenario?.name}?
          </Text>
        </ModalBase>
      )}
    </>
  );
};

export default SaveScenario;
