import { StyleSheet } from "react-native";
import {
  MAIN_COLOR,
  MAIN_COLORS,
  TAB_BAR_COLOR,
} from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";
var styles;

export default styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  header: {
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    zIndex: 999,
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 18,
    color: "#FFFFFF",
    fontFamily: MAIN_FONT.regular,
    paddingBottom: 3,
  },
  type: {
    padding: 10,
    backgroundColor: "#7D888F1A",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
  },
  titleType: {
    fontSize: 14,
    fontWeight: 500,
    color: "#292929",
    fontFamily: MAIN_FONT.regular,
    fontWeight: "bold",
  },
  iconType: {
    width: 40,
    height: 40,
  },
  info: {
    margin: 12,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    zIndex: -1,
    overflow: "hidden",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },
  rowValue: {
    fontSize: 14,
    fontWeight: 600,
    color: "#FFFFFF",
    fontFamily: MAIN_FONT.semi,
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: "#ADB9C74D",
    zIndex: -1,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
  },
  timeLabel: {
    fontSize: 14,
    fontWeight: 500,
    color: "#303A43",
    fontFamily: MAIN_FONT.regular,
  },
  time: {
    fontSize: 12,
    fontWeight: 400,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },
  btnEdit: {
    width: 40,
    height: 40,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR,
  },
  btnView: {
    width: 24,
    height: 24,
    borderRadius: 5,
    borderColor: "#ADB9C74D",
    top: -11,
    margin: "auto",
    left: 0,
    right: 0,
    position: "absolute",
    borderWidth: 1,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
  },
  tippyContainer: {
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 5,
    padding: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 4,
    display: "flex",
    gap: 16,
    borderWidth: 1,
    borderColor: MAIN_COLORS.BORDER_COLOR,
    borderStyle: "solid",
  },

  tippyItem: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  tippyDriver: {
    width: "100%",
    height: 1,
    backgroundColor: "#ADB9C74D",
  },
  tippyTitle: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.TEXT_LIGHT,
  },

  content: {
    zIndex: 2,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "#4B555D99",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
  },

  active: {
    shadowColor: "#FCCE15",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 30,
    elevation: 10,
    borderWidth: 1,
    borderColor: "#D3B10080",
  },
});
