import React from "react";
import {
  View,
  Text
} from "react-native";

// static
import { MAIN_COLOR } from "../../../Utility/Colors";
// style
import styles from "./stylev2";

export class CheckListItem extends React.Component {

  render() {
    const { itemContainer, groupRadio, groupRadioNonChecked, textName, circleGroupRadio } = styles;
    const { item } = this.props;
    
    return (
      <View style={itemContainer}>
        {item?.resolved ? (
          <View style={groupRadio}>
            <View style={circleGroupRadio}></View>
          </View>
        ) : (
          <View style={groupRadioNonChecked}></View>
        )}

        <Text style={[textName, { color: item?.resolved ? "#FFFFFF" : MAIN_COLOR.G600 }]}>
          {item?.name ?? ""}
        </Text>
      </View>
    );
  }
}