import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { MAIN_COLOR } from "../../Colors";
import { MAIN_FONT } from "../../Fonts/font";
import { Linking } from "react-native";

const ContactCard = ({
  type = "Legal Partner",
  name,
  phoneNumber,
  email,
  employeeAvatar,
  onCopyPhone,
  backgroundColor,
  styles
}) => {
  const getDefaultAvatar = (type) => {
    switch (type) {
      case "Legal Partner":
        return require("../../../assets/default-avatar-employee.svg");
      case "Financial Partner":
        return require("../../../assets/default-avatar-employee.svg");
      case "Property Manager":
        return require("../../../assets/default-avatar-employee.svg");
      case "Building and Pest Inspector":
        return require("../../../assets/default-avatar-employee.svg");
      case "Quantity Surveyor":
        return require("../../../assets/default-avatar-employee.svg");
      default:
        return require("../../../assets/default-avatar-employee.svg");
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return;

    const cleanedPhone = phoneNumber
      .replace(/\s/g, "")
      .replace(/\b0+/g, "")
      .replace(/^\+61\s*/, "");
    if (cleanedPhone.length === 9) {
      return cleanedPhone.replace(/^(\d)(\d{4})(\d{4})$/, "+61 $1 $2 $3");
    }

    return cleanedPhone.replace(/^(\d{4})(\d{3})(\d{3})$/, "+61 $1 $2 $3");
  };

  const onCall = () => {
    // Linking.openURL(`tel:${phoneNumber}`);
    navigator.clipboard.writeText(formatPhoneNumber(phoneNumber));
    onCopyPhone();
  };

  const onEmail = () => {
    Linking.openURL(`mailto:${email}`);
  };

  return (
    <View
      style={{
        padding: 12,
        backgroundColor: backgroundColor ?? MAIN_COLOR.BACKGROUND_COLOR_MAIN,
        borderRadius: 6,
        ...styles,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flexDirection: "row", marginRight: 12 }}>
          <Image
            source={employeeAvatar ? { uri: employeeAvatar } : getDefaultAvatar(type)}
            resizeMode="cover"
            style={{
              height: 46,
              width: 46,
              borderRadius: 6,
              marginRight: 12
            }}
          />
          <View>
            <Text
              style={{
                fontSize: 14,
                color: "#FFFFFF",
                fontFamily: MAIN_FONT.semi,
                marginBottom: 4,
              }}
            >
              {name}
            </Text>
            <Text
              style={{
                fontSize: 14,
                color: MAIN_COLOR.G600,
                fontFamily: MAIN_FONT.regular,
              }}
            >
              {type}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF26",
              borderRadius: 77,
              padding: 4,
              width: 32,
              height: 32,
              marginRight: 12,
            }}
            onPress={onEmail}
            disabled={!email}
          >
            <Image
              style={{
                resizeMode: "contain",
                width: 16,
                height: 16,
              }}
              source={require("../../../assets/white-sms.png")}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#D3B10040",
              borderRadius: 77,
              padding: 4,
              width: 32,
              height: 32,
            }}
            onPress={onCall}
            disabled={!phoneNumber}
          >
            <Image
              style={{
                resizeMode: "contain",
                width: 16,
                height: 16,
              }}
              source={require("../../../assets/yellow-call.svg")}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default ContactCard;
