import { Picker } from "@react-native-picker/picker";

import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { MAIN_COLORS } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

const SelectBase = ({ label, required, options = [], onChange, ...props }) => {
  return (
    <View style={styles.container}>
      {label && (
        <Text style={styles.label}>
          {label}
          {required && <Text style={{ color: MAIN_COLORS.TEXT_GRAY }}>*</Text>}
        </Text>
      )}
      <Picker
        style={styles.selectStyle}
        selectionColor={MAIN_COLORS.PRIMARY_COLOR}
        dropdownIconColor={MAIN_COLORS.PRIMARY_COLOR}
        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
        {...props}
      >
        {options.map((one, index) => {
          return (
            <Picker.Item key={index} label={one.label} value={one.value} />
          );
        })}
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 0,
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 20,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  selectStyle: {
    borderWidth: 1,
    borderColor: "rgba(173, 185, 199, 0.3)",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 14,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: MAIN_FONT.regular,
    height: 46,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
});
export { styles };
export default SelectBase;
