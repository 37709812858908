import React, { memo, useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  Linking,
  Dimensions,
} from "react-native";

//Functions
import { MAIN_COLORS } from "../../Utility/Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Utility/Fonts/font";
import { getPresentation, getContactByEmail } from "../../Clickup/api";

//Components
import Overivew from "./components/Overview";
import SpecificData from "./components/SpecificData";
import SuburbInsight from "./components/SuburbInsight";
import LOADER from "../../Utility/ComponentCard/Loader";
import PropertyFormatData from "./components/PropertyFormatData";
import { TouchableOpacity } from "react-native";
import {
  TEST_STATE,
  TEST_SUBURB,
  TEST_SUBURB_REAL,
} from "../../Utility/common";

const { height: screenHeight } = Dimensions.get("window");
const Presentation = ({ market, deal, assignee }) => {
  const [projects, setProjects] = useState([]);
  const [presentation, setPersentation] = useState([]);
  const [economic_drivers, setEconomic_drivers] = useState([]);
  const [buyingTeamPhoto, setBuyingTeamPhoto] = useState(null);

  const market_name = deal?.["Markets"]?.[0]?.name?.trim();
  const body_market = {
    bedrooms: deal?.bedrooms,
    ssc_name: market_name === TEST_SUBURB ? TEST_SUBURB_REAL : market_name,
    state: market_name === TEST_SUBURB ? TEST_STATE : deal?.["PROPERTY STATE"],
  };

  const handleRefresh = async () => {
    getPresentation(market.id).then((result) => {
      if (result) {
        const object = result?.custom_fields?.reduce(
          (obj, item) => Object.assign(obj, { [item.name]: item.value }),
          {}
        );

        result["custom_fields"] = null;

        const presentation = { ...result, ...object };

        let Images_Economic_Driver = Object.keys(presentation).filter((item) =>
          item.includes("Economic Drivers (Image")
        );

        let economic_drivers = [];

        Images_Economic_Driver.map((item, index) => {
          index = index + 1;

          if (
            presentation[`Economic Drivers (Image - ${index})`] &&
            presentation[`Economic Drivers (Description - ${index})`] &&
            presentation[`Economic Drivers (URL - ${index})`] &&
            presentation[`Economic Drivers (Title - ${index})`]
          ) {
            economic_drivers.push({
              image: presentation[`Economic Drivers (Image - ${index})`],
              description:
                presentation[`Economic Drivers (Description - ${index})`],
              url: presentation[`Economic Drivers (URL - ${index})`],
              title: presentation[`Economic Drivers (Title - ${index})`],
            });
          }
        });

        let Images_Project = Object.keys(presentation).filter((item, index) =>
          item.includes("Projects (Image")
        );

        let projects = [];

        Images_Project.map((item, index) => {
          if (
            presentation[`Projects (Image - ${index})`] &&
            presentation[`Projects (Description - ${index})`] &&
            presentation[`Projects (URL - ${index})`] &&
            presentation[`Projects (Title - ${index})`]
          ) {
            projects.push({
              image: presentation[`Projects (Image - ${index})`],
              description: presentation[`Projects (Description - ${index})`],
              url: presentation[`Projects (URL - ${index})`],
              title: presentation[`Projects (Title - ${index})`],
            });
          }
        });

        setProjects(projects);
        setPersentation(presentation);
        setEconomic_drivers(economic_drivers);
      }
    });
  };

  const openUrl = (url) => {
    let reg =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    if (!!reg.test(url)) {
      Linking.openURL(url);
    }
  };

  useEffect(() => {
    handleRefresh();
    if (assignee) {
      getContactByEmail(assignee.email).then((result) => {
        const tasks = result?.tasks;
        if (tasks?.length) {
          const custom_fields = tasks[0].custom_fields;
          const contactPhoto = custom_fields.find(
            (field) => field.name === "Buying Team Photo"
          )?.value[0].url;
          setBuyingTeamPhoto(contactPhoto);
        }
      });
    }
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Market Selection</Text>
      <Overivew presentation={presentation} />
      <SuburbInsight body_market={body_market} deal={deal} />
      <View>
        <Text style={[styles.subTitleSection, { marginBottom: 16 }]}>
          Property Format Purchase Data
        </Text>
        <PropertyFormatData body_market={body_market} />
      </View>

      <SpecificData body_market={body_market} />

      <View>
        <Text style={[styles.subTitleSection, { marginBottom: 16 }]}>
          Economic Drivers & Infrastructure Projects
        </Text>
        <View style={styles.marketOverview}>
          {economic_drivers.length &&
            economic_drivers.map((item, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => openUrl(item.url)}
                activeOpacity={1}
                style={styles.marketOverviewItem}
              >
                <Image
                  style={styles.marketOverviewImage}
                  source={{ uri: item.image }}
                />
                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <View>
                    <Text
                      style={[
                        styles.introduceDescSubTitle,
                        { color: MAIN_COLORS.TEXT_LIGHT },
                      ]}
                    >
                      {item.title}
                    </Text>
                    <Text style={styles.introduceDescSubTitle}>
                      {item.description}
                    </Text>
                  </View>
                  <View style={{ alignSelf: "flex-end" }}>
                    <Text
                      style={{
                        textDecorationLine: "underline",
                        fontSize: 14,
                        fontFamily: MAIN_FONT.regular,
                        color: MAIN_COLORS.PRIMARY_COLOR,
                      }}
                    >
                      View
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            ))}
        </View>
      </View>
      {!Object.keys(presentation).length ? <LOADER /> : null}
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    display: "flex",
    gap: 22,
  },
  title: {
    fontSize: 20,
    lineHeight: 30,
    fontWeight: FONT_WEIGHT.bold,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  subTitle: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    marginBottom: 16,
    fontFamily: MAIN_FONT.semi,
  },

  introduce: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    maxHeight: 353,
  },

  introduceImage: {
    flex: 1,
    height: "100%",
    borderRadius: 12,
  },

  introduceDesc: {
    flex: 1,
    display: "flex",
    gap: 17,
    height: "100%",
    overflow: "hidden",
  },

  introduceDescSubTitle: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  boxSection: {
    padding: 16,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#4B555D99",
    flex: 1,
    maxHeight: 350,
    overflow: "hidden",
  },

  marketOverview: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    flexWrap: "wrap",
  },

  marketOverviewItem: {
    flexDirection: "row",
    borderRadius: 10,
    padding: 12,
    gap: 8,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    width: "calc(50% - 8px)",
  },

  marketOverviewImage: {
    width: 60,
    height: 60,
    borderRadius: 6,
  },

  textSection: {
    fontSize: FONT_SIZE.small,
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },

  valueSection: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  subTitleSection: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
    textTransform: "capitalize",
  },

  rowCenterBetween: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  btnClause: {
    borderWidth: 1,
    borderColor: "#4B555D99",
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderRadius: 6,
  },
  btnClauseAvtive: {
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
    borderRadius: 6,
  },

  seeMoreContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    height: 18,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    paddingBottom: 16,
  },

  seeMoreText: {
    fontWeight: 500,
    fontSize: FONT_SIZE.small,
    lightHeight: 18,
    color: MAIN_COLORS.PRIMARY_COLOR,
    textDecorationLine: "underline",
    fontFamily: MAIN_FONT.regular,
  },

  footer: {
    position: "absolute",
    bottom: 6,
    left: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transperant",
    height: 80,
  },

  modelDetail: {
    width: 667,
    borderWidth: 0,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    padding: 24,
    display: "flex",
    height: "max-content",
    aspectRatio: "none",
    maxHeight: screenHeight * 0.8,
  },

  renderMarkup: {
    fontWeight: "400",
    fontSize: FONT_SIZE.medium,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 21,
    color: MAIN_COLORS.G600,
    textDecorationLine: "none",
  },
});

export default memo(Presentation);
