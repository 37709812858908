import { StyleSheet } from "react-native";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    height: 102,
    width: "100%",
    paddingHorizontal: 16,
    paddingVertical: 35,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: MAIN_COLORS.BACKGROUND_MAIN,
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 8,
  },
  text: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLOR.BACKGROUND_COLOR,
  },
  arrowDown: {
    position: "absolute",
    right: 16,
    width: 14,
    height: 7,
  },
  blurView: {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
});
