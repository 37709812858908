import { sumBy } from "lodash";
import moment from "moment";
import { calcStampDuty } from "./StampDuty/stamp-duty-utils";

export const calcProfileBar = (purchased, scenarioSelected, listPurchases) => {
  const totalNetCF = sumBy(purchased, "Annual Net Cashflow");
  const totalDepositAmount = sumBy(purchased, "Deposit Amount");
  const totalGrossCash = sumBy(purchased, "Annual Gross Income");
  const totalPurchasePrice = sumBy(purchased, (item) =>
    item["Purchase Price"] ? Number(item["Purchase Price"]) : 0
  );

  // const totalStampDuty = sumBy(
  //   purchased,
  //   (item) => item["Stamp Duty"] && Number(item["Stamp Duty"])
  // );
  const totalStampDuty = sumBy(purchased, (item) =>
    item["Purchase Price"] && item["PROPERTY STATE"]
      ? calcStampDuty(Number(item[["Purchase Price"]]), item["PROPERTY STATE"])
      : 0
  );
  const totalBuildAndPest = sumBy(purchased, (item) =>
    item["Building & Pest"] ? Number(item["Building & Pest"]) : 0
  );
  const totalSolicitor = sumBy(purchased, (item) =>
    item["Solicitors Fees"] ? Number(item["Solicitors Fees"]) : 0
  );
  const totalBankAppFees = sumBy(purchased, (item) =>
    item["Bank APP. Fee"] ? Number(item["Bank APP. Fee"]) : 0
  );
  const totalPotentialEquity = sumBy(purchased, (item) =>
    item["Potential Equity"] ? Number(item["Potential Equity"]) : 0
  );
  const totalPotentialValue = sumBy(purchased, (item) =>
    item["Current Potential Value"]
      ? Number(item["Current Potential Value"])
      : 0
  );

  const netRetailYet = totalPurchasePrice
    ? ((totalNetCF / totalPurchasePrice) * 100)?.toFixed(2)
    : 0;
  const grossRetailYet =
    totalPurchasePrice !== 0
      ? ((totalGrossCash / totalPurchasePrice) * 100)?.toFixed(2)
      : 0;
  const netIncomeTarget = totalNetCF?.toFixed(2);
  const grossRetailIncome = totalGrossCash?.toFixed(2);
  const approxPortfolioValue = totalPotentialValue?.toFixed(2);
  const currentROI =
    totalDepositAmount +
      totalStampDuty +
      totalBuildAndPest +
      totalSolicitor +
      totalBankAppFees !==
    0
      ? (
          (totalPotentialEquity /
            (totalDepositAmount +
              totalStampDuty +
              totalBuildAndPest +
              totalSolicitor +
              totalBankAppFees)) *
          100
        )?.toFixed(2)
      : 0;

  const approxTimeTarget = scenarioSelected?.investingTimeLine * 12;

  const valueProfileBar = {
    netRetailYet,
    grossRetailYet,
    netIncomeTarget,
    grossRetailIncome,
    approxPortfolioValue,
    currentROI,
    approxTimeTarget,
  };

  return scenarioSelected
    ? valueProfileBar
    : {
        netRetailYet: 0,
        grossRetailYet: 0,
        netIncomeTarget: 0,
        grossRetailIncome: 0,
        approxPortfolioValue: 0,
        currentROI: 0,
        approxTimeTarget: 0,
      };
};
