import { StyleSheet } from "react-native";

import { MAIN_FONT } from "../../Utility/Fonts/font";
import { MAIN_COLOR, MAIN_HEADER } from "../../Utility/Colors";

var styles;

export default styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    marginBottom: 20
  },

  sidebar: {
    width: 380,
    display: "flex",
    gap: 22,
  },

  content: {
    flex: 1,
  },
});
