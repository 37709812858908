import {
  StyleSheet,
} from 'react-native';

import { MAIN_FONT } from "../../../Utility/Fonts/font";
import { MAIN_COLOR } from "../../../Utility/Colors"

const styles = StyleSheet.create({
  itemContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: 16,
  },
  groupRadio: {
    position: "relative",
    width: 20,
    height: 20,
    borderRadius: "50%",
    borderWidth: 2,
    borderColor: "#D3B100",
  },
  circleGroupRadio: {
    position: "absolute",
    top: 3,
    left: 3,
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#D3B100",
  },
  groupRadioNonChecked: {
    flexDirection: "row",
    width: 20,
    height: 20,
    marginRight: 12,
    borderRadius: "50%",
    borderWidth: 2,
    borderColor: MAIN_COLOR.G600,
  },
  textName: {
    fontSize: 14,
    color: MAIN_COLOR.G600,
    fontFamily: MAIN_FONT.regular,
  },
});

export default styles;
