import { StyleSheet } from "react-native";
import { MAIN_COLOR, MAIN_COLORS } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    padding: 16,
    borderWidth: 1,
    borderColor: "#4B555D99",
    backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
    borderRadius: 10,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: MAIN_FONT.semi,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
  currentLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: MAIN_COLORS.G600,
    fontFamily: MAIN_FONT.regular,
  },
  currentValue: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.PRIMARY_COLOR,
  },
  round: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    marginRight: 12,
  },
  textLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: MAIN_COLOR.G800,
    fontFamily: MAIN_FONT.regular,
  },
});
