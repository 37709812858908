import {
  budgetOptionsStep,
  assumptionsAtTargetStep,
  findEquityAllowanceStep,
} from "./build-roadmap";
import { getValueInput, calcMasterTimeLine } from "./utility-portfolio-roadmap";
import { cloneDeep, forEach, omit, sumBy } from "lodash";
import moment from "moment";

export const MAX_RECOMMENDED_BORROWING = 520000;
export const MIN_RECOMMENDED_BORROWING = 300000;

export const INCOME_TYPE = {
  SINGLE: "single",
  COUPLE: "couple",
};

export const LOAN_TYPE = {
  IO: "io",
  PI: "pi",
};

export const OPTIONS_LOAN_STRUCTURE = [
  { label: "Interest Only", value: 1 },
  { label: "Principal & Interest", value: 2 },
];

export const OPTIONS_LOAN_TYPE = {
  [LOAN_TYPE.IO]: { label: "I/O", value: LOAN_TYPE.IO },
  [LOAN_TYPE.PI]: { label: "P&I", value: LOAN_TYPE.PI },
};

export const filterDataDetailProperty = (data) => {
  if (Array.isArray(data) && !data.length) return null;

  if (!Object.keys(data).length) return null;

  if (data.listings && !data.listings.length) return null;

  const _data = Array.isArray(data) ? data : data.listings;

  const itemValid = _data[0];

  for (let i = 1; i < _data.length; i++) {
    if (new Date(_data[i].saleDate) >= new Date(itemValid.saleDate))
      itemValid = _data[i];
  }

  return itemValid;
};

export const calcBuyingStructure = async (
  values,
  wiwo,
  newData,
  dataRedux,
  newDataRedux,
  listPurchaseTarget
) => {
  let { cashSavings, availableEquity, years, customIncomes } = values;
  let widgetProperties = cloneDeep(values.widgetProperties);

  let totalSavingsAndEquity = cashSavings + availableEquity;

  // Get data from widget
  let recommendedBorrowing =
    widgetProperties.dataList[0].input.bpResult.borrowingPowerMaximum;
  let livingExpenses =
    widgetProperties.dataList[0].input.bpResult.expenseResult
      .livingExpenseCustomerMonthly;
  let interestPerMonth =
    widgetProperties.dataList[1].output.repaymentResultModel.repaymentList[0]
      .paymentDisplayRounded;

  let incomeType =
    widgetProperties.dataList[0].input.borrowingRequestModel.applicationType ===
    INCOME_TYPE.SINGLE
      ? "single"
      : "couple";

  const wiwoBorrowingPower = widgetProperties?.dataList.find(
    (item) => item.id === "wiwo-borrowing-power"
  );

  const incomes =
    wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
      (one) => one.incomeList
    );

  // calc total primary incomes
  const primaryIncomes = incomes.map((incomeList) =>
    incomeList.find((income) => income.id === "primary")
  );

  let income1 =
    incomeType === INCOME_TYPE.COUPLE ? primaryIncomes[0].income : null;
  let income2 =
    incomeType === INCOME_TYPE.COUPLE ? primaryIncomes[1].income : null;
  let totalPrimaryIncome = sumBy(primaryIncomes, "income");

  const listPurchase = [];
  let masterTimeLines = [];
  if (newDataRedux?.length && newData) {
    const newDataFromRedux = newDataRedux.filter(
      (item) => item.indexChange === newData?.indexChange
    );
    if (newDataFromRedux?.length) {
      newData = Object.assign(newDataFromRedux[0], newData);
    }
  }
  years = newData?.years ? newData?.years : years;
  //Build First Purchase Target
  let valueInputAssumptionsAtTargetStep = null;
  recommendedBorrowing =
    recommendedBorrowing <= MAX_RECOMMENDED_BORROWING
      ? recommendedBorrowing
      : MAX_RECOMMENDED_BORROWING;

  if (newData?.dependant && listPurchase.length === newData?.indexChange) {
    widgetProperties.dataList[0].input.borrowingRequestModel.dependants =
      newData.dependant;
  }

  if (newData?.interestRate && listPurchase.length === newData?.indexChange) {
    widgetProperties.dataList[1].input.repaymentModel.interestRate =
      newData.interestRate / 100;
  }
  let flag = false; //flag used to check the first purchase to set the default depositSize to 20% from the 2nd purchase onwards
  //checkFirstWidget to check the first time you push the widget
  //so that from the second purchase, loanAmount will add 2 values ​​cashContribution and equity
  // Step 2: Budget Options
  let valuesDeposit = budgetOptionsStep({
    recommendedBorrowing,
    totalSavingsAndEquity,
    dataChange: newData,
    listPurchase,
    flag,
  });
  if (!valuesDeposit) return;
  listPurchase.push({
    noOfMonth: 0,
    equityTarget: 0,
    valueAtTime: 0,
    salaryAtTime: incomeType === INCOME_TYPE.SINGLE ? totalPrimaryIncome : 0,
    expensesPM:
      widgetProperties.dataList[0].input.borrowingRequestModel.households[0]
        .livingExpenseModel.livingExpenseMonthly,
    rentTracks: null,
    newWidgetProperty: widgetProperties,
    deposit: valuesDeposit?.deposit,
    // Add/Edit cashContribution
    cashContribute: null,
    equityFromPurchase: 0,
    cashAndEquityForNextPurchase: null,
    totalIncome: totalPrimaryIncome,
    incomeType: incomeType,
    loanAmount: valuesDeposit?.debtOnProperty,
    primaryIncomes: primaryIncomes,
    totalSavingsAndEquity,
    targetRentPW: null,
    paymentDisplayRounded:
      widgetProperties.dataList[1].input.repaymentModel.interestRate * 100,
    interestPerMonth: (valuesDeposit?.debtOnProperty * 0.065) / 12,
    recommendedBorrowing,
    id: `purchase-${listPurchase.length + 1}`,
    years,
    purchaseDate: moment().format("MM/DD/YYYY"),
    dependant: null,
    interestRate:
      widgetProperties.dataList[1].input.repaymentModel.interestRate * 100,
    propertyPrice: valuesDeposit?.propertyPrice,
    rateSelected: valuesDeposit?.rateSelected,
    totalDepositBuyingCosts: valuesDeposit?.totalDepositBuyingCosts,
    rentPW: valuesDeposit?.rentPW,
    valuesDeposit,
  });
  income1 && (listPurchase[listPurchase.length - 1].income1 = income1);
  income2 && (listPurchase[listPurchase.length - 1].income2 = income2);
  if (newData?.indexChange != null) {
    const dataPurchaseTargetRedux = listPurchaseTarget;
    if (
      dataPurchaseTargetRedux.length >= newData?.indexChange &&
      newData?.indexChange > 0
    ) {
      for (let i = 0; i < newData?.indexChange; i++) {
        // Nếu index i vượt quá số phần tử của listPurchase, thêm phần tử mới từ dataPurchaseTargetRedux
        if (i >= listPurchase.length) {
          listPurchase.push({ ...dataPurchaseTargetRedux[i] }); // Thêm một đối tượng mới từ dataPurchaseTargetRedux
        } else {
          // Nếu phần tử đã tồn tại trong listPurchase, thì gán giá trị của dataPurchaseTargetRedux vào
          listPurchase[i] = Object.assign(
            listPurchase[i],
            dataPurchaseTargetRedux[i]
          );
        }

        if (listPurchase.length < newData?.indexChange) {
          const currentMasterTimeLine = calcMasterTimeLine({
            propertyPrice: listPurchase[i]?.propertyPrice,
            rentPW: listPurchase[i]?.rentPW,
            paymentPurchasePerMonth: listPurchase[i]?.interestPerMonth,
            debtOnProperty: listPurchase[i]?.loanAmount,
            availableEquity: listPurchase[i]?.equityFromPurchase,
            months: years * 12,
            deposit: listPurchase[i]?.deposit,
          });
          masterTimeLines.push(currentMasterTimeLine);
        }
      }
    }
  }
  while (recommendedBorrowing >= MIN_RECOMMENDED_BORROWING) {
    valueInputAssumptionsAtTargetStep = getValueInput(
      listPurchase[listPurchase.length - 1]
    );
    flag = true;
    let {
      propertyPrice,
      rateSelected,
      debtOnProperty,
      totalDepositBuyingCosts,
      rentPW,
      deposit,
    } = valueInputAssumptionsAtTargetStep?.valuesDeposit;

    let {
      livingExpenses,
      totalPrimaryIncome,
      recommendedBorrowing,
      interestPerMonth,
      income1,
      income2,
      widgetProperties,
      totalSavingsAndEquity,
    } = valueInputAssumptionsAtTargetStep;
    let oldNoOfMonth = null;
    let valueResult = {};
    let currentNoOfMonth = 0;

    //Add/edit IncomeType
    if (newData?.incomeType && listPurchase.length === newData?.indexChange) {
      incomeType =
        newData?.incomeType === INCOME_TYPE.SINGLE ? "single" : "couple";
      if (incomeType === INCOME_TYPE.COUPLE) {
        income1 = newData?.income1 ? newData?.income1 : totalPrimaryIncome;
        income2 = newData?.income2;
        customIncomes = [...customIncomes, []];
      }
    }
    if (newData?.customIncomes) {
      customIncomes = [...customIncomes, []];
    }
    // Step 3: Assumptions at target
    while (true) {
      if (oldNoOfMonth) {
        masterTimeLines.pop();
      }
      let {
        equityTarget,
        noOfMonth,
        valueAtTime,
        salaryAtTime,
        expensesPM,
        rentTracks,
        salaryAtTimeIncome1,
        salaryAtTimeIncome2,
        // totalIncome,
      } = assumptionsAtTargetStep({
        propertyPrice,
        livingExpenses,
        salary: totalPrimaryIncome,
        listPurchase,
        recommendedBorrowing,
        paymentPurchasePerMonth: interestPerMonth,
        masterTimeLines,
        rentPW,
        years,
        dataChange: newData,
        oldNoOfMonth,
        deposit,
        debtOnProperty,
        income1,
        income2,
        incomeType,
      });
      const totalNoOfMonth = sumBy(listPurchase, "noOfMonth");
      currentNoOfMonth = noOfMonth;
      if (totalNoOfMonth + noOfMonth >= years * 12) {
        break;
      }
      // Step 4: Find Equity Allowance
      let {
        availableEquity,
        yourBorrowingAmount,
        paymentDisplayRounded,
        newWidgetProperty,
        totalIncome,
        cashEquityNextPurchase,
        cashContribution,
      } = await findEquityAllowanceStep({
        equityTarget,
        widgetProperties,
        expensesPM,
        salaryAtTime,
        rentTracks,
        recommendedBorrowing,
        wiwo,
        rentPW,
        masterTimeLines,
        propertyPrice,
        paymentPurchasePerMonth: interestPerMonth,
        listPurchase,
        customIncomes,
        years,
        newData,
        debtOnProperty,
        totalDepositBuyingCosts,
        totalSavingsAndEquity,
        deposit,
        salaryAtTimeIncome1,
        salaryAtTimeIncome2,
        incomeType,
      });

      if (availableEquity) {
        valueResult = {
          equityTarget,
          noOfMonth,
          valueAtTime,
          salaryAtTime,
          expensesPM,
          rentTracks,
          salaryAtTimeIncome1,
          salaryAtTimeIncome2,

          availableEquity,
          yourBorrowingAmount,
          paymentDisplayRounded,
          newWidgetProperty,
          totalIncome,
          cashEquityNextPurchase,
          cashContribution,
        };
        break;
      }
      oldNoOfMonth = noOfMonth;
    }
    let {
      equityTarget,
      noOfMonth,
      valueAtTime,
      salaryAtTime,
      expensesPM,
      rentTracks,
      availableEquity,
      yourBorrowingAmount,
      paymentDisplayRounded,
      newWidgetProperty,
      totalIncome,
      cashEquityNextPurchase,
      cashContribution,
      salaryAtTimeIncome1,
      salaryAtTimeIncome2,
    } = valueResult;

    // const totalNoOfMonth =
    //   sumBy(listPurchase, "noOfMonth") + noOfMonth - listPurchase.length - 1;
    const totalNoOfMonth = sumBy(listPurchase, "noOfMonth");
    if (totalNoOfMonth + currentNoOfMonth >= years * 12) break;
    const cashAndEquityForNextPurchase = cashEquityNextPurchase;
    const prevPurchase = listPurchase[listPurchase.length - 1];

    const purchaseDate = moment(prevPurchase?.purchaseDate)
      .add(noOfMonth - 1, "month")
      .format("MM/DD/YYYY");
    // Step 6: Contributions for the next purchase
    // totalSavingsAndEquity = cashAndEquityForNextPurchase;
    recommendedBorrowing = yourBorrowingAmount;
    // totalPrimaryIncome = salaryAtTime;
    // livingExpenses = expensesPM;
    // widgetProperties = newWidgetProperty;
    // income1 = salaryAtTimeIncome1;
    // income2 = salaryAtTimeIncome2;

    recommendedBorrowing =
      recommendedBorrowing <= MAX_RECOMMENDED_BORROWING
        ? recommendedBorrowing
        : MAX_RECOMMENDED_BORROWING;
    // Step 2: Budget Options
    const newValues = budgetOptionsStep({
      recommendedBorrowing,
      totalSavingsAndEquity: cashAndEquityForNextPurchase,
      dataChange: newData,
      listPurchase,
      flag,
    });
    if (!newValues) return;
    const interestRateTemp =
      newWidgetProperty.dataList[1].input.repaymentModel.interestRate *
      (100).toFixed(2);
    const result = {
      noOfMonth,
      equityTarget,
      valueAtTime,
      salaryAtTime,
      expensesPM,
      rentTracks,
      newWidgetProperty,
      equityFromPurchase: availableEquity,
      deposit: 0,
      // Add/Edit cashContribution
      cashContribute: cashContribution,
      cashAndEquityForNextPurchase,
      totalIncome,
      incomeType,
      loanAmount: newValues?.debtOnProperty,
      primaryIncomes,
      totalSavingsAndEquity: cashAndEquityForNextPurchase,
      targetRentPW: Math.round(rentTracks.slice(-1)[0]),
      paymentDisplayRounded,
      interestPerMonth:
        (newValues?.debtOnProperty * interestRateTemp) / 100 / 12,
      recommendedBorrowing,
      id: `purchase-${listPurchase.length + 1}`,
      years,
      purchaseDate,
      dependant:
        widgetProperties.dataList[0].input.borrowingRequestModel.dependants,
      interestRate: interestRateTemp,
      valuesDeposit: newValues,
    };
    // Set new Interest perMonth
    // interestPerMonth =
    //   (newValues?.debtOnProperty * interestRateTemp) / 100 / 12;
    // Assign new value to valuesDeposit in step 2
    listPurchase.push(result);
    if (listPurchase.length > 0) {
      Object.assign(listPurchase[listPurchase.length - 1], {
        propertyPrice: newValues?.propertyPrice,
        rateSelected: newValues?.rateSelected,
        totalDepositBuyingCosts: newValues?.totalDepositBuyingCosts,
        rentPW: newValues?.rentPW,
        deposit: newValues?.deposit,
      });
      // assign input values ​​to a variable to declare the value
      income1 &&
        (listPurchase[listPurchase.length - 1].income1 = salaryAtTimeIncome1);
      income2 &&
        (listPurchase[listPurchase.length - 1].income2 = salaryAtTimeIncome2);
    }

    // valueInputAssumptionsAtTargetStep = getValueInputAssumptionsAtTargetStep(
    //   listPurchase[listPurchase.length - 1]
    // );
  }
  listPurchase.forEach((item) => {
    const widgetProperty = item?.newWidgetProperty;
    const wiwoBorrowingPower = widgetProperty?.dataList.find(
      (item) => item.id === "wiwo-borrowing-power"
    );
    const incomes =
      wiwoBorrowingPower.input.borrowingRequestModel.applicantList.map(
        (one) => one.incomeList
      );
    const primaryIncomes = incomes.map((incomeList) =>
      incomeList.find((income) => income.id === "primary")
    );
    if (primaryIncomes.length != 0) {
      primaryIncomes.forEach((income, index) => {
        item[`income${index + 1}`] = Math.round(income.income);
      });
    }
  });
  return listPurchase;
};
