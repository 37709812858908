import moment from "moment";
import { isEmpty } from "lodash";
import { Bar } from "react-chartjs-2";
import React, { memo, useMemo } from "react";
import { View, Text, StyleSheet } from "react-native";

//Functions
import { MAIN_COLORS } from "../../../../Utility/Colors";
import {
  formatDollar,
  formatDollarUnit,
  externalTooltipHandlerNew,
} from "../../../../Utility/common";
import {
  FONT_SIZE,
  FONT_WEIGHT,
  MAIN_FONT,
} from "../../../../Utility/Fonts/font";

const RetirementOverview = ({ result, lastRetirementOverviewDraff }) => {
  const filteredData = useMemo(() => {
    const firstYear = moment(result?.[0]?.date).year();

    let modifiedResult = result?.filter((item, index) => {
      const month = moment(item.date).month();
      const year = moment(item.date).year();

      return (
        (month === 11 && (year - firstYear) % 3 === 0) ||
        index === result.length - 1
      );
    });

    const lastItem = lastRetirementOverviewDraff;
    const lastItemIndex = modifiedResult?.length - 1;

    if (!isEmpty(lastItem) && lastItemIndex !== -1) {
      modifiedResult[lastItemIndex] = {
        ...modifiedResult[lastItemIndex],
        grossCFValue: lastItem.grossCFValue,
        equityValue: lastItem.equityValue,
        value: lastItem.value,
      };
    }

    modifiedResult = modifiedResult?.map((item, index) => {
      const value = item.value;
      return {
        ...item,
        grossCFValue: value * 0.25,
        equityValue: value * 0.25,
        value: value * 0.5,
        grossCFValueReal: item.grossCFValue,
        equityValueReal: item.equityValue,
        valueReal: item.value,
      };
    });

    return modifiedResult;
  }, [result, lastRetirementOverviewDraff]);

  const labels = filteredData?.map((item) => moment(item?.date).format("YYYY"));

  const maxGrossCF =
    filteredData &&
    Math.max(...filteredData?.map(({ grossCFValueReal }) => grossCFValueReal));
  const minGrossCF =
    filteredData &&
    Math.min(...filteredData?.map(({ grossCFValueReal }) => grossCFValueReal));

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        position: "nearest",
        callbacks: {
          label: (value) => formatDollar(value.raw.real),
        },
        external: (context) => {
          const backgroudColor =
            context?.tooltip?.dataPoints?.[0]?.dataset?.backgroundColor;

          return externalTooltipHandlerNew(
            context,
            { top: 4, right: 8, bottom: 4, left: 8 },
            "46px",
            backgroudColor
          );
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        border: { color: "#34393D" },
        stacked: true,
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          color: MAIN_COLORS.G600,
          font: { family: MAIN_FONT.regular },
          callback: (value, index) => {
            const firstYear = labels[0];
            if (!index) return 1;
            return labels[index] - firstYear + 1;
          },
        },
      },
      y: {
        position: "left",
        border: { dash: [3, 3] },
        grid: { color: "#34393D", drawTicks: false },
        stacked: true,
        beginAtZero: true,
        ticks: {
          color: MAIN_COLORS.G600,
          padding: 10,
          font: { family: MAIN_FONT.regular },
          callback: (value) => formatDollarUnit(value),
        },
      },
      y1: {
        beginAtZero: false,
        position: "right",
        stacked: true,
        max: maxGrossCF,
        min: minGrossCF > 0 ? 0 : minGrossCF,
        ticks: {
          color: MAIN_COLORS.G600,
          font: { family: MAIN_FONT.regular },
          callback: (value, index) => formatDollarUnit(value),
        },
      },
    },
  };

  const data = {
    datasets: [
      {
        data: filteredData?.map((one) => ({
          y: one.grossCFValue,
          real: one.grossCFValueReal,
          x: moment(one?.date).format("YYYY"),
        })),
        backgroundColor: MAIN_COLORS.GREEN,
        barPercentage: 0.6,
        maxBarThickness: 25,
      },
      {
        data: filteredData?.map((one) => ({
          y: one.equityValue,
          real: one.equityValueReal,
          x: moment(one?.date).format("YYYY"),
        })),
        backgroundColor: MAIN_COLORS.G600,
        barPercentage: 0.6,
        maxBarThickness: 25,
      },
      {
        data: filteredData?.map((one) => ({
          y: one.value,
          real: one.valueReal,
          x: moment(one?.date).format("YYYY"),
        })),
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        borderRadius: 18,
        barPercentage: 0.6,
        maxBarThickness: 25,
      },
    ],
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Overview</Text>
      <View style={styles.content}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={styles.chartTitle}>Value</Text>
          <Text style={styles.chartTitle}>Cash Flow</Text>
        </View>
        <View style={styles.chart}>
          <Bar data={data} options={options} height={"100%"} width={"100%"} />
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.footerItem}>
          <View
            style={[
              styles.footerDot,
              { backgroundColor: MAIN_COLORS.PRIMARY_COLOR },
            ]}
          />
          <Text style={styles.footerTitle}>Value</Text>
        </View>
        <View style={styles.footerItem}>
          <View
            style={[styles.footerDot, { backgroundColor: MAIN_COLORS.G600 }]}
          />
          <Text style={styles.footerTitle}>Equity</Text>
        </View>
        <View style={styles.footerItem}>
          <View
            style={[styles.footerDot, { backgroundColor: MAIN_COLORS.GREEN }]}
          />
          <Text style={styles.footerTitle}>Cash Flow</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#4B555D99",
    minWidth: 343,
    minHeight: 385,
    flex: 385 / 301,
  },

  title: {
    fontFamily: MAIN_FONT.semi,
    fontWeight: FONT_WEIGHT.bold,
    fontSize: FONT_SIZE.medium,
    lineHeight: 22,
    color: MAIN_COLORS.TEXT_LIGHT,
  },

  content: {
    flex: 1,
    marginVertical: 24,
  },

  chart: {
    marginTop: 16,
    flex: 1,
  },

  chartTitle: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: 500,
    fontSize: 10,
    lineHeight: 11,
    color: MAIN_COLORS.TEXT_LIGHT,
  },

  footer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  footerItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },

  footerDot: {
    width: 12,
    height: 12,
    borderRadius: "50%",
  },

  footerTitle: {
    fontFamily: MAIN_FONT.regular,
    fontWeight: FONT_WEIGHT.medium,
    fontSize: FONT_SIZE.small,
    lineHeight: 16,
    color: MAIN_COLORS.TEXT_LIGHT,
  },
});

export default memo(RetirementOverview);
